import React from "react";
import { Button } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";

function ComponenteOpcionesFiltroDisparador(props) {
  const [decision, setDecision] = React.useState();
  const [chips, setChips] = React.useState([]);
  const opcion = props.opciones;
  const agregarEtiqueta = (value) => {
    setChips([...chips, value]);
    setDecision([...chips, value]);
  };
  const removerEtiqueta = (index) => {
    const chip = chips;
    chip.splice(index, 1);
    setChips(chip);
  };
  React.useEffect(() => {}, []);
  const OpcionActual = () => {
    var x = null;
    if (!opcion) {
      return x;
    }
    switch (opcion.tipo) {
      case "select":
        x = (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={estilos.coint}>{opcion.indicaciones}</div>
            <select
              value={decision}
              onChange={(e) => {
                setDecision(e.target.value);
              }}
              className="seleccionar"
            >
              {opcion.opciones.map((res) => {
                return <option value={res}>{res}</option>;
              })}
            </select>
          </div>
        );
        break;
      case "number":
        x = (
          <div style={{ display: "flex", flexDirection: "column", justifyContent:"center" }}>
            <div style={estilos.coint}>{opcion.indicaciones}</div>
            <input
              style={estilos.input}
              value={decision}
              autoFocus
              type="number"
              min="1"
              onChange={(event) => {
                setDecision(event.target.value);
              }}
            />
          </div>
        );
        break;
      case "etiquetas":
        x = (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={estilos.coint}>{opcion.indicaciones}</div>
            <div>
              <div style={{display: "flex", flexDirection:"row", flexWrap: "wrap", maxWidth: 225, marginBottom: 10, marginTop: 5}}>
                {chips.map((tag, index) => {
                  return (
                    <Chip style={{margin:2, fontSize: 10}} label={tag} />
                  );
                })}
              </div>
              <input
                autoFocus
                style={{border: "1px solid rgb(232, 232, 232)", width:"100%", height:40, marginBottom: 10, textAlign: "center", outline: "none"}}
                placeholder="Escribe la etiqueta y luego ENTER"
                type="text"
                onKeyDown={(e) => {
                  e.key === "Enter" && agregarEtiqueta(e.target.value);
                }}
              />
            </div>
          </div>
        );
        break;
      default:
        break;
    }

    return x;
  };
  return (
    <div style={estilos.containe}>
      <OpcionActual  />
      <Button
        onClick={() => {
          props.setDecision(decision);
          props.setVistaactiva(0);
        }}
        style={{ marginTop: 5 }}
        variant="outlined"
        color="primary"
      >
        Agregar
      </Button>
    </div>
  );
}
const estilos = {
  coint: {
    maxWidth: 200,
    fontSize: 11,
    background: "rgb(224 247 231)",
    marginTop: 10,
    borderRadius: 3,
    padding: 10,
    marginBottom: 5,
  },
  input: {
    outline: "none",
    fontSize: 20,
    border: "1px solid rgb(232, 232, 232)",
    paddingTop: 5,
    paddingBottom: 5,
    textAlign: "center",
    marginTop: 5,
    marginBottom: 5
  },
  containe: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
};
export default ComponenteOpcionesFiltroDisparador;
