import { Form, Input, InputNumber, Radio, Select } from "antd";
import React from "react";
import { store } from "../../../../../store";
const { Option } = Select;

const CamposPersonalizados = (props) => {
  const refListaCriterioBusqueda = React.useRef(["email", "ide"]);
  const [valorCheckLimites, setvalorCheckLimites] = React.useState();
  const [valorCheckMontoFijo, setvalorCheckMontoFijo] = React.useState();
  const [valorCheckComision, setvalorCheckComision] = React.useState();
  const [valorCheckTrans, setValorCheckTrans] = React.useState();
  const [valorCheckDenomProp, setValorCheckDenomProp] = React.useState();
  const refMult = React.useRef();
  const [mult, setMult] = React.useState(false);
  const refFormatos = React.useRef(["Moneda", "Miles", "Ninguno"]);
  const refFiat = React.useRef([
    "AED",
    "AFN",
    "ALL",
    "AMD",
    "ANG",
    "AOA",
    "ARS",
    "AUD",
    "AWG",
    "AZN",
    "BAM",
    "BBD",
    "BDT",
    "BGN",
    "BHD",
    "BIF",
    "BMD",
    "BND",
    "BOB",
    "BRL",
    "BSD",
    "BTN",
    "BWP",
    "BYR",
    "BZD",
    "CAD",
    "CDF",
    "CHF",
    "CLP",
    "CNY",
    "COP",
    "CRC",
    "CUC",
    "CVE",
    "CZK",
    "DJF",
    "DKK",
    "DOP",
    "DZD",
    "EEK",
    "EGP",
    "ERN",
    "ETB",
    "EUR",
    "FJD",
    "FKP",
    "GBP",
    "GEL",
    "GHS",
    "GIP",
    "GMD",
    "GNF",
    "GQE",
    "GTQ",
    "GYD",
    "HKD",
    "HNL",
    "HRK",
    "HTG",
    "HUF",
    "IDR",
    "ILS",
    "INR",
    "IQD",
    "IRR",
    "ISK",
    "JMD",
    "JOD",
    "JPY",
    "KES",
    "KGS",
    "KHR",
    "KMF",
    "KPW",
    "KRW",
    "KWD",
    "KYD",
    "KZT",
    "LAK",
    "LBP",
    "LKR",
    "LRD",
    "LSL",
    "LTL",
    "LVL",
    "LYD",
    "MAD",
    "MDL",
    "MGA",
    "MKD",
    "MMK",
    "MNT",
    "MOP",
    "MRO",
    "MUR",
    "MVR",
    "MWK",
    "MXN",
    "MYR",
    "MZM",
    "NAD",
    "NGN",
    "NIO",
    "NOK",
    "NPR",
    "NZD",
    "OMR",
    "PAB",
    "PEN",
    "PGK",
    "PHP",
    "PKR",
    "PLN",
    "PYG",
    "QAR",
    "RON",
    "RSD",
    "RUB",
    "SAR",
    "SBD",
    "SCR",
    "SDG",
    "SEK",
    "SGD",
    "SHP",
    "SLL",
    "SOS",
    "SRD",
    "SYP",
    "SZL",
    "THB",
    "TJS",
    "TMT",
    "TND",
    "TRY",
    "TTD",
    "TWD",
    "TZS",
    "UAH",
    "UGX",
    "USD",
    "UYU",
    "UZS",
    "VEB",
    "VND",
    "VUV",
    "WST",
    "XAF",
    "XCD",
    "XDR",
    "XOF",
    "XPF",
    "YER",
    "ZAR",
    "ZMK",
    "ZWR",
  ]);
  const refCripto = React.useRef([
    "Bitcoin BTC",
    "Ethereum, Ether ETH",
    "Dogecoin DOGE",
    "Cardano ADA",
    "Litecoin LTC",
    "Binance Coin BNB",
    "Polkadot DOT",
    "Ripple XRP",
    "BitBay",
    "Stellar XLM",
    "Bitcoin cash BCH",
    "Chainlink LINK",
    "IOTA MIOTA",
    "Monero",
    "NEO",
    "Tether USDT",
    "Tezos XTZ",
    "THETA",
    "Uniswap UNI",
    "USD Coin USDC",
  ]);

  const refMonedas = React.useRef(refFiat.current.concat(refCripto.current));
  const [formatoEscogido, setFormatoEscogido] = React.useState();
  const refMontoFijo = React.useRef();

  React.useEffect(() => {
    setValorCheckTrans(props.formulario.getFieldsValue().usarTransferir);
    setFormatoEscogido(props.formulario.getFieldsValue().formato);
    setValorCheckDenomProp(
      props.formulario.getFieldsValue().usarDenominacionPropia
    );
  }, [props]);

  React.useEffect(() => {
    setvalorCheckLimites(props.formulario.getFieldsValue().usarLimites);
    refMontoFijo.current = props.formulario.getFieldsValue().usarMontoFijo;
    setvalorCheckMontoFijo(refMontoFijo.current);
    setvalorCheckComision(props.formulario.getFieldsValue().usarComision);
    if (props.formulario.getFieldsValue().usarMontoFijo == "true") {
      refMult.current = true;
      setMult(refMult.current);
    }
  }, [valorCheckTrans]);

  return (
    <>
      <Form.Item
        label="Atributo"
        name="id_atributo"
        rules={[
          {
            required: true,
            message: "Seleccionar un atributo o una operación",
          },
        ]}
        hasFeedback
      >
        <Select placeholder="Seleccionar un atributo o una operación">
          {store.losatributos.numbers.map((atri) => {
            return (
              <Option key={atri._id} value={atri._id}>
                {atri.nombre}
              </Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item
        label="Formato del atributo número"
        name="formato"
        rules={[
          {
            required: true,
            message: "Seleccionar una opción",
          },
        ]}
        hasFeedback
      >
        <Select
          placeholder="Seleccionar un formato"
          onChange={(e) => setFormatoEscogido(e)}
        >
          {refFormatos.current.map((formato, index) => {
            return (
              <Option key={index} value={formato}>
                {formato}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      {formatoEscogido == "Moneda" && (
        <Form.Item
          label="Escoge la moneda para formatear el atributo"
          name="moneda"
          rules={[
            {
              required: true,
              message: "Seleccionar una opción",
            },
          ]}
          hasFeedback
        >
          <Select placeholder="Seleccionar una moneda">
            {refMonedas.current.map((formato, index) => {
              return (
                <Option key={index} value={formato}>
                  {formato}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      )}

      <Form.Item
        name="nombreBoton"
        label="Nombre del botón"
        rules={[
          {
            required: true,
            message: "Atributo obligatorio",
          },
        ]}
      >
        <Input
          placeholder="Por favor escoge un nombre para tu botón"
          style={{ width: "100%" }}
        />
      </Form.Item>

      <Form.Item
        name="usarDenominacionPropia"
        label="¿Deseas usar una denominación propia para tu atributo?"
        rules={[
          {
            required: true,
            message: "Atributo obligatorio",
          },
        ]}
      >
        <Radio.Group
          options={[
            { label: "Si", value: "true" },
            { label: "No", value: "false" },
          ]}
          onChange={(e) => {
            setValorCheckDenomProp(e.target.value);
          }}
          value={valorCheckDenomProp}
          optionType="button"
        />
      </Form.Item>

      {valorCheckDenomProp == "true" && (
        <div style={{ display: "flex", alignItems: "start" }}>
          <Form.Item
            name="prefijo"
            label="Ingresa un prefijo"
            rules={[
              {
                required: false,
                message: "Atributo obligatorio",
              },
            ]}
          >
            <Input
              placeholder="Ingresa tu propia denominación"
              style={{ float: "left", width: "100%" }}
            ></Input>
          </Form.Item>

          <Form.Item
            name="sufijo"
            label="Ingresa un sufijo"
            rules={[
              {
                required: false,
                message: "Atributo obligatorio",
              },
            ]}
          >
            <Input
              placeholder="Ingresa tu propia denominación"
              style={{ float: "left", width: "100%" }}
            ></Input>
          </Form.Item>
        </div>
      )}

      <Form.Item
        name="usarTransferir"
        label="¿Deseas permtir tranferencia de unidades?"
        rules={[
          {
            required: true,
            message: "Atributo obligatorio",
          },
        ]}
      >
        <Radio.Group
          options={[
            { label: "Si", value: "true" },
            { label: "No", value: "false" },
          ]}
          onChange={(e) => {
            setValorCheckTrans(e.target.value);
          }}
          value={valorCheckTrans}
          optionType="button"
        />
      </Form.Item>
      {valorCheckTrans == "true" && (
        <div>
          <Form.Item
            label="¿Como deseas que encuentren a tus afiliados?"
            name="criterioBusqueda"
            rules={[
              {
                required: true,
                message: "Seleccionar un criterio de búsqueda",
              },
            ]}
            hasFeedback
          >
            <Select placeholder="Seleccionar un criterio de búsqueda">
              {refListaCriterioBusqueda.current.map((atri) => {
                return (
                  <Option key={atri} value={atri}>
                    {atri}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="usarLimites"
            label="¿Deseas agregar límites a la cantidad?"
            rules={[
              {
                required: true,
                message: "Atributo obligatorio",
              },
            ]}
          >
            <Radio.Group
              options={[
                { label: "Si", value: "true", disabled: mult },
                { label: "No", value: "false" },
              ]}
              onChange={(e) => {
                setvalorCheckLimites(e.target.value);
              }}
              value={valorCheckLimites}
              optionType="button"
            />
          </Form.Item>

          {valorCheckLimites == "true" && valorCheckMontoFijo != "true" && (
            <div style={{ display: "flex", alignItems: "start" }}>
              <Form.Item
                name="montoMinimo"
                label="Monto mínimo a transferir"
                style={{ marginRight: "10px" }}
                rules={[
                  {
                    required: true,
                    message: "Atributo obligatorio",
                  },
                ]}
              >
                <InputNumber
                  defaultValue={0}
                  style={{ float: "left", width: "100%" }}
                ></InputNumber>
              </Form.Item>
              <Form.Item
                name="montoMaximo"
                label="Monto máximo a transferir"
                rules={[
                  {
                    required: true,
                    message: "Atributo obligatorio",
                  },
                ]}
              >
                <InputNumber
                  defaultValue={0}
                  style={{ float: "left", width: "100%" }}
                ></InputNumber>
              </Form.Item>
            </div>
          )}

          <Form.Item
            name="usarMontoFijo"
            label="¿Deseas utilizar un monto fijo para tu cantidad?"
            rules={[
              {
                required: true,
                message: "Atributo obligatorio",
              },
            ]}
          >
            <Radio.Group
              options={[
                { label: "Si", value: "true" },
                { label: "No", value: "false" },
              ]}
              onChange={(e) => {
                if (e.target.value == "true") {
                  setvalorCheckMontoFijo(e.target.value);

                  setMult(true);
                } else {
                  setvalorCheckMontoFijo(e.target.value);
                  setMult(false);
                }
              }}
              value={valorCheckMontoFijo}
              optionType="button"
            />
          </Form.Item>
          {valorCheckMontoFijo == "true" && (
            <div style={{ display: "flex", alignItems: "start" }}>
              <Form.Item
                name="montoFijoTransferir"
                label="Cantidad a ser transferida"
                rules={[
                  {
                    required: true,
                    message: "Atributo obligatorio",
                  },
                ]}
              >
                <InputNumber
                  defaultValue={0}
                  style={{ float: "left", width: "100%" }}
                ></InputNumber>
              </Form.Item>
            </div>
          )}

          <Form.Item
            name="usarComision"
            label="¿Deseas cobrar alguna comisión por transferencia?"
            rules={[
              {
                required: true,
                message: "Atributo obligatorio",
              },
            ]}
          >
            <Radio.Group
              options={[
                { label: "Si", value: "true" },
                { label: "No", value: "false" },
              ]}
              onChange={(e) => {
                setvalorCheckComision(e.target.value);
              }}
              value={valorCheckComision}
              optionType="button"
            />
          </Form.Item>
          {valorCheckComision == "true" && (
            <div style={{ display: "flex", alignItems: "start" }}>
              <Form.Item
                name="porcentajeComision"
                label="Porcentaje de comisión"
                rules={[
                  {
                    required: true,
                    message: "Atributo obligatorio",
                  },
                ]}
              >
                <InputNumber
                  min={0}
                  placeholder="Ingresa el porcentaje de comisión"
                  style={{ float: "left", width: "100%" }}
                ></InputNumber>
              </Form.Item>
            </div>
          )}

          {valorCheckComision == "true" && (
            <div style={{ display: "flex", alignItems: "start" }}>
              <Form.Item
                name="cantidadComision"
                label="Valor de la comisión"
                rules={[
                  {
                    required: true,
                    message: "Atributo obligatorio",
                  },
                ]}
              >
                <InputNumber
                  min={0}
                  placeholder="Ingresa el valor de la comisión"
                  style={{ float: "left", width: "100%" }}
                ></InputNumber>
              </Form.Item>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CamposPersonalizados;
