import React from "react";

import { store } from "../../store";

function InfoHelperModuloComponente({ modulo }) {
  const [informacion, setInformacion] = React.useState([]);
  function fomatearTexto(texto) {
    const textoSinFormatear = texto;

    // sacar las palabras encerradas en {{}}
    function searchByC(string, encierro) {
      const reg = new RegExp(`\\w*(?=${encierro})`, "g");
      const texto = string.match(reg);
      //   eliminar los espacios en blanco

      const textoSinEspacios = texto?.filter((texto) => {
        return texto !== "";
      });

      return textoSinEspacios || [];
    }

    const parametrosUsuario = searchByC(textoSinFormatear, "}");
    const parametrosCuenta = searchByC(textoSinFormatear, "}}");
    const parametrosFormulario = searchByC(textoSinFormatear, "]");

    var textoFormateado = texto;
    parametrosCuenta.forEach((element) => {
      textoFormateado = textoFormateado.replace(
        `{{${element}}}`,
        store.cuenta[element] || "NoDefinido"
      );
    });
    parametrosUsuario.forEach((element) => {
      textoFormateado = textoFormateado.replace(
        `{${element}}`,
        store.afiliado[element]
      );
    });

    parametrosFormulario.forEach((element) => {
      textoFormateado = textoFormateado.replace(
        `[${element}]`,
        modulo.campos.find((e) => e.name === element).value
      );
    });
    return textoFormateado;
  }
  React.useEffect(() => {
    const info = [...modulo.informacion];
    info.forEach((element) => {
      element.titulo = fomatearTexto(element.titulo);
      element.descripcion = fomatearTexto(element.descripcion);
    });
    setInformacion(info);
  }, [modulo]);
  return (
    <div>
      {informacion.map((info, index) => {
        return (
          <div key={index}>
            <h1>{info.titulo}</h1>
            <p>{info.descripcion}</p>
          </div>
        );
      })}
    </div>
  );
}

export default InfoHelperModuloComponente;
