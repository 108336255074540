import React from "react";
import { ButtonT } from "../../../../../plugins/Formularios/FormularioTecclas";
import ListPreguntas from "./ComponentesCamposPersonalizados/ListPreguntas";
import SegundoPaso from "./ComponentesCamposPersonalizados/SegundoPaso";

const CamposPersonalizados = (props) => {
  const [bandera, setBandera] = React.useState(false);

  function click() {
    setBandera(!bandera);
  }

  return (
    <>
      <div hidden={bandera}>
        <ListPreguntas></ListPreguntas>
      </div>
      <div hidden={!bandera}>
        <SegundoPaso></SegundoPaso>
        <div
          style={{
            padding: "20px",
            marginTop: "10px",
            display:"flex",
            justifyContent:"center"
          }}
        >
          <ButtonT type="primary" htmlType="submit" style={{marginRight:"10px"}}>
            Guardar
          </ButtonT>
          {/* <button style={{ marginRight: "30px" }} type="submit">
            Guardar
          </button> */}
          <ButtonT type="secondary" onClick={() => props.setVisible(false)}>
            Cancelar
          </ButtonT>
          {/* <button onClick={() => props.setVisible(false)}>Cancelar</button> */}
        </div>
      </div>
      <div
        style={{
          padding: "10px",
          marginTop: "10px",
          display:"flex",
          justifyContent:"center"
        }}
      >
        <ButtonT type="secondary" onClick={() => click()} hidden={!bandera}>
          Anterior
        </ButtonT>
        <ButtonT type="secondary" onClick={() => click()} hidden={bandera}>
          Siguiente
        </ButtonT>
        {/* <button onClick={() => click()} hidden={!bandera}>
          Anterior
        </button>
        <button onClick={() => click()} hidden={bandera}>
          Siguiente
        </button> */}
      </div>
    </>
  );
};

export default CamposPersonalizados;
