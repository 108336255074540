import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table } from "antd";
import Boton from "../../../../design/elementos/boton";
function ListaPedidosWoocommerce({ pedidos, setMostrarPedidos, params }) {
  function traducir(status) {
    var text = "";
    switch (status) {
      case "pending":
        text = "Pendiente de pago";
        break;
      case "on-hold":
        text = "Esperando confirmación";
        break;
      case "processing":
        text = "Pago recibido";
        break;
      case "completed":
        text = "Completado";
        break;
      case "cancelled":
        text = "Cancelado";
        break;
      default:
        text = status;
        break;
    }
    return text;
  }
  const columns = [
    {
      title: "Numero",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Fecha",
      dataIndex: "date_created",
      key: "date_created",
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
      render: (status) => <strong>{traducir(status)}</strong>,
    },
    {
      title: "",
      dataIndex: "status",
      key: "opciones",
      render: (status, pedido) => (
        <div>
          {status === "pending" && (
            <Boton
              onClick={() => {
                window.open(
                  `${pedido.tiendaurl}/${
                    pedido.paginaCompra || "finalizar-compra"
                  }/order-pay/${pedido.id}/?pay_for_order=true&key=${
                    pedido.order_key
                  }`
                );
              }}
              texto="Pagar"
            />
          )}
        </div>
      ),
    },
  ];
  return (
    <div>
      <div
        onClick={() => {
          setMostrarPedidos(false);
        }}
        style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
        <span style={{ marginLeft: 10 }}>Volver</span>
      </div>
      <div style={{ marginTop: 15 }}>
        <Table dataSource={pedidos} columns={columns} />;
      </div>
    </div>
  );
}

export default ListaPedidosWoocommerce;
