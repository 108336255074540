import React from "react";
import { DatePicker, Form, Input } from "antd";
const { RangePicker } = DatePicker;

const RangoFechas = (props) => {
 
React.useEffect(() => {
  console.log(props, "lajsdhdkjashd")
}, [props])
  return (
    <>
      <Form.Item
        name={props.rangoFechas}
        label={props.labelRangoFechas}
        rules={[
          {
            required: true,
            message: "Debes seleccionar un rango de fechas",
          },
        ]}
      >
          <RangePicker />
      </Form.Item>
    </>
  );
};

export default RangoFechas;
