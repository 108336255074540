import axios from "axios";
import { message, notification } from "antd";

// desarrollo

const config = {
  URI: process.env.REACT_APP_API_API,
};

// produccion

// const config = {
//   URI: "https://anqnletz7c.execute-api.us-east-1.amazonaws.com/prod/api/",
// };

const instance = axios.create({
  baseURL: config.URI,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    "Content-Type": "application/json",
  },
});

function dataxx() {
  function numeroRandom100to150() {
    return Math.floor(Math.random() * (450 - 100 + 1)) + 100;
  }
  var text = "";
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrs+tuv/wxyz0123456789";
  for (var i = 0; i < numeroRandom100to150(); i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  return text;
}

const Api = async function (collection, type, datos, accion, url) {
  var dateds = new Date();
  // console.log(collection, type, datos, accion);
  return new Promise(async (resp, err) => {
    // console de la hora actual para ver cuando se hace la peticion
    // instance.defaults.baseURL = url ? `${url}/api/` : config.URI; //COMENTAR ESTO CADA VEZ QUE VOY A HACER PUSH

    var data = await window.x898(instance, "", type, datos, accion, dateds);

    instance
      .post(collection, {
        a: dataxx() + "=",
        b: dataxx() + "=",
        c: dataxx() + "=",
        d: dataxx(),
        f: dataxx() + "=",
        g: dataxx() + "=",
        h: data,
        i: dataxx() + "=",
        j: dataxx() + "=",
        k: dataxx(),
        z: dateds,
      })
      .then(function (response) {
        if (response.data.hasOwnProperty("data")) {
          resp(response.data.data);
          if (response.data.success && response.data.msg) {
            notification["success"]({
              message: "Procesado",
              description: response.data.msg,
            });
          } else if (!response.data.success) {
            notification["error"]({
              message: "Algo salio mal",
              description:
                response.data.msg ||
                "No pudimos procesar la solicitud. Intentalo nuevamente o contacta con soporte",
            });
          }
        } else {
          if (response.data?.error === "auth") {
            message.error("Algo no salio bien ... . ..");
          } else {
            resp(response.data);
          }
        }
      })
      .catch(function (error) {
        console.log("pOruqe...");
        console.log(error);
        err(error);
      });
  });
};

const Auth = async function (tipo, datos) {
  var datess = new Date();
  var data = await window.x898(instance, "", "type", datos, {}, datess);
  return instance.post(tipo, {
    a: dataxx() + "=",
    b: dataxx() + "=",
    c: dataxx() + "=",
    d: dataxx(),
    f: dataxx() + "=",
    g: dataxx() + "=",
    h: data,
    i: dataxx() + "=",
    j: dataxx() + "=",
    k: dataxx(),
    z: datess,
  });
};

export { Api, Auth };
