import { faMagic } from "@fortawesome/free-solid-svg-icons";
import { DatePicker, Form, Input, InputNumber, Select } from "antd";
import React from "react";

const CompararAtributos = (props) => {
  return (
    <>
      <Form.Item shouldUpdate noStyle>
        {(formulario) => {
          return (
            <>
              <Form.Item
                name={props.operacionRelacional}
                label={props.labelOperacionRelacional}
                rules={[
                  {
                    required: true,
                    message: "Debes seleccionar una opción",
                  },
                ]}
              >
                <Select placeholder="Seleccionar una opción">
                  {formulario
                    .getFieldsValue()
                    .atributo?.slice(
                      formulario.getFieldsValue().atributo.indexOf("-") + 1
                    ) == "text" ? (
                    <>
                      <Select.Option value="$eq">Igual a</Select.Option>
                      <Select.Option value="$ne">Diferente de</Select.Option>
                    </>
                  ) : (
                    <>
                      <Select.Option value="$gt">Mayor que</Select.Option>
                      <Select.Option value="$lt">Menor que</Select.Option>
                      <Select.Option value="$gte">
                        Mayor igual que
                      </Select.Option>
                      <Select.Option value="$lte">
                        Menor igual que
                      </Select.Option>
                      <Select.Option value="$eq">Igual a</Select.Option>
                      <Select.Option value="$ne">Diferente de</Select.Option>
                    </>
                  )}
                </Select>
              </Form.Item>
            </>
          );
        }}
      </Form.Item>

      <Form.Item shouldUpdate noStyle>
        {(formulario) => {
          if (!formulario.getFieldsValue()[props.nombreAtributo]) {
            return null;
          }

          return (
            <>
              {formulario.getFieldsValue()[props.nombreAtributo] &&
                formulario
                  .getFieldsValue()
                  [props.nombreAtributo].slice(
                    formulario
                      .getFieldsValue()
                      [props.nombreAtributo].indexOf("-") + 1
                  ) == "number" && (
                  <Form.Item
                    name={props.valorAtributo}
                    label={props.labelValorAtributo}
                    rules={[
                      {
                        required: true,
                        message: "Debes ingresar un número",
                      },
                    ]}
                  >
                    <InputNumber placeholder="Ingresa un número" />
                  </Form.Item>
                )}
              {formulario.getFieldsValue()[props.nombreAtributo] &&
                formulario
                  .getFieldsValue()
                  [props.nombreAtributo].slice(
                    formulario
                      .getFieldsValue()
                      [props.nombreAtributo].indexOf("-") + 1
                  ) == "text" && (
                  <Form.Item
                    name={props.valorAtributo}
                    label={props.labelValorAtributo}
                    rules={[
                      {
                        required: true,
                        message: "Debes ingresar un dato",
                      },
                    ]}
                  >
                    <Input placeholder="Ingresa un dato" />
                  </Form.Item>
                )}
              {formulario.getFieldsValue()[props.nombreAtributo] &&
                formulario
                  .getFieldsValue()
                  [props.nombreAtributo].slice(
                    formulario
                      .getFieldsValue()
                      [props.nombreAtributo].indexOf("-") + 1
                  ) == "date" && (
                  <Form.Item
                    name="fechaUnica"
                    label={props.labelValorAtributo}
                    rules={[
                      {
                        required: true,
                        message: "Debes ingresar una fecha",
                      },
                    ]}
                  >
                    <DatePicker />
                  </Form.Item>
                )}
            </>
          );
        }}
      </Form.Item>
    </>
  );
};

export default CompararAtributos;
