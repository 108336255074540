import { Button, Drawer, Form, Input, Popover } from "antd";
import React from "react";
import FormatoComponente from "./FormatoComponente";
import datos from "../datos";
import IndexGrupoCampos from "../grupoCampos/IndexGrupoCampos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestion,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

function CamposPersonalizados(props) {
  const [cambioDisplay, setCambioDisplay] = React.useState(false);

  const refFiat = React.useRef([
    "AED",
    "AFN",
    "ALL",
    "AMD",
    "ANG",
    "AOA",
    "ARS",
    "AUD",
    "AWG",
    "AZN",
    "BAM",
    "BBD",
    "BDT",
    "BGN",
    "BHD",
    "BIF",
    "BMD",
    "BND",
    "BOB",
    "BRL",
    "BSD",
    "BTN",
    "BWP",
    "BYR",
    "BZD",
    "CAD",
    "CDF",
    "CHF",
    "CLP",
    "CNY",
    "COP",
    "CRC",
    "CUC",
    "CVE",
    "CZK",
    "DJF",
    "DKK",
    "DOP",
    "DZD",
    "EEK",
    "EGP",
    "ERN",
    "ETB",
    "EUR",
    "FJD",
    "FKP",
    "GBP",
    "GEL",
    "GHS",
    "GIP",
    "GMD",
    "GNF",
    "GQE",
    "GTQ",
    "GYD",
    "HKD",
    "HNL",
    "HRK",
    "HTG",
    "HUF",
    "IDR",
    "ILS",
    "INR",
    "IQD",
    "IRR",
    "ISK",
    "JMD",
    "JOD",
    "JPY",
    "KES",
    "KGS",
    "KHR",
    "KMF",
    "KPW",
    "KRW",
    "KWD",
    "KYD",
    "KZT",
    "LAK",
    "LBP",
    "LKR",
    "LRD",
    "LSL",
    "LTL",
    "LVL",
    "LYD",
    "MAD",
    "MDL",
    "MGA",
    "MKD",
    "MMK",
    "MNT",
    "MOP",
    "MRO",
    "MUR",
    "MVR",
    "MWK",
    "MXN",
    "MYR",
    "MZM",
    "NAD",
    "NGN",
    "NIO",
    "NOK",
    "NPR",
    "NZD",
    "OMR",
    "PAB",
    "PEN",
    "PGK",
    "PHP",
    "PKR",
    "PLN",
    "PYG",
    "QAR",
    "RON",
    "RSD",
    "RUB",
    "SAR",
    "SBD",
    "SCR",
    "SDG",
    "SEK",
    "SGD",
    "SHP",
    "SLL",
    "SOS",
    "SRD",
    "SYP",
    "SZL",
    "THB",
    "TJS",
    "TMT",
    "TND",
    "TRY",
    "TTD",
    "TWD",
    "TZS",
    "UAH",
    "UGX",
    "USD",
    "UYU",
    "UZS",
    "VEB",
    "VND",
    "VUV",
    "WST",
    "XAF",
    "XCD",
    "XDR",
    "XOF",
    "XPF",
    "YER",
    "ZAR",
    "ZMK",
    "ZWR",
  ]);
  const refCripto = React.useRef([
    "Bitcoin BTC",
    "Ethereum, Ether ETH",
    "Dogecoin DOGE",
    "Cardano ADA",
    "Litecoin LTC",
    "Binance Coin BNB",
    "Polkadot DOT",
    "Ripple XRP",
    "BitBay",
    "Stellar XLM",
    "Bitcoin cash BCH",
    "Chainlink LINK",
    "IOTA MIOTA",
    "Monero",
    "NEO",
    "Tether USDT",
    "Tezos XTZ",
    "THETA",
    "Uniswap UNI",
    "USD Coin USDC",
  ]);

  const [visible, setVisible] = React.useState(false);

  const [nombreFuncion, setNombreFuncion] = React.useState(props.funcionActual);
  const [descripcionFuncion, setDescripcionFuncion] = React.useState(
    props.descripcionFuncion
  );
  const [tituloFuncion, setTituloFuncion] = React.useState(
    props.tituloFuncionActual
  );

  const [bool, setBool] = React.useState(false);

  const [indice, setIndice] = React.useState(null);

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  React.useEffect(() => {
    if (props.datosApi.nombreFuncion) {
      setNombreFuncion(props.datosApi.nombreFuncion);
      setDescripcionFuncion(props.datosApi.descripcionFuncion);
      setTituloFuncion(props.datosApi.tituloFuncion);
    }

  }, [props]);

  return (
    <>
      <Form.Item name="nombreFuncion" hidden></Form.Item>
      <Form.Item name="tituloFuncion" hidden></Form.Item>
      <Form.Item name="descripcionFuncion" hidden></Form.Item>
      <div style={cambioDisplay ? { display: "none" } : { display: "block" }}>
        {nombreFuncion && (
          <div>
            <div style={{display:"flex", alignItems:"start"}}>
              <h2>{tituloFuncion}</h2>
              <Popover
                content={<p style={{ width: "300px" }}>{descripcionFuncion}</p>}
                trigger="hover"
              >
                <FontAwesomeIcon
                  style={{ marginRight: 15, marginLeft: 4, paddingTop:"10px", paddingLeft:"10px" }}
                  icon={faQuestionCircle}
                />
              </Popover>
            </div>
            <Form.Item
              label="Nombre"
              name="nombre"
              hasFeedback
              rules={[{ required: true, message: "Ingresa un nombre" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="_id" hidden rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="dinamico" hidden rules={[{ required: true }]}>
              <Input />
            </Form.Item>

            <Form.Item
              label="Titulo"
              name="titulo"
              hasFeedback
              rules={[{ required: true, message: "Ingresa un titulo" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Descripción"
              name="descripcion"
              hasFeedback
              rules={[{ required: true, message: "Ingresa una descripción" }]}
            >
              <Input />
            </Form.Item>
          </div>
        )}
        {nombreFuncion &&
          datos
            .find((consulta) => {
              return consulta.nombreFuncion == nombreFuncion;
            })
            .grupoCampos.map((campo, index) => {
              return <IndexGrupoCampos key={index} consulta={campo} />;
            })}
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          type="secondary"
          onClick={() => {
            showDrawer();
          }}
          style={cambioDisplay ? { display: "none" } : { display: "block" }}
        >
          Disparar indicadores personalizados
        </Button>
        <Drawer
          width="70%"
          title="Elige una consulta"
          placement="right"
          onClose={onClose}
          visible={visible}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "start",
            }}
          >
            {datos.map((funcion, index) => {
              return (
                <div
                  key={index}
                  style={{
                    backgroundColor: "#FBFBFD",
                    padding: "20px 20px 5px 20px",
                    borderRadius: "10px",
                    margin: "10px",
                    maxWidth: "300px",
                  }}
                >
                  <h4
                    style={{
                      textAlign: "left",
                      fontWeight: "bold",
                      cursor: "pointer",
                      marginBottom: "10px",
                    }}
                    onClick={(e) => {
                      // props.formulario.resetFields();
                      // props.formulario.setFieldsValue({
                      //   descripcion: props.datosApi.descripcion,
                      //   nombre: props.datosApi.nombre,
                      //   titulo: props.datosApi.titulo,
                      //   _id: props.datosApi._id,
                      //   dinamico: props.datosApi.dinamico,
                      //   fechaCreacion: props.datosApi.fechaCreacion,
                      //   limiteCantidad: props.datosApi.limiteCantidad,
                      //   limiteWidth: props.datosApi.limiteWidth,
                      //   format: props.datosApi.format,
                      // });

                      setNombreFuncion(funcion.nombreFuncion);
                      setTituloFuncion(funcion.tituloFuncion);
                      setDescripcionFuncion(funcion.descripcionFuncion);
                      props.formulario.setFieldsValue({
                        nombreFuncion: funcion.nombreFuncion,
                        tituloFuncion: funcion.tituloFuncion,
                        descripcionFuncion: funcion.descripcionFuncion,
                      });
                      onClose();
                    }}
                  >
                    {funcion.tituloFuncion}
                  </h4>
                  <p style={{ textAlign: "left", fontWeight: "300" }}>
                    {!(indice == index)
                      ? funcion.descripcionFuncion.substring(0, 60) + " ..."
                      : funcion.descripcionFuncion}
                    {!(indice == index) ? (
                      <p
                        style={{
                          cursor: "pointer",
                          fontWeight: "400",
                          marginTop: "10px",
                        }}
                        onClick={() => setIndice(index)}
                      >
                        Ver más
                      </p>
                    ) : (
                      <p
                        style={{
                          cursor: "pointer",
                          fontWeight: "400",
                          marginTop: "10px",
                        }}
                        onClick={() => setIndice(null)}
                      >
                        Ver menos
                      </p>
                    )}
                  </p>
                </div>
              );
            })}
          </div>
        </Drawer>
      </div>

      <div style={!cambioDisplay ? { display: "none" } : { display: "block" }}>
        <FormatoComponente />
      </div>

      <div
        style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}
      >
        <Button
          disabled={!cambioDisplay}
          style={{ marginRight: "20px" }}
          onClick={() => setCambioDisplay(!cambioDisplay)}
        >
          Anterior
        </Button>
        {!cambioDisplay ? (
          <>
            <Button
              disabled={cambioDisplay}
              type="primary"
              onClick={() => setCambioDisplay(!cambioDisplay)}
            >
              Siguiente
            </Button>
          </>
        ) : (
          <>
            <Button
              loading={props.guardar}
              onClick={() => {
                setNombreFuncion(
                  props.formulario.getFieldsValue().nombreFuncion
                );
                console.log(props.formulario.getFieldsValue(), "formuuuuu")
                props.formulario.submit();
              }}
            >
              Guardar
            </Button>
          </>
        )}
      </div>
    </>
  );
}

export default CamposPersonalizados;
