import React from "react";
import { Api } from "../../api/configApi";
import { store } from "../../store";
import CardModuloComponente from "./CardModuloComponente";

function ListaModulosDisponibles({ modulos, onClose }) {
  const [modulosDisponibles, setModulosDisponibles] = React.useState([]);
  React.useEffect(() => {
    Api("modulosDisponibles", "Get", { version: store.version }).then((res) => {
      if (res.sucess) {
        setModulosDisponibles(res.result);
      }
    });
  }, []);
  function validarLimiteInstalaciones(mod) {
    const match = modulos.filter(m => { return m.nombre === mod.nombre });
    if (match.length >= mod.maxInstalaciones) {
      return true
    } else {
      return false;
    }
  }
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      {modulosDisponibles.map((mod, index) => {
        return <CardModuloComponente key={index} onClose={onClose} alcanzoLimite={validarLimiteInstalaciones(mod)} key={"jas" + index} modulo={mod} />
      })}
    </div>
  );
}

export default ListaModulosDisponibles;
