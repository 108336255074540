import { Button, Drawer, Form, Input } from "antd";
import React from "react";
import { Api } from "../../../api/configApi";

function AgregarNuevoItemDocumentacion() {
  const [visible, setVisible] = React.useState(false);
  const [form] = Form.useForm();

  function nuevoItem(values) {
    Api("documentacion", "nuevoItem", values).then((data) => {
      if (data.success) {
        setVisible(false);
        form.resetFields();
      }
    });
  }

  return (
    <div style={{ textAlign: "right" }}>
      <Button onClick={() => setVisible(true)}> Agregar Item</Button>
      <Drawer
        placement="left"
        visible={visible}
        onClose={() => setVisible(false)}
      >
        <Form form={form} layout="vertical" onFinish={nuevoItem}>
          <Form.Item
            rules={[{ required: true, message: "Campo obligatorio" }]}
            name="titulo"
            label="Titulo"
          >
            <Input />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "Campo obligatorio" }]}
            name="linkVideo"
            label="link Video"
          >
            <Input />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "Campo obligatorio" }]}
            name="ubicacion"
            label="Ubicacion"
          >
            <Input />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "Campo obligatorio" }]}
            name="tags"
            label="Tags"
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Agregar
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
}

export default AgregarNuevoItemDocumentacion;
