import { Form, Input } from "antd";
import { store } from "../../../../../store";
import Boton from "../../../../design/elementos/boton";

function FormularioFacturacion({ comprar, creandoPedido }) {
  return (
    <Form onFinish={comprar} layout="vertical">
      <Form.Item
        rules={[{ required: true, message: "Campo obligatorio" }]}
        name="first_name"
        label="Nombre Completo"
        initialValue={store.usuario.nombre}
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: "Campo obligatorio" }]}
        name="email"
        label="Email"
        initialValue={store.usuario.email}
        hidden
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[{ required: false, message: "Campo obligatorio" }]}
        name="phone"
        label="Teléfono"
      >
        <Input type="number" />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: "Campo obligatorio" }]}
        name="address_1"
        label="Direccion"
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: "Campo obligatorio" }]}
        name="country"
        label="Pais"
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: "Campo obligatorio" }]}
        name="city"
        label="Ciudad"
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[{ required: false, message: "Campo obligatorio" }]}
        name="state"
        label="Estado o provincia"
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[{ required: false, message: "Campo obligatorio" }]}
        name="postcode"
        label="Codigo Postal"
      >
        <Input />
      </Form.Item>
      <Boton loading={creandoPedido} texto="Pagar" htmlType="submit" />
    </Form>
  );
}

export default FormularioFacturacion;
