import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Divider, Form, Input } from "antd";
import React from "react";
import CamposFormAcciones from "./camposFormAcciones";
import CamposFormAccionesMultiple from "./camposFormAccionesMultiple";

function FormConfigAcciones({
  accionActual,
  guardarConfiguracionItem,
  setDrawerConfigAccion,
  disparador,
  datosAccionActual,
}) {
  const [form] = Form.useForm();
  const [cargado, setCargado] = React.useState(false);
  React.useEffect(() => {
    if (datosAccionActual?.newItem && accionActual._id) {
      form.setFieldsValue(datosAccionActual.newItem);
    }
    if (accionActual._id) {
      setCargado(true);
    }
  }, [accionActual]);
  return cargado ? (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          values._accion_nombre = accionActual.titulo;
          values._accion_id = accionActual._id;
          values._accion_descripcion = accionActual.descripcion;
          values._accion_f = accionActual.f;
          guardarConfiguracionItem(values);
          setDrawerConfigAccion();
        }}
      >
        <Form.Item
          name="__titulo__"
          label="Titulo (Identificacion)"
          rules={[{ required: true, message: "Campo obligatorio" }]}
        >
          <Input />
        </Form.Item>
        <Divider />
        {accionActual.form === "multiple" ? (
          <Form.List name="datos">
            {(fields, { add, remove }) => (
              <div>
                {fields.map(({ key, name, ...restField }) => {
                  return (
                    <>
                      <Divider
                        style={{ boderColor: "#000" }}
                        orientation="right"
                      >
                        {" "}
                        <FontAwesomeIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => remove(name)}
                          icon={faWindowClose}
                        />{" "}
                      </Divider>
                      <CamposFormAccionesMultiple
                        key={key}
                        name={name}
                        form={form}
                        restField={{ ...restField }}
                        disparador={disparador}
                        accionActual={accionActual}
                      />
                    </>
                  );
                })}
                <Button
                  style={{ width: "100%", margin: 10 }}
                  onClick={() => add()}
                >
                  + Agregar
                </Button>
              </div>
            )}
          </Form.List>
        ) : (
          <CamposFormAcciones
            form={form}
            disparador={disparador}
            accionActual={accionActual}
          />
        )}

        <Button style={{ display: "flex" }} htmlType="submit">
          Guardar
        </Button>
      </Form>
    </>
  ) : null;
}

export default FormConfigAcciones;
