import React from "react";
import {
  Divider,
  Form,
  Input,
  Button,
  Checkbox,
  Select,
  Switch,
  message,
  InputNumber,
  Space,
} from "antd";
import Boton from "../../../design/elementos/boton";
import { setPensando, setStoreWidgets, store } from "../../../../store";
import { Api } from "../../../../api/configApi";
import { ObjectId } from "bson";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLockOpen,
  faMinusCircle,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Opacity } from "@material-ui/icons";

function CrearActualizarWidgetWoocommerce(props) {
  const { Option } = Select;
  const [loading, setLoading] = React.useState(false);
  const [insertOrUpdate, setInsertOrUpdate] = React.useState("InsertWidgets");
  const [tags, setTags] = React.useState([]);
  const [form] = Form.useForm();

  const guardarWidget = (values) => {
    values.tipo = "woocommerce";
    values.buscador = values.buscador || false;
    values.carrito = values.carrito || false;
    values.categorias = values.categorias || false;
    values.filtros = values.filtros || [];

    setPensando(true);
    setLoading(true);

    Api("Cuentas", insertOrUpdate, { _id: store.cuentaactiva }, values)
      .then((res) => {
        props.setVisible(false);
        setPensando(false);
        setLoading(false);
        return Api(
          "Cuentas",
          "Get",
          { _id: store.cuentaactiva },
          { widgets: 1 }
        );
      })
      .then((res) => {
        setStoreWidgets(res[0].widgets);
      })
      .catch((err) => {
        setPensando(false);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    form.resetFields();
    form.setFieldsValue({ _id: new ObjectId(), dinamico: false });
    Api("modulos", "woocommerce", { consulta: "GetTags" }).then((res) => {
      setTags(res);
    });
  }, [props.visible]);
  React.useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
      setInsertOrUpdate("UpdateWidgets");
    } else {
      form.setFieldsValue({ _id: new ObjectId(), dinamico: false });
    }
  }, [props.data]);
  return (
    <div>
      <Form form={form} onFinish={guardarWidget} layout="vertical">
        <Form.Item
          label="Nombre"
          name="nombre"
          hasFeedback
          rules={[{ required: true, message: "Ingresa un Woocomemrece" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Titulo"
          name="titulo"
          hasFeedback
          rules={[{ required: true, message: "Ingresa un titulo" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Descripción"
          name="descripcion"
          hasFeedback
          rules={[{ required: true, message: "Ingresa una descripción" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Estilo"
          rules={[{ required: true, message: "Selecciona una estilo" }]}
          hasFeedback
          name="estilo"
        >
          <Select placeholder="Selecciona una estilo">
            <Option value="shop">Shop</Option>
          </Select>
        </Form.Item>
        <Space>
          <Form.Item
            label="Monto minimo"
            name="montominimo"
            hasFeedback
            rules={[{ required: false, message: "Ingresa un monto minimo" }]}
          >
            <InputNumber style={{ minWidth: 200 }} />
          </Form.Item>
          <Form.Item
            label="Monto máximo"
            name="montomaximo"
            hasFeedback
            rules={[{ required: false, message: "Ingresa un monto máximo" }]}
          >
            <InputNumber style={{ minWidth: 200 }} />
          </Form.Item>
        </Space>
        <Form.Item
          shouldUpdate
          style={{
            display: "flex",
            marginBottom: 0,
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          {({ getFieldValue }) => {
            return (
              getFieldValue().estilo === "shop" && (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <Form.Item
                      name={["carrito"]}
                      fieldKey={["carrito"]}
                      label="Activar Carrito"
                    >
                      <Switch defaultChecked={form.getFieldValue().carrito} />
                    </Form.Item>
                    <Form.Item
                      name={["buscador"]}
                      fieldKey={["buscador"]}
                      label="Activar Buscador"
                    >
                      <Switch defaultChecked={form.getFieldValue().buscador} />
                    </Form.Item>
                    <Form.Item
                      name={["categorias"]}
                      fieldKey={["categorias"]}
                      label="Mostrar Categorias"
                    >
                      <Switch
                        defaultChecked={form.getFieldValue().categorias}
                      />
                    </Form.Item>
                  </div>
                  <Form.List name="filtros">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(
                          ({ key, name, fieldKey, ...restField }, index) => (
                            <div key={key} style={{ width: "100%" }}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  marginBottom: 10,
                                }}
                              >
                                <Form.Item
                                  hasFeedback
                                  {...restField}
                                  colon={false}
                                  name={[name, "filterTags"]}
                                  fieldKey={[fieldKey, "filterTags"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Campo obligatorio",
                                    },
                                  ]}
                                  label={
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <span>Filtrar productos por Tag</span>
                                    </div>
                                  }
                                  style={{
                                    flexDirection: "column",
                                    width: "100%",
                                    marginBottom: 0,
                                  }}
                                >
                                  <Select
                                    showSearch
                                    optionFilterProp="children"
                                    style={{ width: "100%" }}
                                  >
                                    {tags.map((tag) => {
                                      return (
                                        <Option value={tag.id}>
                                          {tag.name}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                                <FontAwesomeIcon
                                  style={{
                                    cursor: "pointer",
                                    alignSelf: "center",
                                    marginTop: 27,
                                    marginLeft: 10,
                                  }}
                                  icon={faMinusCircle}
                                  onClick={() => remove(name)}
                                />
                              </div>
                              <Form.List name={[name, "condicionales"]}>
                                {(fields, { add, remove }) => (
                                  <>
                                    {fields.map(
                                      (
                                        { key, name, fieldKey, ...restField },
                                        index2
                                      ) => (
                                        <div
                                          key={key}
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                          }}
                                        >
                                          <Form.Item
                                            style={{ flex: 1, margin: 5 }}
                                            hasFeedback
                                            {...restField}
                                            colon={false}
                                            name={[name, "atributo"]}
                                            fieldKey={[fieldKey, "atributo"]}
                                            rules={[
                                              {
                                                required: true,
                                                message: "Campo obligatorio",
                                              },
                                            ]}
                                            label="Atributo"
                                          >
                                            <Select>
                                              {store.losatributos.texts.map(
                                                (atr) => {
                                                  return (
                                                    <Option value={atr.nombre}>
                                                      {atr.nombre}
                                                    </Option>
                                                  );
                                                }
                                              )}
                                            </Select>
                                          </Form.Item>

                                          <Form.Item
                                            style={{ flex: 1, margin: 5 }}
                                            hasFeedback
                                            {...restField}
                                            colon={false}
                                            name={[name, "operador"]}
                                            fieldKey={[fieldKey, "operador"]}
                                            rules={[
                                              {
                                                required: true,
                                                message: "Campo obligatorio",
                                              },
                                            ]}
                                            label="operador"
                                          >
                                            <Select>
                                              <Option value="$eq">
                                                Es Igual
                                              </Option>
                                              <Option value="$ne">
                                                Es diferente
                                              </Option>
                                            </Select>
                                          </Form.Item>

                                          <Form.Item
                                            style={{ flex: 1, margin: 5 }}
                                            shouldUpdate
                                          >
                                            {({ getFieldValue }) => {
                                              return (
                                                getFieldValue().filtros[index][
                                                  "condicionales"
                                                ][index2] && (
                                                  <Form.Item
                                                    {...restField}
                                                    name={[name, "valor"]}
                                                    fieldKey={[
                                                      fieldKey,
                                                      "valor",
                                                    ]}
                                                    label="Valor"
                                                    style={{
                                                      flex: 1,
                                                    }}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message:
                                                          "Campo obligatorio",
                                                      },
                                                    ]}
                                                  >
                                                    <Select>
                                                      {store.losatributos.texts
                                                        .find((at) => {
                                                          return (
                                                            at.nombre ===
                                                            getFieldValue()
                                                              .filtros[index]
                                                              .condicionales[
                                                              index2
                                                            ].atributo
                                                          );
                                                        })
                                                        .opciones.map(
                                                          (op, index) => {
                                                            return (
                                                              <Option
                                                                key={
                                                                  "i" + index
                                                                }
                                                                value={op}
                                                              >
                                                                {op}
                                                              </Option>
                                                            );
                                                          }
                                                        )}
                                                    </Select>
                                                  </Form.Item>
                                                )
                                              );
                                            }}
                                          </Form.Item>
                                          <FontAwesomeIcon
                                            style={{
                                              cursor: "pointer",
                                              alignSelf: "center",
                                              marginTop: 27,
                                              marginLeft: 10,
                                            }}
                                            icon={faMinusCircle}
                                            onClick={() => remove(name)}
                                          />
                                        </div>
                                      )
                                    )}

                                    <p
                                      style={{
                                        fontSize: 13,
                                        marginBottom: 30,
                                        fontWeight: 600,
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        store.losatributos.texts.length > 0
                                          ? add()
                                          : message.error(
                                              "Debe tener atributos TEXTO para crear condicionales"
                                            );
                                      }}
                                    >
                                      + Agregar Condicional
                                    </p>
                                  </>
                                )}
                              </Form.List>

                              <Divider></Divider>
                            </div>
                          )
                        )}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add({ condicionales: [] })}
                            block
                            icon={<FontAwesomeIcon icon={faPlusCircle} />}
                          >
                            Agregar Filtro
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </>
              )
            );
          }}
        </Form.Item>

        <Form.Item name="_id" hidden rules={[{ required: true }]}></Form.Item>

        <Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: 15,
            }}
          >
            <Boton loading={loading} texto="Guardar" htmlType="submit" />
            <Boton
              loading={loading}
              onClick={() => {
                props.setVisible(false);
              }}
              texto="Cancelar"
              tipo="danger"
              htmlType="button"
            />
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}

export default CrearActualizarWidgetWoocommerce;
