import {
  faPencilAlt,
  faSave,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Drawer, Table, Button } from "antd";
import React from "react";
import { Api } from "../../../api/configApi";
import { setPensando, store } from "../../../store";
import EditarFormularioComponente from "./editarFormularioComponente";

function ListaFormulariosPage() {
  const [drawerEditarForm, setDrawerEditarForm] = React.useState(false);
  const [formularioActual, setFormularioActual] = React.useState({});
  const [indexFormularioActual, setIndexFormularioActual] = React.useState();
  const cerrarDrawerForm = () => {
    setDrawerEditarForm(false);
  };
  const [formularios, setFormularios] = React.useState([]);
  const columns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: "Descripción",
      dataIndex: "descripcion",
      key: "descripcion",
    },
    {
      title: "Opciones",
      dataIndex: "opciones",
      key: "opciones",
      render: (config, campo, index) => {
        return (
          <>
            {config.editable && (
              <FontAwesomeIcon
                style={{ margin: 10, cursor: "pointer" }}
                icon={faPencilAlt}
                onClick={() => {
                  setDrawerEditarForm(true);
                  setFormularioActual(campo);
                  setIndexFormularioActual(index);
                }}
              />
            )}
            {config.eliminable && (
              <FontAwesomeIcon
                style={{ margin: 10, cursor: "pointer" }}
                icon={faTrash}
              />
            )}
          </>
        );
      },
    }
  ];

  React.useEffect(() => {
    Api("cuentas", "Get", { _id: store.cuentaactiva }, { formularios: 1 }).then(
      (res) => {
        setFormularios(res[0].formularios);
      }
    );
  }, []);
  function guardarFormulario() {

    setPensando(true);
    cerrarDrawerForm();
    Api(
      "cuentas",
      "UpdateFormularios",
      { _id: store.cuentaactiva },
      { formularios: formularios }
    )
      .then((res) => {
        setPensando(false);
      })
      .catch((err) => {
        setPensando(false);
      });
  }
  return (
    <>
      <div style={{ marginTop: 30 }}></div>
      <Table columns={columns} dataSource={formularios} />
      <Drawer
        width={1200}
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <span>{formularioActual.nombre}</span>

          </div>
        }
        placement="right"
        closable={false}
        onClose={cerrarDrawerForm}
        visible={drawerEditarForm}
      >
        <EditarFormularioComponente
          formularios={formularios}
          setFormularios={setFormularios}
          indexFormularioActual={indexFormularioActual}
          setFormularioActual={setFormularioActual}
          formularioActual={formularioActual}
          cerrarDrawerForm={cerrarDrawerForm}
        />
      </Drawer>
    </>
  );
}

export default ListaFormulariosPage;
