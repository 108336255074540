import React from "react";
import { Form, Input, Select, Button, Space, Tag } from "antd";

import { ObjectId } from "bson";
import { setPensando, setStoreWidgets, store } from "../../../../store";
import { Api } from "../../../../api/configApi";
import CopyToClipboard from "react-copy-to-clipboard";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css";

function CrearActualizarWidgetBuilder(props) {
  const { Option } = Select;
  const [loading, setLoading] = React.useState(false);
  const [insertOrUpdate, setInsertOrUpdate] = React.useState("InsertWidgets");

  const [form] = Form.useForm();
  const [code, setCode] = React.useState("");

  const guardarWidget = (values) => {
    values.tipo = "builder";
    values.codeDesign = code;
    setPensando(true);
    setLoading(true);

    Api("Cuentas", insertOrUpdate, { _id: store.cuentaactiva }, values)
      .then((res) => {
        props.setVisible(false);
        setPensando(false);
        setLoading(false);
        return Api(
          "Cuentas",
          "Get",
          { _id: store.cuentaactiva },
          { widgets: 1 }
        );
      })
      .then((res) => {
        setStoreWidgets(res[0].widgets);
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
        setPensando(false);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    form.resetFields();
    form.setFieldsValue({ _id: new ObjectId(), dinamico: false });
  }, [props.visible]);

  React.useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
      setInsertOrUpdate("UpdateWidgets");
      setCode(props.data.codeDesign);
    } else {
      form.setFieldsValue({ _id: new ObjectId(), dinamico: false });
    }
  }, [props.data]);

  return (
    <div>
      <Form form={form} onFinish={guardarWidget} layout="vertical">
        <Form.Item
          label="Nombre"
          name="nombre"
          hasFeedback
          rules={[{ required: true, message: "Ingresa un nombre" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="_id" hidden rules={[{ required: true }]}></Form.Item>
        <Form.Item
          name="dinamico"
          hidden
          rules={[{ required: true }]}
        ></Form.Item>

        <Form.Item
          label="Titulo"
          name="titulo"
          hasFeedback
          rules={[{ required: true, message: "Ingresa un titulo" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Descripción"
          name="descripcion"
          hasFeedback
          rules={[{ required: true, message: "Ingresa una descripción" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
          {() => (
            <>
              <strong>Código:</strong>
              <Editor
                value={code}
                onValueChange={(code) => setCode(code)}
                highlight={(code) => highlight(code, languages.js)}
                padding={10}
                style={{
                  fontFamily: '"Fira code", "Fira Mono", monospace',
                  fontSize: 12,
                  outline: "none",
                  minHeight: 100,
                  backgroundColor: "#fafafa",
                  marginBottom: 15,
                }}
              />
            </>
          )}
        </Form.Item>

        <p></p>

        <Button loading={loading} htmlType="submit">
          Guardar
        </Button>
      </Form>
    </div>
  );
}

export default CrearActualizarWidgetBuilder;
