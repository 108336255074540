// Intentamos calcular la cantidad de objetos en un array aunque ese array no exista.
// elemento es el array base.
// profundidad son las keys que pueden no existir a profundidad cuando se consulta con .XX
function LLength(elemento, profundidad) {
  if (elemento && typeof elemento === "object") {
    if (profundidad) {
      var profundo = true;
      var consulta = elemento;
      profundidad.forEach((element) => {
        if (!elemento.hasOwnProperty(element)) {
          profundo = false;
        } else {
          consulta = consulta[element];
        }
      });

      if (profundo) {
        return consulta.length;
      } else {
        return 0;
      }
    }
  } else {
    return 0;
  }
}

export default LLength;
