import React from "react";
import { store } from "../../../../../store";
import VistaPrincipal from "./VistaComponentes/VistaPrincipal";

const DesignWidgetAcademia = (props) => {
  const [widget, setWidget] = React.useState({});

  React.useEffect(() => {
    const widgetActual = store.widgets.find((wid) => {
      return wid._id === props._id;
    });

    setWidget(widgetActual);
  }, [props]);

  return (
    <>
      <div className="widgetShadow" style={{padding:"10px"}}>
        <VistaPrincipal widget={widget}></VistaPrincipal>
      </div>
    </>
  );
};

export default DesignWidgetAcademia;
