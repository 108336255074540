import { faList, faRedo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Drawer, Table } from "antd";
import React from "react";
import { Api } from "../../../../../../api/configApi";

import ListaItemsListado from "./listaItemsListado";

function ListaListadosPerfilAfiliado(props) {
  const [drawerVerListado, setDrawerVerListado] = React.useState(false);
  const [listados, setListados] = React.useState([]);
  const [listadoActual, setListadoActual] = React.useState(null);
  const [actualizarCount, setActualizarCount] = React.useState(false);

  const columns = [
    {
      title: "Listado",
      dataIndex: "listado",
      key: "listado",
    },
    {
      title: "Cantidad",
      dataIndex: "cantidad",
      key: "cantidad",
    },
    {
      title: "",
      dataIndex: "acciones",
      key: "acciones",
      render: (e, not) => {
        return (
          <FontAwesomeIcon
            onClick={() => {
              setListadoActual(not);
              setDrawerVerListado(true);
            }}
            style={{ cursor: "pointer" }}
            icon={faList}
          />
        );
      },
    },
  ];

  function getCantidadListados() {
    setActualizarCount(true);
    Api("listados", "CountAll", { afiliado: props.infoAfiliado }).then(
      (res) => {
        setActualizarCount(false);
        setListados(res);
      }
    );
  }
  React.useEffect(() => {
    getCantidadListados();
  }, [props.infoAfiliado]);
  return (
    <>
      {listadoActual && (
        <Drawer
          visible={drawerVerListado}
          title={listadoActual.listado}
          onClose={() => {
            setDrawerVerListado(false);
          }}
          width={800}
          destroyOnClose
        >
          <ListaItemsListado
            listado={listadoActual.listado}
            afiliado={props.infoAfiliado}
            setDrawerEditarAfiliado={setDrawerVerListado}
            getCantidadListados={getCantidadListados}
          />
        </Drawer>
      )}
      <div style={{ textAlign: "right", marginBottom: 5, marginRight: 10 }}>
        <Button
          icon={
            <FontAwesomeIcon
              icon={faRedo}
              style={{ cursor: "pointer", alignItems: "rigth" }}
            />
          }
          loading={actualizarCount}
          onClick={getCantidadListados}
        />
      </div>

      <Table columns={columns} dataSource={listados} />
    </>
  );
}

export default ListaListadosPerfilAfiliado;
