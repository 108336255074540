import { Button } from "antd";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Api } from "../../../../../api/configApi";
import { InputT } from "../../../../../plugins/Formularios/FormularioTecclas";
import FormularioMaterialDescargable from "./FormularioMaterialDescargable";

const FormularioModLeccion = (props) => {
  const methods = useForm();

  function editarLeccion(values) {
    const cursoInf = props.dataCurso;

    // delete cursoInf.lecciones

    Api(
      "academia",
      "editarLeccion",
      { idWidget: props.idWidget._id },
      {
        rutaLeccion: props.data.idLeccion,
        infoCurso: cursoInf,
        actualizarLeccion: values,
      },
      "https://ed3a-181-132-3-197.ngrok.io"
    )
      .then((res) => {
        props.obtenerLecciones()
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function onSubmit(values) {
    props.setVisible(false);
    editarLeccion(values);
  }

  React.useEffect(() => {
    methods.reset(props.data);
  }, []);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          {/* <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  flexDirection: "column",
                  padding: "10px",
                  marginBottom: "10px",
                }}
              >
                <label style={{ marginBottom: "10px" }}>idLeccion</label>
                <InputT
                  control={methods.control}
                  name="idLeccion"
                  rules={{ required: false }}
                ></InputT>
              </div> */}

          <div
            style={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            <label style={{ marginBottom: "10px" }}>Título de la lección</label>
            <InputT
              control={methods.control}
              name="tituloLeccion"
              placeholder="Escribe el título de la lección"
              rules={{ required: true }}
            ></InputT>
            {methods.formState.errors.tituloLeccion && (
              <span style={{ color: "red" }}>Campo requerido</span>
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            <label style={{ marginBottom: "10px" }}>
              Descripción de la lección
            </label>
            <InputT
              control={methods.control}
              name="descripcionLeccion"
              placeholder="Escribe la descripción de la lección"
              rules={{ required: true }}
            ></InputT>
            {methods.formState.errors.descripcionLeccion && (
              <span style={{ color: "red" }}>Campo requerido</span>
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            <label style={{ marginBottom: "10px" }}>
              URL del video de la lección
            </label>
            <InputT
              control={methods.control}
              name="urlVideo"
              placeholder="Escribe la URL del video de la lección"
              rules={{ required: true }}
            ></InputT>

            {methods.formState.errors.urlVideo && (
              <span style={{ color: "red" }}>Campo requerido</span>
            )}
          </div>
              <FormularioMaterialDescargable></FormularioMaterialDescargable>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              margin: "20px 0 20px 0",
            }}
          >
            <Button type="secondary" htmlType="htmlType">
              Guardar
            </Button>
            <Button type="secondary" danger onClick={() => {
              props.setVisible(false)
              methods.reset(props.data)
              }}>
              Cancelar
            </Button>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default FormularioModLeccion;
