import React from "react"
import Badge from "@material-ui/core/Badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import Popover from "@material-ui/core/Popover";

function Accionescontroller() {
  const [ancla, setAncla] = React.useState();
  const mostrarFiltros = () => {
    // console.log("Todo bien ps ps ps ps");
  };

  return (
    <>
      <Badge
        style={{ position: "absolute", alignSelf: "center", background: "#fff" }}
        color="secondary"
        badgeContent={0}
        onClick={(event) => {
            setAncla(event.currentTarget)
        }}
      >
        <FontAwesomeIcon
          style={{
            fontSize: 25,
            color: "#000",
          }}
          icon={faCog}
        />
      </Badge>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        elevation={0}
        id="popov"
        placement="top-start"
        anchorEl={ancla}
        open={Boolean(ancla)}
        onClose={() => {
          setAncla(null);
        }}
      >
        <div
          style={{
            background: "#fff",
            marginTop: 10,
            padding: 15,
            borderRadius: 5,
            borderColor: "rgb(232 232 232)",
            borderStyle: "solid",
            borderWidth: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          Soy el contenido Jua jua jua jua jua juaj ua
        </div>
      </Popover>
    </>
  );
}

export default Accionescontroller;
