import { Space, Spin } from "antd";
import React from "react";
import { Api } from "../../../../../../api/configApi";
import { store } from "../../../../../../store";
import {
  BrowserView,
  MobileView,
  isMobile,
  isBrowser,
} from "react-device-detect";

function PorNivelesDesignArbol(props) {
  const [losNiveles, setLosNiveles] = React.useState([]);
  const [reactNiveles, setReactNiveles] = React.useState([]);
  const [losAtributos, setLosAtributos] = React.useState({});
  const [listaAfiliados, setListaAfiliados] = React.useState([]);
  const [redCompleta, setRedCompleta] = React.useState({});
  const [cargando, setCargando] = React.useState(false);

  React.useEffect(() => {
    const niveles = [];
    const atributos = [];
    setReactNiveles(props.widget.niveles);
    props.widget.niveles.forEach((nivel) => {
      nivel.comprimido = true;
      niveles.push(+nivel.nivel);
      if (nivel.atributo) {
        atributos.push(nivel.atributo);
      }
    });

    //
    Api("red", "GetTotalNiveles", { niveles: niveles }, {}).then((res) => {
      setLosNiveles(res);
    });
    Api("afiliados", "GetAtributos", { atributos: atributos }, {}).then(
      (res) => {
        setLosAtributos(res);
      }
    );
  }, [props.widget]);
  function atributoFormateado(atributo, valor, formato) {
    if (!formato || formato === "ninguno" || !valor) return valor;
    // console.log(atributo);
    if (store.losatributos.numbers.find((atr) => atr.nombre === atributo)) {
      if (formato === "miles") {
        // separar con punto los miles
        return valor.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      } else {
        return Intl.NumberFormat("es-ES", {
          style: "currency",
          currency: formato,
        }).format(valor);
      }
    }
    if (store.losatributos.texts.find((atr) => atr.nombre === atributo)) {
      return valor;
    }
  }

  function ListarAfiliados(nivel, atributo) {
    if (redCompleta[nivel]) {
      setListaAfiliados(redCompleta[nivel]);
      setCargando(false);
    } else {
      setListaAfiliados([]);
      Api("red", "GetAfiliadosNivel", { nivel: nivel, atributo }, {}).then(
        (res) => {
          setRedCompleta({ ...redCompleta, [nivel]: res });
          setListaAfiliados(res || []);
          setCargando(false);
        }
      );
    }
  }
  return (
    <div style={{ padding: 0, height: "100%" }}>
      {reactNiveles.map((nivel, index) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: isBrowser ? "row" : "column",
              alignItems: "center",
              justifyContent: isBrowser ? "left" : "center",
              cursor: "pointer",
              marginBottom: 10,

            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: isBrowser ? "row" : "column",
                alignItems: "center",
                justifyContent: "left",
                cursor: "pointer",
                marginBottom: isBrowser ? 10 : 30,
              }}
              onClick={() => {
                if (!cargando && +nivel.nivel !== 0) {
                  setCargando(true);
                  ListarAfiliados(nivel.nivel, nivel.atributoExpandido);
                  reactNiveles.forEach((niv) => {
                    if (niv.nivel !== nivel.nivel) {
                      niv.comprimido = true;
                    }
                  });
                  nivel.comprimido = !nivel.comprimido;
                  setReactNiveles([...reactNiveles]);
                }
              }}
            >
              <div
                style={{
                  fontSize: isBrowser ? 10 : 14,
                  transform: isBrowser ? "rotate(-90deg)" : "none",
                  width: isBrowser ? 100 : "auto",
                  color: nivel.color || "#000",
                }}
              >
                {nivel.textoLateral || ""}
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img width={130} src={nivel.iconoComprimido} alt="icono" />
              </div>
              {nivel.comprimido && (
                <>
                  <div style={{ marginLeft: 10, color: nivel.color || "#000" }}>
                    {nivel.nivel > 0 ? (
                      <>
                        <strong style={{ fontSize: 20 }}>x</strong>
                        <stron style={{ fontSize: 40 }}>
                          {losNiveles[nivel.nivel] || 0}
                        </stron>
                      </>
                    ) : (
                      <strong></strong>
                    )}
                  </div>

                  {nivel.atributo && (
                    <div
                      style={{
                        background: nivel.color || "#000",
                        color: "#fff",
                        marginLeft: 15,
                        padding: 10,
                        paddingLeft: 20,
                        paddingRight: 20,
                        borderRadius: 30,
                        fontSize: 20,
                      }}
                    >
                      <strong>{nivel.prefijo}</strong>

                      {atributoFormateado(
                        nivel.atributo,
                        losAtributos[nivel.atributo],
                        nivel.formato
                      )}
                      <strong>{nivel.sufijo}</strong>
                    </div>
                  )}
                </>
              )}
            </div>
            {!nivel.comprimido &&
              (cargando ? (
                <Spin style={{ marginLeft: 10 }} />
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 10,
                    marginBottom: 10,
                    overflow: "auto",
                    maxWidth: isBrowser ? 600 : 200,
                    borderLeft: `4px solid ${nivel.color || "#000"}`,
                    paddingLeft: 10,
                  }}
                >
                  <Space>
                    {listaAfiliados.map((afiliado, index) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <span style={{ fontSize: 12 }}>
                            {nivel.mostrarNombreIde === "ninguno"
                              ? ""
                              : afiliado[nivel.mostrarNombreIde]}
                          </span>
                          <img
                            width={50}
                            src={nivel.iconoExpandido || nivel.iconoComprimido}
                            alt="icono"
                          />
                          {afiliado[nivel.atributoExpandido] && (
                            <strong
                              style={{
                                fontSize: 11,
                                background: nivel.color || "#000",
                                color: "#fff",
                                padding: 2,
                                paddingLeft: 10,
                                paddingRight: 10,
                                borderRadius: 30,
                              }}
                            >
                              {afiliado[nivel.atributoExpandido]}
                            </strong>
                          )}
                        </div>
                      );
                    })}
                    {listaAfiliados.length === 0 && <span>Sin regístros</span>}
                  </Space>
                </div>
              ))}
          </div>
        );
      })}
    </div>
  );
}

export default PorNivelesDesignArbol;
