import { message } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import { Api } from "../../api/configApi";
import Moment from "react-moment";
import moment from "moment/min/moment-with-locales";
Moment.globalMoment = moment;
Moment.globalLocale = "es";

function ListadeNotificaciones({ setDrawer, setNotificacionespendientes }) {
  let history = useHistory();
  const [notificaciones, setNotificaciones] = React.useState([]);
  function listarNotificaciones() {
    Api("notificaciones", "Get").then((res) => {
      if (res.success) {
        setNotificaciones(res.notificaciones);
      } else {
        message.error("No pudimos obtener las notificaciones");
      }
    });
  }
  React.useEffect(() => {
    listarNotificaciones();
    setTimeout(() => {
      Api("notificaciones", "UpdateViewAll").then((res) => {
        if (res.success) {
          listarNotificaciones();
          setNotificacionespendientes(false);
        }
      });
    }, 3000);
  }, []);

  return (
    <>
      {notificaciones.map((e, index) => {
        return (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "column",
              background:
                e.estado === "noleida"
                  ? "rgb(241 250 255)"
                  : "rgb(243 243 243)",
              padding: 20,
              borderRadius: 10,
              marginTop: 10,
              cursor: "pointer",
            }}
            onClick={() => {
              if (e.accion !== "nada") {
                history.push("/page/" + e.accion);
                setDrawer(false);
              }
            }}
          >
            <span style={{ fontSize: 10 }}>
              <Moment fromNow>{e.fecha}</Moment>
            </span>
            <strong>{e.titulo}</strong>
            <p>{e.contenido}</p>
          </div>
        );
      })}
    </>
  );
}

export default ListadeNotificaciones;
