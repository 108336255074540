import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { store } from "../../../store";

function ModalListaFiltrosQuienes(props) {
  const [redactiva, setRedactiva] = React.useState("todaslasredes");

  React.useEffect(() => {
    
    if (store.redesactivas.length > 0) {
      setRedactiva(store.redesactivas[0].id);
      if (props.laredactiva !== undefined) {
        setRedactiva(props.laredactiva);
      }
    }
  }, []);
  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <select
          value={redactiva}
          onChange={(e) => {
            setRedactiva(e.target.value);
          }}
          className="seleccionar"
        >
          <option disabled value="1">
            Aplica a la Red:
          </option>
          <option value="todaslasredes">
            Todas las redes
          </option>
          {store.redesactivas.map((red) => {
            return (
              <option key={red.id} value={red.id}>
                {red.nombre}
              </option>
            );
          })}
        </select>
        <div style={estilos.njahskhs}>
          <span style={{ fontSize: 15, fontWeight: "bold", color: "#40af77" }}>
            Incluidos
          </span>
          <span
            onClick={() => {
              props.setVistaactiva(1);
              props.setSwitchIncluidos(true);
              props.setRedActiva(redactiva);
            }}
            style={{ fontSize: 12, cursor: "pointer" }}
          >
            Agregar +
          </span>
        </div>
        <div style={estilos.confiltrospadre}>
          {props.listadefiltros.incluidos
            .filter((fil) => {
              return fil.red === redactiva;
            })
            .map((filtro) => {
              return (
                <Tooltip
                  key={filtro.id}
                  title={filtro.filtro.valor.label}
                  placement="right-start"
                >
                  <div style={estilos.contfiltros}>
                    <span>{filtro.filtro.nombre}</span>
                    <span
                      onClick={() => {
                        props.eliminarfiltro(filtro.id, true, "quienes");
                      }}
                    >
                      X
                    </span>
                  </div>
                </Tooltip>
              );
            })}
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", marginTop: 10 }}>
        <div style={estilos.iijfheiu}>
          <span style={{ fontSize: 15, fontWeight: "bold", color: "#af4063" }}>
            Excluidos
          </span>
          <span
            onClick={() => {
              props.setVistaactiva(1);
              props.setSwitchIncluidos(false);
            }}
            style={{ fontSize: 12, cursor: "pointer" }}
          >
            Agregar +
          </span>
        </div>
        <div style={estilos.confiltrospadre}>
          {props.listadefiltros.excluidos
            .filter((fil) => {
              return fil.red === redactiva;
            })
            .map((filtro) => {
              return (
                <Tooltip
                  key={filtro.id}
                  title={filtro.filtro.valor.label}
                  placement="right-start"
                >
                  <div style={estilos.contfiltros}>
                    <span>{filtro.filtro.nombre}</span>
                    <span
                      onClick={() => {
                        props.eliminarfiltro(filtro.id, false, "quienes");
                      }}
                    >
                      X
                    </span>
                  </div>
                </Tooltip>
              );
            })}
        </div>
      </div>
    </div>
  );
}
const estilos = {
  contfiltros: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    cursor: "pointer",
    background: "#f7fcff",
    padding: 7,
    margin: 5,
    fontSize: 13,
  },
  confiltrospadre: {
    minWidth: 170,
    minHeight: 100,
    borderRadius: 5,
    marginTop: 5,
    background: "rgb(234 244 251)",
    display: "flex",
    flexDirection: "column",
  },
  njahskhs: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10,
  },
  iijfheiu: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
};
export default ModalListaFiltrosQuienes;
