import { PDFDocument } from "pdf-lib";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import React from "react";
import { Api } from "../../../../../api/configApi";

import { store } from "../../../../../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlignLeft,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { Space, Slider, Spin, message } from "antd";
import { UploadClient } from "@uploadcare/upload-client";
import Modal from "./Modal";
import Boton from "../../../../../components/design/elementos/boton";

function DesignWidgetPdf(props) {
  const [widget, setWidget] = React.useState({});
  const [esCelular, setEsCelular] = React.useState(false);
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [ancho, setAncho] = React.useState(500);
  const [minAncho, setMinAncho] = React.useState(500);
  const [maxAncho, setMaxAncho] = React.useState(500);
  const refBlob = React.useRef();
  const refSignature = React.useRef();
  const refExpire = React.useRef();
  const [mostrar, setMostrar] = React.useState(false);
  const [mensajeFirma, setMensajeFirma] = React.useState(
    "La firma se está procesando"
  );

  const refObjEnviar = React.useRef({
    urlPdf: undefined,
    idWidget: undefined,
  });
  const refEsperarNuevoMensajeModal = React.useRef();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    const pp = document.getElementsByClassName("ant-layout-content")[0];

    // es un celular
    if (window.innerWidth <= 768) {
      setEsCelular(true);
      setMinAncho(pp.clientWidth - 60);
      setMaxAncho(500);
      setAncho(pp.clientWidth - 60);
    } else {
      setEsCelular(false);
      setMinAncho(500);
      setMaxAncho(window.innerWidth);
    }
  }

  async function autoCompletarPdf(widgetActual) {
    Api(
      "afiliados",
      "GetCampos",
      { widget: widgetActual._id, campos: widgetActual.campos },
      {}
    ).then(async (losCampos) => {
      const existingPdfBytes = await fetch(widgetActual.url).then((res) =>
        res.arrayBuffer()
      );
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      const form = pdfDoc.getForm();
      const fields = form.getFields();

      for (const key in losCampos) {
        const existe = fields.find((field) => field.getName() === key);
        if (existe) {
          const field = form.getTextField(key);
          field.setText(`${losCampos[key]} ${widgetActual.relleno}`);
          field.setFontSize(13);
          // console.log("procesado " + key);
        }
      }
      form.flatten();

      const pdfBytes = await pdfDoc.save();
      var bytes = new Uint8Array(pdfBytes);
      var blob = new Blob([bytes], { type: "application/pdf" });
      refBlob.current = blob;
      const docUrl = URL.createObjectURL(blob);
      setWidget({ ...widgetActual, url: docUrl });
      // console.log("Aquí, autocompletando el PDF");
    });
  }

  function enviarInfo(elem) {
    Api(
      "integracionFad",
      "gestionarFirma",
      {},
      elem,
      "http://79a6-181-132-3-197.ngrok.io"
    )
      .then((res) => {
        setMostrar(false);
        clearTimeout(refEsperarNuevoMensajeModal.current);
        setMensajeFirma((mensajeFirma) => {
          mensajeFirma = "La firma se está procesando.";
          return mensajeFirma;
        });
        if (!res.success) {
          message.error(
            "Ha ocurrido un error, por favor revisa la configuración de tu widget"
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function obtenerToken() {
    Api(
      "integracionFad",
      "generateToken",
      {},
      {},
      "http://79a6-181-132-3-197.ngrok.io"
    )
      .then((res) => {
        console.log("Iniciar");
        esperarFirmaMensaje();
        setMostrar(true);
        let client = new UploadClient({
          publicKey: "48fb73efa9e179704af8",
          secureSignature: res.token,
          secureExpire: res.expire,
        });

        client.uploadFile(refBlob.current).then((file) => {
          refObjEnviar.current.urlPdf = file.originalUrl;

          if (refObjEnviar.current.urlPdf && refObjEnviar.current.idWidget) {
            enviarInfo(refObjEnviar.current);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function esperarFirmaMensaje() {
    refEsperarNuevoMensajeModal.current = setTimeout(() => {
      setMensajeFirma((mensajeFirma) => {
        mensajeFirma =
          "El procesamiento está tardando más de lo habitual, agradecemos tu paciencia.";
        return mensajeFirma;
      });
    }, 5000);
  }

  React.useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const widgetActual = store.widgets.find((wid) => {
      return wid._id === props._id;
    });

    if (widgetActual.tipoPdf === "visual") {
      setWidget(widgetActual);
    }
    if (widgetActual.tipoPdf === "autocompletar") {
      autoCompletarPdf(widgetActual);
    }
  }, [props]);

  React.useEffect(() => {
    if (widget._id) {
      refObjEnviar.current.idWidget = widget._id;

      // Api("atributosNumero", "GetTotal", { widget: widget._id }, {}).then(
      //   (res) => {
      //     res.success && setCantidadAfiliado(res.total);
      //   }
      // );
    }
  }, [widget]);

  React.useEffect(() => {}, []);

  return (
    <div
      style={{ height: "100%", width: "100%", position: "relative" }}
      className={`contenedorPdf ${props.className}`}
    >
      <Modal mostrar={mostrar} setMostrar={setMostrar}>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#fafafa",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              padding: "20px",
            }}
          >
            <Spin size="large" />
            <p style={{ marginTop: "10px", textAlign: "center" }}>
              {mensajeFirma}
            </p>
          </div>
        </div>
      </Modal>
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: widget.fondo,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        ></div>
        {widget.url ? (
          <>
            <div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Document
                  onLoadError={console.error}
                  file={widget.url}
                  onLoadSuccess={onDocumentLoadSuccess}
                  loading="Cargando Documento"
                >
                  <Page width={ancho} pageNumber={pageNumber} />
                </Document>
              </div>

              <div>
                <Slider
                  value={ancho}
                  min={minAncho}
                  max={maxAncho}
                  onChange={(e) => {
                    setAncho(e);
                  }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 10,
                }}
              >
                <Space>
                  {pageNumber > 1 && (
                    <div>
                      <FontAwesomeIcon
                        onClick={() => {
                          setPageNumber(pageNumber - 1);
                        }}
                        style={{ fontSize: 30, cursor: "pointer" }}
                        icon={faArrowAltCircleLeft}
                      />
                    </div>
                  )}
                  {pageNumber < numPages && (
                    <div>
                      <FontAwesomeIcon
                        onClick={() => {
                          setPageNumber(pageNumber + 1);
                        }}
                        style={{ fontSize: 30, cursor: "pointer" }}
                        icon={faArrowAltCircleRight}
                      />
                    </div>
                  )}
                </Space>
              </div>
              <p style={{ textAlign: "center" }}>
                Página {pageNumber} de {numPages}
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  padding:"0 110px 0 110px",
                  margin:"20px 0 20px 0"
                }}
              >
                {widget.descargar && widget.url && <Boton onClick={()=>window.open(widget.url)}>Descargar</Boton>}
                {widget.firmaDigital && (
                  <Boton
                    onClick={() => {
                      obtenerToken();
                    }}
                  >
                    Firmar
                  </Boton>
                )}
              </div>
            </div>
          </>
        ) : (
          <Spin size="large" />
        )}
      </div>
    </div>
  );
}

export default DesignWidgetPdf;
