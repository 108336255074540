import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
} from "antd";
import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { store } from "../../../../../../store";

const CompararAtributosMulti = (props) => {
  const { Option, OptGroup } = Select;
  const refNombreFormList = React.useRef(
    "compararAtributoMultiConAtribGroupList"
  );

  return (
    <>
      <Form.List name={refNombreFormList.current}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space
                key={key}
                style={{
                  display: "flex",
                  marginBottom: 8,
                  backgroundColor: "#fcfcfc",
                  padding: "20px",
                  borderRadius: "10px",
                }}
                direction="vertical"
              >
                <Form.Item
                  {...restField}
                  name={[name, props.nombreAtributo]}
                  label={props.labelNombreAtributo}
                  rules={[
                    {
                      required: true,
                      message: "Debes seleccionar una opción",
                    },
                  ]}
                >
                  <Select placeholder="Escoge una opción">
                    {(props.filtro == "fecha" || props.filtro == "todos") && (
                      <OptGroup label="Fecha">
                        {store.losatributos.dates.map((elem, index) => {
                          return (
                            <>
                              <Option
                                key={index + elem._id}
                                value={elem._id + "-" + elem.tipo}
                              >
                                {elem.nombre}
                              </Option>
                            </>
                          );
                        })}
                      </OptGroup>
                    )}
                    {(props.filtro == "bandera" || props.filtro == "todos") && (
                      <OptGroup label="Bandera">
                        {store.losatributos.flags.map((elem, index) => {
                          return (
                            <>
                              <Option
                                key={index + elem._id}
                                value={elem._id + "-" + elem.tipo}
                              >
                                {elem.nombre}
                              </Option>
                            </>
                          );
                        })}
                      </OptGroup>
                    )}
                    {(props.filtro == "numero" || props.filtro == "todos") && (
                      <OptGroup label="Numero">
                        {store.losatributos.numbers.map((elem, index) => {
                          return (
                            <>
                              <Option
                                key={index + elem._id}
                                value={elem._id + "-" + elem.tipo}
                              >
                                {elem.nombre}
                              </Option>
                            </>
                          );
                        })}
                      </OptGroup>
                    )}
                    {(props.filtro == "texto" || props.filtro == "todos") && (
                      <OptGroup label="Texto">
                        {store.losatributos.texts.map((elem, index) => {
                          return (
                            <>
                              <Option
                                key={index + elem._id}
                                value={elem._id + "-" + elem.tipo}
                              >
                                {elem.nombre}
                              </Option>
                            </>
                          );
                        })}
                      </OptGroup>
                    )}
                  </Select>
                </Form.Item>

                <Form.Item shouldUpdate noStyle>
                  {(formulario) => {
                    if (
                      !formulario.getFieldsValue()[refNombreFormList.current]
                    ) {
                      return null;
                    }
                    if (
                      !formulario.getFieldsValue()[refNombreFormList.current][
                        name
                      ]
                    ) {
                      return null;
                    }

                    return (
                      <>
                        <Form.Item
                          {...restField}
                          name={[name, props.operacionRelacional]}
                          label={props.labelOperacionRelacional}
                          rules={[
                            {
                              required: true,
                              message: "Debes seleccionar una opción",
                            },
                          ]}
                        >
                          <Select placeholder="Seleccionar una opción">
                            {formulario
                              .getFieldsValue()
                              [refNombreFormList.current][name]?.atributo.slice(
                                formulario
                                  .getFieldsValue()
                                  [refNombreFormList.current][
                                    name
                                  ]?.atributo.indexOf("-") + 1
                              ) == "text" ? (
                              <>
                                <Select.Option value="$eq">
                                  Igual a
                                </Select.Option>
                                <Select.Option value="$ne">
                                  Diferente de
                                </Select.Option>
                              </>
                            ) : (
                              <>
                                <Select.Option value="$gt">
                                  Mayor que
                                </Select.Option>
                                <Select.Option value="$lt">
                                  Menor que
                                </Select.Option>
                                <Select.Option value="$gte">
                                  Mayor igual que
                                </Select.Option>
                                <Select.Option value="$lte">
                                  Menor igual que
                                </Select.Option>
                                <Select.Option value="$eq">
                                  Igual a
                                </Select.Option>
                                <Select.Option value="$ne">
                                  Diferente de
                                </Select.Option>
                              </>
                            )}
                          </Select>
                        </Form.Item>
                      </>
                    );
                  }}
                </Form.Item>

                <Form.Item shouldUpdate noStyle>
                  {(formulario) => {
                    if (
                      !formulario.getFieldsValue()
                        .compararAtributoMultiConAtribGroupList
                    ) {
                      return null;
                    }
                    if (
                      !formulario.getFieldsValue()
                        .compararAtributoMultiConAtribGroupList[name]
                    ) {
                      return null;
                    }

                    return (
                      <>
                        {formulario.getFieldsValue()
                          .compararAtributoMultiConAtribGroupList[name][
                          props.nombreAtributo
                        ] &&
                          formulario
                            .getFieldsValue()
                            .compararAtributoMultiConAtribGroupList[name][
                              props.nombreAtributo
                            ].slice(
                              formulario
                                .getFieldsValue()
                                .compararAtributoMultiConAtribGroupList[name][
                                  props.nombreAtributo
                                ].indexOf("-") + 1
                            ) == "number" && (
                            <Form.Item
                              {...restField}
                              name={[name, props.valorAtributo]}
                              label={props.labelValorAtributo}
                              rules={[
                                {
                                  required: true,
                                  message: "Debes seleccionar un número",
                                },
                              ]}
                            >
                              <InputNumber placeholder="Ingresa un número" />
                            </Form.Item>
                          )}
                        {formulario.getFieldsValue()
                          .compararAtributoMultiConAtribGroupList[name][
                          props.nombreAtributo
                        ] &&
                          formulario
                            .getFieldsValue()
                            .compararAtributoMultiConAtribGroupList[name][
                              props.nombreAtributo
                            ].slice(
                              formulario
                                .getFieldsValue()
                                .compararAtributoMultiConAtribGroupList[name][
                                  props.nombreAtributo
                                ].indexOf("-") + 1
                            ) == "text" && (
                            <Form.Item
                              {...restField}
                              name={[name, props.valorAtributo]}
                              label={props.labelValorAtributo}
                              rules={[
                                {
                                  required: true,
                                  message: "Debes ingresar un dato",
                                },
                              ]}
                            >
                              <Input placeholder="Ingresa un número" />
                            </Form.Item>
                          )}
                        {formulario.getFieldsValue()
                          .compararAtributoMultiConAtribGroupList[name][
                          props.nombreAtributo
                        ] &&
                          formulario
                            .getFieldsValue()
                            .compararAtributoMultiConAtribGroupList[name][
                              props.nombreAtributo
                            ].slice(
                              formulario
                                .getFieldsValue()
                                .compararAtributoMultiConAtribGroupList[name][
                                  props.nombreAtributo
                                ].indexOf("-") + 1
                            ) == "date" && (
                            <Form.Item
                              {...restField}
                              name={[name, props.valorAtributo]}
                              label={props.labelValorAtributo}
                              rules={[
                                {
                                  required: true,
                                  message: "Debes ingresar una fecha",
                                },
                              ]}
                            >
                              <DatePicker />
                            </Form.Item>
                          )}
                      </>
                    );
                  }}
                </Form.Item>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <p
                    style={{
                      marginTop: "0",
                      marginButton: "0",
                      cursor: "pointer",
                    }}
                    onClick={() => remove(name)}
                  >
                    ELIMINAR
                  </p>
                </div>
              </Space>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Agregar campos
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
};

export default CompararAtributosMulti;
