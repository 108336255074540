function searchByC(str, tipo) {
  var reg;
  if (tipo === "cuenta") {
    reg = /\w*(?=}})/g;
  } else {
    reg = /\w*(?=])/g;
  }

  var texto = str.match(reg);
  // console.log("jahs  hh h hajshd");
  // console.log(texto);

  return texto || [];
}

export default searchByC;
