import { Button, List, Tag } from "antd";
import React from "react";
import Moment from "react-moment";
import { Api } from "../../../../../../api/configApi";
import { store } from "../../../../../../store";

function TablaHistorialAtributo(props) {
  const [registros, SetRegistros] = React.useState([]);
  const [cargando, setCargando] = React.useState(true);
  const skip = React.useRef(0);
  const limit = React.useRef(20);
  const [masDatos, setMasDatos] = React.useState(true);

  React.useEffect(() => {
    CargarData();
  }, []);

  function CargarData() {
    setCargando(true);
    const collectAtris = {
      text: "atributosTexto",
      number: "atributosNumero",
      date: "atributosFecha",
    };

    const atris = [
      ...store.losatributos.dates,
      ...store.losatributos.texts,
      ...store.losatributos.numbers,
    ];

    const att = atris.find((e) => e.nombre === props.atri.atributo);

    Api(collectAtris[att.tipo], "GetHistorial", {
      atributo: att.nombre,
      idAfiliado: props.idAfiliado,
      skip: skip.current,
      limit: limit.current,
    }).then((res) => {
      if (res.length < limit.current) {
        setMasDatos(false);
      }
      SetRegistros((dataa) => [...dataa, ...res]);
      setCargando(false);
    });
  }

  const cargarMas =
    !cargando && masDatos ? (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px",
        }}
      >
        <Button
          onClick={() => {
            skip.current += limit.current;
            CargarData();
          }}
        >
          Cargar más
        </Button>
      </div>
    ) : null;

  return (
    <List
      loadMore={cargarMas}
      loading={cargando}
      dataSource={registros}
      renderItem={(e) => (
        <div
          style={{
            background: "rgb(243 243 243)",
            padding: 10,
            margin: 10,
            borderRadius: 5,
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <strong>Valor</strong>
              <span>{e.valor}</span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <strong>Fecha</strong>
              <Moment
                style={{ fontSize: 12 }}
                locale="es"
                local
                format=" ddd D MMM YYYY"
              >
                {e.fecha}
              </Moment>
            </div>
          </div>
          <p
            style={{
              fontSize: 12,
              fontWeight: 300,
              marginTop: 10,
              borderTop: "1px dashed #acacac",
              paddingTop: 8,
            }}
          >
            {e.actualizadoPor && (
              <Tag color="green">{e.actualizadoPor.tipo}</Tag>
            )}

            {e.comentario}
          </p>
        </div>
      )}
    />
  );
}

export default TablaHistorialAtributo;
