import { Form, Input, Select } from "antd";
import React from "react";
import { store } from "../../../../../../store";

const ListarListados = (props) => {
  return (
    <>
      <Form.Item name="nombreFuncion" noStyle>
        <Input style={{ display: "none" }} />
      </Form.Item>

      <Form.Item shouldUpdate>
        {(formu) => {
          return (
            <>
              <Form.Item
                name={props.nombreListado}
                label={props.labelNombreListado}
                rules={[
                  {
                    required: true,
                    message: "Debes seleccionar un listado",
                  },
                ]}
              >
                <Select
                  style={{ width: "310px" }}
                  onSelect={() => {
                    if (formu.getFieldValue(props.valorCampoListener) != "") {
                      formu.setFieldsValue({ [props.valorCampoListener]: "" });
                    }
                  }}
                  placeholder="Selecciona una opción"
                >
                  {store.listados.map((listado, index) => (
                    <Select.Option key={index} value={listado._id}>
                      {listado.nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          );
        }}
      </Form.Item>
    </>
  );
};

export default ListarListados;
