import DesignWidgetAdminRecursos from "../../../../components/widgets/tipos/Admin Recursos/design/DesignWidgetAdminRecursos";
import DesignWidgetListaRecursos from "../../../../components/widgets/tipos/Lista recursos/design/DesignWidgetListaRecursos";
import DesignWidgetDebitoCredito from "../../../../components/widgets/tipos/debitoCredito/design/DesignWidgetDebitoCredito";
import DesignWidgetTexto from "../../../../components/widgets/tipos/texto/design/DesignWidgetTexto";
import DesignWidgetObjetivo from "../../../../components/widgets/tipos/objetivo/design/DesignWidgetObjetivo";
import DesignWidgetBanner from "../../../../components/widgets/tipos/banner/design/DesignWidgetBanner";
import DesignWidgetLogros from "../../../../components/widgets/tipos/logros/design/DesignWidgetLogros";
import DesignWidgetNumero from "../../../../components/widgets/tipos/numero/design/DesignWidgetNumero";
import DesignWidgetLink from "../../../../components/widgets/tipos/link/design/DesignWidgetLink";
import DesignWidgetArbolGenealogico from "../../../../components/widgets/tipos/arbolGenealogico/design/DesignWidgetArbolGenealogico";
import DesignWidgetWoocommerce from "../../../../components/widgets/tipos/woocommerce/design/DesignWidgetWoocommerce";

import DesignWidgetVideo from "../../../../components/widgets/tipos/video/design/DesignWidgetVideo";
import DesignWidgetUploadFiles from "../../../../components/widgets/tipos/uploadFiles/design/DesignWidgetUploadFiles";
import DesignWidgetsComponentes from "../../../../frontDEV1/widgets/DesignWidgetsComponentes";
import DesignWidgetTabla from "../../../../frontDEV1/widgets/tipos/tabla/design/DesignWidgetTabla";
import React from "react";

function Widget(props) {
  var widget;
  switch (props.widget.tipo) {
    case "numero":
      widget = <DesignWidgetNumero {...props.widget} />;
      break;
    case "texto":
      widget = <DesignWidgetTexto {...props.widget} />;
      break;
    case "objetivo":
      widget = <DesignWidgetObjetivo {...props.widget} />;
      break;
    case "link":
      widget = <DesignWidgetLink {...props.widget} className="widgetShadow" />;
      break;
    case "woocommerce":
      widget = <DesignWidgetWoocommerce {...props.widget} />;
      break;
    case "tabla":
      widget = <DesignWidgetTabla {...props.widget} />;
      break;
    case "arbolGenealogico":
      widget = <DesignWidgetArbolGenealogico {...props.widget} />;
      break;
    case "adminRecursos":
      widget = <DesignWidgetAdminRecursos {...props.widget} />;
      break;
    case "listaRecursos":
      widget = <DesignWidgetListaRecursos {...props.widget} />;
      break;
    case "debitoCredito":
      widget = <DesignWidgetDebitoCredito {...props.widget} />;
      break;
    case "uploadFiles":
      widget = <DesignWidgetUploadFiles {...props.widget} />;
      break;
    case "banner":
      widget = <DesignWidgetBanner {...props.widget} />;
      break;
    case "logros":
      widget = <DesignWidgetLogros {...props.widget} />;
      break;
    case "video":
      widget = <DesignWidgetVideo {...props.widget} />;
      break;

    default:
      break;
  }
  if (!widget) {
    widget = <DesignWidgetsComponentes widget={props.widget} />;
  }

  return widget;
}

export default Widget;
