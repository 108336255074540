import React, { useEffect, useState } from "react";
import { scaleLinear } from "d3-scale";
import {
  ComposableMap,
  Geographies,
  Geography,
  Sphere,
  Graticule,
} from "react-simple-maps";
import { Api } from "../../../../../../api/configApi";
import { Popover } from "antd";

const geoUrl =
  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

const LugaresMundo = (props) => {
  const [data, setData] = useState([]);
  const [totalPaises, setTotalPaises] = React.useState(0);
  const [rangoColor, setRangoColor] = React.useState({ desde: "", hasta: "" });

  const colorScale = scaleLinear()
    .domain([0, totalPaises])
    .range([rangoColor.desde, rangoColor.hasta]);

  useEffect(() => {
    if (props.widget) {
      setRangoColor({
        desde: props.widget.colorDesde,
        hasta: props.widget.colorHasta,
      });
      Api("widgets", "Mapas", {
        tipo: props.widget.lugar,
        afiliados: props.widget.cualesAfiliados,
      }).then((paises) => {
        // sumar los paises paises[0].cantidad
        console.log(paises, "losPasies..");
        var sum = 0;
        paises.forEach((pais) => {
          sum += pais.cantidad;
        });
        console.log(paises);
        setTotalPaises(sum);
        setData(paises);
      });
    }
  }, []);

  return (
    <>
      <ComposableMap
        projectionConfig={{
          rotate: [-10, 0, 0],
          scale: 147,
        }}
      >
        <Sphere stroke="#E4E5E6" strokeWidth={0.5} />
        <Graticule stroke="#E4E5E6" strokeWidth={0.5} />
        {data.length > 0 && (
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const d = data.find((s) => s._id === geo.properties.ISO_A2);
                return (
                  <Popover
                    title={geo.properties.NAME}
                    content={d ? d.cantidad : "0"}
                  >
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fill={d ? colorScale(d.cantidad) : "#F5F4F6"}
                    />
                  </Popover>
                );
              })
            }
          </Geographies>
        )}
      </ComposableMap>
    </>
  );
};

export default LugaresMundo;
