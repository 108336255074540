import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Drawer, message, Popconfirm, Space, Table } from "antd";
import React from "react";
import { Api } from "../../../../api/configApi";
import Boton from "../../../../components/design/elementos/boton";
import { setStoreActualizarLaEstructura, store } from "../../../../store";
import FormCrearEmail from "./formCrearEmail";

function RenderEmails() {
  const [drawerCrearNotificacion, setDrawerCrearEmail] = React.useState(false);
  const [emailActual, setEmailActual] = React.useState(null);
  function eliminarEmail(not) {
    Api(
      "Cuentas",
      "DeleteEmails",
      {
        _id: store.cuentaactiva,
      },
      {
        notificacion: not._id,
      }
    ).then((res) => {
      if (res.success) {
        message.success("Email eliminado");
        setStoreActualizarLaEstructura();
      } else {
        message.error("Algo no salio bien");
      }
    });
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Asunto",
      dataIndex: "asunto",
      key: "asunto",
    },
    {
      title: "",
      dataIndex: "opciones",
      key: "opciones",
      render: (e, not) => {
        return (
          <Space>
            <FontAwesomeIcon
              onClick={() => {
                setEmailActual(not);
                setDrawerCrearEmail(true);
              }}
              style={{ cursor: "pointer" }}
              icon={faEdit}
            />
            <Popconfirm
              title="¿Estás seguro?"
              onConfirm={() => {
                eliminarEmail(not);
              }}
            >
              <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faTrash} />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  return (
    <div>
      <Drawer
        title="Email"
        visible={drawerCrearNotificacion}
        onClose={() => {
          setDrawerCrearEmail(false);
        }}
        width={700}
      >
        <FormCrearEmail
          emailActual={emailActual}
          setDrawerCrearNotificacion={setDrawerCrearEmail}
        />
      </Drawer>
      <div style={{ textAlign: "right", marginBottom: 10 }}>
        <Boton
          onClick={() => {
            setEmailActual(null);
            setDrawerCrearEmail(true);
          }}
          texto="Crear Email"
        />
      </div>
      <Table dataSource={store.emails} columns={columns} />
    </div>
  );
}

export default RenderEmails;
