import { DatePicker, Form } from "antd";
import React from "react";

const FechaPicker = (props) => {
  return (
    <>
      <Form.Item
        name={props.fecha}
        label={props.labelFecha}
        rules={[
          {
            required: true,
            message: "Debes llenar este campo",
          },
        ]}
      >
        <DatePicker />
      </Form.Item>
    </>
  );
};

export default FechaPicker;
