import React from "react";
import { Button, Form, Input, InputNumber, Select, Space } from "antd";
import { store } from "../../../../../../store";
import { PlusOneOutlined } from "@material-ui/icons";

const CompararCamposListadoMulti = (props) => {
  const refNombreLista = React.useRef("compararCamposListadosMultiGroupList");

  function renderSelect(campo, index) {
    return (
      <>
        <Select.Option
          key={index}
          value={campo.NombreCampo + "-" + campo.tipoCampo}
        >
          {campo.NombreCampo}
        </Select.Option>
      </>
    );
  }

  return (
    <>
      <Form.List name="compararCamposListadosMultiGroupList">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space
                key={key}
                style={{
                  display: "flex",
                  marginBottom: 8,
                  backgroundColor: "#fcfcfc",
                  padding: "20px",
                  borderRadius: "10px",
                }}
                direction="vertical"
              >
                <Form.Item shouldUpdate noStyle>
                  {(formulario) => {
                    if (!formulario.getFieldsValue()[props.dependencia]) {
                      return null;
                    }
                    return (
                      <>
                        <Form.Item
                          {...restField}
                          name={[name, props.nombreCampo]}
                          label={props.labelNombreCampo}
                          rules={[
                            {
                              required: true,
                              message:
                                "Debes seleccionar una opción",
                            },
                          ]}
                        >
                          <Select placeholder="Selecciona una opción">
                            {store.listados
                              .find(
                                (e) =>
                                  e._id ==
                                  formulario.getFieldsValue()[props.dependencia]
                              )
                              .campos.map((campo, index) => {
                                return (
                                  <>
                                    {(campo.tipoCampo == props.filtro ||
                                      props.filtro == "todos") &&
                                      renderSelect(campo, index)}
                                  </>
                                );
                              })}
                          </Select>
                        </Form.Item>
                      </>
                    );
                  }}
                </Form.Item>
                <Form.Item shouldUpdate noStyle>
                  {(formulario) => {
                    if (!formulario.getFieldsValue()[refNombreLista.current]) {
                      return null;
                    }
                    if (
                      !formulario.getFieldsValue()[refNombreLista.current][name]
                    ) {
                      return null;
                    }

                    return (
                      <>
                        <Form.Item
                          {...restField}
                          name={[name, props.operacionRelacional]}
                          label={props.labelOperacionRelacional}
                          rules={[
                            {
                              required: true,
                              message: "Debes seleccionar una opción",
                            },
                          ]}
                        >
                          <Select placeholder="Selecciona una opción">
                            {formulario
                              .getFieldsValue()
                              [refNombreLista.current][name].idCampo.slice(
                                formulario
                                  .getFieldsValue()
                                  [refNombreLista.current][
                                    name
                                  ].idCampo.indexOf("-") + 1
                              ) == "texto" ? (
                              <>
                                <Select.Option value="$eq">
                                  Igual a
                                </Select.Option>
                                <Select.Option value="$ne">
                                  Diferente de
                                </Select.Option>
                              </>
                            ) : formulario
                                .getFieldsValue()
                                [refNombreLista.current][name].idCampo.slice(
                                  formulario
                                    .getFieldsValue()
                                    [refNombreLista.current][
                                      name
                                    ].idCampo.indexOf("-") + 1
                                ) == "multiple" ? (
                              <>
                                <Select.Option value="$eq">
                                  Igual a
                                </Select.Option>
                                <Select.Option value="$ne">
                                  Diferente de
                                </Select.Option>
                              </>
                            ) : (
                              <>
                                <Select.Option value="$gt">
                                  Mayor que
                                </Select.Option>
                                <Select.Option value="$lt">
                                  Menor que
                                </Select.Option>
                                <Select.Option value="$gte">
                                  Mayor igual que
                                </Select.Option>
                                <Select.Option value="$lte">
                                  Menor igual que
                                </Select.Option>
                                <Select.Option value="$eq">
                                  Igual a
                                </Select.Option>
                                <Select.Option value="$ne">
                                  Diferente de
                                </Select.Option>
                              </>
                            )}
                          </Select>
                        </Form.Item>
                      </>
                    );
                  }}
                </Form.Item>
                <Form.Item shouldUpdate noStyle>
                  {(formulario) => {
                    if (
                      !formulario.getFieldsValue()
                        .compararCamposListadosMultiGroupList
                    ) {
                      return null;
                    }

                    if (
                      !formulario.getFieldsValue()
                        .compararCamposListadosMultiGroupList[name]
                    ) {
                      return null;
                    }

                    return (
                      <>
                        {formulario.getFieldsValue()
                          .compararCamposListadosMultiGroupList[name] &&
                          formulario
                            .getFieldsValue()
                            .compararCamposListadosMultiGroupList[name][
                              props.nombreCampo
                            ].slice(
                              formulario
                                .getFieldsValue()
                                .compararCamposListadosMultiGroupList[name][
                                  props.nombreCampo
                                ].indexOf("-") + 1
                            ) == "numero" && (
                            <Form.Item
                              {...restField}
                              name={[name, props.valorCampo]}
                              label={props.labelValorCampo}
                              rules={[
                                {
                                  required: true,
                                  message: "Debes seleccionar una opción",
                                },
                              ]}
                            >
                              <InputNumber placeholder="Ingresa un número" />
                            </Form.Item>
                          )}
                        {formulario.getFieldsValue()
                          .compararCamposListadosMultiGroupList[name] &&
                          formulario
                            .getFieldsValue()
                            .compararCamposListadosMultiGroupList[name][
                              props.nombreCampo
                            ].slice(
                              formulario
                                .getFieldsValue()
                                .compararCamposListadosMultiGroupList[name][
                                  props.nombreCampo
                                ].indexOf("-") + 1
                            ) == "texto" && (
                            <Form.Item
                              {...restField}
                              name={[name, props.valorCampo]}
                              label={props.labelValorCampo}
                              rules={[
                                {
                                  required: true,
                                  message: "Debes seleccionar una opción",
                                },
                              ]}
                            >
                              <Input placeholder="Ingresa un dato" />
                            </Form.Item>
                          )}
                        {formulario.getFieldsValue()
                          .compararCamposListadosMultiGroupList[name] &&
                          formulario
                            .getFieldsValue()
                            .compararCamposListadosMultiGroupList[name][
                              props.nombreCampo
                            ].slice(
                              formulario
                                .getFieldsValue()
                                .compararCamposListadosMultiGroupList[name][
                                  props.nombreCampo
                                ].indexOf("-") + 1
                            ) == "multiple" && (
                            <Form.Item
                              {...restField}
                              name={[name, props.valorCampo]}
                              label={props.labelValorCampo}
                              rules={[
                                {
                                  required: true,
                                  message: "Debes seleccionar una opción",
                                },
                              ]}
                            >
                              <Select placeholder="Selecciona una opción">
                                {store.listados
                                  .find((lis) => {
                                    return (
                                      lis._id ==
                                      formulario.getFieldsValue().idListado
                                    );
                                  })
                                  .campos.find((camp) => {
                                    return (
                                      camp.NombreCampo ==
                                      formulario
                                        .getFieldsValue()
                                        .compararCamposListadosMultiGroupList[
                                          name
                                        ][props.nombreCampo].slice(
                                          0,
                                          formulario
                                            .getFieldsValue()
                                            .compararCamposListadosMultiGroupList[
                                              name
                                            ][props.nombreCampo].indexOf("-")
                                        )
                                    );
                                  })
                                  .opciones.map((op, index) => {
                                    return (
                                      <Select.Option
                                        key={index}
                                        value={op.opcion}
                                      >
                                        {op.opcion}
                                      </Select.Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                          )}
                      </>
                    );
                  }}
                </Form.Item>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <p
                    style={{
                      marginTop: "0",
                      marginButton: "0",
                      cursor: "pointer",
                    }}
                    onClick={() => remove(name)}
                  >
                    ELIMINAR
                  </p>
                </div>
              </Space>
            ))}

            <Form.Item shouldUpdate>
              {(formu) => {
                function deshabilitarAdd() {
                  if (formu.getFieldsValue().idListado) {
                    return false;
                  } else {
                    return true;
                  }
                }

                return (
                  <>
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        disabled={deshabilitarAdd()}
                        block
                        icon={<PlusOneOutlined />}
                      >
                        Agregar campos
                      </Button>
                    </Form.Item>
                  </>
                );
              }}
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
};

export default CompararCamposListadoMulti;
