import { Divider, Drawer, Input, message } from "antd";
import React from "react";
import { store } from "../../../store";
import ListadoItemsDocumentacion from "./ListadoItemsDocumentacion";
import Hotkeys from "react-hot-keys";
import AgregarNuevoItemDocumentacion from "./AgregarNuevoItemDocumentacion";
import { Api } from "../../../api/configApi";

function IndexDocumentacion() {
  const [filtro, setFiltro] = React.useState("");
  const [visible, setVisible] = React.useState(false);
  const [cargando, setCargando] = React.useState(false);
  const [items, setItems] = React.useState([]);
  const cargarItems = () => {
    setCargando(true);
    Api("documentacion", "getItems")
      .then((data) => {
        setCargando(false);
        if (data.success) {
          setItems(data.items);
        }
      })
      .catch((err) => {
        setCargando(false);
        message.error("Algo no salio bien.");
      });
  };
  return (
    <>
      <Hotkeys
        keyName="shift+d"
        onKeyDown={() => {
          setVisible(!visible);
        }}
      />

      <Drawer
        visible={visible}
        onClose={() => setVisible(false)}
        placement="left"
        width={1000}
        title="Documentación"
      >
        <AgregarNuevoItemDocumentacion />
        <Divider />
        <Input.Search onSearch={(e) => setFiltro(e)} />
        <ListadoItemsDocumentacion
          cargarItems={cargarItems}
          setCargando={setCargando}
          cargando={cargando}
          filtro={filtro}
          items={items}
        />
      </Drawer>
    </>
  );
}

export default IndexDocumentacion;
