import React from "react";
import { useFormContext } from "react-hook-form";
import { useFieldArray } from "react-hook-form";
import {
  ButtonT,
  InputNumberT,
  InputT,
  OptionT,
  SelectT,
} from "../../../../../../plugins/Formularios/FormularioTecclas";
import ListRespuestas from "./ListRespuestas";

const ListPreguntas = () => {
  const {
    register,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "preguntas",
  });

  return (
    <>
      <div style={{ padding: "5px" }}>
        {fields.map((item, index) => {
          return (
            <div
              key={item.id}
              style={{
                marginBottom: "10px",
                backgroundColor: "#fafafa",
                padding: "4px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  padding: "10px",
                }}
              >
                <label style={{ marginBottom: "10px" }}>
                  Escribe tu pregunta
                </label>
                <InputT
                  control={control}
                  name={`preguntas.${index}.pregunta`}
                  placeholder="Escribe tu pregunta"
                  rules={{ required: true }}
                ></InputT>
                {/* <input
                  style={{
                    border: "thin solid rgb(118 118 118)",
                    borderRadius: "3px",
                    width: "100%",
                  }}
                  type="text"
                  {...register(`preguntas.${index}.pregunta`, {
                    required: true,
                  })}
                /> */}
                {errors.preguntas &&
                  errors.preguntas[index] &&
                  errors.preguntas[index].pregunta && (
                    <span>Por favor ingresa tu pregunta</span>
                  )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  padding: "10px",
  
                }}
              >
                <label style={{ marginBottom: "10px" }}>
                  Elige tu tipo de respuesta
                </label>
                <SelectT
                  control={control}
                  name={`preguntas.${index}.tipoRespuestas`}
                  rules={{ required: true }}
                  placeholder="Elija una opción"
                  ancho="400px"
                >
                  <OptionT value={"texto"}>Respuesta tipo texto</OptionT>
                  <OptionT value={"numero"}>Respuesta tipo número</OptionT>
                  <OptionT value={"unico"}>
                    Selección con única respuesta
                  </OptionT>
                  <OptionT value={"multiple"}>
                    Selección con múltple respuesta
                  </OptionT>
                </SelectT>
                {/* <select
                  style={{ width: "100%" }}
                  {...register(`preguntas.${index}.tipoRespuestas`, {
                    required: true,
                  })}
                >
                  <option hidden value="">
                    Seleccione el tipo de las respuestas
                  </option>
                  <option value={"texto"}>Respuesta tipo texto</option>
                  <option value={"numero"}>Respuesta tipo número</option>
                  <option value={"unico"}>
                    Selección con única respuesta{" "}
                  </option>
                  <option value={"multiple"}>
                    Selección con múltple respuesta
                  </option>
                </select> */}
                {errors.preguntas &&
                  errors.preguntas[index] &&
                  errors.preguntas[index].tipoRespuestas && (
                    <span>Por favor ingresa tu pregunta</span>
                  )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  padding: "10px",
                }}
              >
                <label style={{ marginBottom: "10px" }}>
                  Seleccione, sí tu pregunta es obligatoria:
                </label>

                <SelectT
                  control={control}
                  name={`preguntas.${index}.obligatoria`}
                  rules={{ required: true }}
                  placeholder="Elija una opción"
                  ancho="400px"
                >
                  <OptionT value={`{"boll":true}`}>Sí</OptionT>
                  <OptionT value={`{"boll":false}`}>No</OptionT>
                </SelectT>

                {/* <select
                  style={{ width: "100%" }}
                  {...register(`preguntas.${index}.obligatoria`, {
                    required: true,
                  })}
                >
                  <option hidden value="">
                    La pregunta es obligatoria
                  </option>
                  <option value={`{"boll":true}`}>Sí</option>
                  <option value={`{"boll":false}`}>No</option>
                </select> */}
                {errors.preguntas &&
                  errors.preguntas[index] &&
                  errors.preguntas[index].obligatoria && (
                    <span>Por favor ingresa tu pregunta</span>
                  )}
              </div>

              {watch(`preguntas.${index}.tipoRespuestas`) === "texto" ||
              watch(`preguntas.${index}.tipoRespuestas`) === "numero" ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                      padding: "10px",
                    }}
                  >
                    <label style={{ marginBottom: "10px" }}>
                      Ingresa el peso la respuesta
                    </label>
                    <InputNumberT
                      control={control}
                      name={`preguntas.${index}.pesoPregAbierta`}
                      rules={{ required: true }}
                    ></InputNumberT>
                    {/* <input
                      style={{
                        border: "thin solid rgb(118 118 118)",
                        borderRadius: "3px",
                        width: "100%",
                      }}
                      type="number"
                      {...register(`preguntas.${index}.pesoPregAbierta`, {
                        required: true,
                      })}
                    /> */}
                    {errors.preguntas &&
                      errors.preguntas[index] &&
                      errors.preguntas[index].pesoPregAbierta && (
                        <span>
                          Por favor ingresa el peso que va tener la respuesta
                        </span>
                      )}
                  </div>
                </>
              ) : (
                (watch(`preguntas.${index}.tipoRespuestas`) === "unico" ||
                  watch(`preguntas.${index}.tipoRespuestas`) ===
                    "multiple") && (
                  <ListRespuestas indice={index}></ListRespuestas>
                )
              )}
              <div style={{ padding: "10px", display:"flex", justifyContent:"center" }}>
                <ButtonT
                  type="secondary"
                  onClick={() => {
                    remove(index);
                  }}
                >
                  Eliminar pregunta
                </ButtonT>
                {/* <button type="button" onClick={() => remove(index)}>
                  Eliminar pregunta
                </button> */}
              </div>
            </div>
          );
        })}
        <div style={{ padding: "10px" }}>
          <ButtonT type="primary" onClick={() => append({ pregunta: "" })}>
            Agregar pregunta
          </ButtonT>
          {/* <button type="button" onClick={() => append({ pregunta: "" })}>
            Agregar pregunta
          </button> */}
        </div>
      </div>
    </>
  );
};

export default ListPreguntas;
