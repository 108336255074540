import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { setMostrarmenu, setPensando, store } from "../store";

function Inicio(props) {
  const history = useHistory();

  React.useEffect(() => {
    if (store.user.tipo === "afiliado") {
      if (store.menuFront.length > 0) {
        const pestana = store.menuFront[0];
        history.push(`/page/${pestana.id}`);
      } else {
        history.push("/");
      }
    } else {
      if (store.menuAdmin.length > 0) {
        const pestana = store.menuAdmin[0];
        history.push(`/page/${pestana.id}`);
      } else {
        history.push("/");
      }
    }

    setMostrarmenu(true);
  }, [store.menuFront, store.menuAdmin]);

  return <span></span>;
}

export default Inicio;
