import React from "react";
import { useForm } from "react-hook-form";
import { Api } from "../../../../../../api/configApi";
import { Col, Input, Row, Typography } from "antd";
import { ButtonT } from "../../../../../../plugins/Formularios/FormularioTecclas";
import { CloseCircleOutlined } from "@ant-design/icons";
import Modal2 from "./Modal2";
import TarjetaCurso from "./TarjetaCurso";
import Boton from "../../../../../../components/design/elementos/boton";

const VistaPrincipal = (props) => {
  const methods = useForm();

  const [listaCursos, setListaCursos] = React.useState();
  const [listaCursosBuscadas, setListaCursosBuscadas] = React.useState([]);
  const [mostrarEliminarCurso, setMostrarEliminarCurso] = React.useState(false);

  function obtenerCursos(idw) {
    Api(
      "academia",
      "obtenerCursos",
      { idWidget: idw },
      {},
      "https://ed3a-181-132-3-197.ngrok.io"
    )
      .then((res) => {
        setListaCursos(res.cursos);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  React.useEffect(() => {
    if (props.widget._id) {
      obtenerCursos(props.widget._id);
    }
  }, [props]);

  function buscarCursos(x) {
    let listaCursosActualizados = [];
    if (x === "") {
      setMostrarEliminarCurso(true);
    } else {
      listaCursos.forEach((ele, i) => {
        if (ele.nombreCurso.toLowerCase().includes(x.toLowerCase())) {
          listaCursosActualizados.push(
            ...listaCursos.filter(
              (elem) => elem.nombreCurso === ele.nombreCurso
            )
          );
        } else {
          if (listaCursosActualizados.length === 0) {
            setMostrarEliminarCurso(true);
          }
        }
      });
      setListaCursosBuscadas((x) => {
        return (x = [...listaCursosActualizados]);
      });
    }
  }

  return (
    <>
      <div style={{ width: "100%" }}>
        <div style={{ padding: "10px" }}>
          <h1 style={{ fontSize: "28px", margin: "0px", textAlign: "center" }}>
            {props.widget.nombreAcademia}
          </h1>
        </div>

        <div style={{ display: "flex", padding: "10px" }}>
          <Input.Search placeholder="Buscar un curso" onSearch={buscarCursos}></Input.Search>
        </div>
        <Modal2
          mostrar={mostrarEliminarCurso}
          setMostrar={setMostrarEliminarCurso}
          alto="300px"
          ancho="700px"
          color="#ffffff"
        >
          <div style={{ margin: "20px" }}>
            <Typography.Title level={2}>
              No se encontraron cursos
            </Typography.Title>
            <div>
              <CloseCircleOutlined
                style={{ fontSize: "130px", color: "red" }}
              />
            </div>
            <div
              style={{
                margin: "10px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ButtonT
                onClick={() => setMostrarEliminarCurso(false)}
                style={{
                  marginLeft: "5px",
                }}
                danger
              >
                Cerrar
              </ButtonT>
            </div>
          </div>
        </Modal2>
        {listaCursosBuscadas.length != 0 && (
          <>
            <div style={{display:"flex", justifyContent:"center", padding:"10px 0 0 0"}}>
              <Boton
                onClick={() => setListaCursosBuscadas((x) => (x = []))}
              >
                Mostrar todos los cursos
              </Boton>
            </div>
          </>
        )}

        {listaCursosBuscadas.length != 0 ? (
          <>
            <div
              style={{
                marginTop: "10px",
                padding: "10px",
                height: "640px",
                overflow: "hidden",
                overflowY: "scroll",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "start",
              }}
            >
              {listaCursosBuscadas?.map((curso, index) => {
                return (
                  <>
                    <TarjetaCurso
                      key={index}
                      titulo={curso.nombreCurso}
                      descripcion={curso.descripcionCurso}
                      urlImagen={curso.urlImagen}
                      lecciones={curso.lecciones}
                    ></TarjetaCurso>
                  </>
                );
              })}
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                marginTop: "10px",
                padding: "10px",
                height: "640px",
                overflow: "hidden",
                overflowY: "scroll",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "start",
              }}
            >
              {listaCursos?.map((curso, index) => {
                return (
                  <>
                    <TarjetaCurso
                      key={index}
                      titulo={curso.nombreCurso}
                      descripcion={curso.descripcionCurso}
                      urlImagen={curso.urlImagen}
                      lecciones={curso.lecciones}
                    ></TarjetaCurso>
                  </>
                );
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default VistaPrincipal;
