import React from "react";
import { store } from "../../../../store";
import DibujarWidgetComponente from "./DibujarWidgetComponente";
import { observer } from "mobx-react";

function DesignFilaComponente(props) {
  const [widgetsAutorizados, setWidgetsAutorizados] = React.useState([]);

  React.useEffect(() => {
    const widgetsAutorizados = props.fila.filter((widget) => {
      return store.widgets.find((wid) => {
        return wid._id === widget._id;
      });
    });

    setWidgetsAutorizados(widgetsAutorizados);
  }, [props.widgets, store.widgets]);

  return (
    <div style={estilos.fila}>
      {widgetsAutorizados.map((wid, index) => {
        return (
          <DibujarWidgetComponente
            produccion={props.produccion}
            indexWidget={index}
            indexfila={props.index}
            widgets={props.widgets}
            setWidgets={props.setWidgets}
            fila={props.fila}
            key={wid._id}
            widget={wid}
            tipoMenu={props.tipoMenu}
          />
        );
      })}
    </div>
  );
}

const estilos = {
  fila: {
    display: "flex",
    flexDirection: "row",
    padding: 10,
    marginTop: 10,
    marginBottom: 10,
    flexWrap: "wrap",
  },
};

export default observer(DesignFilaComponente);
