import { List } from "antd";
import React from "react";
import { Api } from "../../../../../../api/configApi";
import { store } from "../../../../../../store";

function IndexPerfilAfiliado(props) {
  const [perfil, setPerfil] = React.useState([]);
  React.useEffect(() => {
    // console.log(props.infoAfiliado._id);
    Api(
      "afiliados",
      "perfilAfiliado",
      { idAfiliado: props.infoAfiliado._id },
      {}
    ).then((res) => {
      console.log(res);
      setPerfil(res.campos);
    });
  }, []);
  return (
    <>
      {" "}
      <List
        itemLayout="horizontal"
        dataSource={perfil}
        renderItem={(item) => (
          <List.Item>
            <strong>{item.key}</strong>
            <span>{item.valor}</span>
          </List.Item>
        )}
      />
    </>
  );
}

export default IndexPerfilAfiliado;
