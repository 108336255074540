import React from "react";
import CompararAtributos from "./ComponentesFormulario/CompararAtributos";
import CompararAtributosMulti from "./ComponentesFormulario/CompararAtributosMulti";
import CompararAtributosMultiConAtrib from "./ComponentesFormulario/CompararAtributosMultiConAtrib";
import CompararCamposListadoMulti from "./ComponentesFormulario/CompararCamposLIstadoMulti";
import CompararCamposListado from "./ComponentesFormulario/CompararCamposListado";
import FechaPicker from "./ComponentesFormulario/FechaPicker";
import Interruptor from "./ComponentesFormulario/Interruptor";
import ListarAtributos from "./ComponentesFormulario/ListarAtributos";
import ListarListados from "./ComponentesFormulario/ListarListados";
import ListarOpciones from "./ComponentesFormulario/ListarOpciones";
import RangoFechas from "./ComponentesFormulario/RangoFechas";

const IndexGrupoCampos = (props) => {
  React.useEffect(() => {}, [props]);

  function tiposFormato() {
    switch (props.consulta.tipo) {
      case "CompararAtributos":
        return (
          <>
            <CompararAtributos
              nombreAtributo={props.consulta.nombreAtributo}
              operacionRelacional={props.consulta.operacionRelacional}
              labelOperacionRelacional={props.consulta.labelOperacionRelacional}
              valorAtributo={props.consulta.valorAtributo}
              labelValorAtributo={props.consulta.labelValorAtributo}
            />
          </>
        );

      case "CompararAtributosMulti":
        return (
          <>
            <CompararAtributosMulti
              nombreAtributo={props.consulta.nombreAtributo}
              operacionRelacional={props.consulta.operacionRelacional}
              labelOperacionRelacional={props.consulta.labelOperacionRelacional}
              valorAtributo={props.consulta.valorAtributo}
              labelValorAtributo={props.consulta.labelValorAtributo}
            />
          </>
        );

      case "CompararAtributosMultiConAtrib":
        return (
          <>
            <CompararAtributosMultiConAtrib
              nombreAtributo={props.consulta.nombreAtributo}
              labelNombreAtributo={props.consulta.labelNombreAtributo}
              operacionRelacional={props.consulta.operacionRelacional}
              labelOperacionRelacional={props.consulta.labelOperacionRelacional}
              valorAtributo={props.consulta.valorAtributo}
              labelValorAtributo={props.consulta.labelValorAtributo}
              filtro={props.consulta.filtro}
            />
          </>
        );

      case "CompararCamposListado":
        return (
          <>
            <CompararCamposListado
              nombreCampo={props.consulta.nombreCampo}
              labelNombreCampo={props.consulta.labelNombreCampo}
              operacionRelacional={props.consulta.operacionRelacional}
              labelOperacionRelacional={props.consulta.labelOperacionRelacional}
              valorCampo={props.consulta.valorCampo}
              labelValorCampo={props.consulta.labelValorCampo}
              dependencia={props.consulta.dependencia}
              filtro={props.consulta.filtro}
            />
          </>
        );

      case "CompararCamposListadoMulti":
        return (
          <>
            <CompararCamposListadoMulti
              nombreCampo={props.consulta.nombreCampo}
              labelNombreCampo={props.consulta.labelNombreCampo}
              operacionRelacional={props.consulta.operacionRelacional}
              labelOperacionRelacional={props.consulta.labelOperacionRelacional}
              valorCampo={props.consulta.valorCampo}
              labelValorCampo={props.consulta.labelValorCampo}
              dependencia={props.consulta.dependencia}
              filtro={props.consulta.filtro}
            />
          </>
        );

      case "FechaPicker":
        return (
          <>
            <FechaPicker
              fecha={props.consulta.fecha}
              labelFecha={props.consulta.labelFecha}
            />
          </>
        );

      // case "FechaPickerMulti":
      //     return (
      //         <>
      //         <FechaPickerMulti fecha={} labelFecha={}/>
      //         </>
      //     );

      case "Interruptor":
        return (
          <>
            <Interruptor
              interruptor={props.consulta.interruptor}
              labelInterruptor={props.consulta.labelInterruptor}
            />
          </>
        );

      //     case "InterruptorMulti":
      //     return (
      //         <>
      //         <InterruptorMulti interruptor={} labelInterruptor={}/>
      //         </>
      //     );

      case "ListarAtributos":
        return (
          <>
            <ListarAtributos
              nombreAtributo={props.consulta.nombreAtributo}
              labelNombreAtributo={props.consulta.labelNombreAtributo}
              filtro={props.consulta.filtro}
              valorAtributoListener={props.consulta.valorAtributoListener}
            />
          </>
        );

      //     case "ListarAtributosMulti":
      //     return (
      //         <>
      //         <ListarAtributosMulti nombreAtributo={} labelNombreAtributo={}/>
      //         </>
      //     );

      case "ListarListados":
        return (
          <>
            <ListarListados
              nombreListado={props.consulta.nombreListado}
              labelNombreListado={props.consulta.labelNombreListado}
              valorCampoListener={props.consulta.valorCampoListener}
            />
          </>
        );

      // case "ListarListadosMulti":
      // return (
      //     <>
      //     <ListarListadosMulti nombreListado={} labelNombreListado={}/>
      //     </>
      // );

      case "ListarOpciones":
        return (
          <>
            <ListarOpciones
              nombreOpcion={props.consulta.nombreOpcion}
              labelNombreOpcion={props.consulta.labelNombreOpcion}
              listadoOpciones={props.consulta.listadoOpciones}
            />
          </>
        );

      // case "ListarOpcionesMulti":
      // return (
      //     <>
      //     <ListarOpcionesMulti nombreOpcion={} labelNombreOpcion={} listadoOpciones={}/>
      //     </>
      // );

      case "RangoFechas":
        return (
          <>
            <RangoFechas
              rangoFechas={props.consulta.rangoFechas}
              labelRangoFechas={props.consulta.labelRangoFechas}
            />
          </>
        );

      // case "RangoFechaMulti":
      // return (
      //     <>
      //     <RangoFechaMulti rangoFechas={props.consulta.rangoFechas} labelRangoFechas={props.consulta.labelRangoFechas}/>
      //     </>
      // );

      default:
        return (
          <>
            <p>por defecto</p>
          </>
        );
    }
  }

  return <>{tiposFormato()}</>;
};

export default IndexGrupoCampos;
