import React from "react";

import { store } from "../../../../../store";
import { Api } from "../../../../../api/configApi";

function DesignWidgetTexto(props) {
  const [widget, setWidget] = React.useState({});
  const [contenido, setContenido] = React.useState();
  React.useEffect(() => {
    const widgetActual = store.widgets.find((wid) => {
      return wid._id === props._id;
    });
    setWidget(widgetActual);
  }, [props]);
  React.useEffect(() => {
    
    if (widget._id) {
      Api("atributosTexto", "Get", { widget: widget._id }, {}).then(
        (res) => {
          // console.log("Interesnate...");
          // console.log(res);
          res.success ? setContenido(res.valor) : setContenido("---");
        }
      );
    }
  }, [widget]);

  return (
    <div style={{padding: 20}} className="widgetShadow">
      <span>{widget.titulo || ""}</span>
      <div style={estilos.contenido}>
        <strong>{contenido || "---"}</strong>
      </div>
    </div>
  );
}
const estilos = {
  contenido: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    fontSize: 30,
    fontWeight: 400,
  },
};
export default DesignWidgetTexto;
