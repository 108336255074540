import React, { memo } from "react";
import { setPensando, store } from "../../../../../store";
import ButtonViewUploadFileDesignWidget from "./ButtonViewUploadFileDesignWidget";

function DesignWidgetUploadFiles(props) {
  const [widget, setWidget] = React.useState(null);
  const [DrawerSolicitud, setDrawerSolicitud] = React.useState(false);
  const [solicitudes, setSolicitudes] = React.useState([]);
  const [cargando, setCargando] = React.useState(true);

  React.useEffect(() => {
    const widgetActual = store.widgets.find((wid) => {
      return wid._id === props._id;
    });
    setWidget(widgetActual);
  }, [props]);

  return (
    <div style={{ padding: 20 }} className="widgetShadow">
      <div style={estilos.contenido}>
        {widget && (
          <ButtonViewUploadFileDesignWidget propiedades={{ widget: widget }} />
        )}
      </div>
    </div>
  );
}
const estilos = {
  contenido: {
    display: "flex",
    flexDirection: "column",
    fontSize: 30,
    fontWeight: 400,
  },
};
export default memo(DesignWidgetUploadFiles);
