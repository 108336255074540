import { faComments, faFlagCheckered } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BackTop, Drawer, message, Space, Table, Tag } from "antd";
import React from "react";
import { Api } from "../../api/configApi";
import Boton from "../../components/design/elementos/boton";
import CrearTicketDrawer from "./CrearTicketDrawer";
import ResponderTicketDrawer from "./responderTicketDrawer";
import Moment from "react-moment";
import moment from "moment/min/moment-with-locales";
import { setPensando } from "../../store";
Moment.globalMoment = moment;
Moment.globalLocale = "es";

function SoporteListaAdmin() {
  const [tickets, setTickets] = React.useState([]);
  const [visibleTicket, setVisibleTicket] = React.useState(false);
  const [ticketActual, setTicketActual] = React.useState({});
  const [drawerAbrirTicket, setDrawerCrearTicket] = React.useState(false);
  const referencia = React.useRef();
  function GetTickets() {
    setPensando(true);
    Api("soporte", "GetTickets").then((res) => {
      if (res.success) {
        setPensando(false);
        setTickets(res.tickets);
      } else {
        message.error("Algo salio mal");
      }
    });
  }
  React.useEffect(() => {GetTickets()}, [drawerAbrirTicket]);

  const columns = [
    {
      title: "Asunto",
      dataIndex: "asunto",
      key: "asunto",
    },
    {
      title: "Ultima Interacción",
      dataIndex: "ulimaInteraccion",
      key: "ulimaInteraccion",
      render: (e) => <Moment fromNow>{e}</Moment>,
    },
    {
      title: "Estado",
      dataIndex: "estado",
      key: "estado",
      render: (e) =>
        e === "abierto" ? (
          <Tag color="red">{e}</Tag>
        ) : (
          <Tag color="green">{e}</Tag>
        ),
    },
    {
      title: "",
      dataIndex: "opciones",
      key: "opciones",
      render: (e, a) => (
        <FontAwesomeIcon
          onClick={() => {
            setTicketActual(a);
            setVisibleTicket(true);
          }}
          style={{ cursor: "pointer", fontSize: 20 }}
          icon={faComments}
        />
      ),
    },
  ];

  return (
    <div>
      <Drawer
        title="Ticket"
        onClose={() => {
          setVisibleTicket(false);
        }}
        visible={visibleTicket}
        width={350}
      >
        <>
          <ResponderTicketDrawer
            ticket={ticketActual}
            setVisibleTicket={setVisibleTicket}
            GetTickets={GetTickets}
          />
        </>
      </Drawer>
      <Table dataSource={tickets} columns={columns} />
    </div>
  );
}

export default SoporteListaAdmin;
