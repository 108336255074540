import React from "react";

import { observer } from "mobx-react";
import Titulares from "../../../components/titulares";
import RenderEtapas from "./renderEtapas";
import { useParams, useHistory, useLocation } from "react-router";
import {
  setPensando,
  setStoreActualizarLaEstructura,
  store,
} from "../../../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { Input, message, Popconfirm, Popover, Form } from "antd";
import { Api } from "../../../api/configApi";
import Boton from "../../../components/design/elementos/boton";

function Flujos(props) {
  const [tableros, setTableros] = React.useState();
  const [flujos, setFlujos] = React.useState();

  const params = useParams();
  const history = useHistory();

  const crearNuevoFlujo = (values) => {
    setPensando(true);
    Api(
      "cuentas",
      "InsertFlujo",
      {
        _id: store.cuentaactiva,
      },
      { grupo: params.idgrupo, nombre: values.nombre }
    ).then((res) => {
      if (res.success) {
        setPensando(false);
        setStoreActualizarLaEstructura();
        message.success("Flujo Creado");
      } else {
        message.error("Algo no salio bien");
      }
    });
  };

  const Submenu = (props) => {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <ul className="submenu">
          {props.tableros.map((opc, index) => {
            return (
              <li
                key={index}
                style={{
                  display: "list-item",
                  textAlign: "-webkit-match-parent",
                }}
                className="nav-item "
              >
                <div
                  className={
                    params.id === opc._id
                      ? "activo"
                      : params.id === opc.id && "activo"
                  }
                  onClick={() => {
                    history.push(opc._id);
                  }}
                >
                  {opc.nombre}
                </div>
              </li>
            );
          })}
        </ul>
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        >
          <Popover
            trigger="click"
            content={
              <Form onFinish={crearNuevoFlujo} layout="vertical">
                <Form.Item
                  rules={[{ required: true, message: "Nombre obligatorio" }]}
                  label="Nombre del flujo"
                  name="nombre"
                >
                  <Input />
                </Form.Item>
                <Boton texto="Guardar" htmlType="submit" />
              </Form>
            }
          >
            <FontAwesomeIcon style={{ fontSize: 15 }} icon={faPlusCircle} />
          </Popover>
        </div>
      </div>
    );
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Titulares
        titular={store.cuenta.gruposflujos.find(
          (e) => e._id === params.idgrupo
        )?.nombre}
        contenido="Crea, edita o elimina flujos"
        boton={{ texto: "Nueva Etapa", ancla: "nuevomodulo" }}
      />

      <Submenu
        flujos={flujos}
        tableros={store.flujos.filter((e) => {
          return e.grupo === params.idgrupo;
        })}
        opciones={tableros}
      />

      <div
        style={{ padding: 10, marginTop: 15, overflow: "auto", height: "100%" }}
      >
        <RenderEtapas />
      </div>
    </div>
  );
}

export default observer(Flujos);
