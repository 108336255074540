import SortableTree, { defaultGetNodeKey } from "react-sortable-tree";
import { removeNode, removeNodeAtPath } from "react-sortable-tree";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faLockOpen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import "react-sortable-tree/style.css";
import { Drawer, Form, Input, Popconfirm, Popover } from "antd";
import Boton from "../../../components/design/elementos/boton";
import { Api } from "../../../api/configApi";
import {
  setPensando,
  setStoreActualizarLaEstructura,
  store,
} from "../../../store";
import { observer } from "mobx-react";
import MenuEditorPage from "./MenuEditorPage";
import FormularioCrearDisparador from "../flujos/componentes/FormularioCrearDisparador";
import FormularioFacturacion from "../../../components/widgets/tipos/woocommerce/design/formularioFacturacion";
import FormularioBloqueoPagina from "./componentes/FormularioBloqueoPagina";
var nodoactual;

function PageMenuAdministrador() {
  const [form] = Form.useForm();
  const [popOverIconos, setPopOverIconos] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [modalEditar, setModalEditar] = React.useState(false);
  const [pestanaActual, setPestanaActual] = React.useState();
  const [bloqueoCondicionalPagina, setBloqueoCondicionalPagina] =
    React.useState(false);

  React.useEffect(() => {
    setData(store.menuAdmin);
  }, [store.menuAdmin]);
  const actualizarMenu = (val) => {
    setPensando(true);
    Api(
      "Cuentas",
      "InsertMenuAdministrador",
      { _id: store.cuentaactiva },
      data
    ).then((ress) => {
      setPensando(false);
    });
  };
  React.useEffect(() => {
    if (data.length > 0) {
      actualizarMenu();
    }
  }, [data]);
  const onFinish = (values) => {
    const newId = Math.floor(Math.random() * (100000 - 1000)) + 3000;
    var nuevasPestanas = [...data];
    nuevasPestanas.push({
      title: values.nombrepestana,
      id: "id" + newId,
      widgets: [],
      children: [],
      activa: false,
      icono: "11f.svg",
      bloqueo: {
        bloqueada: false,
      },
    });
    setData(nuevasPestanas);
    form.resetFields();
    setStoreActualizarLaEstructura();
    // console.log(store.menuAdmin);
  };
  const ocultaromostrar = (val) => {
    val.activa = !val.activa;
    var actualizarPestanas = [...data];
    setData(actualizarPestanas);
  };
  const eliminarMenu = (path, node) => {
    const ok = removeNodeAtPath({
      path: path,
      treeData: data,
      getNodeKey: defaultGetNodeKey,
    });
    setData(ok);
    setStoreActualizarLaEstructura();
  };

  function cerrarModal() {
    setBloqueoCondicionalPagina(false);
  }

  const IconosDisponibles = (node) => {
    const icons = [];
    for (let index = 1; index < 380; index++) {
      icons.push(index + "f.svg");
    }
    return (
      <div style={{ maxWidth: 800 }}>
        {icons.map((e, index) => (
          <img
            onClick={() => {
              node.icono = e;
              var actualizarPestanas = [...data];
              setData(actualizarPestanas);
              setPopOverIconos(false);
            }}
            alt="icono"
            width={20}
            style={{ margin: 5, cursor: "pointer" }}
            key={index}
            src={
              "https://octopusmultinivel.b-cdn.net/octopus10/ICONOS/flat/" + e
            }
          />
        ))}
      </div>
    );
  };


  return (
    <div style={{ height: 500 }}>
      <Drawer
        title="Bloquear Página"
        visible={bloqueoCondicionalPagina}
        onClose={cerrarModal}
      >
        <FormularioBloqueoPagina
          actualizarMenu={actualizarMenu}
          setData={setData}
          cerrarModal={cerrarModal}
          data={data}
          pestanaActual={pestanaActual}
        />
      </Drawer>
      <MenuEditorPage
        modalEditar={modalEditar}
        nodoactual={nodoactual}
        pestanaActual={pestanaActual}
        setModalEditar={setModalEditar}
        actualizarMenu={actualizarMenu}
        setData={setData}
        tipoMenu="administrador"
      />

      <div style={{ marginBottom: 16 }}>
        <Form
          form={form}
          name="dynamic_form_nest_item"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="nombrepestana"
            rules={[
              {
                required: true,
                message: "Nombre de la pestaña es obligatorio",
              },
            ]}
          >
            <Input
              addonAfter={<Boton htmlType="submit" texto="Crear Pestaña" />}
              placeholder="Ingresa el nombre de la nueva pestaña"
            />
          </Form.Item>
        </Form>
      </div>
      <SortableTree
        treeData={data}
        onChange={(treeData) => {
          setData(treeData);
          setStoreActualizarLaEstructura();
        }}
        getNodeKey={defaultGetNodeKey}
        maxDepth={2}
        generateNodeProps={({ node, path }) => ({
          title: (
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <Popover
                content={() => {
                  return IconosDisponibles(node);
                }}
                trigger="click"
                visible={popOverIconos}
                onVisibleChange={()=>{ setPopOverIconos() }}
              >
                <img
                  alt="icono"
                  width={20}
                  style={{ marginRight: 10, cursor: "pointer" }}
                  src={`https://octopusmultinivel.b-cdn.net/octopus10/ICONOS/flat/${node.icono}`}
                />
              </Popover>
              <span>{node.title}</span>
              <div>
                <FontAwesomeIcon
                  style={{
                    marginLeft: 20,
                    alignSelf: "center",
                    cursor: "pointer",
                  }}
                  icon={node.activa ? faEye : faEyeSlash}
                  onClick={() => {
                    ocultaromostrar(node);
                  }}
                />
                {node.children.length === 0 && (
                  <FontAwesomeIcon
                    style={{
                      marginLeft: 20,
                      alignSelf: "center",
                      cursor: "pointer",
                    }}
                    icon={faEdit}
                    onClick={() => {
                      nodoactual = node;
                      setTimeout(() => {
                        setPestanaActual(node);
                        setModalEditar(true);
                      }, 200);
                    }}
                  />
                )}
                <FontAwesomeIcon
                  style={{
                    marginLeft: 20,
                    alignSelf: "center",
                    cursor: "pointer",
                    color: node.bloqueo.bloqueada ? "#b91515" : "#000",
                  }}
                  icon={node.bloqueo.bloqueada ? faLock : faLockOpen}
                  onClick={() => {
                    nodoactual = node;
                    setPestanaActual(node);
                    setTimeout(() => {
                      setBloqueoCondicionalPagina(true);
                    }, 200);
                  }}
                />

                {node.id !== "1" && (
                  <Popconfirm
                    title={
                      <div>
                        <strong>¿Lo pensaste bien?</strong>
                        <p>Eliminaremos también las Sub-pestañas</p>
                      </div>
                    }
                    onConfirm={() => {
                      eliminarMenu(path, node);
                    }}
                    okText="Si"
                    cancelText="No"
                  >
                    <FontAwesomeIcon
                      style={{
                        marginLeft: 20,
                        alignSelf: "center",
                        cursor: "pointer",
                      }}
                      icon={faTrash}
                    />
                  </Popconfirm>
                )}
              </div>
            </div>
          ),
        })}
      />
    </div>
  );
}

export default observer(PageMenuAdministrador);
