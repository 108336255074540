import React from "react";

import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import Submenu from "../../../components/submenu";
import RenderGruposFlujos from "./flujos/renderGruposFlujos";
import RenderAtributos from "./atributos/RenderAtributos";
import RenderEmails from "./emails/renderEmails";
import RenderNotificaciones from "./notificaciones/RenderNotificaciones";
import RenderIntegraciones from "./integraciones/RenderIntegraciones";
import RenderListados from "./listados/renderListados";

function IndexConfiguraciones(props) {
  const [tableros, setTableros] = React.useState([
    { nombre: "Grupos de Flujos", id: "flujos", activo: true },
    { nombre: "Atributos", id: "atributos", activo: false },
    { nombre: "Listados", id: "listados", activo: false },
    { nombre: "Emails", id: "emails", activo: false },
    { nombre: "Notificaciones", id: "notificaciones", activo: false },
    { nombre: "Integraciones", id: "integraciones", activo: false },
  ]);
  const [tableroactual, setTableroactual] = React.useState({});
  const [modulos, setModulos] = React.useState([
    {
      nombre: "Interesante",
    },
  ]);
  const params = useParams();
  React.useEffect(() => {
    // console.log(history);
    // store.modulos.forEach(modulo => {
    //     modulo.accion = navega
    // });
    // setModulos(store.modulos);
  }, []);
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Submenu tableros={tableros} />

      <div style={{ padding: 10, marginTop: 15, overflow: "auto" }}>
        {params.id === "flujos" && <RenderGruposFlujos />}
        {params.id === "atributos" && <RenderAtributos />}
        {params.id === "emails" && <RenderEmails />}
        {params.id === "listados" && <RenderListados />}
        {params.id === "notificaciones" && <RenderNotificaciones />}
        {params.id === "integraciones" && <RenderIntegraciones />}
      </div>
    </div>
  );
}

export default observer(IndexConfiguraciones);
