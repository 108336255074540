import { Drawer, Space } from "antd";
import React from "react";
import Boton from "../../../components/design/elementos/boton";

import PageSinWidgetsComponente from "./componentes/PageSinWidgetsComponente";

import DesignFilaComponente from "./componentes/DesignFilaComponente";
import AgregarFilaNuevaComponente from "./componentes/AgregarFilaNuevaComponente";
import { AnimateSharedLayout, motion } from "framer-motion";
import { store } from "../../../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleUp } from "@fortawesome/free-regular-svg-icons";
import { faArrowAltCircleDown } from "@fortawesome/free-solid-svg-icons";

function MenuEditorPage(props) {
  const [titulo, setTitulo] = React.useState("");
  const [widgets, setWidgets] = React.useState([]);
  const [WidgetNuevo, setWidgetNuevo] = React.useState("");

  const onClose = () => {
    props.setModalEditar(false);
  };

  const actualizarWidgets = React.useCallback((wid) => setWidgets(wid), []);

  React.useEffect(() => {
    if (props.pestanaActual) {
      setTitulo(props.pestanaActual.title);
      setWidgets(props.pestanaActual.widgets);
    }
  }, [props]);
  const AgregarNuevaFila = () => {
    const newWidgets = [...widgets];
    newWidgets.push([{ ...WidgetNuevo }]);
    setWidgets(newWidgets);
  };
  React.useEffect(() => {
    if (WidgetNuevo !== "") {
      AgregarNuevaFila();
      setWidgetNuevo("");
    }
  }, [WidgetNuevo]);
  const guardar = () => {
    var actualizarPestanas = [...store.menuFront];
    if (props.tipoMenu === "administrador") {
      actualizarPestanas = [...store.menuAdmin];
    }
    if (props.tipoMenu === "app") {
      actualizarPestanas = [...store.menuApp];
    }
    props.nodoactual.widgets = widgets;
    props.nodoactual.title = titulo;
    props.setData(actualizarPestanas);
    props.actualizarMenu(actualizarPestanas);
    props.setModalEditar(false);
  };

  const subirFila = (index) => {
    const newWidgets = [...widgets];
    const temp = newWidgets[index];
    newWidgets[index] = newWidgets[index - 1];
    newWidgets[index - 1] = temp;
    setWidgets(newWidgets);
  };
  const bajarFila = (index) => {
    const newWidgets = [...widgets];
    const temp = newWidgets[index];
    newWidgets[index] = newWidgets[index + 1];
    newWidgets[index + 1] = temp;
    setWidgets(newWidgets);
  };

  const PrimerWidget = () => {
    return (
      <PageSinWidgetsComponente
        widgets={widgets}
        setWidgetNuevo={setWidgetNuevo}
      />
    );
  };
  return (
    <>
      <Drawer
        title={
          <div style={{ display: "flex", flexDirection: "row" }}>
            <input
              placeholder="Nombre de la pestaña"
              style={{ border: "none", outline: "none", width: "100%" }}
              value={titulo}
              onChange={(e) => {
                setTitulo(e.target.value);
              }}
            />
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Boton
                style={{ marginLeft: 5 }}
                texto="Guardar"
                onClick={() => {
                  guardar();
                }}
              />
              <Boton
                style={{ marginLeft: 5 }}
                tipo="danger"
                texto="Salir"
                onClick={onClose}
              />
            </div>
          </div>
        }
        placement="right"
        closable={false}
        visible={props.modalEditar}
        width={props.tipoMenu === "app" ? 350 : 1250}
      >
        {widgets.length > 0 ? (
          widgets.map((fila, index) => {
            return (
              <AnimateSharedLayout>
                <motion.div
                  key={index}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  layout
                  exit={{ opacity: 0 }}
                >
                  <div style={{ marginLeft: 20 }}>
                    <Space>
                      {index !== 0 && (
                        <FontAwesomeIcon
                          icon={faArrowAltCircleUp}
                          onClick={() => subirFila(index)}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                      {index !== widgets.length - 1 && (
                        <FontAwesomeIcon
                          icon={faArrowAltCircleDown}
                          onClick={() => bajarFila(index)}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </Space>
                  </div>
                  <DesignFilaComponente
                    widgets={widgets}
                    setWidgets={actualizarWidgets}
                    fila={fila}
                    index={index}
                    tipoMenu={props.tipoMenu}
                  />
                </motion.div>
              </AnimateSharedLayout>
            );
          })
        ) : (
          <PrimerWidget />
        )}
        {widgets.length > 0 && (
          <AgregarFilaNuevaComponente setWidgetNuevo={setWidgetNuevo} />
        )}
      </Drawer>
    </>
  );
}

export default MenuEditorPage;
