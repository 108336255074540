import React from "react";

import { store } from "../../../../../store";
import { message } from "antd";

import { Api } from "../../../../../api/configApi";
import ListaDeRecursos from "./ListaDeRecursos";

function DesignWidgetListaRecursos(props) {
  const [widget, setWidget] = React.useState({});
  const [drawerRecurso, setDrawerRecurso] = React.useState(false);
  const [recursos, setRecursos] = React.useState([]);

  function ObtenerLosRecursos(w) {
    Api("recursos", "Get", { widget: w._id, modulo: w.moduloRecursos }).then(
      (res) => {
        if (res.success) {
          setRecursos(res.recursos);
        } else {
          message.error(
            "No pudimos obtener los recursos. Intentalo nuevamente"
          );
        }
      }
    );
  }

  React.useEffect(() => {
    const widgetActual = store.widgets.find((wid) => {
      return wid._id === props._id;
    });

    ObtenerLosRecursos(widgetActual);
    setWidget(widgetActual);
  }, [props]);

  return (
    <div style={{padding: 20}} className="widgetShadow">
      <div>
        <ListaDeRecursos widget={widget} recursos={recursos} />
      </div>
    </div>
  );
}

export default DesignWidgetListaRecursos;
