import { Skeleton } from "antd";
import React from "react";
import { store } from "../../../../../store";
import parse from "html-react-parser";
import DibujarWidgetComponente from "../../../../../pages/configuraciones/menu/componentes/DibujarWidgetComponente";
import Widget from "../../../../../pages/configuraciones/menu/componentes/Widget";

function DesignWidgetBuilder(props) {
  const [widget, setWidget] = React.useState();
  const [cargando, setCargando] = React.useState(false);
  const [contador, setContador] = React.useState(10);

  React.useEffect(() => {
    const widgetActual = store.widgets.find((wid) => {
      return wid._id === props._id;
    });

    // replace all the {{}} with the values
    setCargando(true);

    if (widgetActual) {
      setWidget(widgetActual);
      setCargando(false);
    }
  }, [props]);

  const opciones = {
    replace: (domNode) => {
      if (domNode.attribs?.name === "widget") {
        return (
          <Widget
            widget={{
              estilos: {
                color: "#fff",
              },
              tipo: domNode.attribs?.tipo,
              onlyResult: true,
              _id: domNode.attribs?._id,
            }}
          />
        );
      }
    },
  };

  return (
    <div style={{ height: "100%", width: "100%", position: "relative" }}>
      {widget &&
        (cargando ? (
          <Skeleton.Button active={true} size={200} />
        ) : (
          // <>
          //   <div className="temporal-mmm">
          //     <strong>Ganancias</strong>
          //     <div className="iconssss">
          //       <i class="fi fi-bs-dollar"></i>
          //     </div>
          //     <Widget
          //       widget={{
          //         estilos: {
          //           color: "#fff",
          //         },
          //         tipo: "indicadores",
          //         onlyResult: true,
          //         _id: "62b81bf070f552de4e6b7eef",
          //       }}
          //     />
          //     <span>Total ganancias</span>
          //   </div>
          // </>
          <div>{parse(widget.codeDesign, opciones)}</div>
        ))}
    </div>
  );
}

export default DesignWidgetBuilder;
