import { observer } from "mobx-react";
import React from "react";
import { Api } from "../../../../api/configApi";
import { setStoreAtributos, store } from "../../../../store";

import CrearAtributo from "./crearAtributo";
import {
  Table,
  Drawer,
  message,
  Space,
  Typography,
  Divider,
  Spin,
  Popconfirm,
  Tooltip,
  Tag,
} from "antd";
import CambiarNombreDescripcionAtributo from "./modificarAtributos/CambiarNombreDescripcionAtributo";
import CambiarOpcionesAtributo from "./modificarAtributos/CambiarOpcionesAtributo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faList,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
function RenderAtributos() {
  const [crearAtributo, setCrearAtributo] = React.useState(false);
  const [editarAtributo, setEditarAtributo] = React.useState(false);
  const [actualizarAtributos, setActualizarAtributos] = React.useState(true);
  const [actualizarFiltros, setActualizarFiltros] = React.useState(true);
  const [atributoActual, setAtributoActual] = React.useState({});
  const [tipodeActualizacion, setTipoActualizacion] = React.useState("");
  const [eliminando, SetEliminando] = React.useState(false);
  const [etiquetas, setEtiquetas] = React.useState([]);
  const [etiquetaActual, setEtiquetaActual] = React.useState("");

  React.useEffect(() => {
    Api("cuentas", "Get", { _id: store.cuentaactiva }, { atributos: 1 })
      .then((res) => {
        setStoreAtributos({ texts: [], numbers: [], dates: [], flags: [] });
        setStoreAtributos(res[0].atributos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [actualizarAtributos]);

  function eliminarAtributo(atri) {
    SetEliminando(true);
    Api(
      "cuentas",
      "actualizaAtributoConfig",
      { actualizar: "eliminarAtributo" },
      {
        idAtributo: atri._id,
        nombreAtributo: atri.nombre,
      }
    ).then((res) => {
      if (!res.success) {
        message.error(res.message);
      } else {
        setEditarAtributo(false);
        message.success("Atributo Eliminado");
      }
      SetEliminando(false);
    });
  }

  const Opciones = ({ opc }) => {
    if (opc) {
      return (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {opc.map((element) => {
            return (
              <span
                style={{
                  margin: 5,
                  background: "rgb(239 242 245)",
                  padding: 10,
                }}
              >
                {element}
              </span>
            );
          })}{" "}
        </div>
      );
    } else {
      return <></>;
    }
  };
  const Tags = ({ tags }) => {
    if (tags) {
      return (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {tags.map((element) => {
            if (!etiquetas.includes(element)) {
              setEtiquetas([...etiquetas, element]);
            }
            return (
              <Tag style={{ borderRadius: "10px 10px 10px 0px", margin: 2 }}>
                {element}
              </Tag>
            );
          })}{" "}
        </div>
      );
    } else {
      return <></>;
    }
  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: "Descripción",
      dataIndex: "descripcion",
      key: "descripcion",
    },
    {
      title: "Tipo",
      dataIndex: "tipo",
      key: "tipo",
    },
    {
      title: "Opciones",
      dataIndex: "opciones",
      key: "opciones",
      render: (e) => <Opciones opc={e} />,
    },
    {
      title: "Etiquetas",
      dataIndex: "tags",
      key: "tags",
      render: (e) => e && <Tags tags={e} />,
    },
    {
      title: "Editar",
      dataIndex: "filtros",
      key: "filtros",
      render: (e, atrib) => (
        <Space split={<Divider type="vertical" />}>
          <Tooltip title="Nombre y Descripción">
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setEditarAtributo(true);
                setAtributoActual(atrib);
                setTipoActualizacion("Nombre y Descripción");
              }}
              icon={faPenToSquare}
            />
          </Tooltip>

          {atrib.tipo === "text" && (
            <Tooltip title="Opciones">
              <FontAwesomeIcon
                style={{ cursor: "pointer" }}
                icon={faList}
                onClick={() => {
                  setEditarAtributo(true);
                  setAtributoActual(atrib);
                  setTipoActualizacion("Opciones");
                }}
              />
            </Tooltip>
          )}
          {eliminando ? (
            <Spin />
          ) : (
            <Tooltip title="Eliminar">
              <Popconfirm
                title="No podrás recuperarlo"
                okText="ELIMINAR PARA SIEMPRE"
                cancelText="cancelar"
                onConfirm={() => eliminarAtributo(atrib)}
                okType="danger"
              >
                <FontAwesomeIcon
                  style={{ cursor: "pointer" }}
                  icon={faTrash}
                  color="red"
                />
              </Popconfirm>
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];
  function onClose() {
    setCrearAtributo(false);
    setEditarAtributo(false);
  }
  return (
    <>
      <Drawer
        title="Crear Atributo"
        placement="right"
        closable={false}
        onClose={onClose}
        visible={crearAtributo}
        width={400}
      >
        <CrearAtributo
          setActualizarAtributos={setActualizarAtributos}
          actualizarAtributos={actualizarAtributos}
          setCrearAtributo={setCrearAtributo}
        />
      </Drawer>

      <Drawer
        title="Editar Atributo"
        placement="right"
        closable={false}
        visible={editarAtributo}
        width={650}
        destroyOnClose
      >
        {tipodeActualizacion === "Nombre y Descripción" ? (
          <CambiarNombreDescripcionAtributo
            setEditarAtributo={setEditarAtributo}
            atributoActual={atributoActual}
          />
        ) : (
          <CambiarOpcionesAtributo
            setEditarAtributo={setEditarAtributo}
            atributoActual={atributoActual}
          />
        )}
      </Drawer>

      <div style={{ textAlign: "right", marginBottom: 10 }}>
        <span
          onClick={() => {
            setCrearAtributo(true);
          }}
          style={{ cursor: "pointer" }}
        >
          Crear Atributo
        </span>
      </div>
      <div style={{ marginTop: -10, marginBottom: 10 }}>
        {etiquetas.map((e) => {
          return (
            <Tag
              style={{
                borderRadius: "10px 10px 10px 0px",
                margin: 2,
                cursor: "pointer",
                background: e === etiquetaActual ? "rgb(226 242 255)" : null,
              }}
              onClick={() =>
                etiquetaActual !== e
                  ? setEtiquetaActual(e)
                  : setEtiquetaActual("")
              }
            >
              {e}
            </Tag>
          );
        })}
      </div>
      <Table
        dataSource={[
          ...store.losatributos.texts,
          ...store.losatributos.numbers,
          ...store.losatributos.dates,
        ].filter((e) => {
          if (etiquetaActual === "") {
            return e;
          } else {
            return e.tags?.includes(etiquetaActual);
          }
        })}
        columns={columns}
      />
    </>
  );
}

export default observer(RenderAtributos);
