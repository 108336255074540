import { Form, Input, message } from "antd";
import React from "react";
import { Api } from "../../api/configApi";
import Boton from "../../components/design/elementos/boton";
import { setPensando, store } from "../../store";

function CrearTicketDrawer({ setDrawerCrearTicket }) {
  const [form] = Form.useForm();

  function crearTicket(values) {
    setPensando(true);
    Api("soporte", "InsertTicket", values).then((res) => {
      if (res.success) {
        setPensando(false);
        form.resetFields();
        message.success("Ticket Creado");
        setDrawerCrearTicket(false);
      } else {
        message.error("Algo no salio bien");
        form.resetFields();
      }
    });
  }
  return (
    <Form for={form} layout="vertical" onFinish={crearTicket}>
      <Form.Item
        rules={[{ required: true, message: "Campo obligatorio" }]}
        name="asunto"
        label="Asunto"
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: "Campo obligatorio" }]}
        name="comentario"
        label="Comentario"
      >
        <Input.TextArea style={{ height: 200 }} />
      </Form.Item>
      <Boton loading={store.pensando} texto="Crear" htmlType="submit" />
    </Form>
  );
}

export default CrearTicketDrawer;
