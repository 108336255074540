import { Button, Checkbox, InputNumber, message, Space } from "antd";
import Input from "antd/lib/input/Input";
import Modal from "antd/lib/modal/Modal";
import React from "react";
import { Api } from "../../../../../../../api/configApi";

const CampoNumeroComponente = (props) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [numero, setNumero] = React.useState(+props.valor);
  const refNumero = React.useRef(numero);
  const [activarDisparadores, setActivarDisparadores] = React.useState(false);

  const actualizar = (data) => {
    // console.log(data);
    Api(
      "listados",
      "UpdateItem",
      {
        actualizarItem: data,
      },
      {},
      "https://f399-181-132-3-197.ngrok.io"
    ).then((res) => {
      message.success("Se actualizó el campo");
      props.Get();
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    if (
      !isNaN(refNumero.current) &&
      refNumero.current !== props.opcionDefecto
    ) {
      setNumero(refNumero.current);
      actualizar({
        valor: refNumero.current,
        idCampo: props.id,
        idListado: props.idListado,
        nombreCampo: props.nombreCampo,
        activarDisparadores: activarDisparadores,
      });
      setActivarDisparadores(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setActivarDisparadores(false);
  };

  const onChangeInput = (e) => {
    refNumero.current = e;
    // console.log(refNumero.current);
  };

  return (
    <>
      {!isNaN(props.valor) ? (
        <p onClick={showModal}>{props.valor}</p>
      ) : (
        <p onClick={showModal} style={{ color: "#ababab" }}>
          Asignar cantidad
        </p>
      )}

      <Modal
        title="Modificar número"
        visible={isModalVisible}
        destroyOnClose
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Guardar
          </Button>,
        ]}
      >
        <Space direction="vertical">
          <InputNumber
            type="number"
            defaultValue={numero}
            onChange={onChangeInput}
            style={{ width: 400 }}
          ></InputNumber>
          <Checkbox
            value={activarDisparadores}
            onChange={(e) => setActivarDisparadores(e.target.checked)}
          >
            Activar Disparadores
          </Checkbox>
        </Space>
      </Modal>
    </>
  );
};

export default CampoNumeroComponente;
