import React from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Select,
  Space,
  Steps,
} from "antd";

const { Option, OptGroup } = Select;

const FormatoComponente = () => {
  const refFiat = React.useRef([
    "AED",
    "AFN",
    "ALL",
    "AMD",
    "ANG",
    "AOA",
    "ARS",
    "AUD",
    "AWG",
    "AZN",
    "BAM",
    "BBD",
    "BDT",
    "BGN",
    "BHD",
    "BIF",
    "BMD",
    "BND",
    "BOB",
    "BRL",
    "BSD",
    "BTN",
    "BWP",
    "BYR",
    "BZD",
    "CAD",
    "CDF",
    "CHF",
    "CLP",
    "CNY",
    "COP",
    "CRC",
    "CUC",
    "CVE",
    "CZK",
    "DJF",
    "DKK",
    "DOP",
    "DZD",
    "EEK",
    "EGP",
    "ERN",
    "ETB",
    "EUR",
    "FJD",
    "FKP",
    "GBP",
    "GEL",
    "GHS",
    "GIP",
    "GMD",
    "GNF",
    "GQE",
    "GTQ",
    "GYD",
    "HKD",
    "HNL",
    "HRK",
    "HTG",
    "HUF",
    "IDR",
    "ILS",
    "INR",
    "IQD",
    "IRR",
    "ISK",
    "JMD",
    "JOD",
    "JPY",
    "KES",
    "KGS",
    "KHR",
    "KMF",
    "KPW",
    "KRW",
    "KWD",
    "KYD",
    "KZT",
    "LAK",
    "LBP",
    "LKR",
    "LRD",
    "LSL",
    "LTL",
    "LVL",
    "LYD",
    "MAD",
    "MDL",
    "MGA",
    "MKD",
    "MMK",
    "MNT",
    "MOP",
    "MRO",
    "MUR",
    "MVR",
    "MWK",
    "MXN",
    "MYR",
    "MZM",
    "NAD",
    "NGN",
    "NIO",
    "NOK",
    "NPR",
    "NZD",
    "OMR",
    "PAB",
    "PEN",
    "PGK",
    "PHP",
    "PKR",
    "PLN",
    "PYG",
    "QAR",
    "RON",
    "RSD",
    "RUB",
    "SAR",
    "SBD",
    "SCR",
    "SDG",
    "SEK",
    "SGD",
    "SHP",
    "SLL",
    "SOS",
    "SRD",
    "SYP",
    "SZL",
    "THB",
    "TJS",
    "TMT",
    "TND",
    "TRY",
    "TTD",
    "TWD",
    "TZS",
    "UAH",
    "UGX",
    "USD",
    "UYU",
    "UZS",
    "VEB",
    "VND",
    "VUV",
    "WST",
    "XAF",
    "XCD",
    "XDR",
    "XOF",
    "XPF",
    "YER",
    "ZAR",
    "ZMK",
    "ZWR",
  ]);
  const refCripto = React.useRef([
    "Bitcoin BTC",
    "Ethereum, Ether ETH",
    "Dogecoin DOGE",
    "Cardano ADA",
    "Litecoin LTC",
    "Binance Coin BNB",
    "Polkadot DOT",
    "Ripple XRP",
    "BitBay",
    "Stellar XLM",
    "Bitcoin cash BCH",
    "Chainlink LINK",
    "IOTA MIOTA",
    "Monero",
    "NEO",
    "Tether USDT",
    "Tezos XTZ",
    "THETA",
    "Uniswap UNI",
    "USD Coin USDC",
  ]);

  return (
    <>
      <Form.Item
        name={["format", "tipoFormato"]}
        label="Escoge el tipo de formato a mostrar"
        rules={[
          {
            required: true,
            message: "Debes elegir un formato",
          },
        ]}
      >
        <Select>
          <OptGroup label="Número">
            <Option value="moneda">Moneda</Option>
            <Option value="redondearDecimales">Redondear decimales</Option>
            <Option value="miles">Miles</Option>
          </OptGroup>

          <Option value="fecha">Fecha</Option>
          <Option value="texto">Texto</Option>
          <Option value="sinFormato">Sin formato</Option>
        </Select>
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, curValues) =>
          prevValues.format?.tipoFormato !== curValues.format?.tipoFormato
        }
      >
        {(formu) => {
          function renderFormato() {
            switch (formu.getFieldsValue().format?.tipoFormato) {
              case "moneda":
                return (
                  <>
                    <Form.Item
                      name={["format", "monedas"]}
                      label="Escoge una moneda"
                      rules={[
                        {
                          required: true,
                          message: "Debes elegir una moneda",
                        },
                      ]}
                    >
                      <Select placeholder="Elige un tipo de moneda" showSearch>
                        {refCripto.current
                          .concat(refFiat.current)
                          .map((moneda, index) => {
                            return (
                              <Option key={index} value={moneda}>
                                {moneda}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </>
                );
              case "miles":
                return <></>;
              case "sinFormato":
                return <></>;

              default:
                return (
                  <>
                    <></>
                  </>
                );
            }
          }
          return (
            <>
              {renderFormato()}
              <Form.Item
                name={["format", "denominacionPropia"]}
                label="¿Deseas usar una denominación propia?"
                rules={[
                  {
                    required: true,
                    message: "Debes elegir una opción",
                  },
                ]}
              >
                <Select placeholder="Elige una opción">
                  <Option value="si">Si</Option>
                  <Option value="no">No</Option>
                </Select>
              </Form.Item>

              <Form.Item
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.format?.denominacionPropia !==
                  curValues.format?.denominacionPropia
                }
              >
                {(formu) => {


                  return (
                    <>
                      {formu.getFieldsValue().format?.denominacionPropia ==
                        "si" && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Form.Item
                            name={["format", "prefijo"]}
                            label="Ingresa un prefijo"
                            rules={[
                              {
                                required: false,
                                message: "Debes establecer un prefijo",
                              },
                            ]}
                          >
                            <Input
                              style={{ width: "220px" }}
                              placeholder="Ingresa un prefijo"
                            />
                          </Form.Item>
                          <Form.Item
                            name={["format", "sufijo"]}
                            label="Ingresa un sufijo"
                            rules={[
                              {
                                required: false,
                                message: "Debes establecer un sufijo",
                              },
                            ]}
                          >
                            <Input
                              style={{ width: "220px" }}
                              placeholder="Ingresa un sufijo"
                            />
                          </Form.Item>
                        </div>
                      )}
                    </>
                  );
                }}
              </Form.Item>
            </>
          );
        }}
      </Form.Item>
    </>
  );
};

export default FormatoComponente;
