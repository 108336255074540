import React from "react";
import {
  Divider,
  Form,
  Input,
  Button,
  Checkbox,
  Select,
  InputNumber,
} from "antd";
import Boton from "../../../design/elementos/boton";
import { setPensando, setStoreWidgets, store } from "../../../../store";
import { Api } from "../../../../api/configApi";
import { ObjectId } from "bson";

function CrearActualizarWidgetObjetivo(props) {
  const { Option } = Select;
  const [loading, setLoading] = React.useState(false);
  const [insertOrUpdate, setInsertOrUpdate] = React.useState("InsertWidgets");
  const [form] = Form.useForm();
  const onFinish = (values) => {
    values.tipo = "objetivo";
    setPensando(true);
    setLoading(true);
    Api("Cuentas", insertOrUpdate, { _id: store.cuentaactiva }, values)
      .then((res) => {
        props.setVisible(false);

        setPensando(false);
        setLoading(false);
        return Api(
          "Cuentas",
          "Get",
          { _id: store.cuentaactiva },
          { widgets: 1 }
        );
      })
      .then((res) => {
        setStoreWidgets(res[0].widgets);
      })
      .catch((err) => {
        setPensando(false);
        setLoading(false);
      });
  };
  React.useEffect(() => {
    form.resetFields();
    form.setFieldsValue({ _id: new ObjectId() });
  }, [props.visible]);
  React.useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
      setInsertOrUpdate("UpdateWidgets");
    } else {
      form.setFieldsValue({ _id: new ObjectId() });
    }
  }, [props.data]);
  return (
    <div>
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          label="Nombre"
          name="nombre"
          hasFeedback
          rules={[{ required: true, message: "Ingresa un nombre" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="_id" hidden rules={[{ required: true }]}></Form.Item>

        <Form.Item
          label="Titulo"
          name="titulo"
          hasFeedback
          rules={[{ required: true, message: "Ingresa un titulo" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Descripción"
          name="descripcion"
          hasFeedback
          rules={[{ required: true, message: "Ingresa una descripción" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Campo obligatorio" }]}
          name="diseno"
          label="Diseño"
        >
          <Select>
            <Option value="line">Barra</Option>
            <Option value="circle">Circulo</Option>
            <Option value="dashboard">Media luna</Option>
          </Select>
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Campo obligatorio" }]}
          name="fondo"
          label="Tipo de fondo"
        >
          <Select>
            <Option value="oscuro">Oscuro</Option>
            <Option value="claro">Claro</Option>
          </Select>
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Campo obligatorio" }]}
          name="tipoDato"
          label="Data"
        >
          <Select>
            <Option value="atributo">Atributo</Option>
            <Option value="listado">Listado</Option>
          </Select>
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {() => (
            <>
              {form.getFieldValue("tipoDato") === "atributo" && (
                <Form.Item
                  label="Atributo numero"
                  name="id_atributo"
                  rules={[
                    {
                      required: true,
                      message: "Seleccionar un atributo",
                    },
                  ]}
                  hasFeedback
                >
                  <Select placeholder="Seleccionar un atributo o una operación">
                    {store.losatributos.numbers.map((atri) => {
                      return (
                        <Option key={atri._id} value={atri._id}>
                          {atri.nombre}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              )}
              {form.getFieldValue("tipoDato") === "listado" && (
                <>
                  <Form.Item
                    label="Listado"
                    name="id_listado"
                    rules={[
                      {
                        required: true,
                        message: "Seleccionar un listado",
                      },
                    ]}
                    hasFeedback
                  >
                    <Select placeholder="Seleccionar un listado">
                      {store.listados.map((list) => {
                        return (
                          <Option key={list._id} value={list._id}>
                            {list.nombre}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate>
                    {() => (
                      <>
                        <Form.Item
                          label="Campo Base"
                          name="id_campo_Base"
                          rules={[
                            {
                              required: true,
                              message: "Seleccionar un campo",
                            },
                          ]}
                          hasFeedback
                        >
                          <Select placeholder="Seleccionar un campo">
                            {form.getFieldValue("id_listado")
                              ? store.listados
                                  .find(
                                    (e) =>
                                      e._id === form.getFieldValue("id_listado")
                                  )
                                  .campos.filter(
                                    (e) => e.tipoCampo === "numero"
                                  )
                                  .map((campo) => {
                                    return (
                                      <Option
                                        key={campo.NombreCampo}
                                        value={campo.NombreCampo}
                                      >
                                        {campo.NombreCampo}
                                      </Option>
                                    );
                                  })
                              : null}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label="Campo Comparación"
                          name="id_campo_Comparacion"
                          rules={[
                            {
                              required: true,
                              message: "Seleccionar un campo",
                            },
                          ]}
                          hasFeedback
                        >
                          <Select placeholder="Seleccionar un campo">
                            {form.getFieldValue("id_listado")
                              ? store.listados
                                  .find(
                                    (e) =>
                                      e._id === form.getFieldValue("id_listado")
                                  )
                                  .campos.filter(
                                    (e) => e.tipoCampo === "numero"
                                  )
                                  .map((campo) => {
                                    return (
                                      <Option
                                        key={campo.NombreCampo}
                                        value={campo.NombreCampo}
                                      >
                                        {campo.NombreCampo}
                                      </Option>
                                    );
                                  })
                              : null}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label="Operacion"
                          name="operacion"
                          rules={[
                            {
                              required: true,
                              message: "Coloca un numero objetivo",
                            },
                          ]}
                          hasFeedback
                        >
                          <Select>
                            <Option value="numeroExact">Número exacto</Option>
                            <Option value="porcentaje">Porcentaje</Option>
                          </Select>
                        </Form.Item>
                      </>
                    )}
                  </Form.Item>
                </>
              )}
            </>
          )}
        </Form.Item>

        <Form.Item
          label="Objetivo"
          name="objetivo"
          rules={[
            {
              required: true,
              message: "Coloca un numero objetivo",
            },
          ]}
          hasFeedback
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: 15,
            }}
          >
            <Boton loading={loading} texto="Guardar" htmlType="submit" />
            <Boton
              loading={loading}
              onClick={() => {
                props.setVisible(false);
              }}
              texto="Cancelar"
              tipo="danger"
              htmlType="button"
            />
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}

export default CrearActualizarWidgetObjetivo;
