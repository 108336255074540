import React from "react";
import Modulo from "./Modulo";
import { InfoCircleOutlined, DownloadOutlined } from "@ant-design/icons";

const VistaCursoMovil = (props) => {
  const [tituloModulo, setTituloModulo] = React.useState(
    props.modulos[0]?.padre?.tituloLeccion ||
      props.modulos[0]?.hijos[0]?.tituloLeccion
  );
  const [descripcionModulo, setDescripcionModulo] = React.useState(
    props.modulos[0]?.padre?.descripcionLeccion ||
      props.modulos[0]?.hijos[0]?.descripcionLeccion
  );
  const [descargables, setDescargables] = React.useState(
    props.modulos[0]?.padre?.materialDescargable ||
      props.modulos[0]?.hijos[0]?.materialDescargable
  );
  const [link, setLink] = React.useState(
    props.modulos[0]?.padre?.urlVideo || props.modulos[0]?.hijos[0]?.urlVideo
  );
  const [codigoLink, setCodigoLink] = React.useState("");
  const refCodigoLink = React.useRef();

  const [banderaEstilo, setBanderaEstilo] = React.useState(true);
  const [banderaEstilo2, setBanderaEstilo2] = React.useState(false);

  const [estiloInfo, setEstiloInfo] = React.useState("2px solid #cfcfcf");
  const [estiloMatDes, setEstiloMatDes] = React.useState(null);

  React.useEffect(() => {
    if (link?.includes("youtube")) {
      let preCodigo = link.split("=")[1];
      let codigo = preCodigo.slice(0, 11);
      refCodigoLink.current = codigo;
      setCodigoLink(refCodigoLink.current);
    } else if (link?.includes("vimeo")) {
      let indice = link.lastIndexOf("/");
      let codigo = link.slice(indice + 1, link.length);
      refCodigoLink.current = codigo;
      setCodigoLink(refCodigoLink.current);
    } else if (link?.includes("youtu.")) {
      let preCodigo = link.split("be")[1];
      let codigo = preCodigo.slice(1, 12);
      refCodigoLink.current = codigo;
      setCodigoLink(refCodigoLink.current);
    }
  }, [link]);

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ width: "100%" }}>
          <div
            style={{
              backgroundColor: "black",
              height: "200px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {(link?.includes("youtube") || link?.includes("youtu.")) &&
            codigoLink ? (
              <>
                <iframe
                  src={`https://www.youtube.com/embed/${codigoLink}`}
                  height="100%"
                  frameborder="0"
                  title="Youtube video player"
                  allowFullScreen={true}
                ></iframe>
              </>
            ) : link?.includes("vimeo") && codigoLink ? (
              <>
                <iframe
                  src={`https://player.vimeo.com/video/${codigoLink}?h=ce693d66c6`}
                  width="100%"
                  height="100%"
                  frameborder="0"
                  title="Vimeo video player"
                  allow="autoplay; picture-in-picture"
                  allowFullScreen={true}
                ></iframe>
              </>
            ) : (
              link?.includes("mp4") && (
                <>
                  <iframe
                    width="100%"
                    height="100%"
                    src={link}
                    title="MP4 video player"
                    frameborder="0"
                    allow="autoplay; clipboard-write; picture-in-picture"
                    allowFullScreen={true}
                  ></iframe>
                </>
              )
            )}
          </div>
          <div
            style={{
              backgroundColor: "#fff",

              padding: "10px",
              textAlign: "left",
            }}
          >
            <div
              style={{
                padding: "0",
                display: "flex",
                width: "100%",
                justifyContent: "space-evenly",

              }}
            >
              <p
                style={{
                  margin: 0,
                  borderBottom: estiloInfo,
                  cursor: "pointer",
                  width: "50px",
                  textAlign: "center",
                }}
                onClick={() => {
                  setEstiloMatDes(null);
                  setEstiloInfo("2px solid #cfcfcf");
                  setBanderaEstilo(!banderaEstilo);
                  setBanderaEstilo2(false);
                }}
              >
                <InfoCircleOutlined style={{ fontSize: "20px" }} />
              </p>
              <p
                style={{
                  margin: 0,
                  borderBottom: estiloMatDes,
                  cursor: "pointer",
                  width: "50px",
                  textAlign: "center",
                }}
                onClick={() => {
                  setEstiloMatDes("2px solid #cfcfcf");
                  setEstiloInfo(null);
                  setBanderaEstilo(false);
                  setBanderaEstilo2(!banderaEstilo2);
                }}
              >
                <DownloadOutlined style={{ fontSize: "20px" }} />
              </p>
            </div>
            {banderaEstilo && (
              <>
                <div
                  style={{
                    paddingTop: "20px",
                    overflow: "hidden",
                    overflowY: "scroll",
                    height: "17vh",
                  }}
                >
                  <h2 style={{ margin: "0", fontWeight: 600 }}>
                    {tituloModulo}
                  </h2>
                  <hr style={{ border: "1px solid #cfcfcf" }} />

                  <p style={{ margin: "10px 0 0 0" }}>{descripcionModulo}</p>
                </div>
              </>
            )}
            {banderaEstilo2 && (
              <>
                <div
                  style={{
                    paddingTop: "5px",
                    overflow: "hidden",
                    overflowY: "scroll",
                    height: "17vh",
                  }}
                >
                  <h3 style={{ margin: "15px 0 0 0" }}>Material descargable</h3>
                  <hr style={{ border: "1px solid #cfcfcf" }} />

                  <ul
                    style={{
                      listStyle: "none",
                      margin: "10px 0 0 0",

                      padding: "0px",
                    }}
                  >
                    {descargables?.map((descargable, index) => {
                      return (
                        <>
                          <li key={index}>{descargable.tituloMaterialDes}</li>
                        </>
                      );
                    })}
                  </ul>
                </div>
              </>
            )}
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#f5f5f5",
            width: "100%",
            height: "35vh",
            overflow: "hidden",
            overflowY: "scroll",
          }}
        >
          <div
            style={{
              backgroundColor: "#000",
              display: "flex",
              alignItems: "center",
              height: "40px",
              justifyContent: "center",
            }}
          >
            <p style={{ color: "#fff", margin: 0 }}>Lista de reproducción</p>
          </div>
          {props.modulos.map((modulo, index) => {
            return (
              <>
                <div>
                  <Modulo
                    setEstiloMatDes={setEstiloMatDes}
                    setEstiloInfo={setEstiloInfo}
                    setBanderaEstilo={setBanderaEstilo}
                    setBanderaEstilo2={setBanderaEstilo2}
                    setTituloModulo={setTituloModulo}
                    setDescripcionModulo={setDescripcionModulo}
                    setDescargables={setDescargables}
                    setLink={setLink}
                    mod={modulo}
                    key={index}
                  ></Modulo>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default VistaCursoMovil;
