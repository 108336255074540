import { Button, Form, Input, message, Select, Space, Switch } from "antd";
import React from "react";
import { Api } from "../../../../api/configApi";
import Boton from "../../../../components/design/elementos/boton";
import { setStoreActualizarLaEstructura, store } from "../../../../store";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";

const { Option } = Select;
var campoObligatorio = [{ required: true, message: "Campo obligatorio" }];

function FormCrearEmail({ setDrawerCrearNotificacion, emailActual }) {
  const { quill, quillRef } = useQuill({
    placeholder: "Contenido del email",
    modules: {
      toolbar: "#toolbar",
    },
    formats: ["size", "bold", "script", "italic", "underline", "strike"],
  });
  const [pestana, setPestanas] = React.useState([]);
  const [guardando, setGuardando] = React.useState(false);
  const [form] = Form.useForm();
  const [insertOrUpdate, setInsertOrUpdate] = React.useState("InsertEmails");

  React.useEffect(() => {
    const pesta = [];
    store.menuFront.forEach((element) => {
      pesta.push({ nombre: element.title, id: element.id });
      element.children.forEach((element) => {
        pesta.push({ nombre: element.title, id: element.id });
      });
    });
    setPestanas(pesta);
    if (emailActual) {
      setInsertOrUpdate("UpdateEmails");
      form.setFieldsValue(emailActual);
      if (quill) {
        quill.clipboard.dangerouslyPasteHTML(emailActual.contenido);
      }
    } else {
      form.resetFields();
      setInsertOrUpdate("InsertEmails");
      if (quill) {
        quill.clipboard.dangerouslyPasteHTML("");
      }
    }
  }, [emailActual, quill]);

  function guardarEmail(values) {
    setGuardando(true);
    if (emailActual) values._id = emailActual._id;
    values.contenido = quillRef.current.firstChild.innerHTML;

    Api("Cuentas", insertOrUpdate, { _id: store.cuentaactiva }, values).then(
      (re) => {
        setGuardando(false);
        if (re.success) {
          message.success("Notificación guardada");
          setStoreActualizarLaEstructura();
          setDrawerCrearNotificacion(false);
        } else {
          message.error("Algo no salio bien");
        }
      }
    );
  }

  return (
    <>
      <Form form={form} layout="vertical" onFinish={guardarEmail}>
        <Form.Item rules={campoObligatorio} name="name" label="A nombre de">
          <Input />
        </Form.Item>
        <Form.Item rules={campoObligatorio} name="asunto" label="Asunto">
          <Input />
        </Form.Item>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 50,
          }}
        >
          <div style={{ width: 500, height: 200, marginBottom: 10 }}>
            <div ref={quillRef} />
            <div id="toolbar">
              <select className="ql-size">
                <option value="small" />
                <option selected />
                <option value="large" />
                <option value="huge" />
              </select>

              <button className="ql-bold" />
              <button className="ql-italic" />
            </div>
          </div>
        </div>
        <Form.Item
          valuePropName="checked"
          name="especificarDestinatarios"
          label="Especificar destinatarios"
        >
          <Switch />
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {() => (
            <>
              {form.getFieldValue("especificarDestinatarios") && (
                <Form.Item
                  rules={[{ required: true, message: "Campo obligatorio" }]}
                  name="destinatarios"
                  label="Emails divididos por coma"
                >
                  <Input />
                </Form.Item>
              )}
            </>
          )}
        </Form.Item>
        <Button loading={guardando} htmlType="submit">
          Guardar
        </Button>
      </Form>
    </>
  );
}

export default FormCrearEmail;
