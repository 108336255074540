import { Form, Input, Select, Button, Space, Switch } from "antd";
import React from "react";

const { Option } = Select;

function CamposPersonalizados(props) {

  const refPlataformas = React.useRef(["YouTube", "Vimeo", "MP4"])
  const refRatios = React.useRef(["16:9", "4:3", "14:9", "37:20", "13:6"]);
  const [limpiarInput, setLimpiarInput] = React.useState("");


  return (
  <>
    <Form.Item
        name="plataforma"
        label="Plataforma de video"
        rules={[
          {
            required: true,
            message: "Selecciona la plataforma donde quieres poner tu video",
          },
        ]}
      >
        <Select placeholder="Seleccione plataforma de video" onChange={() => {
          props.formulario.setFieldsValue({
            url: ""
          })

        }}>
          {refPlataformas.current.map((plataforma, index) => <Option key={index} value={plataforma}>{plataforma}</Option>)}
        </Select>
      </Form.Item>

      <Form.Item
        name="ratio"
        label="Relación de aspecto de video"
        rules={[
          {
            required: true,
            message: "Selecciona una relación de aspecto del video a mostrar",
          },
        ]}
      >
        <Select placeholder="Seleccione relación de aspecto" >
          {refRatios.current.map((ratio, index) => <Option key={index} value={ratio}>{ratio}</Option>)}
        </Select>
      </Form.Item>

      <Form.Item
        name="url"
        label="URL del video"
        rules={[
          {
            required: true,
            message: "Ingresa la URL de tu video",
          },
        ]}
      >
        <Input type="url"></Input>
      </Form.Item>

      <Form.Item
      name="fullscreen"
      label="Permitir pantalla completa"
      valuePropName="checked"
      initialValue={true}
      >
        <Switch checkedChildren="Si" unCheckedChildren="No"  style={{float: "left"}} />

      </Form.Item>
  </>
  );
}

export default CamposPersonalizados;
