import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, DatePicker, Form, InputNumber, Select, Space } from "antd";
import { store } from "../../../../store";

const { Option } = Select;
const { RangePicker } = DatePicker;

function AgregarCondicionalesGeneralesWidgetTabla({
  atributoActual,
  listar,
  form,
}) {
  function atributo(listar) {
    const atri = store.losatributos;
    const atrib = [...atri.numbers, ...atri.texts];
    const atribut = atrib.find((e) => e._id === listar);
    if (atribut) {
      return atribut;
    } else {
      return { tipo: null, nombre: null, opciones: {} };
    }
  }
  function actualizarCampo({ index, campo, valor }) {
    const condicionales = [...form.getFieldsValue().condicionales];
    condicionales[index][campo] = valor;
    form.setFieldsValue({ condicionales: condicionales });
  }

  return (
    <Form.List name="condicionales">
      {(fields, { add, remove }) => (
        <div
          style={{
            background: "rgb(239 242 245)",
            padding: 12,
            marginTop: 10,
            borderRadius: 5,
            marginBottom: 15,
          }}
        >
          {fields.map(({ key, name, fieldKey, ...restField }, index) => {
            return (
              <Space>
                <FontAwesomeIcon
                  style={{
                    cursor: "pointer",
                    alignSelf: "center",
                    marginTop: 15,
                    marginLeft: 10,
                  }}
                  icon={faMinusCircle}
                  onClick={() => remove(name)}
                />
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.listar !== curValues.listar
                  }
                >
                  {({ getFieldsValue }) => {
                    const x =
                      getFieldsValue().listar === "todosAfiliados" ||
                      getFieldsValue().listar === "afiliadosPropios";

                    return (
                      x && (
                        <Form.Item
                          hasFeedback
                          {...restField}
                          colon={false}
                          name={[name, "atributo"]}
                          fieldKey={[fieldKey, "atributo"]}
                          rules={[
                            {
                              required: true,
                              message: "Campo obligatorio",
                            },
                          ]}
                          style={{
                            flexDirection: "column",
                            width: "100%",
                            marginBottom: 0,
                            marginTop: 10,
                          }}
                        >
                          <Select
                            onChange={(e) => {
                              var valor = "atributoIgualA";
                              switch (e) {
                                case "desdeHace":
                                  valor = "dias";
                                  break;
                                case "desdeFecha":
                                  valor = {};
                                  break;
                                default:
                                  break;
                              }
                              actualizarCampo({
                                index: index,
                                campo: "tipo",
                                valor: valor,
                              });
                              actualizarCampo({
                                index: index,
                                campo: "opcion",
                                valor: "",
                              });
                            }}
                            style={{ minWidth: 150 }}
                          >
                            <Option value="desdeHace">Desde hace</Option>
                            <Option value="desdeFecha">Desde la fecha</Option>
                            <Option value="rangoFecha">Rango de fechas</Option>
                            {store.losatributos.numbers.map((e) => {
                              return (
                                <Option key={e._id} value={e._id}>
                                  {e.nombre}
                                </Option>
                              );
                            })}
                            {store.losatributos.texts.map((e) => {
                              return (
                                <Option key={e._id} value={e._id}>
                                  {e.nombre}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      )
                    );
                  }}
                </Form.Item>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldsValue }) => {
                    return (
                      <>
                        {getFieldsValue().condicionales[index].atributo ===
                          "desdeHace" && (
                          <Form.Item
                            hasFeedback
                            {...restField}
                            colon={false}
                            name={[name, "valor"]}
                            fieldKey={[fieldKey, "valor"]}
                            rules={[
                              {
                                required: true,
                                message: "Campo obligatorio",
                              },
                            ]}
                            style={{
                              flexDirection: "column",
                              width: "100%",
                              marginBottom: 0,
                              marginTop: 10,
                            }}
                          >
                            <InputNumber />
                          </Form.Item>
                        )}
                      </>
                    );
                  }}
                </Form.Item>

                <Form.Item noStyle shouldUpdate>
                  {({ getFieldsValue }) => {
                    return (
                      <>
                        {getFieldsValue().condicionales[index].atributo !==
                          "desdeFecha" &&
                          getFieldsValue().condicionales[index].atributo !==
                            "rangoFecha" && (
                            <Form.Item
                              hasFeedback
                              {...restField}
                              colon={false}
                              name={[name, "tipo"]}
                              fieldKey={[fieldKey, "tipo"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Campo obligatorio",
                                },
                              ]}
                              style={{
                                flexDirection: "column",
                                width: "100%",
                                marginBottom: 0,
                                marginTop: 10,
                              }}
                            >
                              <Select style={{ width: 270 }}>
                                {atributo(
                                  getFieldsValue().condicionales[index].atributo
                                ).tipo === "number" && (
                                  <>
                                    <Option value="atributoMayorA">
                                      Mayores a
                                    </Option>
                                    <Option value="atributoMenorA">
                                      Menores a
                                    </Option>
                                    <Option value="atributoIgualA">
                                      Iguales a
                                    </Option>
                                  </>
                                )}
                                {atributo(
                                  getFieldsValue().condicionales[index].atributo
                                ).tipo === "text" && (
                                  <>
                                    <Option value="atributoDiferenteA">
                                      Diferente a
                                    </Option>
                                    <Option value="atributoIgualA">
                                      Igual a
                                    </Option>
                                  </>
                                )}
                                {getFieldsValue().condicionales[index]
                                  .atributo === "desdeHace" && (
                                  <>
                                    <Option value="dias">Dias</Option>
                                    <Option value="semanas">Semanas</Option>
                                    <Option value="meses">Meses</Option>
                                    <Option value="años">Años</Option>
                                  </>
                                )}
                              </Select>
                            </Form.Item>
                          )}
                      </>
                    );
                  }}
                </Form.Item>

                <Form.Item noStyle shouldUpdate>
                  {({ getFieldsValue }) => {
                    return (
                      <>
                        {(getFieldsValue().condicionales[index].atributo ===
                          "desdeFecha" ||
                          getFieldsValue().condicionales[index].atributo ===
                            "fechaExacta") && (
                          <Form.Item
                            hasFeedback
                            {...restField}
                            colon={false}
                            name={[name, "fecha"]}
                            fieldKey={[fieldKey, "fecha"]}
                            rules={[
                              {
                                required: true,
                                message: "Campo obligatorio",
                              },
                            ]}
                            style={{
                              flexDirection: "column",
                              width: "100%",
                              marginBottom: 0,
                              marginTop: 10,
                            }}
                          >
                            <DatePicker style={{ width: 200 }} />
                          </Form.Item>
                        )}

                        {atributo(
                          getFieldsValue().condicionales[index].atributo
                        ).tipo === "text" && (
                          <Form.Item
                            hasFeedback
                            {...restField}
                            colon={false}
                            name={[name, "opcion"]}
                            fieldKey={[fieldKey, "opcion"]}
                            rules={[
                              {
                                required: true,
                                message: "Campo obligatorio",
                              },
                            ]}
                            style={{
                              flexDirection: "column",
                              width: "100%",
                              marginBottom: 0,
                              marginTop: 10,
                            }}
                          >
                            <Select style={{ minWidth: 150 }}>
                              {store.losatributos.texts.find(
                                (e) =>
                                  e.nombre ===
                                  atributo(
                                    getFieldsValue().condicionales[index]
                                      .atributo
                                  ).nombre
                              ) &&
                                store.losatributos.texts
                                  .find(
                                    (e) =>
                                      e.nombre ===
                                      atributo(
                                        getFieldsValue().condicionales[index]
                                          .atributo
                                      ).nombre
                                  )
                                  .opciones.map((ee) => {
                                    return <Option value={ee}>{ee}</Option>;
                                  })}
                            </Select>
                          </Form.Item>
                        )}

                        {getFieldsValue().condicionales[index].atributo ===
                          "rangoFecha" && (
                          <Form.Item
                            hasFeedback
                            {...restField}
                            colon={false}
                            name={[name, "rangoFecha"]}
                            fieldKey={[fieldKey, "rangoFecha"]}
                            rules={[
                              {
                                required: true,
                                message: "Campo obligatorio",
                              },
                            ]}
                            style={{
                              flexDirection: "column",
                              width: "100%",
                              marginBottom: 0,
                              marginTop: 10,
                            }}
                          >
                            <RangePicker />
                          </Form.Item>
                        )}
                        {(getFieldsValue().condicionales[index].tipo ===
                          "desdeAtributoFecha" ||
                          getFieldsValue().condicionales[index].tipo ===
                            "AtributoFechaExacta") && (
                          <Form.Item
                            hasFeedback
                            {...restField}
                            colon={false}
                            name={[name, "atributoFecha"]}
                            fieldKey={[fieldKey, "atributoFecha"]}
                            rules={[
                              {
                                required: true,
                                message: "Campo obligatorio",
                              },
                            ]}
                            style={{
                              flexDirection: "column",
                              width: "100%",
                              marginBottom: 0,
                              marginTop: 10,
                            }}
                          >
                            <Select style={{ minWidth: 150 }}>
                              {store.losatributos.dates.map((re) => {
                                return (
                                  <Option value={re.nombre}>{re.nombre}</Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        )}
                        {atributo(
                          getFieldsValue().condicionales[index].atributo
                        ).tipo === "number" && (
                          <Form.Item
                            hasFeedback
                            {...restField}
                            colon={false}
                            name={[name, "valorAtributo"]}
                            fieldKey={[fieldKey, "valorAtributo"]}
                            rules={[
                              {
                                required: true,
                                message: "Campo obligatorio",
                              },
                            ]}
                            style={{
                              flexDirection: "column",
                              width: "100%",
                              marginBottom: 0,
                              marginTop: 10,
                            }}
                          >
                            <InputNumber />
                          </Form.Item>
                        )}
                      </>
                    );
                  }}
                </Form.Item>
              </Space>
            );
          })}
          <Form.Item>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                marginTop: 20,
              }}
            >
              <Button
                style={{ marginTop: 20 }}
                type="text"
                onClick={() => add({ atributo: "desdeHace" })}
              >
                Agregar condicional
              </Button>
            </div>
          </Form.Item>
        </div>
      )}
    </Form.List>
  );
}

export default AgregarCondicionalesGeneralesWidgetTabla;
