import { Select } from "antd";
import React from "react";

import { store } from "../../../../store";
function PageSinWidgetsComponente(props) {
  const { Option } = Select;
  const [value, setValue] = React.useState("default");
  

  React.useEffect(() => {
    setValue("default")
  },[props.widgets]);

  function onChange(value) {
    props.setWidgetNuevo(store.widgets[value]);
  }

  function onBlur() {
    // console.log("blur");
  }

  function onFocus() {
    // console.log("focus");
  }

  function onSearch(val) {
    // console.log("search:", val);
  }

  const Seleccion = () => {
    return (
      <Select
        showSearch
        defaultActiveFirstOption={false}
        style={{ width: 300 }}
        placeholder="Agregar Nuevo Widget"
        optionFilterProp="children"
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onSearch={onSearch}
      >
        {store.widgets.map((widget, index) => {
          return <Option value={index}>{widget.titulo}</Option>;
        })}
      </Select>
    );
  };

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src="https://octopusmultinivel.b-cdn.net/octopus10/blocks%20(1).svg"
          alt="Widgets"
          width="300px"
        />
        <span style={{ marginTop: 5, marginBottom: 15 }}>
          Aún no tienes WIDGETS en esta página
        </span>
        <Seleccion />,
      </div>
    </div>
  );
}

export default PageSinWidgetsComponente;
