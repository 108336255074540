import { Skeleton } from "antd";
import React from "react";
import { Api } from "../../../../../api/configApi";
import { store } from "../../../../../store";
import { jVar } from "json-variables";

function DesignWidgetListados(props) {
  const [widget, setWidget] = React.useState();
  const [items, setItems] = React.useState([]);
  const [cargando, setCargando] = React.useState(true);

  function Contenido(item) {
    const montarVue = (id) => {
      var varis = {};
      var varss = widget.variables;
      varss = JSON.parse(varss);
      console.log(item, "que viene..");
      varss._varis = {};
      varss._varis = item;

      varis = jVar(varss, {
        heads: "{",
        tails: "}",
      });

      const { createApp } = window.Vue;
      createApp({
        data() {
          return varis;
        },

        methods: {
          sumar() {
            this.raro = 5 + this.numero;
          },
        },
      }).mount("#" + id);
    };
    const idAletorioString = "c" + Math.random().toString(36).substring(7);
    var conte = `<div id=${idAletorioString}> ${widget.codeDesign} <div>`;
    setTimeout(() => {
      montarVue(idAletorioString);
    }, 3);
    return conte;
  }

  React.useEffect(() => {
    const widgetActual = store.widgets.find((wid) => {
      return wid._id === props._id;
    });

    // replace all the {{}} with the values
    setCargando(true);
    console.log("Lo mande a llamar");
    Api("listados", "GetItemsAll", {
      listado: widgetActual.listado,
    }).then((res) => {
      setItems(res);
      setCargando(false);
    });

    if (widgetActual) {
      setWidget(widgetActual);
    }
  }, [props]);

  return (
    <div style={{ height: "100%", width: "100%", position: "relative" }}>
      {widget &&
        (cargando ? (
          <Skeleton.Button active={true} size={200} />
        ) : (
          <div style={{ display: "flex", flexDirection: "row" }}>
            {items.map((item, index) => (
              <div
                key={"x" + index + item._id}
                dangerouslySetInnerHTML={{ __html: Contenido(item) }}
              ></div>
            ))}
          </div>
        ))}
    </div>
  );
}

export default DesignWidgetListados;
