import React from "react";

import { store } from "../../../../../store";
import { Api } from "../../../../../api/configApi";
import { Input, Tooltip, notification } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { faCopy, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import searchByC from "../../../../../helpers/searchByC";

function DesignWidgetLink(props) {
  const [widget, setWidget] = React.useState({});
  const [contenido, setContenido] = React.useState("");

  React.useEffect(() => {
    const widgetActual = store.widgets.find((wid) => {
      return wid._id === props._id;
    });
    setWidget(widgetActual);
  }, [props]);
  React.useEffect(() => {
    if (widget.link) {
      const linkSinFormatear = widget.link;
      const parametrosCuenta = searchByC(linkSinFormatear, "cuenta");
      const parametrosUsuario = searchByC(linkSinFormatear, "usuario");

      var linkFormateado = widget.link;
      parametrosCuenta.forEach((element) => {
        var newElement = store.cuenta[element];
        if (element === "dominio" && widget.nombre === "Link de registro") {
          newElement = newElement.replace(newElement.split(".")[0]+".", "");
        }
        linkFormateado = linkFormateado.replace(
          `{{${element}}}`,
          newElement || "NoDefinido"
        );
      });
      parametrosUsuario.forEach((element) => {
        linkFormateado = linkFormateado.replace(
          `[${element}]`,
          store.afiliado[element]
        );
      });
      setContenido(linkFormateado);
    }
  }, [widget]);

  const openNotification = () => {
    notification.open({
      message: "Link Copiado",
      description: contenido,
    });
  };

  return (
    <div style={{padding: 20}} className="widgetShadow">
      <div style={estilos.contenido}>
        <Input
          addonBefore={widget.titulo}
          addonAfter={
            <>
              <Tooltip title="Copiar Link">
                <CopyToClipboard text={contenido} onCopy={openNotification}>
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    title="Copiar link"
                    icon={faCopy}
                  />
                </CopyToClipboard>
              </Tooltip>
              <span> &#8287; </span>
              <Tooltip title="Abrir link">
                <FontAwesomeIcon
                  style={{ cursor: "pointer" }}
                  icon={faExternalLinkAlt}
                  onClick={() => {
                    window.open(contenido, "_blank");
                  }}
                />
              </Tooltip>
            </>
          }
          value={contenido}
        />
      </div>
    </div>
  );
}
const estilos = {
  contenido: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    fontSize: 30,
    fontWeight: 400,
  },
};
export default DesignWidgetLink;
