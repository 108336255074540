import React from "react";
import calculateSize from "calculate-size";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { Api } from "../../../../api/configApi";
import { setPensando, setStoreActualizarLaEstructura, setStoreGrupoFlujos, store } from "../../../../store";
import { ObjectId } from "bson";

function CrearGrupoFlujos(props) {
  const [anchoInputGrupo, setAnchoInputGrupo] = React.useState(200);
  const [anchoInputDescripcion, setAnchoInputGrupoDescripcion] = React.useState(
    200
  );
  const [nombreGrupo, setNombreGrupo] = React.useState("");
  const [descripcionGrupo, setDescripcionGrupo] = React.useState("");
  React.useEffect(() => {
    setAnchoInputGrupo(calculateSize(nombreGrupo, { fontSize: "18px" }));
    setAnchoInputGrupoDescripcion(
      calculateSize(descripcionGrupo, { fontSize: "18px" })
    );
  }, [nombreGrupo, descripcionGrupo]);
  const crearGrupo = () => {
    setPensando(true);
    const newId = new ObjectId();

    Api(
      "cuentas",
      "CrearGrupoFlujo",
      { _id: store.cuentaactiva },
      {
        gruposflujos: {
          nombre: nombreGrupo,
          descripcion: descripcionGrupo,
          _id: newId
        }
      }
    )
      .then((r) => {
          props.setActualizarGrupos(!props.actualizarGrupos);
          setStoreActualizarLaEstructura();
          setPensando(false);
          props.setCrearGrupo(false);
        })
    .catch((err) => {
      setPensando(false);
      console.log(err);
    });
};
return (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      background: "rgb(104 204 116 / 8%)",
      padding: 16,
      marginBottom: 50,
    }}
  >
    <div style={{ flex: 1 }}>
      <input
        autoFocus
        placeholder="Nombre del grupo"
        style={{
          minWidth: anchoInputGrupo.width,
          fontSize: 15,
          border: "none",
          background: "none",
          outline: "none",
        }}
        value={nombreGrupo}
        onChange={(e) => {
          setNombreGrupo(e.target.value);
        }}
      />
    </div>
    <div style={{ flex: 1 }}>
      <input
        placeholder="Descripción del grupo"
        style={{
          minWidth: anchoInputDescripcion.width,
          fontSize: 15,
          border: "none",
          background: "none",
          outline: "none",
        }}
        value={descripcionGrupo}
        onChange={(e) => {
          setDescripcionGrupo(e.target.value);
        }}
      />
    </div>
    <div style={{ position: "relative" }}>
      <span
        onClick={() => {
          crearGrupo();
        }}
        style={{ cursor: "pointer" }}
      >
        Guardar
      </span>
      <FontAwesomeIcon
        onClick={() => {
          props.setCrearGrupo(false);
        }}
        icon={faTimesCircle}
        style={{
          cursor: "pointer",
          position: "absolute",
          right: -20,
          top: -20,
        }}
      />

    </div>
  </div>
);
}

export default CrearGrupoFlujos;
