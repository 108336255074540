import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
} from "antd";
import React from "react";
import { PlusOutlined } from "@ant-design/icons";

const CompararAtributosMulti = (props) => {
  return (
    <>
      <Form.List name="compararAtributoMultiGroupList">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space
                key={key}
                style={{
                  display: "flex",
                  marginBottom: 8,
                  backgroundColor: "#fcfcfc",
                  padding: "20px",
                  borderRadius: "10px",
                }}
                direction="vertical"
              >
                <Form.Item
                  {...restField}
                  name={[name, props.operacionRelacional]}
                  label={props.labelOperacionRelacional}
                  rules={[
                    {
                      required: true,
                      message: "Debes seleccionar una opción",
                    },
                  ]}
                >
                  <Select placeholder="Seleccionar una opción">
                    <Select.Option value="$gt">Mayor que</Select.Option>
                    <Select.Option value="$lt">Menor que</Select.Option>
                    <Select.Option value="$gte">Mayor igual que</Select.Option>
                    <Select.Option value="$lte">Menor igual que</Select.Option>
                    <Select.Option value="$eq">Igual a</Select.Option>
                    <Select.Option value="$ne">Diferente de</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item shouldUpdate noStyle>
                  {(formulario) => {
                    if (
                      !formulario.getFieldsValue()
                        .compararAtributoMultiGroupList
                    ) {
                      return null;
                    }

                    return (
                      <>
                        {formulario.getFieldsValue()[props.nombreAtributo] &&
                          formulario
                            .getFieldsValue()
                            [props.nombreAtributo].slice(
                              formulario
                                .getFieldsValue()
                                [props.nombreAtributo].indexOf("-") + 1
                            ) == "number" && (
                            <Form.Item
                              {...restField}
                              name={[name, props.valorAtributo]}
                              label={props.labelValorAtributo}
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Debes ingresar un número",
                                },
                              ]}
                            >
                              <InputNumber placeholder="Ingresa un número"/>
                            </Form.Item>
                          )}
                        {formulario.getFieldsValue()[props.nombreAtributo] &&
                          formulario
                            .getFieldsValue()
                            [props.nombreAtributo].slice(
                              formulario
                                .getFieldsValue()
                                [props.nombreAtributo].indexOf("-") + 1
                            ) == "text" && (
                            <Form.Item
                              {...restField}
                              name={[name, props.valorAtributo]}
                              label={props.labelValorAtributo}
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Debes ingresar un dato",
                                },
                              ]}
                            >
                              <Input placeholder="Ingresa un dato"/>
                            </Form.Item>
                          )}
                        {formulario.getFieldsValue()[props.nombreAtributo] &&
                          formulario
                            .getFieldsValue()
                            [props.nombreAtributo].slice(
                              formulario
                                .getFieldsValue()
                                [props.nombreAtributo].indexOf("-") + 1
                            ) == "date" && (
                            <Form.Item
                              {...restField}
                              name={[name, "fechaUnica"]}
                              label={props.labelValorAtributo}
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Debes ingresar una fecha",
                                },
                              ]}
                            >
                              <DatePicker />
                            </Form.Item>
                          )}
                      </>
                    );
                  }}
                </Form.Item>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <p
                    style={{
                      marginTop: "0",
                      marginButton: "0",
                      cursor: "pointer",
                    }}
                    onClick={() => remove(name)}
                  >
                    ELIMINAR
                  </p>
                </div>
              </Space>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Agregar campos
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
};

export default CompararAtributosMulti;
