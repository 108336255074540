import React from "react";
import { useFormContext } from "react-hook-form";
import { useFieldArray } from "react-hook-form";
import {
  ButtonT,
  InputNumberT,
  InputT,
  OptionT,
  SelectT,
  TextAreaT,
} from "../../../../../../plugins/Formularios/FormularioTecclas";
import { store } from "../../../../../../store";

const SegundoPaso = () => {
  const [listaAtributos, setListaAtributos] = React.useState([]);

  const {
    register,
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "logica",
  });

  React.useEffect(() => {
    setListaAtributos(() => {
      return [...store.losatributos.numbers, ...store.losatributos.texts];
    });
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          padding: "10px 10px 0px 10px",

          width: "100%",
          marginBottom: "10px",
        }}
      >
        <p>Establece al afiliado un atributo según su resultado:</p>
      </div>
      <div>
        {fields.map((item, index) => {
          return (
            <div
              key={item.id}
              style={{
                display: "grid",
                backgroundColor: "#fcfcfc",
                marginBottom: "10px",
              }}
            >
              <div style={{ width: "100%", display: "grid" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    padding: "10px 10px 0px 10px",
                    textAlign: "justify",
                  }}
                >
                  <label style={{ marginBottom: "10px" }}>
                    Ingrese un rango de puntaje :
                  </label>
                  <div
                    style={{
                      width: "100%",
                      marginBottom:"10px"
                    }}
                  >
                    <label style={{ marginRight: "10px" }}>
                      Ingresa el valor mínimo:
                    </label>
                    <InputNumberT
                      min={0}
                      control={control}
                      name={`logica.${index}.min`}
                      rules={{ required: true }}
                      placeholder="Valor mínimo"
                    ></InputNumberT>
                    {/* <input
                      style={{
                        border: "thin solid rgb(118 118 118)",
                        borderRadius: "3px",
                        color: "black",
                        margin: "2px",
                      }}
                      min={0}
                      type="number"
                      {...register(`logica.${index}.min`, { required: true })}
                      placeholder="Valor mínimo"
                    /> */}

                    {errors.logica &&
                      errors.logica[index] &&
                      errors.logica[index].min && (
                        <span
                          style={{
                            color: "rgb(255, 0, 0 )",
                            padding: "5px",
                          }}
                        >
                          Escribe el valor mínimo
                        </span>
                      )}
                  </div>

                  <div
                    style={{ width: "100%", flex: "grid", flexWrap: "wrap" }}
                  >
                    <label style={{ marginRight: "10px" }}>
                      Ingresa el valor máximo:
                    </label>
                    <InputNumberT
                      control={control}
                      name={`logica.${index}.max`}
                      rules={{ required: true }}
                      placeholder="Valor máximo"
                    ></InputNumberT>
                    {/* <input
                      style={{
                        border: "thin solid rgb(118 118 118)",
                        borderRadius: "3px",
                        color: "black",
                        margin: "2px",
                      }}
                      min={0}
                      type="number"
                      {...register(`logica.${index}.max`, { required: true })}
                      placeholder="Valor máximo"
                    /> */}
                    {errors.logica &&
                      errors.logica[index] &&
                      errors.logica[index].max && (
                        <span
                          style={{
                            color: "rgb(255, 0, 0 )",
                            padding: "5px",
                          }}
                        >
                          Escribe el valor máximo
                        </span>
                      )}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    padding: "10px",
                    width: "100%",
                  }}
                >
                  <label style={{ marginBottom: "10px" }}>
                    Seleccione un atributo :
                  </label>
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <SelectT
                      control={control}
                      name={`logica.${index}.atributoSeleccionado`}
                      placeholder="Selecciona la operación a realizar"
                      ancho="300px"
                      rules={{ required: true }}
                    >
                      {listaAtributos.map((atrib, indiceAtrib) => {
                        return (
                          <OptionT
                            key={indiceAtrib}
                            value={atrib.nombre + "-" + atrib.tipo}
                          >
                            {atrib.nombre}
                          </OptionT>
                        );
                      })}
                    </SelectT>
                    {/* <select
                      style={{
                        width: "100%",
                      }}
                      {...register(`logica.${index}.atributoSeleccionado`, {
                        required: true,
                      })}
                    >
                      <option hidden value={""}>
                        seleccione una opción :
                      </option>
                      {listaAtributos.map((atrib, indiceAtrib) => {
                        return (
                          <option
                            key={indiceAtrib}
                            value={atrib.nombre + "-" + atrib.tipo}
                          >
                            {atrib.nombre}
                          </option>
                        );
                      })}
                    </select> */}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                    }}
                  >
                    {errors.logica &&
                      errors.logica[index] &&
                      errors.logica[index].atributoSeleccionado && (
                        <span
                          style={{
                            color: "rgb(255, 0, 0 )",
                            padding: "5px",
                          }}
                        >
                          Por favor seleccione un atributo
                        </span>
                      )}
                  </div>
                </div>
                <div
                  style={{
                    display: "grid",
                    flexDirection: "column",
                    padding: "10px",
                    marginBottom: "10px",
                    textAlign: "justify",
                    width: "100%",
                  }}
                >
                  <label style={{ marginBottom: "10px" }}>
                    {watch(`logica.${index}.atributoSeleccionado`)?.slice(
                      watch(`logica.${index}.atributoSeleccionado`).indexOf(
                        "-"
                      ) + 1,
                      watch(`logica.${index}.atributoSeleccionado`)?.length
                    ) === "text"
                      ? "Selecciona una opción"
                      : watch(`logica.${index}.atributoSeleccionado`)?.slice(
                          watch(`logica.${index}.atributoSeleccionado`).indexOf(
                            "-"
                          ) + 1,
                          watch(`logica.${index}.atributoSeleccionado`)?.length
                        ) === "number" && "Ingresa el nuevo valor:"}
                  </label>
                  <div>
                    {watch(`logica.${index}.atributoSeleccionado`)?.slice(
                      watch(`logica.${index}.atributoSeleccionado`).indexOf(
                        "-"
                      ) + 1,
                      watch(`logica.${index}.atributoSeleccionado`)?.length
                    ) === "text" ? (
                      <>
                        <SelectT
                          control={control}
                          name={`logica.${index}.valorAtributoTexto`}
                          placeholder="Selecciona una opción"
                          ancho="300px"
                        >
                          {listaAtributos.map((atrib2, indiceAtrib2) => {
                            if (
                              atrib2.nombre ==
                              watch(
                                `logica.${index}.atributoSeleccionado`
                              )?.slice(
                                0,
                                watch(
                                  `logica.${index}.atributoSeleccionado`
                                ).indexOf("-")
                              )
                            ) {
                              return atrib2.opciones.map(
                                (opcion, indiceOpcion) => {
                                  return (
                                    <OptionT key={indiceOpcion} value={opcion}>
                                      {opcion}
                                    </OptionT>
                                  );
                                }
                              );
                            }
                          })}
                        </SelectT>
                        {/* <select
                          {...register(`logica.${index}.valorAtributoTexto`, {
                            required: true,
                          })}
                        >
                          <option hidden>Seleccione una opción</option>
                          {listaAtributos.map((atrib2, indiceAtrib2) => {
                            if (
                              atrib2.nombre ==
                              watch(
                                `logica.${index}.atributoSeleccionado`
                              )?.slice(
                                0,
                                watch(
                                  `logica.${index}.atributoSeleccionado`
                                ).indexOf("-")
                              )
                            ) {
                              return atrib2.opciones.map(
                                (opcion, indiceOpcion) => {
                                  return (
                                    <option key={indiceOpcion} value={opcion}>
                                      {opcion}
                                    </option>
                                  );
                                }
                              );
                            }
                          })}
                        </select> */}
                      </>
                    ) : (
                      watch(`logica.${index}.atributoSeleccionado`)?.slice(
                        watch(`logica.${index}.atributoSeleccionado`).indexOf(
                          "-"
                        ) + 1,
                        watch(`logica.${index}.atributoSeleccionado`)?.length
                      ) === "number" && (
                        <>
                          <div>
                            <InputNumberT
                              control={control}
                              name={`logica.${index}.valorAtributoNumber`}
                              rules={{ required: true }}
                              placeholder="Ingresa nuevo valor"
                            ></InputNumberT>
                            {/* <input
                              style={{
                                border: "thin solid rgb(118 118 118)",
                                borderRadius: "3px",
                                width: "100%",
                                margin: "3px",
                              }}
                              min={0}
                              type="number"
                              {...register(
                                `logica.${index}.valorAtributoNumber`,
                                { required: true }
                              )}
                            /> */}

                            {errors.logica &&
                              errors.logica[index] &&
                              errors.logica[index].valorAtributoNumber && (
                                <span
                                  style={{
                                    color: "rgb(255, 0, 0 )",
                                    padding: "5px",
                                  }}
                                >
                                  Escribe el valor máximo
                                </span>
                              )}

                            <div>
                              <label style={{ marginRight: "10px" }}>
                                Elige lo que deseas hacer:
                              </label>
                              <SelectT
                                control={control}
                                name={`logica.${index}.operacion`}
                                placeholder="Selecciona la operación a realizar"
                                ancho="300px"
                                rules={{ required: true }}
                              >
                                <OptionT value="sumar">Sumar</OptionT>
                                <OptionT value="restar">Restar</OptionT>
                                <OptionT value="remplazar">Remplazar</OptionT>
                              </SelectT>
                              {/* <select
                                style={{ margin: "3px", width: "100%" }}
                                {...register(`logica.${index}.operacion`, {
                                  required: true,
                                })}
                              >
                                <option hidden value="">
                                  Seleccione la operación
                                </option>
                                <option value="sumar">Sumar</option>
                                <option value="restar">Restar</option>
                                <option value="remplazar">Remplazar</option>
                              </select> */}
                            </div>

                            {errors.logica &&
                              errors.logica[index] &&
                              errors.logica[index].operacion && (
                                <span
                                  style={{
                                    color: "rgb(255, 0, 0 )",
                                    padding: "5px",
                                  }}
                                >
                                  Por favor seleccione una operacion
                                </span>
                              )}
                          </div>
                        </>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ButtonT type="secondary" onClick={() => remove(index)}>
                  Eliminar
                </ButtonT>
                {/* <button type="button" onClick={() => remove(index)}>
                  Eliminar
                </button> */}
              </div>
            </div>
          );
        })}
        <div
          style={{
            padding: "10px 0 10px 0",
            marginBottom: "10px",
            display:"flex",
            justifyContent:"center",
          }}
        >
          <ButtonT type="secondary" onClick={() => append({ min: null })}>
            Agregar configuración para establecer un atributo
          </ButtonT>
          {/* <button type="button" onClick={() => append({ min: null })}>
            Agregar configuración para establecer un atributo
          </button> */}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            padding: "10px",
            marginBottom: "10px",
          }}
        >
          <label>Seleccione que sucederá al enviar la encuesta</label>
          <SelectT
            control={control}
            name={`finalizarEncuesta`}
            placeholder="Selecciona la operación a realizar"
            ancho="300px"
            rules={{ required: true }}
          >
            <OptionT value={`{"boll":true}`}>Actualizar la página</OptionT>
            <OptionT value={`{"boll":false}`}>
              Mostrar resultado obtenido
            </OptionT>
          </SelectT>
          {/* <select
            style={{ width: "100%" }}
            {...register(`finalizarEncuesta`, {
              required: true,
            })}
          >
            <option hidden value="">
              Seleccione que sucederá al enviar la encuesta
            </option>
            <option value={`{"boll":true}`}>Actualizar la página</option>
            <option value={`{"boll":false}`}>Mostrar resultado obtenido</option>
          </select> */}
          {errors.finalizarEncuesta && (
            <span>Por favor ingresa tu pregunta</span>
          )}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            padding: "10px",
          }}
        >
          <label style={{ marginBottom: "10px" }}>Ingresa un comentario</label>
          <TextAreaT control={control} name="comentario"></TextAreaT>
          {/* <textarea
            style={{
              width: "100%",
              backgroundColor: "rgb(243, 243, 243)",
              height: "50px",
            }}
            {...register("comentario")}
          /> */}
        </div>
      </div>
    </>
  );
};

export default SegundoPaso;
