import Vimeo from "@u-wave/react-vimeo";
import { Button, message, Popconfirm, Space, Tag } from "antd";
import React from "react";
import { Api } from "../../../api/configApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faRedo } from "@fortawesome/free-solid-svg-icons";

function ListadoItemsDocumentacion(props) {
  const { cargarItems, filtro, setCargando, items, cargando } = props;

  const eliminarItem = (id) => {
    setCargando(true);

    Api("documentacion", "eliminarItem", { id })
      .then((data) => {
        setCargando(false);
        if (data.success) {
          message.success("Item eliminado.");
          cargarItems();
        } else {
          message.error("Algo no salio bien.");
        }
      })
      .catch((err) => {
        setCargando(false);
        message.error("Algo no salio bien.");
      });
  };

  React.useEffect((e) => {
    cargarItems();
  }, []);
  return (
    <div style={{ marginTop: 20 }}>
      <Button
        style={{ alignSelf: "flex-end" }}
        onClick={cargarItems}
        icon={<FontAwesomeIcon icon={faRedo} />}
        loading={cargando}
      />
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {items
          .filter((e) => {
            const nre = e.titulo + e.tags + e.Ubicacion;
            // console.log(nre);
            return nre.toLowerCase().search(filtro.toLowerCase()) > -1;
          })
          .map((item) => {
            return (
              <div
                key={item._id}
                style={{ margin: 10, maxWidth: 425, position: "relative" }}
              >
                <div
                  style={{
                    fontWeight: 300,
                    width: 425,
                    textAlign: "center",
                    fontSize: 15,
                  }}
                >
                  {item.titulo}
                </div>
                <Popconfirm
                  title="Eliminar Item"
                  okText="Eliminar"
                  cancelText="Cancelar"
                  onConfirm={() => eliminarItem(item._id)}
                >
                  <div
                    style={{
                      position: "absolute",
                      width: 30,
                      height: 30,
                      borderRadius: "50%",
                      backgroundColor: "#ff7e7e",
                      color: "#fff",
                      right: 0,
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      zIndex: 100,
                    }}
                  >
                    <FontAwesomeIcon icon={faClose} />
                  </div>
                </Popconfirm>
                <div style={{ marginTop: 10 }}>
                  <Vimeo
                    width={425}
                    video={item.linkVideo}
                    showTitle={false}
                    showPortrait={false}
                    showByline={false}
                    controls={false}
                    volume={1}
                  />
                </div>
                <div
                  style={{
                    textAlign: "center",
                    fontSize: 9,
                    marginBottom: 5,
                  }}
                >
                  {item.ubicacion}
                </div>
                <div style={{ textAlign: "center", width: 425 }}>
                  {
                    // convertir string a array
                    item.tags.split(",").map((tag) => {
                      return <Tag style={{ margin: 5 }}>{tag}</Tag>;
                    })
                  }
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
export default ListadoItemsDocumentacion;
