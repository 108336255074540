import React from "react";
import { Drawer, Modal, Button } from "antd";
import { Api } from "../../../../../api/configApi";
import { setPensando, store } from "../../../../../store";
import Boton from "../../../../design/elementos/boton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import FormularioFacturacion from "./formularioFacturacion";
const ButtonGroup = Button.Group;
function CardProductoShop({
  producto,
  widget,
  setPedidosPendientes,
  setProductosenCarrito,
  productosenCarrito,
}) {
  const [creandoPedido, setCreandoPedido] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  function comprar(values) {
    if (producto.virtual || visible) {
      var billing = {
        first_name: store.usuario.nombre,
        email: store.usuario.email,
      };
      if (visible) {
        billing = values;
      }

      setCreandoPedido(true);
      Api("modulos", "woocommerce", {
        consulta: "SetPedido",
        items: [{ product_id: producto.id, quantity: 1 }],
        billing: billing,
      }).then((res) => {
        setCreandoPedido(false);
        setPedidosPendientes(true);
        window.open(
          `${res.tiendaurl}/${
            res.paginaCompra || "finalizar-compra"
          }/order-pay/${res.id}/?pay_for_order=true&key=${res.order_key}`
        );
        setVisible(false);
      });
    } else {
      setVisible(true);
    }
  }

  function onClose() {
    setVisible(false);
  }
  return (
    <div
      style={{
        margin: 20,
        borderRadius: 5,
        background: "#eff2f594",
        padding: 20,
        maxWidth: 220,
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Drawer
        title={"Datos de facturación"}
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
        width={600}
      >
        <FormularioFacturacion
          comprar={comprar}
          creandoPedido={creandoPedido}
        />
      </Drawer>
      <img
        style={{
          width: "100%",
          opacity: producto.images.length ? 1 : 0.2,
          padding: producto.images.length ? 0 : 30,
        }}
        src={
          producto.images.length
            ? producto.images[0].src
            : "https://octopusmultinivel.b-cdn.net/octopus10/new-product.svg"
        }
        alt={producto.images.length ? producto.images[0].alt : "producto"}
      />
      <strong style={{ fontSize: 15, marginTop: 10 }}>{producto.name}</strong>

      <span dangerouslySetInnerHTML={{ __html: producto.price_html }}></span>
      {widget.carrito ? (
        <>
          {productosenCarrito.find((e) => {
            return e.product_id === producto.id;
          }) ? (
            <ButtonGroup>
              <Button
                onClick={() => {
                  const nuevoProducto = [...productosenCarrito];
                  const prod = nuevoProducto.find((e) => {
                    return e.product_id === producto.id;
                  });
                  prod.quantity--;
                  if (prod.quantity <= 0) {
                    const indexPro = nuevoProducto.findIndex((e) => {
                      return e.product_id === producto.id;
                    });
                    nuevoProducto.splice(indexPro, 1);
                    setProductosenCarrito(nuevoProducto);
                  }

                  setProductosenCarrito(nuevoProducto);
                }}
              >
                <FontAwesomeIcon icon={faMinus} />
              </Button>
              <Button>
                {
                  productosenCarrito.find((e, index) => {
                    return e.product_id === producto.id;
                  }).quantity
                }
              </Button>
              <Button
                onClick={() => {
                  const nuevoProducto = [...productosenCarrito];
                  nuevoProducto.find((e) => {
                    return e.product_id === producto.id;
                  }).quantity++;

                  setProductosenCarrito(nuevoProducto);
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </ButtonGroup>
          ) : (
            <Boton
              onClick={() => {
                const nuevoProducto = [...productosenCarrito];
                nuevoProducto.push({
                  product_id: producto.id,
                  quantity: 1,
                  name: producto.name,
                  valor: producto.price,
                  tipo: producto.virtual,
                });
                setProductosenCarrito(nuevoProducto);
              }}
              style={{ marginTop: 5 }}
              texto="Agregar al Carrito"
            />
          )}
        </>
      ) : (
        <Boton
          loading={creandoPedido}
          onClick={comprar}
          style={{ marginTop: 5 }}
          texto={creandoPedido ? "Procesando" : "Comprar"}
        />
      )}
      <span
        style={{
          fontSize: 13,
          cursor: "pointer",
          color: "#000",
          marginTop: 10,
        }}
        onClick={() => {
          Modal.success({
            content: (
              <span
                dangerouslySetInnerHTML={{ __html: producto.description }}
              ></span>
            ),
          });
          setIsModalVisible(true);
        }}
      >
        Descripción
      </span>
    </div>
  );
}

export default CardProductoShop;
