import React from "react";
import { Drawer } from "antd";
import Boton from "../../../../design/elementos/boton";
import { Api } from "../../../../../api/configApi";
import FormularioFacturacion from "./formularioFacturacion";
import { store } from "../../../../../store";
function CarritoDrawer({
  productosenCarrito,
  setPedidosPendientes,
  setDrawerCarrito,
  setProductosenCarrito,
  widget,
}) {
  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);

  function Total() {
    var total = 0;
    productosenCarrito.forEach((producto) => {
      var semiTotal = producto.valor * parseInt(producto.quantity);
      total = total + semiTotal;
    });
    return total;
  }
  function procesarPedidoCarrito(values) {
    const productosFisicos = productosenCarrito.find((e) => {
      return e.tipo === false;
    });

    if (!productosFisicos || visible) {
      var billing = {
        first_name: store.usuario.nombre,
        email: store.usuario.email,
      };
      if (visible) {
        billing = values;
      }
      // console.log(billing);
      setLoading(true);
      Api("modulos", "woocommerce", {
        consulta: "SetPedido",
        items: productosenCarrito,
        billing: billing,
      }).then((res) => {
        setLoading(false);
        setPedidosPendientes(true);
        window.open(
          `${res.tiendaurl}/${
            res.paginaCompra || "finalizar-compra"
          }/order-pay/${res.id}/?pay_for_order=true&key=${res.order_key}`
        );
        setDrawerCarrito(false);
        setProductosenCarrito([]);
      });
    } else {
      setVisible(true);
    }
  }
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h1>Carrito</h1>
      {productosenCarrito.map((producto, index) => {
        return (
          <div
            key={producto.producto_id}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: 15,
            }}
          >
            <span style={{ flex: 1, fontSize: 15, fontWeight: 500 }}>
              {producto.name}
            </span>
            <span style={{ flex: 1, textAlign: "center" }}>
              x <span style={{ fontSize: 15 }}>{producto.quantity}</span>
            </span>
            <span style={{ flex: 1, textAlign: "right" }}>
              {producto.valor * parseInt(producto.quantity)}
            </span>
          </div>
        );
      })}
      <strong
        style={{
          fontSize: 20,
          marginTop: 20,
          borderTop: "1px solid rgb(232 232 232)",
        }}
      >
        Total: <span style={{ fontSize: 30 }}>{Total()}</span>
      </strong>
      <div style={{ marginTop: 10, display: "flex", justifyContent: "center" }}>
        {Total() < widget.montominimo ? (
          <span>
            Monto minimo de <strong>{widget.montominimo}</strong> no alcanzado
          </span>
        ) : Total() > widget.montomaximo ? (
          <span>
            Monto máximo de <strong>{widget.montomaximo}</strong> superado
          </span>
        ) : Total() <= 0 ? (
          <span>
            Debes agregar productos al carrito
          </span>
        ) : (
          <Boton
            loading={loading}
            style={{ marginTop: 10, width: "100%" }}
            onClick={procesarPedidoCarrito}
            texto="pagar"
          />
        )}
      </div>

      <Drawer
        width={500}
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
      >
        <FormularioFacturacion
          comprar={procesarPedidoCarrito}
          creandoPedido={loading}
        />
      </Drawer>
    </div>
  );
}

export default CarritoDrawer;
