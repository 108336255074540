import React from "react";
import { useHistory, useLocation, useParams } from "react-router";


function Submenu(props) {
  const params = useParams();
  const history = useHistory();
  
  return (
    <div>
      <ul className="submenu">
        {props.tableros.map((opc, index) => {
          return (
            <li
              key={index}
              style={{
                display: "list-item",
                textAlign: "-webkit-match-parent",
              }}
              className="nav-item "
            >
              <div
                className={ params.id === opc._id ? "activo" : params.id === opc.id && "activo" }
                onClick={()=>{ history.push(opc.id); }}
              >
                {opc.nombre}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default Submenu;
