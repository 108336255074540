import { Button, Drawer, Popover } from "antd";
import React from "react";
import { Api } from "../../../../../api/configApi";
import DrawerLecciones from "../Drawers/DrawerLecciones";
import FormularioModCurso from "../Formularios/FormularioModCurso";

const ContenedorCursos = (props) => {
  const [visiblePop, setVisiblePop] = React.useState(false);
  const [visibleLecciones, setVisibleLecciones] = React.useState(false);
  const [visibleModCurso, setVisibleModCurso] = React.useState(false);

  function eliminarCurso() {
    Api(
      "academia",
      "eliminarCurso",
      { idWidget: props.infoWidget._id },
      props.data,
      "https://ed3a-181-132-3-197.ngrok.io"
    )
      .then((res) => {
        props.obtenerCursos()
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const showDrawerLecciones = () => {
    setVisibleLecciones(true);
  };

  const onCloseLecciones = () => {
    setVisibleLecciones(false);
  };
  const showDrawerModCurso = () => {
    setVisibleModCurso(true);
  };

  const onCloseModCurso = () => {
    setVisibleModCurso(false);
  };

  const hide = () => {
    setVisiblePop(false);
  };

  const handleVisibleChange = (newVisible) => {
    setVisiblePop(newVisible);
  };

  React.useEffect(() => {}, [props.infoWidget]);

  return (
    <>
      <div
        style={{
          padding: "10px",
          backgroundColor: "#fff",
          borderRadius: "10px",
          margin: "10px 30px 10px 10px",
          boxShadow: "0 0px 10px -6px "
        }}
      >
        <div style={{ borderRadius:"5px", marginBottom:"5px", padding:"10px"}}>
          <h3 style={{fontWeight:600, margin:"0 0 5px 0"}}>{props.nombreCurso}</h3>
          <p style={{margin:0}}>{props.descripcionCurso}</p>
        </div>
        <hr style={{width:"95%", border:"1px solid #f5f5f5"}}/>
        <div style={{ display: "flex", justifyContent: "center",  borderRadius:"5px", padding:"5px 0 5px 0" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "70%",
            }}
          >
            <Button type="secondary" style={{ cursor: "pointer", margin:0 }} onClick={showDrawerLecciones}>
              Lecciones
            </Button>
            <Button type="secondary" style={{ cursor: "pointer", margin:0 }} onClick={showDrawerModCurso}>
              Modificar
            </Button>
            <Popover
              content={
                <>
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <Button
                      type="danger"
                      onClick={() => {
                        hide();
                        eliminarCurso();
                        
                      }}
                    >
                      Eliminar
                    </Button>
                    <Button type="secondary" onClick={hide}>
                      Cancelar
                    </Button>
                  </div>
                </>
              }
              title="¿Estás seguro de que deseas eliminar el curso?"
              trigger="click"
              visible={visiblePop}
              onVisibleChange={handleVisibleChange}
            >
              <Button type="secondary" danger style={{ cursor: "pointer", margin:0 }}>Eliminar</Button>
            </Popover>
          </div>
        </div>
      </div>
      <Drawer
        title="Crear de lecciones"
        placement="right"
        closable={true}
        onClose={onCloseLecciones}
        visible={visibleLecciones}
        width="900px"
      >
        <DrawerLecciones
          closeLecciones={onCloseLecciones}
          data={props.data}
          idWidget={props.infoWidget}
        ></DrawerLecciones>
      </Drawer>
      <Drawer
        title="Modificar curso"
        placement="right"
        closable={false}
        // onClose={onCloseModCurso}
        visible={visibleModCurso}
      >
        <FormularioModCurso
          infoWidget={props.infoWidget}
          data={props.data}
          setVisible={setVisibleModCurso}
          obtenerCursos = {props.obtenerCursos}
        ></FormularioModCurso>
      </Drawer>
    </>
  );
};

export default ContenedorCursos;
