import React from "react";
import Titulares from "../../components/titulares";
import Submenu from "../../components/submenu";
import { observer } from "mobx-react";
import { store } from "../../store";
import { toJS } from "mobx";
import TablaBase from "../../components/tablas/tablaBase";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ListasFlujos from "../../components/listas/ListasFlujosComponente";

function Flujos(props) {
  const [tableros, setTableros] = React.useState([
    { nombre: "Default", id: "untableronuevo", activo: true },
  ]);
  const [tableroactual, setTableroactual] = React.useState({});
  const [modulos, setModulos] = React.useState([
    {
      nombre: "Interesante",
    },
  ]);
  const history = useParams();
  React.useEffect(() => {
    // console.log(history);
    // store.modulos.forEach(modulo => {
    //     modulo.accion = navega
    // });
    // setModulos(store.modulos);
  }, []);
  return (
    <div style={{display: "flex", flexDirection: "column", height:"100%"}}>
      {/* <Titulares
        navegacion="Configuraciones"
        titular="Flujos"
        contenido="Crea, edita o elimina flujos"
        boton={{ texto: "Nuevo Modulo", ancla: "nuevomodulo" }}
      /> */}
      {/* <Submenu /> */}

      <div style={{ padding: 10, marginTop: 15, overflow:"auto" }}>
        <ListasFlujos tablero={tableroactual} />
      </div>
    </div>
  );
}

export default observer(Flujos);
