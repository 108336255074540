import React from "react";
import { store } from "../../../../../store";
import LugaresMundo from "./lugares/mundo";

function DesignWidgetMapas(props) {
  const [widget, setWidget] = React.useState();

  React.useEffect(() => {
    const widgetActual = store.widgets.find((wid) => {
      return wid._id === props._id;
    });

    if (widgetActual) {
      setWidget(widgetActual);
    }
  }, [props]);

  return (
    <div className="widgetShadow" style={{ height: "100%", width: "100%", position: "relative" }}>
      {widget && <LugaresMundo widget={widget} />}
    </div>
  );
}

export default DesignWidgetMapas;
