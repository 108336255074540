import { Popover } from "antd";
import React from "react";
import Boton from "../../../../../../components/design/elementos/boton";
import { ButtonT } from "../../../../../../plugins/Formularios/FormularioTecclas";
import Modal from "../../../../../../plugins/Modal";
import ModalMovil from "../../../../../../plugins/ModalMovil";
import VistaCurso from "./VistaCurso";
import VistaCursoMovil from "./VistaCursoMovil";

const TarjetaCurso = (props) => {
  const [resolucion, setResolucion] = React.useState();
  const refResolucion = React.useRef();

  React.useEffect(() => {
    refResolucion.current = window.innerWidth;
    setResolucion(refResolucion.current);
  }, []);

  const [mostrar, setMostrar] = React.useState(false);
  const [urlImagen, setUrlImagen] = React.useState(
    "https://pulosfDevelopers.b-cdn.net/iconosWidgets/video_placeholder.png"
  );
  const [modulos, setModulos] = React.useState([]);

  const refModulos = React.useRef([]);
  const refPadre = React.useRef();

  function formateo() {
    let ubicaciones = [];
    let padres = [];
    props.lecciones.forEach((elem) => {
      ubicaciones.push(elem.idLeccion);
    });

    ubicaciones.forEach((el) => {
      if (!padres.includes(el.split("/")[0])) {
        padres.push(el.split("/")[0]);
      }
    });

    padres.forEach((elemento) => {
      let hijos = [];
      let objLecciones = [];
      ubicaciones.forEach((elemen, index) => {
        if (elemento === elemen.split("/")[0]) {
          hijos.push(elemen.split("/")[1]);

          if (props.lecciones[index].idLeccion.includes("/")) {
            objLecciones.push(props.lecciones[index]);
            refPadre.current = elemento;
          } else {
            refPadre.current = props.lecciones[index];
          }
        }
      });

      refModulos.current.push({
        padre: refPadre.current,
        hijos: objLecciones,
      });
    });

    setModulos((x) => (x = [...refModulos.current]));
  }

  React.useEffect(() => {
    formateo();

    if (props.urlImagen) {
      setUrlImagen((img) => {
        img = props.urlImagen;
        return img;
      });
    }
  }, []);

  return (
    <>
      <div
        style={{
          backgroundColor: "#fafafa",
          width: "200px",
          minHeight: "350px",
          boxShadow: "0px 0px 5px 0px #ebebeb",
          borderRadius: "10px",
          margin: "20px",
        }}
      >
        <div
          onClick={(e) => setMostrar(true)}
          style={{
            backgroundColor: "orange",
            height: "120px",
            overflow: "hidden",
            position: "relative",
            cursor: "pointer",
          }}
        >
          <img
            style={{
              position: "absolute",
              top: "50%",
              transform: "translate(0, -50%)",
              minWidth: "100%",
            }}
            src={urlImagen}
            alt="video images"
          />
        </div>
        <div
          style={{
            padding: "10px 10px 20px 10px",
            minHeight: "230px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <div style={{ height: "150px" }}>
            <h2
              onClick={(e) => setMostrar(true)}
              style={{ fontSize: "17px", fontWeight: 600, cursor: "pointer" }}
            >
              {props.titulo.length > 40
                ? props.titulo.slice(0, 40) + "..."
                : props.titulo.slice(0, 40)}
            </h2>
            <p>
              {props.descripcion.length > 120 ? (
                <>
                  {props.descripcion.slice(0, 100)}
                  <Popover
                    content={
                      <>
                        <div style={{ width: "300px" }}>
                          {props.descripcion}
                        </div>
                      </>
                    }
                    title={props.titulo}
                  >
                    <span style={{ cursor: "pointer" }}>... Ver más</span>
                  </Popover>
                </>
              ) : (
                props.descripcion
              )}
            </p>
          </div>
          <Boton onClick={(e) => setMostrar(true)}>Ingresar</Boton>
        </div>
      </div>

      {resolucion > 768 ? (
        <>
          <Modal
            mostrar={mostrar}
            setMostrar={setMostrar}
            ancho="90%"
            alto="90vh"
          >
            <VistaCurso modulos={modulos}></VistaCurso>
          </Modal>
        </>
      ) : (
        <>
          <ModalMovil
            mostrar={mostrar}
            setMostrar={setMostrar}
            ancho="95%"
            alto="400px"
          >
            <VistaCursoMovil modulos={modulos}></VistaCursoMovil>
          </ModalMovil>
        </>
      )}
    </>
  );
};

export default TarjetaCurso;
