import { Drawer, message, Popconfirm } from "antd";
import React from "react";
import { Api } from "../../api/configApi";
import Boton from "../design/elementos/boton";
import FormCrearActualizarGrupoPulpitos from "./FormCrearActualizarGrupoPulpitos";
import ListaGruposPulpitos from "./ListaGruposPulpitos";

function Pulpitos({ setDrawerPulpitos }) {
  const [drawerNuevoGrupo, setDrawerNuevoGrupo] = React.useState(false);
  const [grupos, setGrupos] = React.useState([]);
  const [duplicado, setDuplicado] = React.useState({});
  function Get() {
    Api("Cuentas", "Get", {}, { pulpitos: 1 }).then((res) => {
      if (res) {
        setGrupos(res[0]?.pulpitos || []);
      } else {
        message.error("Algo no salio bien");
      }
    });
  }
  React.useEffect(() => {
    Get();
  }, []);

  return (
    <>
      <Drawer
        width={800}
        visible={drawerNuevoGrupo}
        onClose={() => {
          setDrawerNuevoGrupo(false);
        }}
        title="Nuevo Grupo Pulpitos"
      >
        <FormCrearActualizarGrupoPulpitos
          duplicado={duplicado}
          Get={Get}
          setDrawerNuevoGrupo={setDrawerNuevoGrupo}
          grupos={grupos}
        />
      </Drawer>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          marginBottom: 20,
        }}
      >
        <Boton
          onClick={() => {
            setDuplicado({});
            setDrawerNuevoGrupo(true);
          }}
          texto="Crear grupo"
        />
        <Popconfirm
          title="Limpiaremos la base datos de todos los pulpitos registrados"
          okText="Limpiar"
          cancelText="Cancelar"
        >
          <Boton tipo="danger" texto="Eliminar Pulpitos" />
        </Popconfirm>
      </div>

      <ListaGruposPulpitos
        setDuplicado={setDuplicado}
        Get={Get}
        grupos={grupos}
        setDrawerNuevoGrupo={setDrawerNuevoGrupo}
      />
    </>
  );
}

export default Pulpitos;
