import React from "react";
import { Form, Input, Select, Button, InputNumber, Space } from "antd";

import { ObjectId } from "bson";
import { setPensando, setStoreWidgets, store } from "../../../../store";
import { Api } from "../../../../api/configApi";

function CrearActualizarWidgetMapas(props) {
  const { Option } = Select;
  const [loading, setLoading] = React.useState(false);
  const [insertOrUpdate, setInsertOrUpdate] = React.useState("InsertWidgets");
  const [form] = Form.useForm();

  const guardarWidget = (values) => {
    values.tipo = "mapas";
    setPensando(true);
    setLoading(true);

    Api("Cuentas", insertOrUpdate, { _id: store.cuentaactiva }, values)
      .then((res) => {
        props.setVisible(false);
        setPensando(false);
        setLoading(false);
        return Api(
          "Cuentas",
          "Get",
          { _id: store.cuentaactiva },
          { widgets: 1 }
        );
      })
      .then((res) => {
        setStoreWidgets(res[0].widgets);
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
        setPensando(false);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    form.resetFields();
    form.setFieldsValue({ _id: new ObjectId(), dinamico: false });
  }, [props.visible]);

  React.useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
      setInsertOrUpdate("UpdateWidgets");
    } else {
      form.setFieldsValue({ _id: new ObjectId(), dinamico: false });
    }
  }, [props.data]);

  return (
    <div>
      <Form form={form} onFinish={guardarWidget} layout="vertical">
        <Form.Item
          label="Nombre"
          name="nombre"
          hasFeedback
          rules={[{ required: true, message: "Ingresa un nombre" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="_id" hidden rules={[{ required: true }]}></Form.Item>
        <Form.Item
          name="dinamico"
          hidden
          rules={[{ required: true }]}
        ></Form.Item>

        <Form.Item
          label="Titulo"
          name="titulo"
          hasFeedback
          rules={[{ required: true, message: "Ingresa un titulo" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Descripción"
          name="descripcion"
          hasFeedback
          rules={[{ required: true, message: "Ingresa una descripción" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="lugar"
          label="Lugares"
          hasFeedback
          rules={[{ required: true, message: "Ingresa una descripción" }]}
        >
          <Select>
            <Option value="todosPaises">Todo el mundo</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="cualesAfiliados"
          label="Cuales afiliados"
          hasFeedback
          rules={[{ required: true, message: "Ingresa una descripción" }]}
        >
          <Select>
            <Option value="todosAfiliados">Todos los afiliados</Option>
          </Select>
        </Form.Item>
        <Space>
          <Form.Item
            rules={[{ required: true, message: "Campo obligatorio" }]}
            name="colorDesde"
            label="Rango color desde"
            initialValue="#eafff1"
          >
            <Input />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "Campo obligatorio" }]}
            name="colorHasta"
            label="Rango color hasta"
            initialValue="#43ef00"
          >
            <Input />
          </Form.Item>
        </Space>
        <p></p>

        <Button loading={loading} htmlType="submit">
          Guardar
        </Button>
      </Form>
    </div>
  );
}

export default CrearActualizarWidgetMapas;
