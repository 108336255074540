import { Form, Input, Mentions, message, Select, Switch } from "antd";
import React from "react";
import { Api } from "../../../../api/configApi";
import Boton from "../../../../components/design/elementos/boton";
import { setStoreActualizarLaEstructura, store } from "../../../../store";
const { Option } = Select;
function FormCrearNotificacion({
  setDrawerCrearNotificacion,
  notificacionActual,
}) {
  var campoObligatorio = [{ required: true, message: "Campo obligatorio" }];
  const [pestana, setPestanas] = React.useState([]);
  const [form] = Form.useForm();
  const [insertOrUpdate, setInsertOrUpdate] = React.useState(
    "InsertNotificaciones"
  );
  React.useEffect(() => {
    const pesta = [];
    store.menuFront.forEach((element) => {
      pesta.push({ nombre: element.title, id: element.id });
      element.children.forEach((element) => {
        pesta.push({ nombre: element.title, id: element.id });
      });
    });
    setPestanas(pesta);
    if (notificacionActual) {
      setInsertOrUpdate("UpdateNotificaciones");
      form.setFieldsValue(notificacionActual);
    } else {
      form.resetFields();
      setInsertOrUpdate("InsertNotificaciones");
    }
  }, [notificacionActual]);
  function guardarNotificacion(values) {
    if (notificacionActual) values._id = notificacionActual._id;
    Api("Cuentas", insertOrUpdate, { _id: store.cuentaactiva }, values).then(
      (re) => {
        if (re.success) {
          message.success("Notificación guardada");
          setStoreActualizarLaEstructura();
          setDrawerCrearNotificacion(false);
        } else {
          message.error("Algo no salio bien");
        }
      }
    );
  }
  return (
    <Form form={form} layout="vertical" onFinish={guardarNotificacion}>
      <Form.Item rules={campoObligatorio} name="titulo" label="Titulo">
        <Input />
      </Form.Item>
      {/* <Form.Item rules={campoObligatorio} name="disparador" label="Disparador">
        <Select>
          <Option value="compra">Compra</Option>
          <Option value="cron">Cada x tiempo</Option>
        </Select>
      </Form.Item> */}
      <Form.Item name="contenido" label="Contenido" rules={campoObligatorio}>
        {/* <Mentions
          autoSize
          prefix="#"
          placeholder="Contenido de la notificación"
        >
          {store.losatributos.numbers.map((e) => {
            return <Option value={"atributo" + e.nombre}>{e.nombre}</Option>;
          })}
        </Mentions> */}
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        name="burbuja"
        label="Activar Burbuja"
        rules={campoObligatorio}
        valuePropName="checked"
        initialValue={false}
      >
        <Switch defaultChecked={form.getFieldsValue().burbuja} />
      </Form.Item>

      <Form.Item
        style={{ marginTop: 20 }}
        name="accion"
        label="Al hacer clic enviar a"
        rules={campoObligatorio}
      >
        <Select>
          <Option value="nada">Ninguna parte</Option>
          {pestana.map((e) => {
            return (
              <Option key={e.id} value={e.id}>
                {e.nombre}
              </Option>
            );
          })}
        </Select>
      </Form.Item>

      <Boton style={{ marginTop: 20 }} htmlType="submit" texto="Guardar" />
    </Form>
  );
}

export default FormCrearNotificacion;
