import { Form, Select } from "antd";
import React from "react";

const ListarOpciones = (props) => {
  return (
    <>
      <Form.Item
        name={props.nombreOpcion}
        label={props.labelNombreOpcion}
        rules={[
          {
            required: true,
            message: "Debes seleccionar una opción",
          },
        ]}
      >
        <Select placeholder="Selecciona una opción">
          {props.listadoOpciones.map((item, index) => {
            return (
              <Select.Option key={index} value={item.valor}>
                {item.valorMostrar}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </>
  );
};

export default ListarOpciones;
