import { Form, Input, message, Select } from "antd";
import React from "react";
import { Api } from "../../../../../api/configApi";
import Boton from "../../../../design/elementos/boton";
const { Option } = Select;
function FormCrearActualizarNuevoRecurso({
  widget,
  setDrawerRecurso,
  ObtenerLosRecursos,
  actualizarRecurso,
}) {
  const [guardando, setGuardando] = React.useState(false);
  const [form] = Form.useForm();
  React.useEffect(() => {
    if (actualizarRecurso) {
      form.setFieldsValue(actualizarRecurso);
    }
  }, [actualizarRecurso]);
  function guardarRecurso(values) {
    setGuardando(true);
    var match = true;
    var error = false;
    switch (values.tipo) {
      case "video":
        values.tipoVideo === "youtube" && (match = /v=/);
        values.tipoVideo === "vimeo" && (match = /vimeo/);
        values.tipoVideo === "mp4" && (match = /\.mp4/);
        !values.link.match(match) && (error = true);
        break;
      case "imagen":
        match = /\.png|\.jpg|\.jpeg|\.svg/;
        !values.link.match(match) && (error = true);
        break;
      case "pdf":
        match = /\.pdf/;
        !values.link.match(match) && (error = true);
        break;
      case "audio":
        match = /\.mp3|\.mp4/;
        !values.link.match(match) && (error = true);
        break;
      default:
        break;
    }
    if (error) {
      setGuardando(false);
      return message.error(
        "El link puede no ser el correcto. Revisalo antes de intentarlo nuevamente"
      );
    }
    var recursoActual = false;
    if (actualizarRecurso) {
      recursoActual = actualizarRecurso;
    }
    Api(
      "recursos",
      "Insert",
      {
        widget: widget._id,
        modulo: widget.moduloRecursos,
        recursoActual: recursoActual,
      },
      values
    ).then((res) => {
      if (res.success) {
        ObtenerLosRecursos(widget);
        message.success("Recurso creado");
        setGuardando(false);
        setDrawerRecurso(false);
      } else {
        setGuardando(false);
        message.error("Algo no ha salido bien.");
      }
    });
  }

  return (
    <Form form={form} onFinish={guardarRecurso} layout="vertical">
      <Form.Item
        name="titulo"
        rules={[{ required: true, message: "Campo obligatorio" }]}
        label="Titulo"
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: "Campo obligatorio" }]}
        name="descripcion"
        label="Descripción"
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: "Campo obligatorio" }]}
        name="tipo"
        label="Tipo de recurso"
      >
        <Select>
          <Option value="pdf">Pdf</Option>
          <Option value="video">Video</Option>
          <Option value="audio">Audio</Option>
          <Option value="imagen">Imagen</Option>
          <Option value="otro">Otro</Option>
        </Select>
      </Form.Item>
      <Form.Item noStyle shouldUpdate={(prev, prox) => prev.tipo !== prox.tipo}>
        {({ getFieldsValue }) => {
          return (
            getFieldsValue().tipo === "video" && (
              <Form.Item
                rules={[
                  { required: true, message: "Este campo es obligatorio" },
                ]}
                name="tipoVideo"
                label="De donde es el video"
              >
                <Select>
                  <Option value="youtube">Youtube</Option>
                  <Option value="vimeo">Vimeo</Option>
                  <Option value="mp4">Mp4</Option>
                </Select>
              </Form.Item>
            )
          );
        }}
      </Form.Item>

      <Form.Item
        rules={[
          {
            required: true,
            message: "Campo obligatorio",
          },
        ]}
        name="link"
        label="Url del recurso"
      >
        <Input type="url" />
      </Form.Item>
      <Boton loading={guardando} texto="Guardar" htmlType="submit" />
    </Form>
  );
}

export default FormCrearActualizarNuevoRecurso;
