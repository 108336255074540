import { Form, Input, message } from "antd";
import React from "react";
import { Api } from "../../../../../api/configApi";
import Boton from "../../../../../components/design/elementos/boton";

function IntegracionFad(props) {
  const [form] = Form.useForm();
  function guardar(values) {
    Api(
      "Cuentas",
      "InsertIntegracion",
      { tipoIntegracion: "firmaElectronica", proveedor: "Fad" },
      values
    ).then((res) => {
      if (res.success) {
        message.success("Integración Fad guardada");
      } else {
        message.error("Algo no salio bien.");
      }
    });
  }
  React.useEffect(() => {
    form.setFieldsValue(props.integraciones?.Fad);
  }, [props.integraciones]);
  return (
    <Form form={form} onFinish={guardar} layout="vertical">
      <Form.Item
        rules={[{ required: true, message: "campo obligatorio" }]}
        label="Username"
        name="username"
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: "campo obligatorio" }]}
        label="Contraseña"
        name="password"
      >
        <Input />
      </Form.Item>

      <Boton texto="Guardar" htmlType="submit" />
    </Form>
  );
}

export default IntegracionFad;
