import { Form, Input, InputNumber, message, Select } from "antd";
import { observer } from "mobx-react";
import React from "react";
import { Api } from "../../api/configApi";
import Boton from "../../components/design/elementos/boton";
import ZonasHorarias from "../../helpers/zonasHorarias";
import { setStoreActualizarLaEstructura, store } from "../../store";
const { Option } = Select;


function RenderZonaHoraria() {
  const [form] = Form.useForm();
  const [guardando, setGuardando] = React.useState(false);

  function guardarConfiguracion(values) {
    setGuardando(true);
    
    Api(
      "cuentas",
      "UpdateZonaHoraria",
      { _id: store.cuentaactiva },
      { zona: values }
    )
      .then((res) => {
        message.success("Actualizado Correctamente");
        setStoreActualizarLaEstructura();
        setGuardando(false);
      })
      .catch((e) => {
        setGuardando(false);
      });
  }
  return (
    <>
      <Form form={form} onFinish={guardarConfiguracion} layout="vertical">
        <Form.Item
          name="zonaHoraria"
          label="Zona Horaria"
          rules={[{ required: true, message: "Campo obligatorio" }]}
        >
          <Select showSearch={true}>
            {ZonasHorarias.map((e) => (
              <Option value={e} key={e}>
                {e}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Boton loading={guardando} texto="Guardar" htmlType="submit" />
      </Form>
    </>
  );
}

export default observer(RenderZonaHoraria);
