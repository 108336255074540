import { Tabs } from "antd";
import ListaAtributosPerfilAfiliado from "./atributosPerfilAfiliado/listaAtributosPerfilAfiliado";
import ListaListadosPerfilAfiliado from "./listadosPerfilAfiliado/listaListadosPerfilAfiliado";
import IndexPerfilAfiliado from "./perfilAfiliadoTab/indexPerfilAfiliado";

const { TabPane } = Tabs;

function EditarPerfilAfiliado(props) {
  return (
    <>
      <Tabs destroyInactiveTabPane={true}>
        <TabPane tab="Atributos" key="atributos">
          <div>
            <ListaAtributosPerfilAfiliado infoAfiliado={props.infoElemento} />
          </div>
        </TabPane>
        <TabPane tab="Listados" key="listados">
          <ListaListadosPerfilAfiliado infoAfiliado={props.infoElemento} />
        </TabPane>
        <TabPane tab="Perfil" key="Perfil">
          <IndexPerfilAfiliado infoAfiliado={props.infoElemento} />
        </TabPane>
      </Tabs>
    </>
  );
}

export default EditarPerfilAfiliado;
