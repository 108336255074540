import { Button, Form, Input, message, Modal } from "antd";
import React from "react";
import { Api } from "../../../../../api/configApi";
import Boton from "../../../../../components/design/elementos/boton";

function InvitarRegistro(props) {
  const [visible, setVisible] = React.useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);

  function enviarInvitacion(values) {
    setLoading(true);
    Api("widgets", "acciones", {
      accion: "invitarRegistro",
      datos: values,
      email: props.widget.email,
      widget: props.widget._id,
    }).then((res) => {
      if (res.success) {
        message.success("Invitación enviada");
        setVisible(false);
      } else {
        message.error("SMTP no configurado. Contacte al administrador");
      }
      setLoading(false);
    });
  }

  return (
    <>
      <Modal
        visible={visible}
        okText="Enviar"
        cancelText="Cancelar"
        onOk={() => form.submit()}
        onCancel={() => setVisible(false)}
        destroyOnClose={true}
        confirmLoading={loading}
      >
        <p>Enviaremos un email con un link para completar el registro.</p>
        <Form onFinish={enviarInvitacion} form={form} layout="vertical">
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Ingresa un email",
              },
              {
                type: "email",
                message: "Ingresa un email valido",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Nombre"
            name="nombre"
            rules={[
              {
                required: true,
                message: "Ingresa un nombre",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <Boton onClick={() => setVisible(true)}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <i
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "10px",
            }}
            class="fi fi-rr-envelope"
          ></i>
          <span>{props.widget.textoBoton || "Regístrar"}</span>
        </div>
      </Boton>
    </>
  );
}

export default InvitarRegistro;
