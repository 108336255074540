import { Skeleton } from "antd";
import React from "react";

import { Api } from "../../../../api/configApi";
import moment from "moment";
import { store } from "../../../../store";

function DesignWidgetIndicadores(props) {
  const [widget, setWidget] = React.useState();

  const refNumeroMostrar = React.useRef();
  const [numeroMostrar, setNumeroMostrar] = React.useState();
  const refRuta = React.useRef(process.env.REACT_APP_RUTA);
  const refEsFecha = React.useRef(false);
  const refEsTexto = React.useRef(false);
  const refCriptoPortada = React.useRef([
    "Bitcoin BTC",
    "Ethereum, Ether ETH",
    "Dogecoin DOGE",
    "Cardano ADA",
    "Litecoin LTC",
    "Binance Coin BNB",
    "Polkadot DOT",
    "Ripple XRP",
    "BitBay",
    "Stellar XLM",
    "Bitcoin cash BCH",
    "Chainlink LINK",
    "IOTA MIOTA",
    "Monero",
    "NEO",
    "Tether USDT",
    "Tezos XTZ",
    "THETA",
    "Uniswap UNI",
    "USD Coin USDC",
  ]);

  const enviarDatosPeticion = () => {
    Api(
      "indicadores",
      "ml8_hacerConsultaAvanzada",
      {},
      { idWidget: props._id },
      refRuta.current
    )
      .then((res) => {
        refNumeroMostrar.current = res.resultado;

        if (
          isNaN(refNumeroMostrar.current) &&
          moment(refNumeroMostrar.current).isValid() &&
          refNumeroMostrar.current != undefined
        ) {
          refEsFecha.current = true;
        } else if (
          isNaN(refNumeroMostrar.current) &&
          !moment(refNumeroMostrar.current).isValid() &&
          refNumeroMostrar.current != undefined
        ) {
          refEsTexto.current = true;
        }

        if (refNumeroMostrar.current == "undefined") {
          setNumeroMostrar("-");
        } else {
          setNumeroMostrar(refNumeroMostrar.current);
        }
      })
      .catch((err) => console.log(err));
  };

  React.useEffect(() => {
    const widgetActual = store.widgets.find((wid) => {
      return wid._id === props._id;
    });
    setWidget(widgetActual);
  }, [props]);

  React.useEffect(() => {
    enviarDatosPeticion();
  }, []);

  function tiposFormato() {
    switch (widget.format.tipoFormato) {
      case "miles":
        return (
          <>
            <h1
              style={{ fontSize: "30px", fontWeight: "bold", ...props.estilos }}
            >
              {widget.format.prefijo &&
                numeroMostrar?.toString() &&
                " " + widget.format.prefijo + " "}{" "}
              {!isNaN(numeroMostrar) &&
              !refEsFecha.current &&
              !refEsTexto.current ? (
                new Intl.NumberFormat("de-DE").format(numeroMostrar)
              ) : (
                <Skeleton.Button style={{ width: "200px" }} active />
              )}{" "}
              {widget.format.sufijo &&
                numeroMostrar?.toString() &&
                " " + widget.format.sufijo + " "}
            </h1>
          </>
        );

      case "redondearDecimales":
        return (
          <>
            <h1
              style={{ fontSize: "30px", fontWeight: "bold", ...props.estilos }}
            >
              {widget.format.prefijo &&
                numeroMostrar?.toString() &&
                " " + widget.format.prefijo + " "}{" "}
              {!isNaN(numeroMostrar) &&
              !refEsFecha.current &&
              !refEsTexto.current ? (
                numeroMostrar.toFixed(2)
              ) : (
                <Skeleton.Button style={{ width: "200px" }} active />
              )}{" "}
              {widget.format.sufijo &&
                numeroMostrar?.toString() &&
                " " + widget.format.sufijo + " "}
            </h1>
          </>
        );

      case "moneda":
        return (
          <>
            <h1
              style={{ fontSize: "30px", fontWeight: "bold", ...props.estilos }}
            >
              {widget.format.prefijo &&
                numeroMostrar?.toString() &&
                " " + widget.format.prefijo + " "}{" "}
              {widget.format.monedas &&
                (refCriptoPortada.current.includes(widget.format.monedas) ? (
                  !isNaN(numeroMostrar) &&
                  !refEsFecha.current &&
                  !refEsTexto.current ? (
                    new Intl.NumberFormat("de-DE").format(numeroMostrar) +
                    " " +
                    widget.format.monedas
                  ) : (
                    <Skeleton.Button style={{ width: "200px" }} active />
                  )
                ) : !isNaN(numeroMostrar) &&
                  !refEsFecha.current &&
                  !refEsTexto.current ? (
                  new Intl.NumberFormat("de-DE", {
                    style: "currency",
                    currency: props.format.monedas,
                  }).format(numeroMostrar)
                ) : (
                  <Skeleton.Button style={{ width: "200px" }} active />
                ))}
              {widget.format.sufijo &&
                numeroMostrar?.toString() &&
                " " + widget.format.sufijo + " "}
            </h1>
          </>
        );

      case "fecha":
        return (
          <>
            <h1
              style={{ fontSize: "30px", fontWeight: "bold", ...props.estilos }}
            >
              {widget.format.prefijo &&
                numeroMostrar?.toString() &&
                " " + widget.format.prefijo + " "}{" "}
              {isNaN(numeroMostrar) &&
              refEsFecha.current &&
              !refEsTexto.current ? (
                moment(numeroMostrar).format("DD-MM-YYYY")
              ) : (
                <Skeleton.Button style={{ width: "200px" }} active />
              )}{" "}
              {widget.format.sufijo &&
                numeroMostrar?.toString() &&
                " " + widget.format.sufijo + " "}
            </h1>
          </>
        );

      case "texto":
        return (
          <>
            <h1
              style={{ fontSize: "30px", fontWeight: "bold", ...props.estilos }}
            >
              {widget.format.prefijo &&
                numeroMostrar?.toString() &&
                " " + widget.format.prefijo + " "}{" "}
              {isNaN(numeroMostrar) &&
              !refEsFecha.current &&
              refEsTexto.current ? (
                numeroMostrar
              ) : (
                <Skeleton.Button style={{ width: "200px" }} active />
              )}{" "}
              {widget.format.sufijo &&
                numeroMostrar?.toString() &&
                " " + widget.format.sufijo + " "}
            </h1>
          </>
        );

      default:
        return (
          <>
            <h1
              style={{ fontSize: "30px", fontWeight: "bold", ...props.estilos }}
            >
              {widget.format.prefijo &&
                numeroMostrar?.toString() &&
                " " + widget.format.prefijo + " "}{" "}
              {!isNaN(numeroMostrar) ? (
                numeroMostrar
              ) : refEsFecha.current || refEsTexto.current ? (
                numeroMostrar
              ) : (
                <Skeleton.Button style={{ width: "200px" }} active />
              )}
              {widget.format.sufijo &&
                numeroMostrar?.toString() &&
                " " + widget.format.sufijo + " "}
            </h1>
          </>
        );
    }
  }

  return (
    <>
      <div
        style={{
          padding: "10px 0px 10px 0px",
          display: "flex",
          flexDirection: "column",
          alignItems: !props.onlyResult && "center",
        }}
        className={!props.onlyResult && "widgetShadow"}
      >
        {widget && (
          <div style={{ marginBottom: "-20px" }}>{tiposFormato()}</div>
        )}
        {widget && !props.onlyResult && (
          <span style={{ fontWeight: "300" }}>{widget.titulo}</span>
        )}
      </div>
    </>
  );
}
export default DesignWidgetIndicadores;
