import Tooltip from "@material-ui/core/Tooltip";

function ModalListaFiltrosQuienes(props) {
  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <span style={{ fontSize: 15, fontWeight: "bold", color: "#40af77" }}>
            Incluidos
          </span>
          <span
            onClick={() => {
              props.setVistaactiva(1);
              props.setSwitchIncluidos(true);
            }}
            style={{ fontSize: 12, cursor: "pointer" }}
          >
            Agregar +
          </span>
        </div>
        <div style={estilos.confiltrospadre}>
          {props.listadefiltros.incluidos.map((filtro) => {
            return (
              <Tooltip
                key={filtro.id}
                title={`Valor: ${filtro.filtro.valor}`}
                placement="right-start"
              >
                <div style={estilos.contfiltros}>
                  <span>{filtro.filtro.nombre}</span>
                  <span
                    onClick={() => {
                      props.eliminarfiltro(filtro.id, true, "disparadores");
                    }}
                  >
                    X
                  </span>
                </div>
              </Tooltip>
            );
          })}
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", marginTop: 10 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <span style={{ fontSize: 15, fontWeight: "bold", color: "#af4063" }}>
            Excluidos
          </span>
          <span
            onClick={() => {
              props.setVistaactiva(1);
              props.setSwitchIncluidos(false);
            }}
            style={{ fontSize: 12, cursor: "pointer" }}
          >
            Agregar +
          </span>
        </div>
        <div style={estilos.confiltrospadre}>
          {props.listadefiltros.excluidos.map((filtro) => {
            return (
              <Tooltip
                key={filtro.id}
                title={`Valor: ${filtro.filtro.valor}`}
                placement="right-start"
              >
                <div style={estilos.contfiltros}>
                  <span>{filtro.filtro.nombre}</span>
                  <span
                    onClick={() => {
                      props.eliminarfiltro(filtro.id, false, "disparadores");
                    }}
                  >
                    X
                  </span>
                </div>
              </Tooltip>
            );
          })}
        </div>
      </div>
    </div>
  );
}
const estilos = {
  contfiltros: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    cursor: "pointer",
    background: "#f7fcff",
    padding: 7,
    margin: 5,
    fontSize: 13,
  },
  confiltrospadre: {
    minWidth: 170,
    minHeight: 100,
    borderRadius: 5,
    marginTop: 5,
    background: "rgb(234 244 251)",
    display: "flex",
    flexDirection: "column",
  },
};
export default ModalListaFiltrosQuienes;
