import { useParams } from "react-router-dom";
import Submenu from "../../components/submenu";
import BorrarDb from "./borrarDb";
import RenderLicencia from "./RenderLicencia";
import RenderTipodeRed from "./RenderTipodeRed";
import ZonaHoraria from "./ZonaHoraria";

function ConfigCuenta() {
  const params = useParams();
  return (
    <>
      <Submenu
        tableros={[
          { nombre: "Licencia", id: "licencia", _id: "licencia" },
          { nombre: "Tipo de Red", id: "plan", _id: "plan" },
          { nombre: "Zona horaria", id: "zona", _id: "zona" },
          { nombre: "Borrar DB", id: "borrarDb", _id: "borrarDb" },
        ]}
      />
      <div style={{ padding: 10, marginTop: 15, overflow: "auto" }}>
        {params.id === "licencia" && <RenderLicencia />}
        {params.id === "plan" && <RenderTipodeRed />}
        {params.id === "zona" && <ZonaHoraria />}
        {params.id === "borrarDb" && <BorrarDb />}
      </div>
    </>
  );
}

export default ConfigCuenta;
