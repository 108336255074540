import React from "react";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PlusOneOutlined } from "@material-ui/icons";
import { Form, Input, Button, InputNumber, Space, Select, message } from "antd";
import { store } from "../../store";
import Boton from "../design/elementos/boton";
import { Api } from "../../api/configApi";

const { Option } = Select;

const rules = [{ required: true, message: "Campo obligatorio" }];

function FormCrearActualizarGrupoPulpitos({
  setDrawerNuevoGrupo,
  Get,
  grupos,
  duplicado,
}) {
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();

  React.useEffect(() => {
    form.setFieldsValue({ atributosRed: [], atributosPropios: [] });
    if (duplicado) {
      const nuevoGrupo = { ...duplicado };
      nuevoGrupo.nombre = "";
      form.setFieldsValue(nuevoGrupo);
    }
  }, [duplicado]);
  function AtributoEs(atri) {
    if (atri) {
      const totalAtributos = [
        ...store.losatributos.numbers,
        ...store.losatributos.texts,
        ...store.losatributos.dates,
      ];
      const atributoAtu = totalAtributos.find((e) => e.nombre === atri);
      return atributoAtu.tipo;
    } else {
      return false;
    }
  }
  function GuardarGrupo(values) {
    const nombreExiste = grupos.some(
      (e) => e.nombre.toLowerCase() === values.nombre.toLowerCase()
    );
    if (nombreExiste) return message.error("El nombre no puede ser repetido");

    const totalAtributos = [
      ...store.losatributos.numbers,
      ...store.losatributos.texts,
      ...store.losatributos.dates,
    ];
    values.atributosPropios.forEach((element) => {
      const atri = totalAtributos.find((e) => e.nombre === element.atributo);
      element.tipo = atri.tipo;
    });
    values.atributosRed.forEach((element) => {
      const atri = totalAtributos.find((e) => e.nombre === element.atributo);
      element.tipo = atri.tipo;
    });

    setLoading(true);

    Api("Cuentas", "InsertPulpitos", { _id: store.cuentaactiva }, values).then(
      (res) => {
        if (res.success) {
          Get();
          setLoading(false);
          setDrawerNuevoGrupo(false);
          message.success("Grupo Creado");
        } else {
          setLoading(false);
          message.error("Algo no salio bien");
        }
      }
    );
  }
  return (
    <Form form={form} onFinish={GuardarGrupo} layout="vertical">
      <Form.Item rules={rules} name="nombre" label="Nombre">
        <Input />
      </Form.Item>
      <div
        style={{
          background: "rgb(239 242 245)",
          padding: 12,
          marginTop: 10,
          borderRadius: 5,
          marginBottom: 15,
        }}
      >
        <span>Atributos Propios</span>
        <Form.Item
          style={{ marginTop: 10 }}
          rules={rules}
          name="cantidad"
          label="Cantidad de pulpitos principales"
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        <Form.List name="atributosPropios">
          {(fields, { add, remove }) => (
            <div>
              {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                <Space key={index}>
                  <FontAwesomeIcon
                    style={{
                      cursor: "pointer",
                      alignSelf: "center",
                      marginTop: 15,
                      marginLeft: 10,
                    }}
                    icon={faMinusCircle}
                    onClick={() => remove(name)}
                  />
                  <Form.Item
                    {...restField}
                    name={[name, "atributo"]}
                    fieldKey={[fieldKey, "atributo"]}
                    rules={rules}
                    style={{
                      flexDirection: "column",
                      width: "100%",
                      marginBottom: 0,
                      marginTop: 10,
                    }}
                  >
                    <Select
                      onChange={() => {
                        const x = { ...form.getFieldValue() };
                        x.atributosPropios[index].valor = "";
                        form.setFieldsValue(x);
                      }}
                      style={{ minWidth: 300 }}
                    >
                      {store.losatributos.numbers.map((e) => {
                        return (
                          <Option key={e.nombre} value={e.nombre}>
                            {e.nombre}
                          </Option>
                        );
                      })}
                      {store.losatributos.texts.map((e) => {
                        return (
                          <Option key={e.nombre} value={e.nombre}>
                            {e.nombre}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    noStyle
                    shouldUpdate={(a, b) =>
                      a.atributosPropios[index]?.atributo !==
                      b.atributosPropios[index]?.atributo
                    }
                  >
                    {({ getFieldsValue }) => {
                      return (
                        <>
                          {AtributoEs(
                            getFieldsValue().atributosPropios[index].atributo
                          ) === "number" && (
                            <Form.Item
                              {...restField}
                              name={[name, "valor"]}
                              fieldKey={[fieldKey, "valor"]}
                              rules={rules}
                              style={{
                                flexDirection: "column",
                                width: "100%",
                                marginBottom: 0,
                                marginTop: 10,
                              }}
                            >
                              <InputNumber
                                style={{ minWidth: 260 }}
                                placeholder="Valor"
                              />
                            </Form.Item>
                          )}
                          {AtributoEs(
                            getFieldsValue().atributosPropios[index].atributo
                          ) === "text" && (
                            <Form.Item
                              {...restField}
                              name={[name, "valor"]}
                              fieldKey={[fieldKey, "valor"]}
                              rules={rules}
                              style={{
                                flexDirection: "column",
                                width: "100%",
                                marginBottom: 0,
                                marginTop: 10,
                              }}
                            >
                              <Select style={{ minWidth: 260 }}>
                                {store.losatributos.texts
                                  .find(
                                    (e) =>
                                      e.nombre ===
                                      getFieldsValue().atributosPropios[index]
                                        .atributo
                                  )
                                  .opciones.map((e) => {
                                    return (
                                      <Option key={e} value={e}>
                                        {e}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                          )}
                        </>
                      );
                    }}
                  </Form.Item>
                </Space>
              ))}
              <Button
                style={{ marginTop: 10 }}
                type="dashed"
                onClick={() => add({ atributo: "" })}
                block
              >
                Agregar Atributos
              </Button>
            </div>
          )}
        </Form.List>
      </div>
      <div
        style={{
          background: "rgb(239 242 245)",
          padding: 12,
          marginTop: 10,
          borderRadius: 5,
          marginBottom: 15,
        }}
      >
        <span>Atributos De la red</span>
        <Form.Item
          style={{ marginTop: 10 }}
          rules={rules}
          name="cantidadRed"
          label="Cantidad de pulpitos en la red de los principales"
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        <Form.List name="atributosRed">
          {(fields, { add, remove }) => (
            <div>
              {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                <Space key={index}>
                  <FontAwesomeIcon
                    style={{
                      cursor: "pointer",
                      alignSelf: "center",
                      marginTop: 15,
                      marginLeft: 10,
                    }}
                    icon={faMinusCircle}
                    onClick={() => remove(name)}
                  />
                  <Form.Item
                    {...restField}
                    name={[name, "atributo"]}
                    fieldKey={[fieldKey, "atributo"]}
                    rules={rules}
                    style={{
                      flexDirection: "column",
                      width: "100%",
                      marginBottom: 0,
                      marginTop: 10,
                    }}
                  >
                    <Select
                      onChange={() => {
                        const x = { ...form.getFieldValue() };
                        x.atributosRed[index].valor = "";
                        form.setFieldsValue(x);
                      }}
                      style={{ minWidth: 300 }}
                    >
                      {store.losatributos.numbers.map((e) => {
                        return (
                          <Option key={e.nombre} value={e.nombre}>
                            {e.nombre}
                          </Option>
                        );
                      })}
                      {store.losatributos.texts.map((e) => {
                        return (
                          <Option key={e.nombre} value={e.nombre}>
                            {e.nombre}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    noStyle
                    shouldUpdate={(a, b) =>
                      a.atributosRed[index]?.atributo !==
                      b.atributosRed[index]?.atributo
                    }
                  >
                    {({ getFieldsValue }) => {
                      return (
                        <>
                          {AtributoEs(
                            getFieldsValue().atributosRed[index].atributo
                          ) === "number" && (
                            <Form.Item
                              {...restField}
                              name={[name, "valor"]}
                              fieldKey={[fieldKey, "valor"]}
                              rules={rules}
                              style={{
                                flexDirection: "column",
                                width: "100%",
                                marginBottom: 0,
                                marginTop: 10,
                              }}
                            >
                              <InputNumber
                                style={{ minWidth: 260 }}
                                placeholder="Valor"
                              />
                            </Form.Item>
                          )}
                          {AtributoEs(
                            getFieldsValue().atributosRed[index].atributo
                          ) === "text" && (
                            <Form.Item
                              {...restField}
                              name={[name, "valor"]}
                              fieldKey={[fieldKey, "valor"]}
                              rules={rules}
                              style={{
                                flexDirection: "column",
                                width: "100%",
                                marginBottom: 0,
                                marginTop: 10,
                              }}
                            >
                              <Select style={{ minWidth: 260 }}>
                                {store.losatributos.texts
                                  .find(
                                    (e) =>
                                      e.nombre ===
                                      getFieldsValue().atributosRed[index]
                                        .atributo
                                  )
                                  .opciones.map((e) => {
                                    return (
                                      <Option key={e} value={e}>
                                        {e}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                          )}
                        </>
                      );
                    }}
                  </Form.Item>
                </Space>
              ))}
              <Button
                style={{ marginTop: 10 }}
                type="dashed"
                onClick={() => add({ atributo: "" })}
                block
              >
                Agregar Atributos
              </Button>
            </div>
          )}
        </Form.List>
      </div>

      <Boton loading={loading} texto="Guardar" htmlType="submit" />
    </Form>
  );
}

export default FormCrearActualizarGrupoPulpitos;
