import { Form, Input, message } from "antd";
import React from "react";
import { Api } from "../../../../../api/configApi";
import Boton from "../../../../../components/design/elementos/boton";

function IntegracionCoinpayments(props) {
  const [form] = Form.useForm();
  function guardar(values) {
    Api(
      "Cuentas",
      "InsertIntegracion",
      { tipoIntegracion: "pasarelas", proveedor: "coinpayments" },
      values
    ).then((res) => {
      if (res.success) {
        message.success("Integración Coinpayments guardada");
      } else {
        message.error("Algo no salio bien.");
      }
    });
  }
  React.useEffect(() => {
    form.setFieldsValue(props.integraciones?.coinpayments);
  }, [props.integraciones]);
  return (
    <Form form={form} onFinish={guardar} layout="vertical">
      <Form.Item
        rules={[{ required: true, message: "campo obligatorio" }]}
        label="Public Key"
        name="key"
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: "campo obligatorio" }]}
        label="Private Key"
        name="secret"
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: "campo obligatorio" }]}
        label="IPN secret"
        name="ipnSecret"
      >
        <Input />
      </Form.Item>

      <Boton texto="Guardar" htmlType="submit" />
    </Form>
  );
}

export default IntegracionCoinpayments;
