import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table } from "antd";
import { observer } from "mobx-react";
import React from "react";
import { Api } from "../../../../api/configApi";
import {
  setStoreAtributos,
  setStoreGrupoFlujos,
  store,
} from "../../../../store";
import CrearGrupoFlujos from "./crearGrupoFlujos";
import GrupoFlujo from "./grupoFlujo";
import { useHistory } from "react-router";

function RenderGruposFlujos() {
  const [crearGrupo, setCrearGrupo] = React.useState(false);
  const [actualizarGrupos, setActualizarGrupos] = React.useState(true);
  const [cargandoData, setCargandoData] = React.useState(true);
  const history = useHistory();
  const columns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: "Descripción",
      dataIndex: "descripcion",
      key: "descripcion",
    },

    {
      title: "",
      dataIndex: "_id",
      key: "_id",
      render: (id) => (
        <FontAwesomeIcon
          onClick={() => {
            history.push(`/configuraciones/flujos/flujo/${id}/${id}`);
          }}
          style={{ cursor: "pointer" }}
          icon={faArrowRight}
        />
      ),
    },
  ];
  React.useEffect(() => {
    setCargandoData(true);
    Api(
      "cuentas",
      "Get",
      { _id: store.cuentaactiva },
      { gruposflujos: 1, atributos: 1 }
    )
      .then((res) => {
        // setStoreGrupoFlujos([]);
        // setStoreGrupoFlujos(res[0].gruposflujos);
        setStoreAtributos(res[0].atributos);
      })
      .catch((err) => {
        console.log(err);
      });
    Api("cuentas", "getGruposFlujos", {}, {}).then((e) => {
      console.log(e.grupos);
      setStoreGrupoFlujos(e.grupos);
      setCargandoData(false);
    });
  }, [actualizarGrupos]);
  return (
    <>
      {crearGrupo ? (
        <CrearGrupoFlujos
          setActualizarGrupos={setActualizarGrupos}
          actualizarGrupos={actualizarGrupos}
          setCrearGrupo={setCrearGrupo}
        />
      ) : (
        <div style={{ textAlign: "right", marginBottom: 10 }}>
          <span
            onClick={() => {
              setCrearGrupo(true);
            }}
            style={{ cursor: "pointer" }}
          >
            Crear Grupo
          </span>
        </div>
      )}
      <Table
        loading={cargandoData}
        dataSource={store.gruposFlujos}
        columns={columns}
      />
    </>
  );
}

export default observer(RenderGruposFlujos);
