import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, DatePicker, Form, InputNumber, Select, Space } from "antd";
import { store } from "../../../../store";

const { Option } = Select;
const { RangePicker } = DatePicker;

function AgregarCondicionalesAtributosWidgetTabla({ atributoActual, listar }) {
  function atributo(listar) {
    // console.log("El listar");
    // console.log(listar);
    const atri = store.losatributos;
    const atrib = [...atri.numbers, ...atri.texts];
    const atribut = atrib.find((e) => e._id === listar);
    if (atribut) {
      return atribut;
    } else {
      return { tipo: null, nombre: null, opciones: {} };
    }
  }
  return (
    <Form.List name="condicionales">
      {(fields, { add, remove }) => (
        <div
          style={{
            background: "rgb(239 242 245)",
            padding: 12,
            marginTop: 10,
            borderRadius: 5,
            marginBottom: 15,
          }}
        >
          {fields.map(({ key, name, fieldKey, ...restField }, index) => {
            return (
              <Space>
                <FontAwesomeIcon
                  style={{
                    cursor: "pointer",
                    alignSelf: "center",
                    marginTop: 15,
                    marginLeft: 10,
                  }}
                  icon={faMinusCircle}
                  onClick={() => remove(name)}
                />
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.listar !== curValues.listar
                  }
                >
                  {({ getFieldsValue }) => {
                    const x =
                      getFieldsValue().listar === "todosAfiliados" ||
                      getFieldsValue().listar === "afiliadosPropios";

                    return (
                      x && (
                        <Form.Item
                          hasFeedback
                          {...restField}
                          colon={false}
                          name={[name, "atributo"]}
                          fieldKey={[fieldKey, "atributo"]}
                          rules={[
                            {
                              required: true,
                              message: "Campo obligatorio",
                            },
                          ]}
                          style={{
                            flexDirection: "column",
                            width: "100%",
                            marginBottom: 0,
                            marginTop: 10,
                          }}
                        >
                          <Select style={{ minWidth: 150 }}>
                            {store.losatributos.numbers.map((e, elindex) => {
                              return (
                                <Option key={e._id} value={e._id}>
                                  {e.nombre}
                                </Option>
                              );
                            })}
                            {store.losatributos.texts.map((e, elindex) => {
                              return (
                                <Option key={e._id} value={e._id}>
                                  {e.nombre}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      )
                    );
                  }}
                </Form.Item>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldsValue }) => {
                    return (
                      <Form.Item
                        hasFeedback
                        {...restField}
                        colon={false}
                        name={[name, "tipo"]}
                        fieldKey={[fieldKey, "tipo"]}
                        rules={[
                          {
                            required: true,
                            message: "Campo obligatorio",
                          },
                        ]}
                        style={{
                          flexDirection: "column",
                          width: "100%",
                          marginBottom: 0,
                          marginTop: 10,
                        }}
                      >
                        <Select style={{ width: 270 }}>
                          <Option value="desdeFecha">Desde la Fecha</Option>
                          <Option value="fechaExacta">Fecha exacta</Option>
                          <Option value="rangoFecha">Rango de fechas</Option>
                          <Option value="desdeAtributoFecha">
                            Desde el Atributo Fecha
                          </Option>
                          <Option value="AtributoFechaExacta">
                            Atributo Fecha
                          </Option>
                          {atributo(listar).tipo === "number" && (
                            <>
                              <Option value="atributoMayorA">Mayores a</Option>
                              <Option value="atributoMenorA">Menores a</Option>
                            </>
                          )}

                          <Option value="atributoIgualA">Iguales a</Option>
                        </Select>
                      </Form.Item>
                    );
                  }}
                </Form.Item>

                <Form.Item noStyle shouldUpdate>
                  {({ getFieldsValue }) => {
                    return (
                      <>
                        {(getFieldsValue().condicionales[index].tipo ===
                          "desdeFecha" ||
                          getFieldsValue().condicionales[index].tipo ===
                            "fechaExacta") && (
                          <Form.Item
                            hasFeedback
                            {...restField}
                            colon={false}
                            name={[name, "fecha"]}
                            fieldKey={[fieldKey, "fecha"]}
                            rules={[
                              {
                                required: true,
                                message: "Campo obligatorio",
                              },
                            ]}
                            style={{
                              flexDirection: "column",
                              width: "100%",
                              marginBottom: 0,
                              marginTop: 10,
                            }}
                          >
                            <DatePicker />
                          </Form.Item>
                        )}

                        {atributo(listar).tipo === "text" &&
                          getFieldsValue().condicionales[index].tipo ===
                            "atributoIgualA" && (
                            <Form.Item
                              hasFeedback
                              {...restField}
                              colon={false}
                              name={[name, "opcion"]}
                              fieldKey={[fieldKey, "opcion"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Campo obligatorio",
                                },
                              ]}
                              style={{
                                flexDirection: "column",
                                width: "100%",
                                marginBottom: 0,
                                marginTop: 10,
                              }}
                            >
                              <Select style={{ minWidth: 150 }}>
                                {store.losatributos.texts.find(
                                  (e) => e._id === atributo(listar)._id
                                ) &&
                                  store.losatributos.texts
                                    .find((e) => e._id === atributo(listar)._id)
                                    .opciones.map((ee) => {
                                      return <Option value={ee}>{ee}</Option>;
                                    })}
                              </Select>
                            </Form.Item>
                          )}

                        {getFieldsValue().condicionales[index].tipo ===
                          "rangoFecha" && (
                          <Form.Item
                            hasFeedback
                            {...restField}
                            colon={false}
                            name={[name, "rangoFecha"]}
                            fieldKey={[fieldKey, "rangoFecha"]}
                            rules={[
                              {
                                required: true,
                                message: "Campo obligatorio",
                              },
                            ]}
                            style={{
                              flexDirection: "column",
                              width: "100%",
                              marginBottom: 0,
                              marginTop: 10,
                            }}
                          >
                            <RangePicker />
                          </Form.Item>
                        )}
                        {(getFieldsValue().condicionales[index].tipo ===
                          "desdeAtributoFecha" ||
                          getFieldsValue().condicionales[index].tipo ===
                            "AtributoFechaExacta") && (
                          <Form.Item
                            hasFeedback
                            {...restField}
                            colon={false}
                            name={[name, "atributoFecha"]}
                            fieldKey={[fieldKey, "atributoFecha"]}
                            rules={[
                              {
                                required: true,
                                message: "Campo obligatorio",
                              },
                            ]}
                            style={{
                              flexDirection: "column",
                              width: "100%",
                              marginBottom: 0,
                              marginTop: 10,
                            }}
                          >
                            <Select style={{ minWidth: 150 }}>
                              {store.losatributos.dates.map((re) => {
                                return (
                                  <Option value={re._id}>{re.nombre}</Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        )}
                        {(getFieldsValue().condicionales[index].tipo ===
                          "atributoMayorA" ||
                          getFieldsValue().condicionales[index].tipo ===
                            "atributoMenorA" ||
                          (getFieldsValue().condicionales[index].tipo ===
                            "atributoIgualA" &&
                            atributo(listar).tipo === "number")) && (
                          <Form.Item
                            hasFeedback
                            {...restField}
                            colon={false}
                            name={[name, "valorAtributo"]}
                            fieldKey={[fieldKey, "valorAtributo"]}
                            rules={[
                              {
                                required: true,
                                message: "Campo obligatorio",
                              },
                            ]}
                            style={{
                              flexDirection: "column",
                              width: "100%",
                              marginBottom: 0,
                              marginTop: 10,
                            }}
                          >
                            <InputNumber />
                          </Form.Item>
                        )}
                      </>
                    );
                  }}
                </Form.Item>
              </Space>
            );
          })}
          <Form.Item>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                marginTop: 20,
              }}
            >
              <Button
                style={{ marginTop: 20 }}
                type="text"
                onClick={() => add({ tipo: "atributoIgualA" })}
              >
                Agregar condicional
              </Button>
            </div>
          </Form.Item>
        </div>
      )}
    </Form.List>
  );
}

export default AgregarCondicionalesAtributosWidgetTabla;
