import React from "react";
import { Form, Select, message } from "antd";

import { ObjectId } from "bson";

import moment from "moment";

import { ConsoleView } from "react-device-detect";
import { setStoreWidgets, store } from "../../../../store";
import { Api } from "../../../../api/configApi";
import nombresFormList from "./grupoCampos/nombresFormList";
import CamposPersonalizados from "./CamposPersonalizados/CamposPersonalizados";

function CrearActualizarWidget(props) {
  const [loading, setLoading] = React.useState(false);
  const [insertOrUpdate, setInsertOrUpdate] = React.useState("InsertWidgets");
  const [form] = Form.useForm();
  const [formularioCargado, setFormularioCargado] = React.useState({});
  const [guardando, setGuardando] = React.useState(false);
  const [funcionActual, setFuncionActual] = React.useState("");
  const [tituloFuncionActual, setTituloFuncionActual] = React.useState("");
  const [descripcionFuncionActual, setDescripcionFuncionActual] = React.useState("");
  const refNombreFormList = React.useRef();
  const refNombreFormListMoment = React.useRef();
  const refCampossinFormatear = React.useRef();
  const guardarWidget = (values) => {
   console.log(values, "interesante...")
    //   tipo de widget
    setGuardando(true);
    values.tipo = "indicadores";
    setLoading(true);
    values.limiteCantidad = +values.limiteCantidad;
    values.limiteWidth = +values.limiteWidth;


    Object.keys(values).forEach((el) => {
      if (nombresFormList.includes(el)) {
        refNombreFormList.current = el;
      }
    });

    let arrayformateado = [];

    if (refNombreFormList.current) {
      refCampossinFormatear.current = values[refNombreFormList.current];
      values[refNombreFormList.current].forEach((campo) => {
        let keyone = {};
        let keyprinc = {};
        if (campo.idCampo) {
          keyone[campo.operacionRelacional] = campo.valor;
          keyprinc[campo.idCampo?.slice(0, campo.idCampo.indexOf("-"))] =
            keyone;
        } else {
          keyone[campo.operacionRelacional] = campo.valor;
          keyprinc[campo.atributo?.slice(0, campo.atributo.indexOf("-"))] =
            keyone;
        }
        arrayformateado.push(keyprinc);
      });
    } else {
      let keyone = {};
      let keyprinc = {};
      if (values.idCampo) {
        keyone[values.operacionRelacional] = values.valor;
        keyprinc[values.idCampo?.slice(0, values.idCampo.indexOf("-"))] =
          keyone;
      } else {
        keyone[values.operacionRelacional] = values.valor;
        keyprinc[values.atributo?.slice(0, values.atributo.indexOf("-"))] =
          keyone;
      }
      arrayformateado.push(keyprinc);
    }

    values.consultas = {
      idListado: values.idListado || null,
      afiliadoActual: values.afiliadoActual || null,
      tipoOperacion: values.tipoOperacion || null,
      atributo: values.atributo?.slice(0, values.atributo.indexOf("-")) || null,
      rangoFechas: values.rangoFechas || null,
      tipoMovimiento: values.tipoMovimiento || null,
      condiciones: arrayformateado || null,
    };

    Api("Cuentas", insertOrUpdate, { _id: store.cuentaactiva }, values)
      .then((res) => {
        props.setVisible(false);
        setLoading(false);
        return Api(
          "Cuentas",
          "Get",
          { _id: store.cuentaactiva },
          { widgets: 1 }
        );
      })
      .then((res) => {
        setStoreWidgets(res[0].widgets);
        setGuardando(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setGuardando(false);
      });
  };

  React.useEffect(() => {
    form.resetFields();
    form.setFieldsValue({ _id: new ObjectId(), dinamico: false });
  }, [props.visible]);
  React.useEffect(() => {
    if (props.data) {
      console.log(props.data, "datoooooooos")
      Object.keys(props.data).forEach((el) => {
        if (nombresFormList.includes(el)) {
          refNombreFormListMoment.current = el;
        }
      });

      if (refNombreFormListMoment.current) {
        props.data[refNombreFormListMoment.current]?.forEach((item, index) => {
          if (props.data[refNombreFormListMoment.current][index].rangoFechas) {
            props.data[refNombreFormListMoment.current][index].rangoFechas =
              moment(
                props.data[refNombreFormListMoment.current][index].rangoFechas
              );
          }
          if (
            moment(
              props.data[refNombreFormListMoment.current][index].valor
            ).isValid() &&
            isNaN(props.data[refNombreFormListMoment.current][index].valor)
          ) {
            props.data[refNombreFormListMoment.current][index].valor = moment(
              props.data[refNombreFormListMoment.current][index].valor
            );
          }
        });
      }

      props.data.rangoFechas?.forEach((el, index) => {
        props.data.rangoFechas[index] = moment(props.data.rangoFechas[index]);
      });

      if (moment(props.data.valor).isValid() && isNaN(props.data.valor)) {
        props.data.valor = moment(props.data.valor);
      }

      form.setFieldsValue(props.data);
      setInsertOrUpdate("UpdateWidgets");
      setFormularioCargado(props.data);
      if (props.data.nombreFuncion) {
        setFuncionActual(props.data.nombreFuncion);
        setTituloFuncionActual(props.data.tituloFuncion)
        setDescripcionFuncionActual(props.data.descripcionFuncion)
      }
    } else {
      form.setFieldsValue({ _id: new ObjectId(), dinamico: false });
    }
  }, [props.data]);

  return (
    <div>
      <Form
        form={form}
        onFinish={(values) => {
          guardarWidget(values);
        }}
        onFinishFailed={(values) => {
          console.log(values, "aja vemoa quien gfalta..")
          message.error("Faltan campos obligatorios por llenar");
        }}
        layout="vertical"
      >
        {/* Campos personalizados de este WIDGET */}
        {!guardando && (
          <CamposPersonalizados
            funcionActual={funcionActual}
            tituloFuncionActual={tituloFuncionActual}
            datosApi={formularioCargado}
            formulario={form}
            cargando={loading}
          />
        )}
        {/* Fin campos personalizados de este Widget... */}

        {/* <Form.Item noStyle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: 15,
            }}
          >
            <Button loading={loading} htmlType="submit" onClick={()=> {
            }}>
              Guardar
            </Button>
            <Button
              loading={loading}
              onClick={() => {
                props.setVisible(false);
              }}
              htmlType="button"
            >
              Cancelar
            </Button>
          </div>
        </Form.Item> */}
      </Form>
    </div>
  );
}

export default CrearActualizarWidget;
