import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Switch,
} from "antd";
import { store } from "../../../../store";

const { Option } = Select;

function DrawerListados({
  eliminar,
  guardarConfiguracionItem,
  listado,
  props,
  form,
  editando,
  cancelar,
}) {
  function desactivar(campo) {
    return form.getFieldValue(campo)?.variable !== "sinVariable";
  }
  return (
    <>
      {!eliminar ? (
        <Form form={form} onFinish={guardarConfiguracionItem} layout="vertical">
          {store.listados
            .find((e) => e._id === listado?.idListado)
            ?.campos.map((e) => (
              <Space key={e.NombreCampo} direction="horizontal">
                <Form.Item
                  label="-"
                  name={[e.NombreCampo, "variable"]}
                  rules={[{ required: true, message: "Campo obligatorio" }]}
                  initialValue="sinVariable"
                >
                  <Select style={{ width: 200 }}>
                    <Select.Option value="sinVariable">
                      Campo abierto
                    </Select.Option>
                    <Select.OptGroup label="Variables">
                      {props.objetoDisparador.variablesDisparador?.map((e) => (
                        <Select.Option
                          value={
                            "variable_" +
                            e.tipoVariable +
                            "_>" +
                            e.elcampo +
                            "_>" +
                            e.nombreVariable
                          }
                        >
                          {e.nombreVariable}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                  </Select>
                </Form.Item>
                <Form.Item noStyle shouldUpdate>
                  {() => (
                    <>
                      <Form.Item
                        label={e.NombreCampo}
                        name={[e.NombreCampo, "campo"]}
                        rules={[
                          {
                            required: desactivar(e.NombreCampo)
                              ? false
                              : e.obligatorio,
                            message: "Campo obligatorio",
                          },
                        ]}
                        key={e.NombreCampo}
                        valuePropName={
                          e.tipoCampo === "switch" ? "checked" : "value"
                        }
                      >
                        {e.tipoCampo === "fecha" ? (
                          <DatePicker
                            disabled={desactivar(e.NombreCampo)}
                            showTime
                            style={{ width: "100%" }}
                          />
                        ) : e.tipoCampo === "numero" ? (
                          <InputNumber
                            disabled={desactivar(e.NombreCampo)}
                            style={{ width: "100%" }}
                          />
                        ) : e.tipoCampo === "multiple" ? (
                          <Select
                            disabled={desactivar(e.NombreCampo)}
                            style={{ width: 200 }}
                          >
                            {e.opciones.map((e) => (
                              <Option value={e.opcion}>{e.opcion}</Option>
                            ))}
                          </Select>
                        ) : e.tipoCampo === "switch" ? (
                          <Switch disabled={desactivar(e.NombreCampo)} />
                        ) : e.tipoCampo === "texto" ? (
                          <Input disabled={desactivar(e.NombreCampo)} />
                        ) : e.tipoCampo === "imagen" ? (
                          <Input
                            disabled={desactivar(e.NombreCampo)}
                            type="url"
                          />
                        ) : (
                          <Input
                            disabled={desactivar(e.NombreCampo)}
                            type="url"
                          />
                        )}
                      </Form.Item>
                    </>
                  )}
                </Form.Item>
              </Space>
            ))}

          {!editando && <Button onClick={cancelar}>Cancelar</Button>}
          <Button style={{ marginTop: 10, width: 200 }} htmlType="submit">
            Guardar
          </Button>
        </Form>
      ) : (
        <Form onFinish={guardarConfiguracionItem} layout="vertical" form={form}>
          <Form.Item name="itemEliminar" label="¿Cual eliminamos?">
            <Select>
              {props.disparador === "listados" &&
                props.objetoDisparador.listado === listado.idListado && (
                  <Option value="itemActual">Item actual</Option>
                )}

              <Option value="ultimoItem">Último item agregado</Option>
              <Option value="primerItem">Primer item agregado</Option>
            </Select>
          </Form.Item>
          <Space>
            {!editando && <Button onClick={cancelar}>Cancelar</Button>}
            <Button htmlType="submit">Guardar</Button>
          </Space>
        </Form>
      )}
    </>
  );
}

export default DrawerListados;
