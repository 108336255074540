import { Button, Modal, Input, InputNumber } from "antd";
import React from "react";
import { Api } from "../../../../../api/configApi";
import { store } from "../../../../../store";

const DesignWidgetTranferNumero = (props) => {
  const [widget, setWidget] = React.useState({});
  const [contenido, setContenido] = React.useState(0);
  const [valorCheckTrans, setValorCheckTrans] = React.useState();
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const refDatosEnviar = React.useRef();
  const refCantidadMostrar = React.useRef();
  const [cantidadMostrar, setCantidadMostrar] = React.useState(0);
  const refCriptoPortada = React.useRef([
    "Bitcoin BTC",
    "Ethereum, Ether ETH",
    "Dogecoin DOGE",
    "Cardano ADA",
    "Litecoin LTC",
    "Binance Coin BNB",
    "Polkadot DOT",
    "Ripple XRP",
    "BitBay",
    "Stellar XLM",
    "Bitcoin cash BCH",
    "Chainlink LINK",
    "IOTA MIOTA",
    "Monero",
    "NEO",
    "Tether USDT",
    "Tezos XTZ",
    "THETA",
    "Uniswap UNI",
    "USD Coin USDC",
  ]);
  const refRuta = React.useRef(process.env.REACT_APP_RUTA);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (
      refDatosEnviar.current.cantidad &&
      refDatosEnviar.current.cantidad != 0 &&
      refDatosEnviar.current.direccion
    ) {
      setIsModalVisible(false);
      enviarDatosTrans(refDatosEnviar.current);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCantidadMostrar(0);
  };

  const enviarDatosTrans = (data) => {
    Api(
      "atributoNumero",
      "mm2_ValidateTransferNumb",
      {},
      { data },
      refRuta.current
    )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  function renderFormato() {
    switch (props.formato) {
      case "Moneda":
        return (
          <>
            {refCriptoPortada.current.includes(props.moneda) ? (
              <strong>
                {props.prefijo && " " + props.prefijo + " "}
                {!isNaN(contenido)
                  ? new Intl.NumberFormat("de-DE").format(contenido)
                  : new Intl.NumberFormat("de-DE").format(0)}
                {" " + props.moneda}
                {props.sufijo && " " + props.sufijo + " "}
              </strong>
            ) : (
              <strong>
                {props.prefijo && " " + props.prefijo + " "}
                {!isNaN(contenido)
                  ? new Intl.NumberFormat("de-DE", {
                      style: "currency",
                      currency: props.moneda,
                    }).format(contenido)
                  : new Intl.NumberFormat("de-DE", {
                      style: "currency",
                      currency: props.moneda,
                      maximumFractionDigits: 2,
                    }).format(5)}
                {props.sufijo && " " + props.sufijo + " "}
              </strong>
            )}
          </>
        );
      case "Miles":
        return (
          <>
            {
              <strong>
                {props.prefijo && " " + props.prefijo + " "}{" "}
                {new Intl.NumberFormat("de-DE").format(contenido) || "0"}{" "}
                {props.sufijo && " " + props.sufijo + " "}
              </strong>
            }
          </>
        );
      case "Ninguno":
        return (
          <>
            {
              <strong>
                {props.prefijo && " " + props.prefijo + " "} {contenido || "0"}{" "}
                {props.sufijo && " " + props.sufijo + " "}
              </strong>
            }
          </>
        );

      default:
        return (
          <>
            <p>Caso por defecto</p>
          </>
        );
    }
  }

  React.useEffect(() => {
    const widgetActual = store.widgets.find((wid) => {
      return wid._id === props._id;
    });

    setWidget(widgetActual);

    let obj = {};

    if (widgetActual.montoFijoTransferir) {
      refCantidadMostrar.current = widgetActual.montoFijoTransferir;
      setCantidadMostrar(refCantidadMostrar.current);
      obj = {
        cantidad: widgetActual.montoFijoTransferir,
        cantidadTotal:
          widgetActual.montoFijoTransferir +
          widgetActual.montoFijoTransferir *
            (widgetActual.porcentajeComision / 100) +
          widgetActual.cantidadComision,
        type: widgetActual.criterioBusqueda,
        direccion: undefined,
        porcentajeComision:
          widgetActual.montoFijoTransferir *
          (widgetActual.porcentajeComision / 100),
        valorComision: widgetActual.cantidadComision || 0,
        idWidget: widgetActual._id,
      };
    } else {
      obj = {
        cantidad: undefined,
        cantidadTotal: undefined,
        type: widgetActual.criterioBusqueda,
        direccion: undefined,
        porcentajeComision: undefined,
        valorComision: widgetActual.cantidadComision || 0,
        idWidget: widgetActual._id,
      };
    }

    refDatosEnviar.current = obj;

    setValorCheckTrans(props.usarTransferir);
  }, [props]);

  React.useEffect(() => {
    if (widget._id) {
      Api("atributosNumero", "GetTotal", { widget: widget._id }, {}).then(
        (res) => {
          res.success ? setContenido(res.total) : setContenido(0);
        }
      );
    }
  }, [widget]);

  return widget ? (
    <div className="widgetShadow">
      <div  style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ padding: 20 }}>
          <span>{widget.titulo || ""}</span>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              fontSize: 30,
              fontWeight: 400,
            }}
          >
            {renderFormato()}
          </div>

          {valorCheckTrans == "true" && (
            <>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button type="primary" onClick={showModal}>
                  {widget.nombreBoton || "Asignar nombre"}
                </Button>
              </div>
              <Modal
                title="Transferir"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                destroyOnClose
              >
                <div>
                  <div>
                    {widget.formato == "Moneda" ? (
                      refCriptoPortada.current.includes(widget.moneda) ? (
                        <p>
                          Valor total a transferir: {cantidadMostrar}
                          {widget.prefijo && " " + widget.prefijo + " "}
                          <span style={{ fontSize: "30px" }}>
                            {new Intl.NumberFormat("de-DE").format(
                              cantidadMostrar +
                                cantidadMostrar *
                                  (widget.porcentajeComision / 100) +
                                widget.cantidadComision || cantidadMostrar
                            )}
                          </span>
                          {" " + widget.moneda}{" "}
                          {widget.sufijo && " " + widget.sufijo + " "}
                        </p>
                      ) : (
                        <p>
                          Valor total a transferir: {cantidadMostrar}
                          <span style={{ fontSize: "30px" }}>
                            {new Intl.NumberFormat("de-DE", {
                              style: "currency",
                              currency: props.moneda,
                              maximumFractionDigits: 2,
                            }).format(
                              cantidadMostrar +
                                cantidadMostrar *
                                  (widget.porcentajeComision / 100) +
                                widget.cantidadComision || cantidadMostrar
                            )}
                          </span>
                        </p>
                      )
                    ) : (
                      <p>
                        Valor total a transferir:{" "}
                        <span style={{ fontSize: "30px" }}>
                          {cantidadMostrar +
                            cantidadMostrar *
                              (widget.porcentajeComision / 100) +
                            widget.cantidadComision || cantidadMostrar}
                        </span>
                      </p>
                    )}
                  </div>

                  {widget.montoFijoTransferir ? (
                    <label style={{ display: "block" }}>
                      Al afiliado le va a llegar:
                    </label>
                  ) : (
                    <label style={{ display: "block" }}>
                      ¿Cuanto quieres que le llegue al otro afiliado?
                    </label>
                  )}
                  {widget.montoFijoTransferir ? (
                    <InputNumber
                      value={widget.montoFijoTransferir}
                      prefix={widget.moneda}
                      style={{ marginBottom: "20px", width: "100%" }}
                      min={widget.montoMinimo || 0}
                      max={widget.montoMaximo}
                      defaultValue={0}
                    />
                  ) : (
                    <InputNumber
                      prefix={widget.moneda}
                      style={{ marginBottom: "20px", width: "100%" }}
                      min={widget.montoMinimo || 0}
                      max={widget.montoMaximo}
                      defaultValue={0}
                      onChange={(e) => {
                        refDatosEnviar.current.cantidad = e;
                        setCantidadMostrar(e);
                        refDatosEnviar.current.porcentajeComision =
                          e * (widget.porcentajeComision / 100) || 0;
                        refDatosEnviar.current.cantidadTotal =
                          e +
                            e * (widget.porcentajeComision / 100) +
                            widget.cantidadComision || e;
                      }}
                    />
                  )}
                </div>
                <label>{widget.criterioBusqueda}</label>
                <Input
                  placeholder={`Ingresa el ${widget.criterioBusqueda.toLowerCase()} del usuario`}
                  onChange={(e) => {
                    refDatosEnviar.current.direccion = e.target.value;
                  }}
                ></Input>
              </Modal>
            </>
          )}
        </div>
      </div>
    </div>
  ) : null;
};

export default DesignWidgetTranferNumero;
