import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { ObjectId } from "bson";
import { Api } from "../../../../api/configApi";
import { store, setStoreWidgets } from "../../../../store";
import CamposPersonalizados from "./CamposPersonalizados/CamposPersonalizados";
import { InputT } from "../../../../plugins/Formularios/FormularioTecclas";

const CrearActualizarWidgetEncuestas = (props) => {
  const methods = useForm();
  const [loading, setLoading] = React.useState(false);
  const [insertOrUpdate, setInsertOrUpdate] = React.useState("InsertWidgets");

  const onSubmit = (values) => {
    //   tipo de widget
    values.tipo = "encuestas";
    methods.setValue("tipo", "encuestas");
    setLoading(true);
    values.limiteCantidad = +values.limiteCantidad;
    values.limiteWidth = +values.limiteWidth;

    Api("Cuentas", insertOrUpdate, { _id: store.cuentaactiva }, values)
      .then((res) => {
        props.setVisible(false);
        setLoading(false);
        return Api(
          "Cuentas",
          "Get",
          { _id: store.cuentaactiva },
          { widgets: 1 }
        );
      })
      .then((res) => {
        setStoreWidgets(res[0].widgets);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    methods.reset();
    const obj1 = new ObjectId();
    methods.setValue("_id", obj1.toString());
    methods.setValue("dinamico", false);
  }, [props.visible]);

  React.useEffect(() => {
    if (props.data) {
      methods.reset(props.data);
      setInsertOrUpdate("UpdateWidgets");
    } else {
      const obj2 = new ObjectId();
      methods.setValue("_id", obj2.toString());
      methods.setValue("dinamico", false);
    }
  }, [props.data]);

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div
            style={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            <label style={{ marginBottom: "10px" }}>Nombre</label>
            <InputT
              control={methods.control}
              name="nombre"
              placeholder="Escribe el nombre"
              rules={{ required: true }}
            ></InputT>
            {/* <input
              style={{
                width: "100%",
                border: "thin solid rgb(118 118 118)",
                borderRadius: "3px",
              }}
              type="text"
              {...methods.register("nombre", { required: true })}
            /> */}
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            <label style={{ marginBottom: "10px" }}>Título</label>
            <InputT
              control={methods.control}
              name="titulo"
              placeholder="Escribe el titulo"
              rules={{ required: true }}
            ></InputT>
            {/* <input
              style={{
                border: "thin solid rgb(118 118 118)",
                borderRadius: "3px",
                width: "100%",
              }}
              type="text"
              {...methods.register("titulo", { required: true })}
            /> */}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            <label style={{ marginBottom: "10px" }}>Descripción</label>
            <InputT
              control={methods.control}
              name="descripcion"
              placeholder="Escribe la descripcion"
              rules={{ required: true }}
            ></InputT>
            {/* <input
              style={{
                width: "100%",
                border: "thin solid rgb(118 118 118)",
                borderRadius: "3px",
              }}
              type="text"
              {...methods.register("descripcion", { required: true })}
            /> */}
          </div>
          <div>
            <InputT control={methods.control} name="_id" hidden={true}></InputT>
          </div>
          <div hidden>
            <InputT
              control={methods.control}
              name="dinamico"
              hidden={true}
            ></InputT>
          </div>
          <CamposPersonalizados setVisible={props.setVisible} />
        </form>
      </FormProvider>
    </div>
  );
};

export default CrearActualizarWidgetEncuestas;
