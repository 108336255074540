import { Button, Form, Input, message } from "antd";
import React from "react";
import { Api } from "../../../../../api/configApi";
import { store } from "../../../../../store";

function IntegracionUploadCare(props) {
  const [guardando, setGuardando] = React.useState(false);
  const [form] = Form.useForm();
  function guardar(values) {
    if (values.secretKey.slice(8, 9) === "*") {
      values.secretKey = store.cuenta.integraciones.UploadCare.secretKey;
    }
    setGuardando(true);
    Api(
      "Cuentas",
      "InsertIntegracion",
      { tipoIntegracion: "uploadFile", proveedor: "UploadCare" },
      values
    ).then((res) => {
      setGuardando(false);
      if (res.success) {
        message.success("Integración Upload Care guardada");
      } else {
        message.error("Algo no salio bien.");
      }
    });
  }
  React.useEffect(() => {
    form.setFieldsValue(props.integraciones?.UploadCare);
  }, [props.integraciones]);
  return (
    <Form form={form} onFinish={guardar} layout="vertical">
      <Form.Item
        rules={[{ required: true, message: "campo obligatorio" }]}
        label="Public Key"
        name="publicKey"
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: "campo obligatorio" }]}
        label="Secret Key"
        name="secretKey"
      >
        <Input />
      </Form.Item>

      <Button loading={guardando} htmlType="submit">
        Guardar
      </Button>
    </Form>
  );
}

export default IntegracionUploadCare;
