import React from "react";
import Modulo from "./Modulo";
import {
  InfoCircleOutlined,
  DownloadOutlined,
  FileOutlined,
} from "@ant-design/icons";

const VistaCurso = (props) => {
  const [tituloModulo, setTituloModulo] = React.useState(
    props.modulos[0]?.padre?.tituloLeccion ||
      props.modulos[0]?.hijos[0]?.tituloLeccion
  );
  const [descripcionModulo, setDescripcionModulo] = React.useState(
    props.modulos[0]?.padre?.descripcionLeccion ||
      props.modulos[0]?.hijos[0]?.descripcionLeccion
  );
  const [descargables, setDescargables] = React.useState(
    props.modulos[0]?.padre?.materialDescargable ||
      props.modulos[0]?.hijos[0]?.materialDescargable
  );
  const [link, setLink] = React.useState(
    props.modulos[0]?.padre?.urlVideo || props.modulos[0]?.hijos[0]?.urlVideo
  );
  const [codigoLink, setCodigoLink] = React.useState("");
  const refCodigoLink = React.useRef();

  const [banderaEstilo, setBanderaEstilo] = React.useState(true);

  const [estiloInfo, setEstiloInfo] = React.useState("2px solid #cfcfcf");
  const [estiloMatDes, setEstiloMatDes] = React.useState(null);

  React.useEffect(() => {
    if (link?.includes("youtube")) {
      let preCodigo = link.split("=")[1];
      let codigo = preCodigo.slice(0, 11);
      refCodigoLink.current = codigo;
      setCodigoLink(refCodigoLink.current);
    } else if (link?.includes("vimeo")) {
      let indice = link.lastIndexOf("/");
      let codigo = link.slice(indice + 1, link.length);
      refCodigoLink.current = codigo;
      setCodigoLink(refCodigoLink.current);
    } else if (link?.includes("youtu.")) {
      let preCodigo = link.split("be")[1];
      let codigo = preCodigo.slice(1, 12);
      refCodigoLink.current = codigo;
      setCodigoLink(refCodigoLink.current);
    }
  }, [link]);

  return (
    <>
      <div
        style={{
          backgroundColor: "green",
          width: "70%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ backgroundColor: "#000", height: "70%" }}>
          {(link?.includes("youtube") || link?.includes("youtu.")) &&
          codigoLink ? (
            <>
              <iframe
                src={`https://www.youtube.com/embed/${codigoLink}`}
                width="100%"
                height="100%"
                frameborder="0"
                title="Youtube video player"
                allowFullScreen={true}
              ></iframe>
            </>
          ) : link?.includes("vimeo") && codigoLink ? (
            <>
              <iframe
                src={`https://player.vimeo.com/video/${codigoLink}?h=ce693d66c6`}
                width="100%"
                height="100%"
                frameborder="0"
                title="Vimeo video player"
                allow="autoplay; picture-in-picture"
                allowFullScreen={true}
              ></iframe>
            </>
          ) : (
            link?.includes("mp4") && (
              <>
                <iframe
                  width="100%"
                  height="100%"
                  src={link}
                  title="MP4 video player"
                  frameborder="0"
                  allow="autoplay; clipboard-write; picture-in-picture"
                  allowFullScreen={true}
                ></iframe>
              </>
            )
          )}
        </div>
        <div style={{ backgroundColor: "#fff", height: "30%" }}>
          <div
            style={{
              height: "100%",
              padding: "10px",
              textAlign: "left",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "380px",
                justifyContent: "space-between",
              }}
            >
              <p
                style={{
                  margin: 0,
                  borderBottom: estiloInfo,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setEstiloMatDes(null);
                  setEstiloInfo("2px solid #cfcfcf");
                  setBanderaEstilo(true);
                }}
              >
                <InfoCircleOutlined style={{ marginRight: "5px" }} />{" "}
                Información de la lección
              </p>
              <p
                style={{
                  margin: 0,
                  borderBottom: estiloMatDes,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setEstiloMatDes("2px solid #cfcfcf");
                  setEstiloInfo(null);
                  setBanderaEstilo(false);
                }}
              >
                <DownloadOutlined style={{ marginRight: "5px" }} /> Material
                descargable
              </p>
            </div>

            {banderaEstilo ? (
              <>
                <div
                  style={{
                    overflow: "hidden",
                    overflowY: "scroll",
                    height: "85%",
                    padding: "20px 20px 0 20px",
                  }}
                >
                  <h2
                    style={{ margin: "0", fontWeight: 600, fontSize: "18px" }}
                  >
                    {tituloModulo}
                  </h2>
                  <div
                    style={{
                      backgroundColor: "#f7f7f7",
                      borderRadius: "5px",
                      padding: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <p style={{ margin: "0 0 0 0" }}>{descripcionModulo}</p>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    overflow: "hidden",
                    overflowY: "scroll",
                    height: "85%",
                    padding: "5px 20px 0 20px",
                  }}
                >
                  <h3 style={{ margin: "15px 0 0 0" }}>Material descargable</h3>
                  <div
                    style={{
                      backgroundColor: "#f7f7f7",
                      borderRadius: "5px",
                      padding: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <ul
                      style={{
                        listStyle: "none",
                        margin: "10px 0 0 0",

                        padding: "0px",
                      }}
                    >
                      {descargables?.map((descargable, index) => {
                        return (
                          <>
                            <li
                              onClick={() =>
                                window.open(descargable.urlMaterialAdjunto)
                              }
                              style={{ margin: 0, cursor: "pointer" }}
                              key={index}
                            >
                              <FileOutlined style={{ marginRight: "10px" }} />{" "}
                              {descargable.tituloMaterialDes}
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#fff",
          width: "30%",
          overflow: "hidden",
          overflowY: "scroll",
        }}
      >
        <div
          style={{
            backgroundColor: "#000",
            display: "flex",
            alignItems: "center",
            height: "40px",
            justifyContent: "center",
          }}
        >
          <p style={{ color: "#fff", margin: 0 }}>Lista de reproducción</p>
        </div>
        {props.modulos.map((modulo, index) => {
          return (
            <>
              <div>
                <Modulo
                  setEstiloMatDes={setEstiloMatDes}
                  setEstiloInfo={setEstiloInfo}
                  setBanderaEstilo={setBanderaEstilo}
                  setTituloModulo={setTituloModulo}
                  setDescripcionModulo={setDescripcionModulo}
                  setDescargables={setDescargables}
                  setLink={setLink}
                  mod={modulo}
                  key={index}
                ></Modulo>
              </div>
            </>
          );
        })}
      </div>
      <div>
        {/* <div style={{ width: "70%" }}>
          <div style={{ backgroundColor: "black", height: "400px" }}>
            {(link?.includes("youtube") || link?.includes("youtu.")) &&
            codigoLink ? (
              <>
                <iframe
                  src={`https://www.youtube.com/embed/${codigoLink}`}
                  width="100%"
                  height="100%"
                  frameborder="0"
                  title="Youtube video player"
                  allowFullScreen={true}
                ></iframe>
              </>
            ) : link?.includes("vimeo") && codigoLink ? (
              <>
                <iframe
                  src={`https://player.vimeo.com/video/${codigoLink}?h=ce693d66c6`}
                  width="100%"
                  height="100%"
                  frameborder="0"
                  title="Vimeo video player"
                  allow="autoplay; picture-in-picture"
                  allowFullScreen={true}
                ></iframe>
              </>
            ) : (
              link?.includes("mp4") && (
                <>
                  <iframe
                    width="100%"
                    height="100%"
                    src={link}
                    title="MP4 video player"
                    frameborder="0"
                    allow="autoplay; clipboard-write; picture-in-picture"
                    allowFullScreen={true}
                  ></iframe>
                </>
              )
            )}
          </div>
          <div
            style={{
              height: "200px",
              backgroundColor: "#fff",

              padding: "10px",
              textAlign: "left",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "380px",
                justifyContent: "space-between",
              }}
            >
              <p
                style={{
                  margin: 0,
                  borderBottom: estiloInfo,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setEstiloMatDes(null);
                  setEstiloInfo("2px solid #cfcfcf");
                  setBanderaEstilo(true);
                }}
              >
                <InfoCircleOutlined style={{ marginRight: "5px" }} />{" "}
                Información de la lección
              </p>
              <p
                style={{
                  margin: 0,
                  borderBottom: estiloMatDes,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setEstiloMatDes("2px solid #cfcfcf");
                  setEstiloInfo(null);
                  setBanderaEstilo(false);
                }}
              >
                <DownloadOutlined style={{ marginRight: "5px" }} /> Material
                descargable
              </p>
            </div>

            {banderaEstilo ? (
              <>
                <div
                  style={{
                    paddingTop: "20px",
                    overflow: "hidden",
                    overflowY: "scroll",
                    height: "150px",
                  }}
                >
                  <h2
                    style={{ margin: "0", fontWeight: 600, fontSize: "18px" }}
                  >
                    {tituloModulo}
                  </h2>
                  <div
                    style={{
                      backgroundColor: "#f7f7f7",
                      borderRadius: "5px",
                      padding: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <p style={{ margin: "0 0 0 0" }}>{descripcionModulo}</p>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    paddingTop: "5px",
                    overflow: "hidden",
                    overflowY: "scroll",
                    height: "150px",
                  }}
                >
                  <h3 style={{ margin: "15px 0 0 0" }}>Material descargable</h3>
                  <div
                    style={{
                      backgroundColor: "#f7f7f7",
                      borderRadius: "5px",
                      padding: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <ul
                      style={{
                        listStyle: "none",
                        margin: "10px 0 0 0",

                        padding: "0px",
                      }}
                    >
                      {descargables?.map((descargable, index) => {
                        return (
                          <>
                            <li
                              onClick={() =>
                                window.open(descargable.urlMaterialAdjunto)
                              }
                              style={{ margin: 0, cursor: "pointer" }}
                              key={index}
                            >
                              {descargable.tituloMaterialDes}
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#f5f5f5",
            width: "30%",
            height: "600px",
            overflow: "hidden",
            overflowY: "scroll",
          }}
        >
          <div
            style={{
              backgroundColor: "#000",
              display: "flex",
              alignItems: "center",
              height: "40px",
              justifyContent: "center",
            }}
          >
            <p style={{ color: "#fff", margin: 0 }}>Lista de reproducción</p>
          </div>
          {props.modulos.map((modulo, index) => {
            return (
              <>
                <div>
                  <Modulo
                    setTituloModulo={setTituloModulo}
                    setDescripcionModulo={setDescripcionModulo}
                    setDescargables={setDescargables}
                    setLink={setLink}
                    mod={modulo}
                    key={index}
                  ></Modulo>
                </div>
              </>
            );
          })}
        </div> */}
      </div>
    </>
  );
};

export default VistaCurso;
