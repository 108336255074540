import { Form, Select } from "antd";
import React from "react";
import { store } from "../../../../../../store";

const ListarAtributos = (props) => {
  const { Option, OptGroup } = Select;

  return (
    <>
      <Form.Item shouldUpdate>
        {(formu) => {
          return (
            <>
              <Form.Item
                name={props.nombreAtributo}
                label={props.labelNombreAtributo}
                rules={[
                  {
                    required: true,
                    message: "Debes seleccionar una opción del listado",
                  },
                ]}
              >
                <Select
                  onChange={() => {
                    // if (
                    //   formu.getFieldValue(props.valorAtributoListener) != ""
                    // ) {
                    //   formu.setFieldsValue({
                    //     [props.valorAtributoListener]: "",
                    //   });
                    // }
                  }}
                  placeholder="Escoge una opción"
                >
                  {(props.filtro == "fecha" || props.filtro == "todos") && (
                    <OptGroup label="Fecha">
                      {store.losatributos.dates.map((elem, index) => {
                        return (
                          <>
                            <Option
                              key={index + elem._id}
                              value={elem._id + "-" + elem.tipo}
                            >
                              {elem.nombre}
                            </Option>
                          </>
                        );
                      })}
                    </OptGroup>
                  )}
                  {(props.filtro == "bandera" || props.filtro == "todos") && (
                    <OptGroup label="Bandera">
                      {store.losatributos.flags.map((elem, index) => {
                        return (
                          <>
                            <Option
                              key={index + elem._id}
                              value={elem._id + "-" + elem.tipo}
                            >
                              {elem.nombre}
                            </Option>
                          </>
                        );
                      })}
                    </OptGroup>
                  )}
                  {(props.filtro == "numero" || props.filtro == "todos") && (
                    <OptGroup label="Numero">
                      {store.losatributos.numbers.map((elem, index) => {
                        return (
                          <>
                            <Option
                              key={index + elem._id}
                              value={elem._id + "-" + elem.tipo}
                            >
                              {elem.nombre}
                            </Option>
                          </>
                        );
                      })}
                    </OptGroup>
                  )}
                  {(props.filtro == "texto" || props.filtro == "todos") && (
                    <OptGroup label="Texto">
                      {store.losatributos.texts.map((elem, index) => {
                        return (
                          <>
                            <Option
                              key={index + elem._id}
                              value={elem._id + "-" + elem.tipo}
                            >
                              {elem.nombre}
                            </Option>
                          </>
                        );
                      })}
                    </OptGroup>
                  )}
                </Select>
              </Form.Item>
            </>
          );
        }}
      </Form.Item>
    </>
  );
};

export default ListarAtributos;
