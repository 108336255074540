import React from "react";
import { Divider, Input, Tree, Badge, Drawer } from "antd";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Api } from "../../../../../api/configApi";
import CardProductoShop from "./CardProductoShop";
import ListaPedidosWoocommerce from "./ListaPedidosWoocommerce";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import CarritoDrawer from "./CarritoDrawer";
import { BrowserView } from "react-device-detect";
const { Search } = Input;
function WoocommerceShop({
  widget,
  productos,
  search,
  setSearch,
  BuscarProductos,
  buscando,
  categoriaSeleccionada,
}) {
  const [pedidos, setPedidos] = React.useState([]);
  const [pedidoPendiente, setPedidosPendientes] = React.useState(false);
  const [mostrarPedidos, setMostrarPedidos] = React.useState(false);
  const [categorias, setCategorias] = React.useState([]);
  const [productosenCarrito, setProductosenCarrito] = React.useState([]);
  const [drawerCarito, setDrawerCarrito] = React.useState(false);
  async function encontrarHijos(categorias, categoriasOrganizadas, padre) {
    const encontroPadre = categorias.filter((e) => {
      return e.parent === padre.id;
    });
    encontroPadre.forEach((element) => {
      categoriasOrganizadas.push({
        title: element.name,
        key: element.id,
        children: [],
      });
      var tieneHijos = categorias.find((e) => {
        return e.parent === element.id;
      });
      if (tieneHijos) {
        encontrarHijos(
          categorias,
          categoriasOrganizadas[categoriasOrganizadas.length - 1].children,
          element
        );
      }
    });
  }

  React.useEffect(() => {
    var categoriasOrganizadas = [];
    Api("modulos", "woocommerce", { consulta: "GetCategorias" }).then(
      async (res) => {
        if (res) {
          // console.log(res);
          res.forEach(async (element) => {
            if (element.parent === 0) {
              categoriasOrganizadas.push({
                title: element.name,
                key: element.id,
                children: [],
              });
              await encontrarHijos(
                res,
                categoriasOrganizadas[categoriasOrganizadas.length - 1]
                  .children,
                element
              );
            }
          });
          categoriasOrganizadas.unshift({
            title: "Todas",
            key: "",
            children: [],
          });
          setCategorias(categoriasOrganizadas);
        }
      }
    );
  }, []);
  React.useEffect(() => {
    Api("modulos", "woocommerce", {
      consulta: "GetPedidos",
    }).then((res) => {
      if (typeof res === "object" && res.length > 0) {
        setPedidos(res);
        const pedidospendientes = res.find((e) => {
          return e.status === "pending" || e.status === "on-hold";
        });
        pedidospendientes
          ? setPedidosPendientes(true)
          : setPedidosPendientes(false);
      }
    });
  }, [mostrarPedidos]);
  function verpedidos() {
    setMostrarPedidos(true);
  }
  return (
    <div>
      <span>{widget.titulo || ""}</span>
      {mostrarPedidos ? (
        <div style={{ marginTop: 20 }}>
          <ListaPedidosWoocommerce
            setMostrarPedidos={setMostrarPedidos}
            pedidos={pedidos}
          />
        </div>
      ) : (
        <>
          <div
            style={{
              background: pedidoPendiente ? "rgb(68 197 96 / 13%)" : null,
              marginTop: 15,
              padding: 10,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <span> {pedidoPendiente && "Tienes un pedido en curso"}</span>
            <strong
              onClick={verpedidos}
              style={{ fontWeight: "bold", cursor: "pointer" }}
            >
              Ver Pedidos
            </strong>
          </div>
          {widget.buscador && (
            <div style={{ marginTop: 10 }}>
              <Input.Search
                loading={buscando}
                placeholder="Buscar Productos"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                onSearch={BuscarProductos}
              />
            </div>
          )}

          {buscando && (
            <LinearProgress
              style={{
                color: "rgb(11, 134, 183)",
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 10000,
              }}
            />
          )}

          <div style={{ display: "flex", flexDirection: "row" }}>
            <BrowserView>
              {widget.categorias && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 20,
                    marginRight: 20,
                  }}
                >
                  <strong style={{ fontSize: 20 }}>Categorias</strong>
                  <div style={{ marginTop: 15 }}>
                    {categorias.length > 0 && (
                      <Tree
                        showIcon
                        defaultExpandAll={true}
                        selectedKeys={categoriaSeleccionada}
                        treeData={categorias}
                        onSelect={(e) => {
                          BuscarProductos({ category: e });
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
            </BrowserView>
            <BrowserView>
              <Divider
                type="vertical"
                orientation="center"
                style={{ height: 300 }}
              />
            </BrowserView>
            {productos.length > 0 ? (
              <div>
                <div style={estilos.contenido}>
                  {productos.map((producto) => {
                    return (
                      <CardProductoShop
                        setPedidosPendientes={setPedidosPendientes}
                        key={producto.id}
                        widget={widget}
                        producto={producto}
                        productosenCarrito={productosenCarrito}
                        setProductosenCarrito={setProductosenCarrito}
                      />
                    );
                  })}
                </div>
              </div>
            ) : (
              <div
                style={{
                  minHeight: 80,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flex: 1,
                }}
              >
                <span>Sin resultados</span>
              </div>
            )}
          </div>
        </>
      )}
      {widget.carrito && (
        <div
          style={{
            position: "fixed",
            width: 50,
            height: 50,
            margin: "auto",
            right: 30,
            bottom: 30,
            zIndex: 10000,
          }}
        >
          <Badge count={productosenCarrito.length}>
            <div
              onClick={() => {
                setDrawerCarrito(true);
              }}
              style={{
                cursor: "pointer",
                width: 50,
                height: 50,
                background: "#42ce42",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
              }}
            >
              <FontAwesomeIcon style={{ fontSize: 20 }} icon={faShoppingCart} />
            </div>
          </Badge>
          <Drawer
            width={700}
            visible={drawerCarito}
            onClose={() => {
              setDrawerCarrito(false);
            }}
          >
            <CarritoDrawer
              setProductosenCarrito={setProductosenCarrito}
              setDrawerCarrito={setDrawerCarrito}
              setPedidosPendientes={setPedidosPendientes}
              productosenCarrito={productosenCarrito}
              widget={widget}
            />
          </Drawer>
        </div>
      )}
    </div>
  );
}

const estilos = {
  contenido: {
    display: "flex",
    flexDirection: "row",
    fontSize: 30,
    fontWeight: 400,
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
};

export default WoocommerceShop;
