import { Button } from "antd";
import React from "react";
import { FormProvider, set, useForm } from "react-hook-form";
import { Api } from "../../../../../api/configApi";
import { InputT } from "../../../../../plugins/Formularios/FormularioTecclas";

const FormularioCrearCurso = (props) => {
  const methods = useForm();
  const [idCurso, setIdCurso] = React.useState();

  function crearCurso(values) {
    Api(
      "academia",
      "crearCurso",
      { idWidget: props.idWidget._id },
      values,
      "https://ed3a-181-132-3-197.ngrok.io"
    )
      .then((res) => {
        props.obtenerCursos()
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function onSubmit(values) {
    props.setVisible(false);
    const valores = values;
    valores.id = Math.floor(Math.random() * (9952 - 1210)) + 1210;

    methods.reset("");
    crearCurso(valores);
    
  }

  React.useEffect(() => {
    setIdCurso(Math.floor(Math.random() * (9952 - 1210)) + 1210);
  }, []);

  React.useEffect(() => {}, [props]);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          {/* <div
            style={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            <label style={{ marginBottom: "10px" }}>idCurso</label>
            <InputT
              control={methods.control}
              name="idCurso"
              value={{value:"casa"}}
              rules={{ required: false }}
            ></InputT>
          </div> */}

          <div
            style={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            <label style={{ marginBottom: "10px" }}>Nombre del curso</label>
            <InputT
              control={methods.control}
              name="nombreCurso"
              placeholder="Escribe el nombre del curso"
              rules={{ required: true }}
            ></InputT>
            {methods.formState.errors.nombreCurso && (
              <span style={{ color: "red" }}>Campo requerido</span>
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            <label style={{ marginBottom: "10px" }}>
              Descripción del curso
            </label>
            <InputT
              control={methods.control}
              name="descripcionCurso"
              placeholder="Escribe la descripción del curso"
              rules={{ required: true }}
            ></InputT>
            {methods.formState.errors.descripcionCurso && (
              <span style={{ color: "red" }}>Campo requerido</span>
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            <label style={{ marginBottom: "10px" }}>
              URL de la imagen del curso
            </label>
            <InputT
              control={methods.control}
              name="urlImagen"
              placeholder="Escribe la URL de la imagen del curso"
              rules={{ required: true, pattern:/(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/ }}
            ></InputT>

            {(methods.formState.errors.urlImagen && methods.formState.errors.urlImagen.type=="required") && (
              <span style={{ color: "red" }}>Campo requerido</span>
            )}
            {(methods.formState.errors.urlImagen && methods.formState.errors.urlImagen.type=="pattern") && (
              <span style={{ color: "red" }}>URL Requerida</span>
            )}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              margin: "20px 0 20px 0",
            }}
          >
            <Button type="secondary" htmlType="htmlType">
              Guardar
            </Button>
            <Button type="secondary" danger onClick={() => props.setVisible(false)}>
              Cancelar
            </Button>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default FormularioCrearCurso;
