import React from "react";
import { store } from "../../../../../store";

function DesignWidgetIframe(props) {
  const [widget, setWidget] = React.useState({});

  React.useEffect(() => {
    const widgetActual = store.widgets.find((wid) => {
      return wid._id === props._id;
    });

    if (widgetActual) {
      console.log(widgetActual);
      setWidget(widgetActual);
    }
  }, [props]);

  return (
    <div className="widgetShadow" style={{ height: "100%", width: "100%", position: "relative" }}>
      <iframe
        style={{
          overflow: "hidden",
          border: "none",
          height: widget.alto || null,
          width: widget.ancho || "100%",
        }}
        title="iframe"
        srcDoc={widget.codigo}
      />
    </div>
  );
}

export default DesignWidgetIframe;
