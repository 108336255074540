import React from "react";

import { store } from "../../../../../store";
import { Api } from "../../../../../api/configApi";
import { Progress } from "antd";

function DesignWidgetObjetivo(props) {
  const [widget, setWidget] = React.useState({});
  const [contenido, setContenido] = React.useState();
  const [objetivo, setObjetivo] = React.useState();
  const [porcentaje, setPorcentaje] = React.useState(0);

  React.useEffect(() => {
    const widgetActual = store.widgets.find((wid) => {
      return wid._id === props._id;
    });
    setWidget(widgetActual);
  }, [props]);
  React.useEffect(() => {
    if (widget._id) {
      if (widget.tipoDato === "atributo") {
        Api("atributosNumero", "GetTotal", { widget: widget._id }, {}).then(
          (res) => {
            res.success ? setContenido(res.total) : setContenido(0);
          }
        );
      }
      if (widget.tipoDato === "listado") {
        Api("listados", "operacionCampos", { widget: widget }, {}).then(
          (res) => {
            var obje = res.campoBase * (200 / 100);
            setObjetivo(obje);
            setContenido(res.campoComparacion);
            setPorcentaje(
              parseFloat((res.campoComparacion / obje) * 100).toFixed(1)
            );
          }
        );
      }
    }
  }, [widget]);

  return (
    <div
      style={{
        marginBottom: 20,
        marginTop: 20,
        textAlign: widget.diseno !== "line" ? "center" : "left",
      }}
      className="widgetShadow"
    >
      <span>{widget.titulo || ""}</span>
      <div style={estilos.contenido}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            color: "#fff",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Progress
            strokeColor="#51c41a"
            format={(e) => <strong style={{ color: "#fff" }}>{e}%</strong>}
            type={widget.diseno}
            style={{ marginTop: 15, color: "#fff" }}
            percent={porcentaje}
          />
          <span style={{ fontSize: 12, marginTop: 5 }}>
            {contenido} de {objetivo}
          </span>
        </div>
      </div>
    </div>
  );
}
const estilos = {
  contenido: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    fontSize: 30,
    fontWeight: 400,
  },
};
export default DesignWidgetObjetivo;
