import { Form, Switch } from "antd";
import React from "react";

const Interruptor = (props) => {
  return (
    <>
      <Form.Item
        name={props.interruptor}
        label={props.labelInterruptor}
        valuePropName="checked"
        initialValue={false}
      >
        <Switch />
      </Form.Item>
    </>
  );
};

export default Interruptor;
