import { SpaceContext } from "antd/lib/space";
import React from "react";
import { Api } from "../../../../../api/configApi";

import { store } from "../../../../../store";

function DesignWidgetLogros(props) {
  const [widget, setWidget] = React.useState({});
  const [esCelular, setEsCelular] = React.useState(false);
  const [cantidadAfiliado, setCantidadAfiliado] = React.useState(0);

  React.useEffect(() => {
    const widgetActual = store.widgets.find((wid) => {
      return wid._id === props._id;
    });
    setWidget(widgetActual);
    // es un celular
    if (window.innerWidth <= 768) {
      setEsCelular(true);
    } else {
      setEsCelular(false);
    }
  }, [props]);

  React.useEffect(() => {
    if (widget._id) {
      Api("atributosNumero", "GetTotal", { widget: widget._id }, {}).then(
        (res) => {
          res.success && setCantidadAfiliado(res.total);
        }
      );
    }
  }, [widget]);

  // crear un array de un numero
  const cantidad = () => {
    let array = [];
    for (let i = 0; i < widget.limiteCantidad; i++) {
      array.push(i + 1);
    }
    return array;
  };

  return (
    <div style={{ height: "100%" }} className="widgetShadow">
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: widget.fondo,
          padding: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {cantidad().map((item) => {
            return (
              <div style={{ margin: 5, maxWidth: widget.limiteWidth }}>
                {item <= cantidadAfiliado ? (
                  <img
                    alt="logro"
                    style={{ maxWidth: "100%", minWidth: 50 }}
                    src={widget.imagen}
                    key={item}
                  />
                ) : (
                  <img
                    alt="logro"
                    style={{
                      maxWidth: "100%",
                      minWidth: 50,
                      opacity: 0.4,
                      filter: "grayscale(100%)",
                    }}
                    key={item}
                    src={widget.imagen}
                  />
                )}
              </div>
            );
          })}
        </div>
        <strong style={{ marginTop: 10 }}>{widget.titulo}</strong>
      </div>
    </div>
  );
}

export default DesignWidgetLogros;
