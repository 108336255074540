import React from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ModalBaseFlotante from "../modalbaseflotante";
import { Api } from "../../../api/configApi";
import { setPensando, store, setStoreEtapas } from "../../../store";
import { useParams } from "react-router";
import { Form, Input } from "antd";
import Boton from "../../design/elementos/boton";

function Nuevomodulo(props) {
  const params = useParams();

  function GetEtapas() {
    Api("etapas", "Get", {
      cuenta: store.cuentaactiva,
      flujo: params.id,
      grupo: params.idgrupo,
    })
      .then((res) => {
        // console.log(res);
        setStoreEtapas(res);
      })
      .catch((err) => {
        // console.log("Algo no ha ido bien.");
      });
  }
  function crearEtapa(values) {
    setPensando(true);
    props.setOpen(false);

    Api("etapas", "Insert", {
      cuenta: store.cuentaactiva,
      nombre: values.nombre,
      descripcion: values.descripcion,
      estado: false,
      nuevo: true,
      flujo: params.id,
      prioridad: store.etapas.length + 1,
      grupo: params.idgrupo,
      grupoEtapa: store.grupoEtapaActual,
      tagsConfig: {
        lastags: [],
        objetoAcciones: [],
        nombreydescripcion: {},
        opcionesAtributoActual: [],
        sugerencias: [],
        placeholderOpciones: "",
      },
    })
      .then((res) => {
        GetEtapas();
        setPensando(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div
      style={{
        background: "#fff",
        maxWidth: 300,
        display: "flex",
        flexDirection: "column",
        padding: 10,
        borderRadius: 5,
      }}
      className="shadow"
    >
      <Form layout="vertical" onFinish={crearEtapa}>
        <Form.Item
          rules={[{ required: true, message: "Campo obligatorio" }]}
          label="Nombre"
          name="nombre"
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Campo obligatorio" }]}
          label="Descripción"
          name="descripcion"
        >
          <Input.TextArea />
        </Form.Item>
        <Boton texto="Crear Etapa" htmlType="submit" />
      </Form>
    </div>
  );
}

export default Nuevomodulo;
