import CrearNuevoWidgetLista from "../../../components/widgets/crearNuevoWidgetListaComponente";
import ListaWidgetsComponente from "../../../components/widgets/ListaWidgetsComponente";

function PageWidgetsIndex() {
  return (
    <div style={{ padding: 10 }}>
      <CrearNuevoWidgetLista />
      <ListaWidgetsComponente />
    </div>
  );
}

export default PageWidgetsIndex;
