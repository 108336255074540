import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Select,
} from "antd";
import moment from "moment-timezone";
import React from "react";
import { Api } from "../../../../../../api/configApi";
import { store } from "../../../../../../store";

const { Option } = Select;

function FormEditarAtributos(props) {
  const [atributoActual, setAtributoActual] = React.useState(null);
  const [pensando, setPensando] = React.useState(false);
  const [form] = Form.useForm();
  React.useEffect(() => {
    form.resetFields();
    setPensando(false);
    const atributos = [
      ...store.losatributos.texts,
      ...store.losatributos.numbers,
      ...store.losatributos.dates,
    ];
    var attri = atributos.find((e) => e.nombre === props.atributo.atributo);
    form.setFieldsValue({
      atributo:
        attri.tipo === "date" && props.atributo.valor
          ? moment(props.atributo.valor)
          : props.atributo.valor,
    });
    setAtributoActual(attri);
  }, [props]);

  function actualizarAtributo(values) {
    setPensando(true);

    Api("afiliados", "UpdateAtributos", {
      atributo: atributoActual,
      valor: values.atributo,
      afiliado: props.afiliado._id,
      comentario: values.comentario,
    }).then((res) => {
      setPensando(false);

      if (res.length) {
        props.getAtris();
        message.success("Atributo actualizado correctamente");
        props.setDrawerEditarAfiliado(false);
      } else {
        message.error(res.msg || "Error al actualizar el atributo");
      }
    });
  }

  return (
    <>
      {atributoActual && (
        <Form onFinish={actualizarAtributo} layout="vertical" form={form}>
          <Form.Item
            name="atributo"
            label={atributoActual.nombre}
            rules={[{ required: true, message: "Campo obligatorio" }]}
          >
            {atributoActual.tipo === "text" ? (
              <Select>
                {atributoActual.opciones.map((opcion) => (
                  <Option key={opcion} value={opcion}>
                    {opcion}
                  </Option>
                ))}
              </Select>
            ) : atributoActual.tipo === "number" ? (
              <Input type="number" />
            ) : (
              <DatePicker
                format="YYYY-MM-DD HH:mm:ss"
                showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
              />
            )}
          </Form.Item>
          <Form.Item
            name="comentario"
            label="Comentario"
            rules={[{ required: true, message: "Campo obligatorio" }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Button loading={pensando} htmlType="submit">
            Actualizar Atributo
          </Button>
        </Form>
      )}
      <Divider />
      <div style={{ fontWeight: 300 }}>
        <strong>IMPORTANTE</strong>
        <p style={{ marginTop: 5 }}>
          Cuando actualizas un atributo se crea un regístro de ese cambio y
          los afiliados podrán ver esa transacción
        </p>
        <p>
          <strong>Ejemplo: </strong> Si cambias un atributo que tiene un valor
          de 100 por 70, se creará un regístro de un debito de 30 por parte del
          administrador.
        </p>
      </div>
    </>
  );
}

export default FormEditarAtributos;
