const nombresFormList = [
  "compararAtributoMultiGroupList",
  "compararCamposListadosMultiGroupList",
  "fechaPickerGroupList",
  "interruptorMultiGroupList",
  "listarAtributosMultiGroupList",
  "listarListadosMultiGroupList",
  "listarOpcionesMultiGroupList",
  "rangoFechasMultiGroupList",
  "compararAtributoMultiConAtribGroupList",
];

export default nombresFormList;
