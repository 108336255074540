import React from "react";
import { useFormContext } from "react-hook-form";
import { useFieldArray } from "react-hook-form";
import {
  ButtonT,
  InputNumberT,
  InputT,
} from "../../../../../../plugins/Formularios/FormularioTecclas";

const ListRespuestas = (props) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `preguntas.${props.indice}.respuestas`,
  });

  return (
    <>
      <div style={{ padding: "5px" }}>
        {fields.map((item, index) => {
          return (
            <div
              key={item.id}
              style={{
                backgroundColor: "#fff",
                marginBottom: "10px",
                display: "grid",
                alignItems: "center",
                paddingBottom: "10px",
              }}
            >
              <div style={{ width: "90%" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    padding: "10px",
                  }}
                >
                  <label style={{ marginBottom: "10px" }}>
                    Escribe tu respuesta
                  </label>
                  <InputT
                    control={control}
                    name={`preguntas.${props.indice}.respuestas.${index}.respuesta`}
                    rules={{ required: true }}
                  ></InputT>
                  {/* <input
                    style={{
                      border: "thin solid rgb(118 118 118)",
                      borderRadius: "3px",
                      width: "100%",
                    }}
                    type="text"
                    {...register(
                      `preguntas.${props.indice}.respuestas.${index}.respuesta`,
                      { required: true }
                    )}
                  /> */}
                  {errors.preguntas &&
                    errors.preguntas[props.indice] &&
                    errors.preguntas[props.indice].respuestas &&
                    errors.preguntas[props.indice].respuestas[index] &&
                    errors.preguntas[props.indice].respuestas[index]
                      .respuesta && (
                      <span>Por favor ingresa una respuesta</span>
                    )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    padding: "10px",
                  }}
                >
                  <label style={{ marginBottom: "10px" }}>
                    Escribe el peso de tu respuesta
                  </label>
                  <InputNumberT
                    control={control}
                    name={`preguntas.${props.indice}.respuestas.${index}.pesoRespuesta`}
                    rules={{ required: true }}
                    min={0}
                  ></InputNumberT>
                  {/* <input
                    style={{
                      border: "thin solid rgb(118 118 118)",
                      borderRadius: "3px",
                      width: "100%",
                    }}
                    min={0}
                    type="number"
                    {...register(
                      `preguntas.${props.indice}.respuestas.${index}.pesoRespuesta`,
                      { required: true }
                    )}
                  /> */}
                  {errors.preguntas &&
                    errors.preguntas[props.indice] &&
                    errors.preguntas[props.indice].respuestas &&
                    errors.preguntas[props.indice].respuestas[index] &&
                    errors.preguntas[props.indice].respuestas[index]
                      .pesoRespuesta && (
                      <span>
                        Por favor ingresa el peso que va tener la respuesta
                      </span>
                    )}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  width: "auto",
                  justifyContent: "center",
                }}
              >
                <ButtonT type="secondary" onClick={() => remove(index)}>
                  Eliminar respuesta
                </ButtonT>
                {/* <button
                  style={{ width: "auto", margin: "0 10px 0 10px" }}
                  type="button"
                  onClick={() => remove(index)}
                >
                  Eliminar respuesta
                </button> */}
              </div>
            </div>
          );
        })}

        <div style={{ padding: "10px" }}>
          <ButtonT
            type="primary"
            onClick={() => append({ respuesta: null, pesoRespuesta: null })}
          >
            Agregar respuesta
          </ButtonT>
          {/* <button
            type="button"
            onClick={() => append({ respuesta: null, pesoRespuesta: null })}
          >
            Agregar respuesta
          </button> */}
        </div>
      </div>
    </>
  );
};

export default ListRespuestas;
