import React from "react";
import { Route } from "react-router-dom";
import inicio from "./pages/inicio";
import flujos from "./pages/configuraciones/flujos";

import cuentas from "./pages/cuentas";
import abrircuenta from "./pages/abrircuenta";
import Inputcondicional from "./pages/inputcondicional";
import indexFlujos from "./pages/configuraciones/flujos/indexFlujos";
import indexConfiguraciones from "./pages/configuraciones/index/indexConfiguraciones";
import PagesConfigDiseñoIndex from "./pages/configuraciones/diseño/pagesConfigDiseñoIndex";
import ModulosPage from "./pages/modulosPage";
import pageMenuLeft from "./pages/menuLeft/pageMenuLeft";
import ConfigCuenta from "./pages/cuenta/ConfigCuenta";
import SoporteListaFront from "./pages/soporte/soporteListaFront";
import SoporteListaAdmin from "./pages/soporte/soporteListaAdmin";

function Router() {
  React.useEffect(() => {}, []);
  return (
    <>
      <Route exact path="/" component={inicio} />
      <Route exact path="/soporte" component={SoporteListaFront} />
      <Route exact path="/soportecontrol" component={SoporteListaAdmin} />
      <Route exact path="/cuenta/:id" component={ConfigCuenta} />
      <Route exact path="/page/:id" component={pageMenuLeft} />
      <Route exact path="/inputcondicional" component={Inputcondicional} />
      <Route exact path="/cuentas" component={cuentas} />
      <Route exact path="/abrircuenta" component={abrircuenta} />
      <Route exact path="/modulos" component={ModulosPage} />

      <Route
        exact
        path="/configuraciones/index/:id"
        component={indexConfiguraciones}
      />
      <Route
        exact
        path="/configuraciones/flujos/flujo/:idgrupo/:id/"
        component={indexFlujos}
      />
      <Route
        exact
        path="/configuraciones/diseno/:id"
        component={PagesConfigDiseñoIndex}
      />
    </>
  );
}

export default Router;
