import React from "react";

import { store } from "../../../../../store";
import { Table, Space, Drawer, message, Modal, Card } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import Boton from "../../../../design/elementos/boton";
import FormCrearActualizarNuevoRecurso from "./FormCrearActualizarNuevoRecurso";
import { Api } from "../../../../../api/configApi";
import ListaDeRecursos from "./ListaDeRecursos";

function DesignWidgetAdminRecursos(props) {
  const [widget, setWidget] = React.useState({});
  const [drawerRecurso, setDrawerRecurso] = React.useState(false);
  const [actualizarRecurso, setActualizarRecurso] = React.useState("");
  const [recursos, setRecursos] = React.useState([]);

  function ObtenerLosRecursos(w) {
    Api("recursos", "Get", { widget: w._id, modulo: w.moduloRecursos }).then(
      (res) => {
        if (res.success) {
          setRecursos(res.recursos);
        } else {
          message.error(
            "No pudimos obtener los recursos. Intentalo nuevamente"
          );
        }
      }
    );
  }

  React.useEffect(() => {
    const widgetActual = store.widgets.find((wid) => {
      return wid._id === props._id;
    });
    ObtenerLosRecursos(widgetActual);
    setWidget(widgetActual);
  }, [props]);

  return (
    <div style={{padding: 20}}>
      <Drawer
        title="Recurso"
        visible={drawerRecurso}
        onClose={() => {
          setDrawerRecurso(false);
        }}
      >
        <FormCrearActualizarNuevoRecurso
          widget={widget}
          setDrawerRecurso={setDrawerRecurso}
          ObtenerLosRecursos={ObtenerLosRecursos}
          actualizarRecurso={actualizarRecurso}
        />
      </Drawer>
      <div
        style={{
          marginBottom: 10,
          width: "100%",
          alignItems: "flex-end",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Boton
          onClick={(e) => {
            setActualizarRecurso(false);
            setDrawerRecurso(true);
          }}
          texto="Crear Recurso"
        />
      </div>
      <div>
        <ListaDeRecursos
          setActualizarRecurso={setActualizarRecurso}
          setDrawerRecurso={setDrawerRecurso}
          widget={widget}
          ObtenerLosRecursos={ObtenerLosRecursos}
          recursos={recursos}
        />
      </div>
    </div>
  );
}

export default DesignWidgetAdminRecursos;
