import { Button, Drawer } from "antd";
import React from "react";
import { Api } from "../../../../../api/configApi";
import ContenedorCursos from "../Contenedores/ContenedorCursos";
import FormularioCrearCurso from "../Formularios/FormularioCrearCurso";
import { PlusSquareOutlined, RollbackOutlined } from "@ant-design/icons";

const DrawerCursos = (props) => {
  const [visible2, setVisible2] = React.useState(false);

  const refListaCursos = React.useRef();
  const [listaCursos, setListaCursos] = React.useState();

  function obtenerCursos() {
    Api(
      "academia",
      "obtenerCursos",
      { idWidget: props.infoWidget._id },
      {},
      "https://ed3a-181-132-3-197.ngrok.io"
    )
      .then((res) => {
        refListaCursos.current = res.cursos;
        setListaCursos(refListaCursos.current);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const showDrawer = () => {
    setVisible2(true);
  };

  const onClose = () => {
    setVisible2(false);
  };

  React.useEffect(() => {
    obtenerCursos();
  }, [props]);

  return (
    <>
      <div style={{ display: "flex", height: "85vh", alignItems: "center" }}>
        <div
          style={{
            width: "70%",
            overflow: "hidden",
            overflowY: "scroll",
            position: "absolute",
            top: 55,
            bottom: 0,
          }}
        >
          {listaCursos?.map((curso, index) => {
            return (
              <>
                <ContenedorCursos
                  key={index}
                  descripcionCurso={curso.descripcionCurso}
                  idCurso={curso.idCurso}
                  nombreCurso={curso.nombreCurso}
                  urlImagen={curso.urlImagen}
                  data={curso}
                  infoWidget={props.infoWidget}
                  obtenerCursos={obtenerCursos}
                ></ContenedorCursos>
              </>
            );
          })}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            width: "100%",
            alignItems: "end",
            // backgroundColor:"red"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // backgroundColor: "red",
              marginRight:"35px"
            }}
          >
            <div
              onClick={showDrawer}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "30px",
                cursor:"pointer"
              }}
            >
              <PlusSquareOutlined style={{ fontSize: "30px" }} />
              <p>Crear curso</p>
            </div>
            <div
              onClick={()=>props.vis(false)}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                cursor:"pointer"
              }}
            >
              <RollbackOutlined style={{ fontSize: "30px" }} />
              <p>Cerrar</p>
            </div>
          </div>
        </div>
      </div>

      <Drawer
        title="Creador de cursos"
        placement="right"
        closable={false}
        // onClose={onClose}
        visible={visible2}
      >
        <FormularioCrearCurso
          idWidget={props.infoWidget}
          setVisible={setVisible2}
          obtenerCursos={obtenerCursos}
        ></FormularioCrearCurso>
      </Drawer>
    </>
  );
};

export default DrawerCursos;
