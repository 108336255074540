import React from "react";
import Badge from "@material-ui/core/Badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDoorClosed } from "@fortawesome/free-solid-svg-icons";
import Popover from "@material-ui/core/Popover";
import ModalListaFiltrosDisparadores from "./modalListaFiltrosDisparadores";
import ListarFiltrosDisparadores from "./listarFiltrosDisparadores";
import ConfigurarFiltroDisparador from "./cofigurarFiltroDisparador/cofigurarFiltroDisparador";

function FiltrosDisparadores(props) {
  const [ancla, setAncla] = React.useState();
  const [filtroActual, setFiltroActual] = React.useState(null);
  const [vistaactiva, setVistaactiva] = React.useState(0);
  const [switchIncluidos, setSwitchIncluidos] = React.useState(true);
  React.useEffect(() => {
    const x = vistaactiva;
    setVistaactiva(3);
    setVistaactiva(x);
  }, [props.actualizarlista]);
  const Mostrarvista = () => {
    if (vistaactiva === 0) {
      return (
        <ModalListaFiltrosDisparadores
          setSwitchIncluidos={setSwitchIncluidos}
          eliminarfiltro={props.eliminarfiltro}
          listadefiltros={props.listadefiltros}
          setVistaactiva={setVistaactiva}
        />
      );
    }
    if (vistaactiva === 1) {
      return (
        <ListarFiltrosDisparadores
          setFiltroActual={setFiltroActual}
          disparadoractual={props.disparadoractual}
          setVistaactiva={setVistaactiva}
        />
      );
    }
    if (vistaactiva === 2) {
      return (
        <ConfigurarFiltroDisparador
          switchIncluidos={switchIncluidos}
          agregarFiltro={props.agregarFiltro}
          filtroActual={filtroActual}
          disparadoractual={props.disparadoractual}
          setVistaactiva={setVistaactiva}
        />
      );
    }
    if (vistaactiva === 3) {
      return <div></div>;
    }
  };

  return (
    <>
      <Badge
        style={{
          position: "absolute",
          alignSelf: "center",
          background: "#fff",
          cursor: "pointer",
        }}
        color="secondary"
        badgeContent={0}
        onClick={(event) => {
          setAncla(event.currentTarget);
          setVistaactiva(0);
        }}
      >
        <FontAwesomeIcon
          style={{
            fontSize: 20,
            color: "#000",
          }}
          icon={faDoorClosed}
        />
      </Badge>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        elevation={0}
        id="popov"
        placement="top-start"
        anchorEl={ancla}
        open={Boolean(ancla)}
        onClose={() => {
          setAncla(null);
        }}
      >
        <div
          style={{
            background: "#fff",
            marginTop: 10,
            padding: 15,
            borderRadius: 5,
            borderColor: "rgb(232 232 232)",
            borderStyle: "solid",
            borderWidth: 1,
            display: "flex",
            flexDirection: "column",
            minWidth: 250,
            minHeight: 250,
          }}
        >
          <Mostrarvista />
        </div>
      </Popover>
    </>
  );
}

export default FiltrosDisparadores;
