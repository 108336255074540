import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Switch,
  Typography,
} from "antd";
import FormList from "antd/lib/form/FormList";
import React from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { store } from "../../../../../store";

const CamposPersonalizados = (props) => {
  const refAutenticacion = React.useRef([
    {
      valor: "codigo",
      valorMostrar: "Últimos cuatro dígitos del télefono",
    },
    {
      valor: "SMS",
      valorMostrar: "Código enviado por SMS (OTP)",
    },
  ]);

  const refSeguridadRequired = React.useRef([
    {
      valor: true,
      valorMostrar: "Sí",
    },
    {
      valor: false,
      valorMostrar: "No",
    },
  ]);
  const refNotViudeoRerquired = React.useRef([
    {
      valor: false,
      valorMostrar: "Sí, acepta el videoacuerdo",
    },
    {
      valor: true,
      valorMostrar: "No, acepta el videoacuerdo",
    },
  ]);

  const refexpNatural = React.useRef(/^\d+$/);
  const refContractTypeArr = React.useRef(["Contrato", "Solicitud", "Acuse"]);
  const refPhoneCodeArr = React.useRef([
    93, 358, 355, 213, 1684, 376, 244, 1264, 672, 1268, 54, 374, 297, 61, 43,
    994, 1242, 973, 880, 1246, 375, 32, 501, 229, 1441, 975, 591, 599, 387, 267,
    55, 55, 246, 673, 359, 226, 257, 855, 237, 1, 238, 1345, 236, 235, 56, 86,
    61, 672, 57, 269, 242, 242, 682, 506, 225, 385, 53, 599, 357, 420, 45, 253,
    1767, 1809, 593, 20, 503, 240, 291, 372, 251, 500, 298, 679, 358, 33, 594,
    689, 262, 241, 220, 995, 49, 233, 350, 30, 299, 1473, 590, 1671, 502, 44,
    224, 245, 592, 509, 0, 39, 504, 852, 36, 354, 91, 62, 98, 964, 353, 44, 972,
    39, 1876, 81, 44, 962, 7, 254, 686, 850, 82, 381, 965, 996, 856, 371, 961,
    266, 231, 218, 423, 370, 352, 853, 389, 261, 265, 60, 960, 223, 356, 692,
    596, 222, 230, 269, 52, 691, 373, 377, 976, 382, 1664, 212, 258, 95, 264,
    674, 977, 31, 599, 687, 64, 505, 227, 234, 683, 672, 1670, 47, 968, 92, 680,
    970, 507, 675, 595, 51, 63, 64, 48, 351, 1787, 974, 262, 40, 70, 250, 590,
    290, 1869, 1758, 590, 508, 1784, 684, 378, 239, 966, 221, 381, 381, 248,
    232, 65, 1, 421, 386, 677, 252, 27, 500, 211, 34, 94, 249, 597, 47, 268, 46,
    41, 963, 886, 992, 255, 66, 670, 228, 690, 676, 1868, 216, 90, 7370, 1649,
    688, 256, 380, 971, 44, 1, 1, 598, 998, 678, 58, 84, 1284, 1340, 681, 212,
    967, 260, 263,
  ]);

  const [listaNombres, setListaNombres] = React.useState([]);
  const [listaEmails, setListaEmails] = React.useState([]);
  const [listaTelefonoUno, setListaTelefonoUno] = React.useState([]);
  const [listaTelefonoDos, setListaTelefonoDos] = React.useState([]);

  function resetearCampos(key) {
    const fields = props.form.getFieldsValue();
    const { requisitionObj } = fields;
    if (requisitionObj) {
      const { requisition } = requisitionObj;
      if (requisition) {
        const { signers } = requisition;

        delete signers[key].mail;
        delete signers[key].phone;
        delete signers[key].signerName;
        delete signers[key].signDevicePhone;

        Object.assign(requisition, signers[key]);

        Object.assign(requisitionObj, requisition);

        Object.assign(fields, requisitionObj);
      }
    }
    props.form.setFieldsValue(fields);
  }

  function desactivarSwitch(key) {
    const fields = props.form.getFieldsValue();
    const { requisitionObj } = fields;
    if (requisitionObj) {
      const { requisition } = requisitionObj;
      if (requisition) {
        const { signers } = requisition;

        signers.forEach((firman, index) => {
          if (!(index === key)) {
            firman.isDynamic = false;

          }
          console.log(firman.isDynamic);
        });

        Object.assign(requisition, signers);

        Object.assign(requisitionObj, requisition);

        Object.assign(fields, requisitionObj);
      }
    }
    props.form.setFieldsValue(fields);
  }

  React.useEffect(() => {}, []);

  return (
    <>
      <Divider />

      <Form.Item
        name={["requisitionObj", "requisition", "contractName"]}
        label="Escribe el nombre del contrato"
        rules={[
          { required: true, message: "Debes escribir el nombre del contrato." },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        hidden
        name={["requisitionObj", "requisition", "acceptanceLegend"]}
        initialValue=""
      ></Form.Item>
      <Form.Item
        hidden
        name={["requisitionObj", "requisition", "idDocument"]}
        initialValue=""
      ></Form.Item>

      <Form.Item
        name={["requisitionObj", "requisition", "validity"]}
        label="Escribe los días en que permanecerá el documento activo para los firmantes"
        rules={[
          {
            required: true,
            message:
              "Debes escribir los días en que permanecerá el documento activo.",
          },
        ]}
        initialValue={1}
      >
        <InputNumber min={1} max={5} style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item
        name={["requisitionObj", "requisition", "contractType"]}
        label="Seleccione el tipo de contrato"
        rules={[
          { required: true, message: "Debes selecionar el tipo de contrato." },
        ]}
      >
        <Select
          showSearch
          placeholder="Tipo de contrato"
          style={{ textAlign: "left" }}
        >
          {refContractTypeArr.current.map((tipoContrato, index) => {
            return (
              <Select.Option key={index} value={tipoContrato}>
                {tipoContrato}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item
        hidden
        name={["requisitionObj", "requisition", "certificate", "page"]}
        initialValue={0}
      ></Form.Item>
      <Form.Item
        hidden
        name={["requisitionObj", "requisition", "certificate", "positionX1"]}
        initialValue={0}
      ></Form.Item>
      <Form.Item
        name={["requisitionObj", "requisition", "certificate", "positionX2"]}
        initialValue={0}
        hidden
      ></Form.Item>
      <Form.Item
        hidden
        name={["requisitionObj", "requisition", "certificate", "positionY1"]}
        initialValue={0}
      ></Form.Item>
      <Form.Item
        hidden
        name={["requisitionObj", "requisition", "certificate", "positionY2"]}
        initialValue={0}
      ></Form.Item>

      <FormList name={["requisitionObj", "requisition", "signers"]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => {
              return (
                <Space
                  key={key}
                  direction="vertical"
                  size="middle"
                  style={{ display: "flex" }}
                >
                  <Divider />
                  <Typography.Title level={4}>
                    Firmante {name + 1}
                  </Typography.Title>

                  <Form.Item
                    {...restField}
                    label="Firmante dinámico"
                    initialValue={false}
                    name={[name, "isDynamic"]}
                    valuePropName="checked"
                  >
                    <Switch
                      onChange={() => {
                        // console.log(props.form.getFieldsValue().requisitionObj?.requisition?.signers)

                        resetearCampos(key);
                        desactivarSwitch(key);
                        setListaNombres([]);
                        setListaEmails([]);
                        setListaTelefonoUno([]);
                        setListaTelefonoDos([]);
                      }}
                    ></Switch>
                  </Form.Item>

                  <Form.Item shouldUpdate>
                    {(form) => {
                      return (
                        <>
                          {form.getFieldsValue().requisitionObj?.requisition
                            .signers[name]?.isDynamic ? (
                            <>
                              <Form.Item
                                {...restField}
                                label="Escribe tú nombre y tú apellido. Ejemplo: (Nombre Apellido)"
                                name={[name, "signerName"]}
                                hasFeedback
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "El nombre y el apellido son necesarios",
                                  },
                                ]}
                              >
                                <Select
                                  mode="multiple"
                                  onChange={(e) => {
                                    setListaNombres(e);
                                  }}
                                >
                                  {store.cuenta.formularios
                                    .find(
                                      (e) =>
                                        e._id ===
                                        props.form.getFieldValue("formulario")
                                    )
                                    ?.campos.map((e) => (
                                      <Select.Option
                                        value={`[${e.name}]`}
                                        disabled={
                                          listaNombres.length > 1
                                            ? listaNombres.includes(
                                                `[${e.name}]`
                                              )
                                              ? false
                                              : true
                                            : false
                                        }
                                      >{`[${e.name}]`}</Select.Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </>
                          ) : (
                            <>
                              <Form.Item
                                {...restField}
                                label="Escribe tú nombre y tú apellido. Ejemplo: (Nombre Apellido)"
                                name={[name, "signerName"]}
                                hasFeedback
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "El nombre y el apellido son necesarios",
                                  },
                                ]}
                              >
                                <Input></Input>
                              </Form.Item>
                            </>
                          )}

                          {form.getFieldsValue().requisitionObj?.requisition
                            .signers[name]?.isDynamic ? (
                            <>
                              <Form.Item
                                {...restField}
                                label="Escribe tú correo electrónico "
                                name={[name, "mail"]}
                                hasFeedback
                                rules={[
                                  {
                                    required: true,
                                    message: "Debes escribir tú correo.",
                                  },
                                ]}
                              >
                                <Select
                                  mode="multiple"
                                  onChange={(e) => {
                                    setListaEmails(e);
                                  }}
                                >
                                  {store.cuenta.formularios
                                    .find(
                                      (e) =>
                                        e._id ===
                                        props.form.getFieldValue("formulario")
                                    )
                                    ?.campos.map((e) => (
                                      <Select.Option
                                        value={`[${e.name}]`}
                                        disabled={
                                          listaEmails.length > 0
                                            ? listaEmails.includes(
                                                `[${e.name}]`
                                              )
                                              ? false
                                              : true
                                            : false
                                        }
                                      >{`[${e.name}]`}</Select.Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </>
                          ) : (
                            <>
                              <Form.Item
                                {...restField}
                                label="Escribe tú correo electrónico "
                                name={[name, "mail"]}
                                hasFeedback
                                rules={[
                                  {
                                    required: true,
                                    message: "Debes escribir tú correo.",
                                  },
                                ]}
                              >
                                <Input></Input>
                              </Form.Item>
                            </>
                          )}

                          <div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "start",
                                justifyContent: "start",
                              }}
                            >
                              <Form.Item
                                name={[name, "countryCode"]}
                                style={{ marginRight: "50px" }}
                                label="Ingresa el indicativo de tu país"
                                initialValue="+52"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Seleccione el código de teléfono de tú país",
                                  },
                                ]}
                              >
                                <Select
                                  showSearch
                                  style={{ textAlign: "left", width: "90px" }}
                                >
                                  {refPhoneCodeArr.current.map(
                                    (phoneCode, index) => {
                                      return (
                                        <Select.Option
                                          key={index}
                                          value={`+${phoneCode}`}
                                        >
                                          {`+${phoneCode}`}
                                        </Select.Option>
                                      );
                                    }
                                  )}
                                </Select>
                              </Form.Item>

                              {form.getFieldsValue().requisitionObj?.requisition
                                .signers[name]?.isDynamic ? (
                                <>
                                  <Form.Item
                                    {...restField}
                                    label="Ingresa tu número de teléfono"
                                    name={[name, "phone"]}
                                    hasFeedback
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Debes escribir tú número de teléfono.",
                                      },
                                    ]}
                                  >
                                    <Select
                                      mode="multiple"
                                      style={{ width: "100%" }}
                                      onChange={(e) => {
                                        setListaTelefonoUno(e);
                                      }}
                                    >
                                      {store.cuenta.formularios
                                        .find(
                                          (e) =>
                                            e._id ===
                                            props.form.getFieldValue(
                                              "formulario"
                                            )
                                        )
                                        ?.campos.map((e) => (
                                          <Select.Option
                                            value={`[${e.name}]`}
                                            disabled={
                                              listaTelefonoUno.length > 0
                                                ? listaTelefonoUno.includes(
                                                    `[${e.name}]`
                                                  )
                                                  ? false
                                                  : true
                                                : false
                                            }
                                          >{`[${e.name}]`}</Select.Option>
                                        ))}
                                    </Select>
                                  </Form.Item>
                                </>
                              ) : (
                                <>
                                  <Form.Item
                                    {...restField}
                                    label="Ingresa tu número de teléfono"
                                    name={[name, "phone"]}
                                    hasFeedback
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Debes escribir tú número de teléfono.",
                                      },
                                    ]}
                                  >
                                    <Input></Input>
                                  </Form.Item>
                                </>
                              )}
                            </div>
                          </div>

                          <div>
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <Form.Item
                                style={{ marginRight: "50px" }}
                                name={[name, "signDeviceCountryCode"]}
                                label="Ingresa el indicativo de tu país"
                                initialValue="+52"
                                rules={[
                                  {
                                    require: false,
                                    message:
                                      "Seleccione el código de tu segundo número de teléfono de tú país",
                                  },
                                ]}
                              >
                                <Select
                                  showSearch
                                  style={{ textAlign: "left", width: "90px" }}
                                >
                                  {refPhoneCodeArr.current.map(
                                    (phoneCode, index) => {
                                      return (
                                        <Select.Option
                                          key={index}
                                          value={`+${phoneCode}`}
                                        >
                                          {`+${phoneCode}`}
                                        </Select.Option>
                                      );
                                    }
                                  )}
                                </Select>
                              </Form.Item>

                              {form.getFieldsValue().requisitionObj?.requisition
                                .signers[name]?.isDynamic ? (
                                <>
                                  <Form.Item
                                    {...restField}
                                    label="Ingresa tu segundo número de teléfono"
                                    name={[name, "signDevicePhone"]}
                                    hasFeedback
                                    rules={[
                                      {
                                        required: false,
                                        message:
                                          "Debes escribir tú segundo número de teléfono.",
                                      },
                                    ]}
                                  >
                                    <Select
                                      mode="multiple"
                                      style={{ width: "100%" }}
                                      onChange={(e) => {
                                        setListaTelefonoDos(e);
                                      }}
                                    >
                                      {store.cuenta.formularios
                                        .find(
                                          (e) =>
                                            e._id ===
                                            props.form.getFieldValue(
                                              "formulario"
                                            )
                                        )
                                        ?.campos.map((e) => (
                                          <Select.Option
                                            value={`[${e.name}]`}
                                            disabled={
                                              listaTelefonoDos.length > 0
                                                ? listaTelefonoDos.includes(
                                                    `[${e.name}]`
                                                  )
                                                  ? false
                                                  : true
                                                : false
                                            }
                                          >{`[${e.name}]`}</Select.Option>
                                        ))}
                                    </Select>
                                  </Form.Item>
                                </>
                              ) : (
                                <>
                                  <Form.Item
                                    {...restField}
                                    label="Ingresa tu segundo número de teléfono"
                                    name={[name, "signDevicePhone"]}
                                    hasFeedback
                                    rules={[
                                      {
                                        required: false,
                                        message:
                                          "Debes escribir tú segundo número de teléfono.",
                                      },
                                    ]}
                                  >
                                    <Input></Input>
                                  </Form.Item>
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      );
                    }}
                  </Form.Item>

                  <Form.Item
                    label="Seleccione el tipo de autenticación"
                    name={[name, "authenticationType"]}
                    rules={[
                      {
                        required: true,
                        message: "Debes selccionar el tipo de autenticacion",
                      },
                    ]}
                  >
                    <Select>
                      {refAutenticacion.current.map((opciones, i) => {
                        return (
                          <>
                            <Select.Option key={i} value={opciones.valor}>
                              {opciones.valorMostrar}
                            </Select.Option>
                          </>
                        );
                      })}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name={[name, "authenticationType"]}
                    hidden
                  ></Form.Item>

                  <Typography.Title level={4}>
                    Coordenadas del Campo de la Firma
                  </Typography.Title>

                  <Form.Item
                    name={[name, "signatures", "positionX1"]}
                    label="Escribe la primera coordenada, si es decimal va con punto "
                    rules={[
                      {
                        required: true,
                        message: "Debes escribir la coordenada.",
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      placeholder="llx"
                    />
                  </Form.Item>
                  <Form.Item
                    name={[name, "signatures", "positionY1"]}
                    rules={[
                      {
                        required: true,
                        message: "Debes escribir la coordenada.",
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      placeholder="lly"
                    />
                  </Form.Item>

                  <Form.Item
                    name={[name, "signatures", "positionX2"]}
                    label="Escribe la segunda coordenada, si es decimal va con punto "
                    rules={[
                      {
                        required: true,
                        message: "Debes escribir la coordenada.",
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      placeholder="urx"
                    />
                  </Form.Item>
                  <Form.Item
                    name={[name, "signatures", "positionY2"]}
                    rules={[
                      {
                        required: true,
                        message: "Debes escribir la coordenada.",
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      placeholder="ury"
                    />
                  </Form.Item>

                  <Form.Item
                    label={`Escribe el número de la página donde va la firma del firmante ${
                      name + 1
                    }`}
                    name={[name, "signatures", "page"]}
                    rules={[
                      {
                        required: true,
                        message: "Debes escribir el número de la página .",
                      },
                    ]}
                  >
                    <InputNumber style={{ width: "100%" }} min={0} />
                  </Form.Item>

                  <Form.Item
                    name={[name, "signatures", "signerType"]}
                    label="Escribe tú rol como firmante del contrato" // (x2)
                    initialValue="Firmante"
                  >
                    <Input disabled />
                  </Form.Item>

                  <Typography.Title level={4}>
                    Validaciones de seguridad
                  </Typography.Title>
                  <Form.Item
                    initialValue=""
                    style={{ width: "100%" }}
                    name={[name, "acceptanceVideoNotRequired"]}
                    label="Seleccione la activación del videoacuerdo dentro del proceso
                    de firma."
                    rules={[
                      {
                        required: true,
                        message:
                          "Debes seleccionar alguna de las opciones por favor.",
                      },
                    ]}
                  >
                    <Select>
                      {refNotViudeoRerquired.current.map((opciones, i) => {
                        return (
                          <>
                            <Select.Option key={i} value={opciones.valor}>
                              {opciones.valorMostrar}
                            </Select.Option>
                          </>
                        );
                      })}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    style={{ width: "100%" }}
                    label="Seleccione si es necesario
                    generar la captura fotográfica del frente de la
                    identificación presentada."
                    rules={[
                      {
                        required: true,
                        message:
                          "Debes seleccionar alguna de las opciones por favor.",
                      },
                    ]}
                    name={[name, "inePictureFront"]}
                    initialValue={""}
                  >
                    <Select>
                      {refSeguridadRequired.current.map((opciones, i) => {
                        return (
                          <>
                            <Select.Option key={i} value={opciones.valor}>
                              {opciones.valorMostrar}
                            </Select.Option>
                          </>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Seleccione si es necesario
                     generar la captura fotográfica del reverso de la
                     identificación presentada."
                    style={{ width: "100%" }}
                    name={[name, "inePictureBack"]}
                    initialValue={""}
                    rules={[
                      {
                        required: true,
                        message:
                          "Debes seleccionar alguna de las opciones por favor.",
                      },
                    ]}
                  >
                    <Select>
                      {refSeguridadRequired.current.map((opciones, i) => {
                        return (
                          <>
                            <Select.Option key={i} value={opciones.valor}>
                              {opciones.valorMostrar}
                            </Select.Option>
                          </>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Seleccione si en el proceso se lleva a cabo la
                    comparación biométrica de la selfie con la foto
                    de la identificación oficial del firmante prueba
                    de vida."
                    style={{ width: "100%" }}
                    name={[name, "compareIneSelfie"]}
                    initialValue={""}
                    rules={[
                      {
                        required: true,
                        message:
                          "Debes seleccionar alguna de las opciones por favor.",
                      },
                    ]}
                  >
                    <Select>
                      {refSeguridadRequired.current.map((opciones, i) => {
                        return (
                          <>
                            <Select.Option key={i} value={opciones.valor}>
                              {opciones.valorMostrar}
                            </Select.Option>
                          </>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Seleccione si en el proceso se lleva a cabo la
                    comparación biométrica de la selfie y el firmante."
                    rules={[
                      {
                        required: true,
                        message:
                          "Debes seleccionar alguna de las opciones por favor.",
                      },
                    ]}
                    style={{ width: "100%" }}
                    name={[name, "compareSelfieProof"]}
                    initialValue={""}
                  >
                    <Select>
                      {refSeguridadRequired.current.map((opciones, i) => {
                        return (
                          <>
                            <Select.Option key={i} value={opciones.valor}>
                              {opciones.valorMostrar}
                            </Select.Option>
                          </>
                        );
                      })}
                    </Select>
                  </Form.Item>

                  <Button
                    onClick={() => remove(name)}
                    style={{ marginBottom: "12px" }}
                    type="dashed"
                  >
                    Eliminar Firmante <MinusCircleOutlined />{" "}
                  </Button>
                </Space>
              );
            })}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Agregar firmantes
              </Button>
            </Form.Item>
          </>
        )}
      </FormList>

      <Form.Item
        hidden
        name={["requisitionObj", "requisition", "signOnWeb"]}
        initialValue={true}
      ></Form.Item>

      <FormList
        name={["requisitionObj", "requisition", "mailResend"]}
        initialValue={[]}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => {
              return (
                <Space
                  key={key}
                  direction="vertical"
                  size="middle"
                  style={{ display: "flex" }}
                >
                  <Form.Item
                    {...restField}
                    label="Escribe tú correo electrónico adicional"
                    name={[name, "email"]}
                    rules={[
                      {
                        type: "email",
                        required: true,
                        message: "Debes escribir tú correo electrónico",
                      },
                    ]}
                    style={{ marginBottom: "1px" }}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                  <Button
                    onClick={() => remove(name)}
                    type="dashed"
                    style={{ marginBottom: "12px" }}
                  >
                    Eliminar Correo electrónico <MinusCircleOutlined />{" "}
                  </Button>
                </Space>
              );
            })}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                {"Agregar más correos electrónicos (Opcional)"}
              </Button>
            </Form.Item>
          </>
        )}
      </FormList>

      <FormList
        name={["requisitionObj", "requisition", "extraInfo"]}
        initialValue={[]}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => {
              return (
                <Space
                  key={key}
                  direction="vertical"
                  size="middle"
                  style={{ display: "flex" }}
                >
                  <Form.Item
                    {...restField}
                    label="Escribe la informacion extra que crees necesaria"
                    name={[name, "extraInfo.property"]}
                    rules={[
                      {
                        required: true,
                        message: "Debes escribir la información extra",
                      },
                    ]}
                    style={{ marginBottom: "1px" }}
                  >
                    <TextArea style={{ width: "100%" }} />
                  </Form.Item>
                  <Button
                    onClick={() => remove(name)}
                    type="dashed"
                    style={{ marginBottom: "12px" }}
                  >
                    Eliminar información extra <MinusCircleOutlined />{" "}
                  </Button>
                </Space>
              );
            })}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
                style={{ marginBottom: "12px" }}
              >
                {"Agregar información extra (Opcional)"}
              </Button>
            </Form.Item>
          </>
        )}
      </FormList>
    </>
  );
};

export default CamposPersonalizados;
