import React from "react";
import { Button, Popover, Input, Space, Modal, message, Checkbox } from "antd";
import "antd/dist/antd.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { Api } from "../../../../../../../api/configApi";
import { setStoreCargando } from "../../../../../../../store";

const CampoUrlComponente = (props) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [value, setValue] = React.useState(props.valor);
  const refValue = React.useRef(props.valor);
  const refValueCorto = React.useRef("");
  const [activarDisparadores, setActivarDisparadores] = React.useState(false);

  const actualizar = (data) => {
    Api(
      "listados",
      "UpdateItem",
      {
        actualizarItem: data,
      },
      {},
      "https://f399-181-132-3-197.ngrok.io"
    ).then((res) => {
      message.success("Se actualizó el campo");
      props.Get();
    });
  };

  const acortador = () => {
    // console.log("aquí voy")
    // console.log(props)
    // console.log(refValue.current)
    const listaLetras = refValue.current.split("");
    const listaResumida = [];
    listaLetras.forEach((letra, index) => {
      if (index < 35) {
        listaResumida.push(letra);
      }
    });
    refValueCorto.current = listaResumida.join("");
    setValue(refValueCorto.current);
  };

  React.useEffect(() => {
    acortador();
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    if (
      (refValue.current && refValue.current !== props.valor) ||
      activarDisparadores
    ) {
      setValue(refValue.current);
      actualizar({
        valor: refValue.current,
        idCampo: props.id,
        idListado: props.idListado,
        nombreCampo: props.nombreCampo,
        activarDisparadores: activarDisparadores,
      });
      acortador();
      setActivarDisparadores(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setActivarDisparadores(false);
  };

  const link = () => {
    window.open(refValue.current);
  };

  return (
    <>
      {props.valor !== "" ? (
        <p onClick={showModal}>{value}</p>
      ) : (
        <p onClick={showModal} style={{ color: "#ababab" }}>
          Asignar URL
        </p>
      )}

      <Modal
        title="Modificar URL"
        visible={isModalVisible}
        onOk={handleOk}
        destroyOnClose
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Guardar
          </Button>,
        ]}
      >
        <Input
          type="url"
          defaultValue={refValue.current}
          onChange={(e) => (refValue.current = e.target.value)}
          suffix={
            <FontAwesomeIcon onClick={link} icon={faArrowUpRightFromSquare} />
          }
        />
        <Checkbox
          style={{ marginTop: 10 }}
          value={activarDisparadores}
          onChange={(e) => setActivarDisparadores(e.target.checked)}
        >
          Activar Disparadores
        </Checkbox>
      </Modal>
    </>
  );
};

export default CampoUrlComponente;
