import { Select } from "antd";
import React from "react";
import { store } from "../../../../store";

function AgregarWidgetaFilaComponente(props) {
  const { Option } = Select;
  const [widgetsFiltrados, setWidgetsFiltrados] = React.useState([]);

  function onChange(value) {
    // console.log(value);
    const newWidgets = [...props.widgets];
    newWidgets[props.indexfila].push({ ...store.widgets.find((wid) => wid._id === value) });
    props.setWidgets(newWidgets);
  }

  function onBlur() {
    // console.log("blur");
  }

  function onFocus() {
    const widgets = store.widgets;
    const widgetsEnFila = props.fila;
    const nuevosWidgets = [];
    widgets.forEach((widget) => {
      if (
        !widgetsEnFila.some(
          (widgetEnFila) => widgetEnFila.titulo === widget.titulo
        )
      ) {
        nuevosWidgets.push(widget);
      }
    });
    setWidgetsFiltrados(nuevosWidgets);
  }

  function onSearch(val) {
    // console.log("search:", val);
  }

  return (
    <div style={{ margin: 5 }}>
      <Select
        showSearch
        defaultActiveFirstOption={false}
        style={{ width: 300 }}
        placeholder="Agregar Nuevo Widget a esta fila"
        optionFilterProp="children"
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onSearch={onSearch}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {widgetsFiltrados.map((widget, index) => {
          return (
            <Option disabled={false} value={widget._id} key={widget._id}>
              {widget.titulo}
            </Option>
          );
        })}
      </Select>
    </div>
  );
}

export default AgregarWidgetaFilaComponente;
