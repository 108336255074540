import { Input, Form } from "antd";
import Boton from "../../components/design/elementos/boton";

function RenderLicencia() {
  return (
    <>
      <Form>
        <Form.Item
          name="licencia"
          rules={[{ required: true, message: "Campo obligatorio" }]}
        >
          <Input placeholder="Licencia No Activada" />
        </Form.Item>
        <Form.Item
          name="dominio"
          rules={[{ required: true, message: "Campo obligatorio" }]}
        >
          <Input addonBefore="https://" placeholder="Dominio Autorizado" />
        </Form.Item>
        <Boton texto="Guardar" htmlType="submit" />
      </Form>
    </>
  );
}

export default RenderLicencia;
