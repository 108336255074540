import React from "react";
import { Button, Form, Input, message, Select, Space } from "antd";
import { Api } from "../../../../../api/configApi";
import { setStoreActualizarLaEstructura } from "../../../../../store";

function CambiarNombreDescripcionAtributo({
  atributoActual,
  setEditarAtributo,
}) {
  const [nombreAnterior, setNombreAnterior] = React.useState("");
  const [guardando, setGuardando] = React.useState(false);
  const [descripcion, setDescripcion] = React.useState("");
  const [form] = Form.useForm();

  const CambiarNombre = ({ Nombre, Descripcion, tags }) => {
    const nombreNuevo = Nombre;

    function noTiene() {
      let tiene = false;
      if (
        nombreNuevo.includes(".") ||
        nombreNuevo.includes("$") ||
        nombreNuevo.includes("#") ||
        nombreNuevo.includes("%") ||
        nombreNuevo.includes("&") ||
        nombreNuevo.includes("/") ||
        nombreNuevo.includes("\\") ||
        nombreNuevo.includes("^") ||
        nombreNuevo.includes("*") ||
        nombreNuevo.includes("+") ||
        nombreNuevo.includes("=") ||
        nombreNuevo.includes("-") ||
        nombreNuevo.includes("_") ||
        nombreNuevo.includes("|") ||
        nombreNuevo.includes(")") ||
        nombreNuevo.includes(",") ||
        nombreNuevo.includes("(") ||
        nombreNuevo.includes('"') ||
        nombreNuevo.includes("~")
      ) {
        tiene = true;
        message.error("El nombre no puede contener caracteres especiales");
      }

      if (nombreNuevo.length < 3 || nombreNuevo.length > 25) {
        tiene = false;
        message.error("El nombre debe tener entre 3 y 25 caracteres");
      }

      return tiene;
    }
    if (!noTiene()) {
      setGuardando(true);
      atributoActual.nombre = nombreNuevo;
      Api(
        "Cuentas",
        "actualizaAtributoConfig",
        { actualizar: "nombre" },
        {
          nombreAnterior: nombreAnterior,
          nombreNuevo: nombreNuevo,
          idAtributo: atributoActual._id,
          descripcion: Descripcion,
          tags: tags,
        }
      ).then((res) => {
        setStoreActualizarLaEstructura();
        setTimeout(() => {
          setGuardando(false);
          message.success("Nombre cambiado con éxito");
          setEditarAtributo(false);
        }, 2000);
      });
    }
  };

  React.useEffect(() => {
    setNombreAnterior(atributoActual.nombre);
    setDescripcion(atributoActual.descripcion);
    form.setFieldsValue({
      Nombre: atributoActual.nombre,
      Descripcion: atributoActual.descripcion,
      tags: atributoActual.tags,
    });
  }, []);
  return (
    <>
      <Form form={form} onFinish={CambiarNombre} layout="vertical">
        <Form.Item
          rules={[{ required: true, message: "Campo obligatorio" }]}
          name="Nombre"
          label="Nombre"
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[
            { required: true, message: "Campo obligatorio" },
            { min: 7, message: "Minimo 7 caracteres" },
          ]}
          name="Descripcion"
          label="Descripcion"
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item name="tags" label="Etiquetas">
          <Select
            mode="tags"
            notFoundContent={<span>Agrega una nueva etiqueta</span>}
            open={false}
          />
        </Form.Item>
        <Space>
          <Button onClick={() => setEditarAtributo(false)} loading={guardando}>
            Cancelar
          </Button>
          <Button loading={guardando} htmlType="submit">
            Guardar
          </Button>
        </Space>
      </Form>
    </>
  );
}

export default CambiarNombreDescripcionAtributo;
