
const listaDatos = [





    {
      nombreFuncion: "ms0_consultaAfiliados",
      tituloFuncion: "Consulta afiliados sobre un listado",
      descripcionFuncion:
        "Consulta la cantidad de afiliados que tienen items con campos de un listados con condiciones",
      grupoCampos: [
        {
          tipo: "ListarListados",
          nombreListado: "idListado",
          labelNombreListado: "Selecciona un listado",
          valorCampoListener: "valor"
        },
        {
          tipo: "CompararCamposListadoMulti",
          nombreCampo: "idCampo",
          labelNombreCampo: "Selecciona un campo",
          operacionRelacional: "operacionRelacional",
          labelOperacionRelacional: "Selecciona una operación relacional",
          valorCampo: "valor",
          labelValorCampo: "Ingrese un valor",
          dependencia: "idListado",
          filtro: "todos"
        },
      ]
    },
  
    {
      nombreFuncion: "ms0_consultaItemsList",
      tituloFuncion: "Consulta items de un listado",
      descripcionFuncion:
        "Consulta cantidad de items de un listado de uno o todos los afiliados con campos de un listados con condiciones",
      grupoCampos: [
        {
          tipo: "ListarListados",
          nombreListado: "idListado",
          labelNombreListado: "Selecciona un listado"
        },
        {
          tipo: "CompararCamposListadoMulti",
          nombreCampo: "idCampo",
          labelNombreCampo: "Selecciona un campo",
          operacionRelacional: "operacionRelacional",
          labelOperacionRelacional: "Selecciona una operación relacional",
          valorCampo: "valor",
          labelValorCampo: "Ingrese un valor",
          dependencia: "idListado",
          filtro: "todos"
        },
        {
          tipo: "Interruptor",
          interruptor: "afiliadoActual",
          labelInterruptor: "Aplicar solo al afiliado actual",
        },
      ]
    },
  
    {
      nombreFuncion: "ms0_consultCampoList",
      tituloFuncion: "Consulta un campo tipo número de un listado",
      descripcionFuncion:
        "Totaliza y promedia el valor de un campo tipo número de los items de un listado de uno o todos los afiliados, además calcula la cantidad de items que tienen esas condiciones",
      grupoCampos: [
        {
          tipo: "ListarListados",
          nombreListado: "idListado",
          labelNombreListado: "Selecciona un listado",
          valorCampoListener: "valor"
        },
        {
          tipo: "CompararCamposListado",
          nombreCampo: "idCampo",
          labelNombreCampo: "Selecciona un campo",
          operacionRelacional: "operacionRelacional",
          labelOperacionRelacional: "Selecciona una operación relacional",
          valorCampo: "valor",
          labelValorCampo: "Ingrese un valor",
          dependencia: "idListado",
          filtro: "todos"
        },
        {
          tipo: "Interruptor",
          interruptor: "afiliadoActual",
          labelInterruptor: "Aplicar solo al afiliado actual",
        },
        {
          tipo: "ListarOpciones",
          nombreOpcion: "tipoOperacion",
          labelNombreOpcion: "Elige un tipo de operación",
          listadoOpciones: [{valor: "total", valorMostrar: "Total"}, {valor: "promedio", valorMostrar: "Promedio"}, {valor: "cantidad", valorMostrar: "Cantidad"}],
        },
      ]
    },
  
    {
      nombreFuncion: "ms0_consultaCampoListDate",
      tituloFuncion: "Consulta campos tipo número de un listado en un rango de fechas",
      descripcionFuncion:
        "Totaliza y promedia el valor de un campo tipo numero de los items de un listado de uno o todos los afiliados, ademas calcula la cantidad de items que tienen esas condiciones, todo esto dentro de un rango de fechas",
      grupoCampos: [
        {
          tipo: "ListarListados",
          nombreListado: "idListado",
          labelNombreListado: "Selecciona un listado",
          valorCampoListener: "valor"
        },
        {
          tipo: "CompararCamposListado",
          nombreCampo: "idCampo",
          labelNombreCampo: "Selecciona un campo",
          operacionRelacional: "operacionRelacional",
          labelOperacionRelacional: "Selecciona una operación relacional",
          valorCampo: "valor",
          labelValorCampo: "Ingrese un valor",
          dependencia: "idListado",
          filtro: "numero"
        },
        {
          tipo: "Interruptor",
          interruptor: "afiliadoActual",
          labelInterruptor: "Aplicar solo al afiliado actual",
        },
        {
          tipo: "ListarOpciones",
          nombreOpcion: "tipoOperacion",
          labelNombreOpcion: "Elige un tipo de operación",
          listadoOpciones: [{valor: "total", valorMostrar: "Total"}, {valor: "promedio", valorMostrar: "Promedio"}, {valor: "cantidad", valorMostrar: "Cantidad"}],
        },
  
        {
          tipo: "RangoFechas",
          rangoFechas: "rangoFechas",
          labelRangoFechas: "Elige el periodo de tiempo de la consulta",
        },
      ]
    },
  
    {
      nombreFuncion: "ms0_consultaAfiliadosDate",
      tituloFuncion: "Consulta afiliados sobre un listado en un rango de fechas",
      descripcionFuncion:
        "Consulta la cantidad de afiliados que tienen items con campos de un listados con condiciones en un rango de fechas",
      grupoCampos: [
        {
          tipo: "ListarListados",
          nombreListado: "idListado",
          labelNombreListado: "Selecciona un listado",
          valorCampoListener: "valor"
        },
        {
          tipo: "CompararCamposListadoMulti",
          nombreCampo: "idCampo",
          labelNombreCampo: "Selecciona un campo",
          operacionRelacional: "operacionRelacional",
          labelOperacionRelacional: "Selecciona una operación relacional",
          valorCampo: "valor",
          labelValorCampo: "Ingrese un valor",
          dependencia: "idListado",
          filtro: "todos"
        },
        {
          tipo: "RangoFechas",
          rangoFechas: "rangoFechas",
          labelRangoFechas: "Elige el periodo de tiempo de la consulta",
        },
      ]
    },
  
    {
      nombreFuncion: "mj8_consultaAfiliados",
      tituloFuncion: "Consulta afiliados sobre atributos",
      descripcionFuncion:
        "Consulta la cantidad de afiliados con atributos de cualquier tipo con condiciones",
      grupoCampos: [
        {
          tipo: "CompararAtributosMultiConAtrib",
          nombreAtributo: "atributo",
          labelNombreAtributo: "Escoge un atributo",
          operacionRelacional: "operacionRelacional",
          labelOperacionRelacional: "Selecciona una operación relacional",
          valorAtributo: "valor",
          labelValorAtributo: "Ingrese un valor",
          filtro: "todos"
        },
        
      ]
    },
  
    {
      nombreFuncion: "mj8_consultAttrActAfiliado",
      tituloFuncion: "Consulta valores actuales de un atributo",
      descripcionFuncion:
        "Consulta los valores actuales de los atributos de un afiliado",
      grupoCampos: [
        {
          tipo: "ListarAtributos",
          nombreAtributo: "atributo",
          labelNombreAtributo: "Selecciona un atributo",
          filtro: "todos"
        },
        
      ]
    },
  
    {
      nombreFuncion: "mj8_consultAttrAfiliado",
      tituloFuncion: "Consulta atributo tipo número",
      descripcionFuncion:
        "Totaliza y promedia los atributos tipo número de todos los afiliados, y realiza un conteo de los afiliados.",
      grupoCampos: [
        {
          tipo: "ListarAtributos",
          nombreAtributo: "atributo",
          labelNombreAtributo: "Selecciona un atributo",
          valorAtributoListener: "valor",
          filtro: "numero"
        },
        {
          tipo: "CompararAtributos",
          nombreAtributo: "atributo",
          operacionRelacional: "operacionRelacional",
          labelOperacionRelacional: "Selecciona una operación relacional",
          valorAtributo: "valor",
          labelValorAtributo: "Ingrese un valor",
        },
        {
          tipo: "ListarOpciones",
          nombreOpcion: "tipoOperacion",
          labelNombreOpcion: "Elige un tipo de operación",
          listadoOpciones: [{valor: "total", valorMostrar: "Total"}, {valor: "promedio", valorMostrar: "Promedio"}, {valor: "cantidad", valorMostrar: "Cantidad"}],
        },
        
      ]
    },
  
  
    {
      nombreFuncion: "mj8_consultaCreDebAfiliado",
      tituloFuncion: "Consulta créditos o débitos sobre un atributo",
      descripcionFuncion:
        "Consulta creditos o debitos de un atributo, en la consulta puede mostrar la cantidad, el total o el promedio de creditos o debitos en un rango de fechas",
      grupoCampos: [
        {
          tipo: "ListarAtributos",
          nombreAtributo: "atributo",
          labelNombreAtributo: "Selecciona un atributo",
          filtro: "numero"
        },
        {
          tipo: "Interruptor",
          interruptor: "afiliadoActual",
          labelInterruptor: "Aplicar solo al afiliado actual",
        },
        {
          tipo: "ListarOpciones",
          nombreOpcion: "tipoMovimiento",
          labelNombreOpcion: "Elige si es crédito o débito",
          listadoOpciones: [{valor: "credito", valorMostrar: "Crédito"}, {valor: "debito", valorMostrar: "Débito"}],
        },
        {
          tipo: "RangoFechas",
          rangoFechas: "rangoFechas",
          labelRangoFechas: "Elige el periodo de tiempo de la consulta",
        },
        {
          tipo: "ListarOpciones",
          nombreOpcion: "tipoOperacion",
          labelNombreOpcion: "Elige un tipo de operación",
          listadoOpciones: [{valor: "total", valorMostrar: "Total"}, {valor: "promedio", valorMostrar: "Promedio"}, {valor: "cantidad", valorMostrar: "Cantidad"}],
        },
      ]
    },
  
    {
      nombreFuncion: "mj8_consultaAfiliadosSum",
      tituloFuncion: "Consulta sumatoria de atributo tipo número",
      descripcionFuncion:
        "Consulta sumatoria de un atributo tipo número de uno o todos los afiliados en un rango de fechas",
      grupoCampos: [
        {
          tipo: "ListarAtributos",
          nombreAtributo: "atributo",
          labelNombreAtributo: "Selecciona un atributo",
          filtro: "numero"
        },
        {
          tipo: "Interruptor",
          interruptor: "afiliadoActual",
          labelInterruptor: "Aplicar solo al afiliado actual",
        },
        {
          tipo: "RangoFechas",
          rangoFechas: "rangoFechas",
          labelRangoFechas: "Elige el periodo de tiempo de la consulta",
        },
      ]
    },
  
    {
      nombreFuncion: "mj8_consultaAfiliadosDate",
      tituloFuncion: "Consulta afiliados sobre atributos en un rango de fechas",
      descripcionFuncion:
        "Consulta la cantidad de afiliados con atributos de cualquier tipo con condiciones en un rango de fechas",
      grupoCampos: [
        {
          tipo: "CompararAtributosMultiConAtrib",
          nombreAtributo: "atributo",
          labelNombreAtributo: "Escoge un atributo",
          operacionRelacional: "operacionRelacional",
          labelOperacionRelacional: "Selecciona una operación relacional",
          valorAtributo: "valor",
          labelValorAtributo: "Ingrese un valor",
          filtro: "todos"
        },
        {
          tipo: "RangoFechas",
          rangoFechas: "rangoFechas",
          labelRangoFechas: "Elige el periodo de tiempo de la consulta",
        },
        
      ]
    },
  
  
  ];
  
  export default listaDatos;
  