import { Form, Input, Button, message } from "antd";
import React from "react";
import { Api } from "../../api/configApi";

function RecuperarContrasena(props) {
  const [codigoEnviado, setCodigoEnviado] = React.useState(false);
  const [nuevaContra, setNuevaContra] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  async function recibirCodigo(values) {
    try {
      setLoading(true);
      setEmail(values.email);
      const cx = await Api("recuperarPass", "recibirCodigo", values);
      if (cx.success) {
        message.success("Revisa tu correo");
        setNuevaContra(true);
        setLoading(false);
      } else {
        setLoading(false);
        message.error("Intenta nuevamente o contacta con soporte");
      }
    } catch (error) {
      setLoading(false);
    }
  }
  async function NuevaContrasena(values) {
    if (values.password !== values.passwordRepeat)
      return message.error("Las contraseñas no son iguales");
    setLoading(true);
    values.email = email;
    const cx = await Api("recuperarPass", "actualizarPass", values);
    if (cx.success) {
      message.success(cx.mensaje);
      setLoading(false);
      props.setDrawerContra(false);
    } else {
      message.error(cx.mensaje);
      setLoading(false);
    }
  }
  return (
    <>
      {!nuevaContra ? (
        <Form layout="vertical" onFinish={recibirCodigo}>
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, message: "Campo obligatorio" }]}
          >
            <Input type="email" />
          </Form.Item>

          <Button loading={loading} htmlType="submit">
            Recibir Código
          </Button>
        </Form>
      ) : (
        <Form layout="vertical" onFinish={NuevaContrasena}>
          <Form.Item
            name="codigo"
            label="Código de seguridad"
            rules={[{ required: true, message: "Campo obligatorio" }]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="password"
            label="Nueva Contraseña"
            rules={[{ required: true, message: "Campo obligatorio" }, { min: 9, message:"Minimo 9 caracteres" }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="passwordRepeat"
            label="Repetir Contraseña"
            rules={[{ required: true, message: "Campo obligatorio" }, { min: 9, message:"Minimo 9 caracteres" }]}
          >
            <Input.Password />
          </Form.Item>
          <Button loading={loading} htmlType="submit">
            Cambiar Contraseña
          </Button>
          <div
            style={{
              marginTop: 20,
              textAlign: "center",
              color: "#167dc9",
              cursor: "pointer",
            }}
          >
            <span
              onClick={() => {
                setNuevaContra(false);
              }}
            >
              Solicitar nuevo código
            </span>
          </div>
        </Form>
      )}
    </>
  );
}

export default RecuperarContrasena;
