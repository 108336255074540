import {
  CheckCircleOutline,
  DeleteOutline,
  EditOutlined,
  FileCopyOutlined,
  SettingsOutlined,
} from "@material-ui/icons";
import { Card, Divider, message, Popconfirm } from "antd";
import React from "react";
import { Api } from "../../api/configApi";
import { store } from "../../store";

function ListaGruposPulpitos({
  grupos,
  Get,
  setDuplicado,
  setDrawerNuevoGrupo,
}) {
  function eliminar(id) {
    Api(
      "Cuentas",
      "DeletePulpitos",
      { _id: store.cuentaactiva },
      { pulpito: id }
    ).then((res) => {
      if (res.success) {
        Get();
        message.success("Pulpito eliminado");
      } else {
        message.error("Algo no salio bien.");
      }
    });
  }
  function activar(id) {
    Api("Pulpitos", "ActivarGrupoPulpitos", { grupo: id }).then((res) => {
      if (res.success) {
        message.success("Grupo activado");
      } else {
        message.error("Algo no salio bien");
      }
    });
  }
  return (
    <>
      {grupos.map((resp) => {
        return (
          <Card
            actions={[
              <Popconfirm
                onConfirm={() => {
                  activar(resp._id);
                }}
                title={
                  <span>
                    Eliminaremos todos los pulpitos y crearemos nuevos con la
                    configuración de este grupo
                  </span>
                }
              >
                <CheckCircleOutline key="activar" />
              </Popconfirm>,

              <FileCopyOutlined
                onClick={() => {
                  setDuplicado(resp);
                  setDrawerNuevoGrupo(true);
                }}
                key="editar"
              />,
              <DeleteOutline
                onClick={() => {
                  eliminar(resp._id);
                }}
                key="eliminar"
              />,
            ]}
            style={{
              marginBottom: 10,
              marginTop: 10,
            }}
            title={resp.nombre}
          >
            <div
              style={{
                marginTop: 10,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexWrap: "wrap",
                  flexDirection: "column",
                  marginRight: 5,
                }}
              >
                <div style={{ marginBottom: 10 }}>
                  Principales:
                  <span style={{ fontSize: 15 }}> {resp.cantidad}</span>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {resp.atributosPropios.map((res) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          padding: 10,
                          background: "#f9f9f9",
                          borderRadius: 5,
                          margin: 5,
                        }}
                      >
                        <span style={{ fontSize: 15 }}>{res.valor}</span>
                        <Divider style={{ marginBottom: 5, marginTop: 5 }} />
                        <span>{res.atributo}</span>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  flex: 1,
                  flexDirection: "column",
                  marginLeft: 5,
                }}
              >
                <div style={{ marginBottom: 10 }}>
                  Red: <span style={{ fontSize: 15 }}> {resp.cantidadRed}</span>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {resp.atributosRed.map((res) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          padding: 10,
                          background: "#f9f9f9",
                          borderRadius: 5,
                          margin: 5,
                        }}
                      >
                        <span style={{ fontSize: 15 }}>{res.valor}</span>
                        <Divider style={{ marginBottom: 5, marginTop: 5 }} />
                        <span>{res.atributo}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </Card>
        );
      })}
    </>
  );
}

export default ListaGruposPulpitos;
