import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons";
import ReactTags from "react-tag-autocomplete";
import { Api } from "../api/configApi";
import { store } from "../store";
import { Button, IconButton } from "@material-ui/core";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import LeaderLine from "react-leader-line";

const Inputcondicional = (props) => {
  const [condicional, Setcondicional] = React.useState("");
  const [lastags, setTags] = React.useState([]);
  const [placeholderOpciones, setPlaceholderOpciones] = React.useState();
  const [objetoAcciones, setObjetoAcciones] = React.useState([]);
  const [sugerencias, setSugerencias] = React.useState();
  const inputTags = React.useRef();
  const [parametroAbierto, setParametroabierto] = React.useState(false);
  const [opcionesAtributoActual, setOpcionesAtributoActual] = React.useState(
    ""
  );
  const [campoAtributoActual, setCampoAtributoActual] = React.useState("");

  const guardar = () => {
    Api("etapas", "Insert", {
      cuenta: store.cuentaactiva,
      consultas: lastags,
    }).then((res) => {
      // console.log(res);
    });
  };

  const actualizarSugerencias = (tipo, element, campo) => {
    const sugere = [];
    switch (tipo) {
      case "filtros":
        store.losfiltros.forEach((filtro) => {
          var newid = "id" + Math.floor(Math.random() * (100000 - 1000)) + 1000;
          sugere.push({
            id: newid,
            config: filtro.config,
            name: `${filtro.nombre} son`,
            tipo: "atributo number",
          });
        });
        setSugerencias(sugere);
        break;
      case "atributos":
        store.losatributos.texts.forEach((atri) => {
          var newid = "id" + Math.floor(Math.random() * (100000 - 1000)) + 1000;
          sugere.push({
            id: newid,
            name: `Su ${atri.nombre} es`,
            tipo: "atributo text",
            opciones: atri.opciones,
            campo: atri.nombre,
          });
        });
        if (lastags.length === 0) {
          var newid = "id" + Math.floor(Math.random() * (100000 - 1000)) + 1000;
          sugere.push({
            id: newid,
            name: `Su Red tiene`,
            tipo: "red",
          });
        }

        store.losatributos.numbers.forEach((atri) => {
          var newid = "id" + Math.floor(Math.random() * (100000 - 1000)) + 1000;
          sugere.push({
            id: newid,
            name: `Sus ${atri.nombre} son`,
            tipo: "atributo number",
            campo: atri.nombre,
          });
          sugere.push({
            id: newid,
            name: `Sus ${atri.nombre} filtrados por`,
            tipo: "atributo number filtrado",
            campo: atri.nombre,
          });
        });
        setSugerencias(sugere);
        break;
      case "text":
        var a = "";
        var s = [];
        var newid = "id" + Math.floor(Math.random() * (100000 - 1000)) + 1000;
        element.forEach((el) => {
          a = a + " / " + el;
          s.push({
            id: newid,
            name: el,
            tipo: "valor text",
            valor: el,
            class: "atributoNumero",
          });
        });
        setPlaceholderOpciones(a);
        setSugerencias(s);
        break;
      default:
        break;
    }
  };

  React.useEffect(() => {
    // console.log(lastags);
    const ultimaTag = lastags[lastags.length - 1];
    setParametroabierto(true);
    if (
      ultimaTag === undefined ||
      ultimaTag.tipo === "and" ||
      ultimaTag.tipo === "que"
    ) {
      actualizarSugerencias("atributos");
      setPlaceholderOpciones("Su / Sus");
      return;
    }

    const tagRf = ultimaTag.tipo;
    switch (tagRf) {
      case "atributo number":
        setSugerencias([
          { id: 9888, name: "iguales a", tipo: "number", operador: "eq" },
          { id: 98882, name: "mayores a", tipo: "number", operador: "gt" },
          { id: 98833, name: "menores a", tipo: "number", operador: "lt" },
          {
            id: 988433,
            name: "iguales o menores a",
            tipo: "number",
            operador: "lte",
          },
          {
            id: 9838433,
            name: "iguales o mayores a",
            tipo: "number",
            operador: "gte",
          },
        ]);
        setPlaceholderOpciones(
          " iguales a  / mayores a  / menores a / iguales o mayores a / iguales o menores a"
        );
        break;

      case "valor text":
      case "valor number":
        setPlaceholderOpciones(" Y (Puedes agregar más condiciones)");
        setSugerencias([
          { id: 849834243, name: "Y", tipo: "and", class: "and" },
        ]);
        break;
      case "atributo number filtrado":
        actualizarSugerencias("filtros");
        setPlaceholderOpciones("Nombre del filtro");
        break;
      case "atributo text":
        setSugerencias([
          { id: 554, name: "igual a", tipo: "text", operador: "eq" },
          { id: 5534, name: "diferente a", tipo: "text", operador: "ne" },
        ]);
        setPlaceholderOpciones(" igual a / diferente a");
        setOpcionesAtributoActual(ultimaTag.opciones);
        setCampoAtributoActual(ultimaTag.nombre);
        break;
      case "atributo list":
        setSugerencias();
        setPlaceholderOpciones("Numero de Dias / Atributo tipo Fecha");
        setParametroabierto(false);
        break;
      case "text":
        actualizarSugerencias("text", opcionesAtributoActual);
        break;

      case "number":
        setPlaceholderOpciones("Numero a comparar");
        setParametroabierto(false);
        break;
      case "cantidad afiliados":
        setPlaceholderOpciones(" QUE (Agrega condicionales a estos afiliados)");
        setSugerencias([
          { id: 849834243, name: "QUE", tipo: "que", class: "que" },
        ]);
        break;
      case "number afiliados":
        setPlaceholderOpciones("Cantidad de afiliados");
        setParametroabierto(false);
        break;
      case "red":
        setPlaceholderOpciones(" Minimo / Máximo / Exactamente");
        setSugerencias([
          {
            id: 9844488,
            name: "minimo",
            tipo: "number afiliados",
            operador: "gte",
          },
          {
            id: 984124488,
            name: "máximo",
            tipo: "number afiliados",
            operador: "lte",
          },
          {
            id: 984124488,
            name: "exactamente",
            tipo: "number afiliados",
            operador: "eq",
          },
        ]);
        break;
      default:
        setParametroabierto(true);
        setPlaceholderOpciones("");
        break;
    }
  }, [lastags]);

  const onDelete = (i) => {
    const tags = lastags.slice(0);
    tags.splice(i, 1);
    setTags(tags);
  };

  const onAddition = (tag) => {
    const tags = [].concat(lastags, tag);
    setTags(tags);
  };

  const onInput = (value) => {
    if (parametroAbierto) {
      return;
    }
    const isnumber = parseFloat(value);

    if (isnumber >= 0) {
      if (placeholderOpciones === "Numero a comparar") {
        setSugerencias([
          {
            id: 32,
            tipo: "valor number",
            name: value,
            class: "atributoNumero",
            valor: Number(value),
          },
        ]);
      } else if (placeholderOpciones === "Cantidad de afiliados") {
        setSugerencias([
          {
            id: "valorid" + Math.floor(Math.random() * (100000 - 1000)) + 1000,
            tipo: "cantidad afiliados",
            name: `${Number(value)} afiliados`,
            class: "atributoNumero",
            valor: Number(value),
            filtro: "afiliados",
          },
          {
            id: "valorid" + Math.floor(Math.random() * (100000 - 1000)) + 1000,
            tipo: "cantidad afiliados",
            name: `${Number(value)} afiliados directos`,
            class: "atributoNumero",
            valor: Number(value),
            filtro: "afiliados directos",
          },
          {
            id: "valorid" + Math.floor(Math.random() * (100000 - 1000)) + 1000,
            tipo: "cantidad afiliados",
            name: `${Number(value)} afiliados indirectos`,
            class: "atributoNumero",
            valor: Number(value),
            filtro: "afiliados indirectos",
          },
        ]);
      } else {
        setSugerencias([
          {
            id: "valorid" + Math.floor(Math.random() * (100000 - 1000)) + 1000,
            tipo: "valor number",
            name: `hace ${Number(value)} Dia(s) son`,
            class: "atributoNumero",
            valor: Number(value),
          },
        ]);
      }
    }
  };
  const crearAccion = () => {
    var line = new LeaderLine(
      document.getElementById("start"),
      document.getElementById("end"),
      { hide: true }
    );
    line.color = "#52c57b";
    line.startSocket = "bottom";
    line.endSocket = "left";
    line.size = 3;
    line.path = "grid";
    line.show("draw");
  };
  const Acciones = () => {
    return (
      <ReactTags
        value="S"
        onInput={onInput}
        ref={inputTags}
        tags={lastags}
        suggestions={sugerencias}
        onDelete={onDelete.bind(this)}
        onAddition={onAddition.bind(this)}
        placeholderText={placeholderOpciones}
        autoresize={false}
      />
    );
  };

  return (
    <>
      <div className="main-container">
        <h2>Ascenso de rango</h2>
        <span style={{ fontSize: 14, fontWeight: 100 }}>
          Aquí vamos a ascender al cabron
        </span>
        <div style={{ position: "relative" }}>
          <ReactTags
            value="S"
            onInput={onInput}
            ref={inputTags}
            tags={lastags}
            suggestions={sugerencias}
            onDelete={onDelete.bind(this)}
            onAddition={onAddition.bind(this)}
            placeholderText={placeholderOpciones}
            autoresize={false}
          />
          <Button
            onClick={() => {
              guardar();
            }}
            className="inputbotonguardar"
          >
            Guardar
          </Button>
          <IconButton
            onClick={() => {
              crearAccion();
            }}
            className="botonAccion"
          >
            <RadioButtonCheckedIcon id="start" />
          </IconButton>
          <div className="inputAcciones" id="end">
            <Acciones />
          </div>
        </div>
      </div>
    </>
  );
};

export default Inputcondicional;
