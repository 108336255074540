import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import ComponenteOpcionesFiltroDisparador from "./componenteOpcionesFiltroDisparador";

function ConfigurarFiltroDisparador(props) {
  const filtro = props.filtroActual;
  var idaleatorio = ()=>{ 
    return `Id${Math.floor(Math.random() * (100000000 - 100000)) + 1000}`
  } 
  const agregarFiltro = (e) => {
    props.agregarFiltro({
      id: idaleatorio(),
      filtro: {
        id: filtro.id,
        valor: e,
        nombre: filtro.nombre
      }
    }, props.switchIncluidos, "disparadores");
  }
  React.useEffect(() => {
    // console.log(props.filtroActual);
  }, []);
  return (
    <div>
      <FontAwesomeIcon
        style={{
          fontSize: 15,
          marginBottom: 10,
          color: "#000",
          cursor: "pointer",
        }}
        icon={faArrowLeft}
        onClick={() => {
          props.setVistaactiva(1);
        }}
      />
      <div style={{textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center"}}>
        <span>{filtro.nombre}</span>
        <span style={{fontSize: 12, maxWidth: 200, marginTop: 3}}>{filtro.descripcion}</span>
        <ComponenteOpcionesFiltroDisparador  setVistaactiva={props.setVistaactiva} setDecision={agregarFiltro} opciones={filtro.opciones} />
      </div>
    </div>
  );
}
export default ConfigurarFiltroDisparador;
