import { Divider } from "@material-ui/core";
import { Form, Input, message, Popconfirm } from "antd";
import React from "react";
import { Api } from "../../api/configApi";
import Boton from "../../components/design/elementos/boton";
import Moment from "react-moment";
import moment from "moment/min/moment-with-locales";
import { setPensando } from "../../store";
Moment.globalMoment = moment;
Moment.globalLocale = "es";

function ResponderTicketDrawer({ ticket, setVisibleTicket, GetTickets }) {
  const [comentarios, setComentarios] = React.useState([]);
  const [enviando, setEnviando] = React.useState(false);

  const [form] = Form.useForm();
  function TraerComentarios() {
    setPensando(true);
    Api("soporte", "GetComentarios", { _id: ticket._id }).then((res) => {
      setPensando(false);
      if (res.success) {
        setComentarios(res.comentarios.comentarios.reverse());
      } else {
        message.error("No pudimos obtener los comentarios");
      }
    });
  }
  React.useEffect(() => {
    setComentarios([]);
    TraerComentarios();
  }, [ticket]);
  function enviarRespuesta(values) {
    setEnviando(true);
    Api("soporte", "InsertComentario", { _id: ticket._id }, values).then(
      (res) => {
        if (res.success) {
          message.success("Comentario enviado.");
          TraerComentarios();
          form.resetFields();
          setEnviando(false);
        } else {
          setEnviando(false);
          message.error("No pudimos enviar tu comentario");
        }
      }
    );
  }
  function cerrarTicket() {
    setPensando(true);
    Api("soporte", "CerrarTicket", { _id: ticket._id }).then((res) => {
      GetTickets();
      setVisibleTicket(false);
      setPensando(false);
      message.success("Haz cerrado el ticket");
    });
  }
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {ticket.estado === "abierto" && (
        <div style={{ flex: 1 }}>
          <Popconfirm
            title="¿Estás seguro?"
            onConfirm={cerrarTicket}
            okText="Si"
            cancelText="Cancelar"
          >
            <div
              style={{
                fontSize: 14,
                color: "#b10e0e",
                textAlign: "right",
                cursor: "pointer",
              }}
            >
              Cerrar Ticket
            </div>
          </Popconfirm>

          <Form
            style={{ marginTop: 10 }}
            form={form}
            onFinish={enviarRespuesta}
          >
            <Form.Item
              rules={[
                { required: true, message: "Necesitas agregar un comentario" },
              ]}
              name="comentario"
            >
              <Input.TextArea
                style={{ height: 100 }}
                placeholder="Escribe tu comentario"
              />
            </Form.Item>
            <Boton
              loading={enviando}
              htmlType="submit"
              texto="Enviar Comentario"
            />
          </Form>

          <Divider style={{ marginTop: 20, marginBottom: 10 }} />
        </div>
      )}

      <div style={{ overflow: "auto" }}>
        {comentarios.map((comentario) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                background:
                  comentario.quien === "Soporte"
                    ? "rgb(231 255 237)"
                    : "rgb(243 243 243)",
                padding: 15,
                borderRadius: 5,
                marginTop: 10,
              }}
            >
              <strong>
                {comentario.quien === "Soporte" ? "Soporte" : comentario.quien}
              </strong>
              <Moment fromNow style={{ fontSize: 10, fontWeight: 400 }}>
                {comentario.creadoEl}
              </Moment>
              <span style={{ marginTop: 10 }}>{comentario.comentario}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ResponderTicketDrawer;
