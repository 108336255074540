import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Select,
  Switch,
} from "antd";
import React from "react";
import { store } from "../../../../store";

const { Option } = Select;

function CamposPersonalizados(props) {
  const [formatList, setFormatList] = React.useState([
    {
      format: "image/png",
      message: ".png",
    },
    {
      format: "image/jpeg",
      message: ".jpg/.jpeg",
    },
    {
      format: "application/epub+zip",
      message: ".epub",
    },
    {
      format: "application/msword",
      message: ".doc",
    },
    {
      format: "text/csv",
      message: ".csv",
    },
    {
      format: "application/pdf",
      message: ".pdf",
    },
    {
      format: "application/vnd.ms-powerpoint",
      message: ".ppt",
    },
    {
      format: "application/vnd.ms-excel",
      message: ".xls",
    },
    {
      format: "application/xml",
      message: ".xml",
    },
    {
      format: "video/x-msvideo",
      message: ".avi",
    },
    {
      format: "video/mpeg",
      message: ".mpeg",
    },
    {
      format: "application/vnd.oasis.opendocument.spreadsheet",
      message: ".ods",
    },
    {
      format: "application/vnd.oasis.opendocument.text",
      message: ".odt",
    },
    {
      format: "application/ogg",
      message: ".ogx",
    },
    {
      format: "image/svg+xml",
      message: ".svg",
    },
    {
      format: "application/x-rar-compressed",
      message: ".rar",
    },
    {
      format: "application/zip",
      message: ".zip",
    },
    {
      format: "application/x-7z-compressed",
      message: ".7z",
    },
  ]);

  const valoresFormulario = React.useRef();

  function VerificarProveedor(proveedor) {
    if (proveedor === "UploadCare") {
      if (!store.cuenta?.integraciones?.UploadCare?.secretKey) {
        message.error(
          "Para poder guardar la integración UploadCare, debes ingresar una secret key en INTEGRACIONES > UPLOADCARE"
        );
        props.formulario.setFieldsValue({
          proveedor: "",
        });
      }
    }
  }

  React.useEffect(() => {
    valoresFormulario.current = props.formulario.getFieldsValue().attributes;
  }, [props.formularioGuardado]);

  return (
    <>
      <Form.Item
        name="proveedor"
        label="Proveedor"
        rules={[{ required: true, message: "Campo obligatorio" }]}
      >
        <Select onChange={(e) => VerificarProveedor(e)}>
          <Option value="UploadCare">Upload Care</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="upMultiple"
        label="Permitir subir múltiples archivos"
        rules={[
          {
            required: true,
            message: "Campo obligatorio",
          },
        ]}
        valuePropName="checked"
        initialValue={false}
      >
        <Switch />
      </Form.Item>

      <Form.Item
        name="titleChoice"
        label="Texto del botón"
        rules={[
          {
            required: true,
            message: "Selecciona un texto para tu botón",
          },
        ]}
      >
        <Input type="text"></Input>
      </Form.Item>

      <Form.Item name="formatOptions" label="Formatos de archivos permitidos">
        <Select
          mode="multiple"
          placeholder="Por favor selecciona un formato de archivo"
          style={{ width: "100%" }}
        >
          {formatList.map((elem, index) => (
            <Option key={index} value={elem.format}>
              {elem.message}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="idListado"
        label="Listado a donde se subirá"
        rules={[
          {
            required: true,
            message: "Atributo obligatorio",
          },
        ]}
      >
        <Select
          placeholder="Por favor selecciona un listado"
          style={{ width: "100%" }}
          onSelect={(e) =>
            props.formulario.setFieldsValue({
              ["url"]: "",
            })
          }
        >
          {store.listados.map((elem, index) => {
            return (
              <Option key={index} value={elem._id}>
                {elem.nombre}
              </Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, curValues) =>
          prevValues.idListado !== curValues.idListado
        }
      >
        {(info) => {
          return (
            <>
              <Form.Item
                name={["url"]}
                rules={[
                  {
                    required: true,
                    message: "Atributo obligatorio",
                  },
                ]}
                label="Campo para el archivo"
              >
                <Select
                  placeholder="Seleccionar un campo"
                  style={{ minWidth: "200px", textAlign: "left" }}
                >
                  {info.getFieldValue().idListado !== undefined ? (
                    store.listados
                      .find((l) => l._id == info.getFieldValue().idListado)
                      .campos.filter(
                        (e) => e.tipoCampo == "url" || e.tipoCampo == "imagen"
                      )
                      .map((campo, index) => {
                        return (
                          <Option key={index} value={campo.NombreCampo}>
                            {campo.NombreCampo}
                          </Option>
                        );
                      })
                  ) : (
                    <Option value="Seleccionar un listado" disabled>
                      Seleccionar un listado
                    </Option>
                  )}
                </Select>
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.url !== curValues.url
                }
                style={{ padding: "10px", margin: "10px" }}
              >
                {(info2) => {
                  return (
                    <>
                      {info.getFieldValue().url !== undefined && (
                        <>
                          <p>Valores por defecto</p>
                          {store.listados
                            .find(
                              (l) => l._id == info.getFieldValue().idListado
                            )
                            .campos.filter(
                              (cam) =>
                                cam.NombreCampo !== info2.getFieldValue().url
                            )

                            .map((camp, index) => {
                              return (
                                <Form.Item
                                  name={["camposDefecto", camp.NombreCampo]}
                                  key={index}
                                  label={camp.NombreCampo}
                                  rules={[
                                    {
                                      required: camp.obligatorio,
                                      message: "Atributo obligatorio",
                                    },
                                  ]}
                                >
                                  {camp.tipoCampo === "texto" ? (
                                    <Input type="text"></Input>
                                  ) : camp.tipoCampo === "fecha" ? (
                                    <DatePicker
                                      format="YYYY-MM-DD hh:mm"
                                      placeholder="Seleccionar una fecha"
                                      style={{ width: "100%" }}
                                    />
                                  ) : camp.tipoCampo === "numero" ? (
                                    <InputNumber
                                      min={0}
                                      max={100}
                                      style={{ width: "100%" }}
                                    />
                                  ) : camp.tipoCampo === "multiple" ? (
                                    <Select
                                      placeholder="Seleccionar una opción"
                                      style={{ width: "100%" }}
                                    >
                                      <Option value=""></Option>
                                      {camp.opciones.map((opcion, index) => {
                                        return (
                                          <Option
                                            key={index}
                                            value={opcion.opcion}
                                          >
                                            {opcion.opcion}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  ) : camp.tipoCampo === "url" ||
                                    camp.tipoCampo === "imagen" ? (
                                    <Input
                                      type="url"
                                      placeholder="Coloca una url"
                                      style={{ width: "100%" }}
                                    />
                                  ) : (
                                    camp.tipoCampo === "switch" && (
                                      <Switch defaultChecked={false} />
                                    )
                                  )}
                                </Form.Item>
                              );
                            })}
                        </>
                      )}
                    </>
                  );
                }}
              </Form.Item>
            </>
          );
        }}
      </Form.Item>
    </>
  );
}

export default CamposPersonalizados;
