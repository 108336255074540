import Modal from "antd/lib/modal/Modal";
import { Button, Checkbox, DatePicker, message, Space } from "antd";
import React from "react";
import moment from "moment";
import Moment from "react-moment";
import "moment/locale/es";
import { Api } from "../../../../../../../api/configApi";

const CamposFechaComponente = (props) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [dateTimeString, setDateTimeString] = React.useState(props.valor);
  const refDateTimeString = React.useRef();
  const [activarDisparadores, setActivarDisparadores] = React.useState(false);

  const actualizar = (data) => {
    Api(
      "listados",
      "UpdateItem",
      {
        actualizarItem: data,
      },
      {},
      "https://f399-181-132-3-197.ngrok.io"
    ).then((res) => {
      message.success("Se actualizó el campo");
      props.Get();
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);

    if (
      (refDateTimeString.current &&
        refDateTimeString.current !== props.valor) ||
      activarDisparadores
    ) {
      setDateTimeString(refDateTimeString.current);
      actualizar({
        valor: refDateTimeString.current,
        idCampo: props.id,
        idListado: props.idListado,
        nombreCampo: props.nombreCampo,
        activarDisparadores: activarDisparadores,
      });
      setActivarDisparadores(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setActivarDisparadores(false);
  };

  Moment.globalLocale = "es";

  function cambio(fechaHora) {
    refDateTimeString.current = fechaHora.format();
    //   console.log(refDateTimeString.current)
  }

  return (
    <>
      {props.valor !== "1970-01-01T18:34:27.702+00:00" ? (
        <Moment
          date={moment(dateTimeString)}
          format="dddd-DD-MMMM-YYYY HH:mm"
          onClick={showModal}
        />
      ) : (
        <p onClick={showModal} style={{ color: "#ababab" }}>
          Asignar fecha
        </p>
      )}

      <Modal
        title="Modificar fecha y hora"
        visible={isModalVisible}
        destroyOnClose
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Guardar
          </Button>,
        ]}
      >
        <Space direction="vertical">
          <DatePicker
            defaultValue={
              props.valor !== "1970-01-01T18:34:27.702+00:00"
                ? moment(dateTimeString)
                : ""
            }
            format="YYYY-MM-DD HH:mm"
            showTime={true}
            onChange={cambio}
          />
          <Checkbox
            style={{ marginTop: 10 }}
            value={activarDisparadores}
            onChange={(e) => setActivarDisparadores(e.target.checked)}
          >
            Activar Disparadores
          </Checkbox>
        </Space>
      </Modal>
    </>
  );
};

export default CamposFechaComponente;
