import { toJS } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { useParams } from "react-router";
import { store } from "../../store";
import DesignFilaComponente from "../configuraciones/menu/componentes/DesignFilaComponente";

function PageMenuLeft() {
  const params = useParams();
  const [widgets, setWidgets] = React.useState([]);
  React.useEffect(() => {
    let menu = store.menuAdmin;
    if (store.usuario.tipo === "afiliado") {
      menu = store.menuFront;
    }

    if (menu.length > 0) {
      var paginaActual = [];
      menu.forEach((e) => {
        if (e.children.length > 0) {
          e.children.forEach((e) => {
            if (e.id === params.id) {
              paginaActual = e;
            }
          });
        } else {
          if (e.id === params.id) {
            paginaActual = e;
          }
        }
      });

      setWidgets(paginaActual.widgets || []);
    }
  }, [params]);
  return (
    <>
      {widgets.length > 0 ? (
        widgets.map((fila, index) => {
          return (
            <DesignFilaComponente
              widgets={widgets}
              setWidgets={setWidgets}
              fila={fila}
              index={index}
              produccion={true}
              key={index}
            />
          );
        })
      ) : (
        <></>
      )}
    </>
  );
}

export default observer(PageMenuLeft);
