import { Button, Drawer } from "antd";
import React from "react";
import { Api } from "../../../../../api/configApi";
import ContenedorLecciones from "../Contenedores/ContenedorLecciones";
import FormularioCrearLeccion from "../Formularios/FormularioCrearLeccion";
import { PlusSquareOutlined, RollbackOutlined } from "@ant-design/icons";

const DrawerLecciones = (props) => {
  const [visible, setVisible] = React.useState(false);
  const refListaLecciones = React.useRef();
  const [listaLecciones, setListaLecciones] = React.useState();

  function obtenerLecciones() {
    const dataCurso = props.data;
    delete dataCurso.lecciones;

    Api(
      "academia",
      "obtenerLecciones",
      { idWidget: props.idWidget._id },
      dataCurso,
      "https://ed3a-181-132-3-197.ngrok.io"
    )
      .then((res) => {
        refListaLecciones.current = res.lecciones;
        setListaLecciones(refListaLecciones.current);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  React.useEffect(() => {
    obtenerLecciones();
  }, [props.idWidget]);

  return (
    <>
      <div style={{ display: "flex", height:"85vh", alignItems:"center" }}>
        <div
          style={{
            width: "70%",
            overflow: "hidden",
            overflowY: "scroll",
            position:"absolute",
            top:55,
            bottom:0,
          }}
        >
          {listaLecciones?.map((leccion, index) => {
            return (
              <>
                <ContenedorLecciones
                  key={index}
                  descripcionLeccion={leccion.descripcionLeccion}
                  idLeccion={leccion.idLeccion}
                  tituloLeccion={leccion.tituloLeccion}
                  urlVideo={leccion.urlVideo}
                  data={leccion}
                  idWidget={props.idWidget}
                  dataCurso={props.data}
                  obtenerLecciones={obtenerLecciones}
                ></ContenedorLecciones>
              </>
            );
          })}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            width: "100%",
            alignItems: "end",
            // backgroundColor:"red"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // backgroundColor: "red",
              marginRight:"35px"
            }}
          >
            <div
              onClick={showDrawer}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "30px",
                cursor:"pointer"
              }}
            >
              <PlusSquareOutlined style={{ fontSize: "30px" }} />
              <p>Crear lección</p>
            </div>
            <div
              onClick={()=>props.closeLecciones(false)}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                cursor:"pointer"
              }}
            >
              <RollbackOutlined style={{ fontSize: "30px" }} />
              <p>Cerrar</p>
            </div>
          </div>
        </div>
      </div>

      <Drawer
        title="Crear lección"
        placement="right"
        // onClose={onClose}
        closable={false}
        visible={visible}
      >
        <FormularioCrearLeccion
          data={props.data}
          infWid={props.idWidget}
          setVisible={setVisible}
          obtenerLecciones={obtenerLecciones}
        ></FormularioCrearLeccion>
      </Drawer>
    </>
  );
};

export default DrawerLecciones;
