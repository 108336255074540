import React from "react";
import {
  Divider,
  Form,
  Input,
  Button,
  Checkbox,
  Select,
  Switch,
  message,
  Space,
} from "antd";
import Boton from "../../../design/elementos/boton";
import { setPensando, setStoreWidgets, store } from "../../../../store";
import { Api } from "../../../../api/configApi";
import { ObjectId } from "bson";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";

function CrearActualizarWidgetArbolGenealogico(props) {
  const { Option } = Select;
  const [loading, setLoading] = React.useState(false);
  const [insertOrUpdate, setInsertOrUpdate] = React.useState("InsertWidgets");
  const [form] = Form.useForm();
  const guardarWidget = (values) => {
    values.tipo = "arbolGenealogico";
    setPensando(true);
    setLoading(true);
    Api("Cuentas", insertOrUpdate, { _id: store.cuentaactiva }, values)
      .then((res) => {
        props.setVisible(false);
        setPensando(false);
        setLoading(false);
        return Api(
          "Cuentas",
          "Get",
          { _id: store.cuentaactiva },
          { widgets: 1 }
        );
      })
      .then((res) => {
        setStoreWidgets(res[0].widgets);
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
        setPensando(false);
        setLoading(false);
      });
  };
  React.useEffect(() => {
    // console.log(store.usuario);
    form.resetFields();
    form.setFieldsValue({ _id: new ObjectId(), dinamico: false });
  }, [props.visible]);
  React.useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
      setInsertOrUpdate("UpdateWidgets");
    } else {
      form.setFieldsValue({ _id: new ObjectId(), dinamico: false });
    }
  }, [props.data]);
  return (
    <div>
      <Form form={form} onFinish={guardarWidget} layout="vertical">
        <Form.Item
          label="Nombre"
          name="nombre"
          hasFeedback
          rules={[{ required: true, message: "Ingresa un nombre" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="_id" hidden rules={[{ required: true }]}></Form.Item>
        <Form.Item
          name="dinamico"
          hidden
          rules={[{ required: true }]}
        ></Form.Item>

        <Form.Item
          label="Titulo"
          name="titulo"
          hasFeedback
          rules={[{ required: true, message: "Ingresa un titulo" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Descripción"
          name="descripcion"
          hasFeedback
          rules={[{ required: true, message: "Ingresa una descripción" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Plantilla"
          name="plantilla"
          hasFeedback
          rules={[{ required: true, message: "Selecciona una plantilla" }]}
        >
          <Select>
            <Option value="arbolTradicional">Arbol tradicional</Option>
            <Option value="porNiveles">Por niveles</Option>
          </Select>
        </Form.Item>
        {
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              return prevValues.plantilla !== currentValues.plantilla;
            }}
          >
            {({ getFieldValue }) => {
              if (getFieldValue("plantilla") === "arbolTradicional") {
                return (
                  <>
                    <Form.Item label="Permitir filtrar por" name="filtros">
                      <Select mode="multiple">
                        {store.losatributos.texts.map((e) => {
                          return <Option value={e.nombre}>{e.nombre}</Option>;
                        })}
                        {store.losatributos.numbers.map((e) => {
                          return <Option value={e.nombre}>{e.nombre}</Option>;
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item label="Permitir ver los campos:" name="campos">
                      <Select mode="multiple">
                        {store.cuenta.formularios.find(
                          (e) => e.nombre === "Nuevo registro"
                        ) &&
                          store.cuenta.formularios
                            .find((e) => e.nombre === "Nuevo registro")
                            .campos.map((e) => {
                              return <Option value={e.name}>{e.name}</Option>;
                            })}
                      </Select>
                    </Form.Item>
                  </>
                );
              }

              if (getFieldValue("plantilla") === "porNiveles") {
                return (
                  <>
                    <Form.Item
                      label="Mostrar total de registros por nivel"
                      name="mostrarCantidad"
                      valuePropName="checked"
                    >
                      <Switch />
                    </Form.Item>

                    <Form.List name="niveles">
                      {(fields, { add, remove }) => (
                        <div>
                          {fields.map(
                            ({ key, name, fieldKey, ...restField }) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <div
                                  key={key}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    border: "1px solid #e3e3e3",
                                    padding: 5,
                                    marginBottom: 40,
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <Form.Item
                                    {...restField}
                                    name={[name, "nivel"]}
                                    fieldKey={[fieldKey, "nivel"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Agrega un Nivel",
                                      },
                                    ]}
                                    label="Nivel"
                                    style={{
                                      flex: 1,
                                      marginRight: 3,
                                      minWidth: 50,
                                    }}
                                  >
                                    <Input type="number" />
                                  </Form.Item>
                                  <Form.Item
                                    rules={[
                                      {
                                        required: true,
                                        message: "Ingresa un icono",
                                      },
                                    ]}
                                    label="Icono comprimido"
                                    {...restField}
                                    name={[name, "iconoComprimido"]}
                                    fieldKey={[fieldKey, "iconoComprimido"]}
                                    style={{ minWidth: 250, marginRight: 3 }}
                                  >
                                    <Input type="url" />
                                  </Form.Item>
                                  <Form.Item
                                    rules={[
                                      {
                                        required: true,
                                        message: "Ingresa un icono",
                                      },
                                    ]}
                                    label="Icono expandido"
                                    {...restField}
                                    name={[name, "iconoExpandido"]}
                                    fieldKey={[fieldKey, "iconoExpandido"]}
                                    style={{ minWidth: 250, marginRight: 3 }}
                                  >
                                    <Input type="url" />
                                  </Form.Item>
                                  <Form.Item
                                    label="Atributo Comprimido"
                                    style={{ display: "flex", width: "100%" }}
                                  >
                                    <Input.Group
                                      compact
                                      style={{
                                        display: "flex",
                                        width: "100%",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Form.Item
                                        label="Prefijo"
                                        name={[name, "prefijo"]}
                                        fieldKey={[fieldKey, "prefijo"]}
                                        {...restField}
                                        style={{ flex: 1, marginRight: 3 }}
                                      >
                                        <Input />
                                      </Form.Item>
                                      <Form.Item
                                        label="Atributo"
                                        name={[name, "atributo"]}
                                        fieldKey={[fieldKey, "atributo"]}
                                        {...restField}
                                        style={{ flex: 3 }}
                                      >
                                        <Select
                                          defaultValue="null"
                                          style={{ width: "100%" }}
                                        >
                                          <Option default value="null">
                                            Ningúno
                                          </Option>
                                          {store.losatributos.texts.map((e) => {
                                            return (
                                              <Option value={e.nombre}>
                                                {e.nombre}
                                              </Option>
                                            );
                                          })}
                                          {store.losatributos.numbers.map(
                                            (e) => {
                                              return (
                                                <Option value={e.nombre}>
                                                  {e.nombre}
                                                </Option>
                                              );
                                            }
                                          )}
                                        </Select>
                                      </Form.Item>
                                      {/* escuchar cambios en el atributo */}
                                      <Form.Item noStyle shouldUpdate>
                                        {({ getFieldValue }) => {
                                          return (
                                            <Form.Item
                                              label="Formato"
                                              name={[name, "formato"]}
                                              fieldKey={[fieldKey, "formato"]}
                                              {...restField}
                                              style={{ flex: 1, marginLeft: 3 }}
                                            >
                                              <Select
                                                style={{ width: "100%" }}
                                                defaultValue="ningúno"
                                                showSearch
                                              >
                                                <Option default value="ningúno">
                                                  Ningúno
                                                </Option>
                                                {getFieldValue().niveles[name]
                                                  ?.atributo &&
                                                  store.losatributos.texts.find(
                                                    (e) =>
                                                      e.nombre ===
                                                      getFieldValue().niveles[
                                                        name
                                                      ].atributo
                                                  ) && (
                                                    <>
                                                      <Option value="mayusculas">
                                                        Mayúsculas
                                                      </Option>
                                                    </>
                                                  )}
                                                {getFieldValue().niveles[name]
                                                  ?.atributo &&
                                                  store.losatributos.numbers.find(
                                                    (e) =>
                                                      e.nombre ===
                                                      getFieldValue().niveles[
                                                        name
                                                      ]?.atributo
                                                  ) && (
                                                    <>
                                                      <Option value="miles">
                                                        Miles
                                                      </Option>
                                                      <Option value="BTC">
                                                        BTC
                                                      </Option>
                                                      <Option value="USD">
                                                        USD
                                                      </Option>
                                                      <Option value="COP">
                                                        COP
                                                      </Option>
                                                      <Option value="MXN">
                                                        MXN
                                                      </Option>
                                                      <Option value="EUR">
                                                        EUR
                                                      </Option>
                                                    </>
                                                  )}
                                              </Select>
                                            </Form.Item>
                                          );
                                        }}
                                      </Form.Item>

                                      <Form.Item
                                        label="Sufijo"
                                        name={[name, "sufijo"]}
                                        fieldKey={[fieldKey, "sufijo"]}
                                        {...restField}
                                        style={{ flex: 1, marginLeft: 3 }}
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Input.Group>
                                  </Form.Item>

                                  <Form.Item
                                    label="Atributo Expandido"
                                    style={{ display: "flex", width: "100%" }}
                                  >
                                    <Input.Group
                                      compact
                                      style={{
                                        display: "flex",
                                        width: "100%",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Form.Item
                                        label="Prefijo"
                                        name={[name, "prefijoExpandido"]}
                                        fieldKey={[
                                          fieldKey,
                                          "prefijoExpandido",
                                        ]}
                                        {...restField}
                                        style={{ flex: 1, marginRight: 3 }}
                                      >
                                        <Input />
                                      </Form.Item>
                                      <Form.Item
                                        label="Atributo"
                                        name={[name, "atributoExpandido"]}
                                        fieldKey={[
                                          fieldKey,
                                          "atributoExpandido",
                                        ]}
                                        {...restField}
                                        style={{ flex: 3 }}
                                      >
                                        <Select
                                          defaultValue="null"
                                          style={{ width: "100%" }}
                                        >
                                          <Option default value="null">
                                            Ningúno
                                          </Option>
                                          {store.losatributos.texts.map((e) => {
                                            return (
                                              <Option value={e.nombre}>
                                                {e.nombre}
                                              </Option>
                                            );
                                          })}
                                          {store.losatributos.numbers.map(
                                            (e) => {
                                              return (
                                                <Option value={e.nombre}>
                                                  {e.nombre}
                                                </Option>
                                              );
                                            }
                                          )}
                                        </Select>
                                      </Form.Item>
                                      {/* escuchar cambios en el atributo */}
                                      <Form.Item noStyle shouldUpdate>
                                        {({ getFieldValue }) => {
                                          return (
                                            <Form.Item
                                              label="Formato"
                                              name={[name, "formatoExpandido"]}
                                              fieldKey={[
                                                fieldKey,
                                                "formatoExpandido",
                                              ]}
                                              {...restField}
                                              style={{ flex: 1, marginLeft: 3 }}
                                            >
                                              <Select
                                                style={{ width: "100%" }}
                                                defaultValue="ningúno"
                                                showSearch
                                              >
                                                <Option default value="ningúno">
                                                  Ningúno
                                                </Option>
                                                {getFieldValue().niveles[name]
                                                  ?.atributoExpandido &&
                                                  store.losatributos.texts.find(
                                                    (e) =>
                                                      e.nombre ===
                                                      getFieldValue().niveles[
                                                        name
                                                      ]?.atributoExpandido
                                                  ) && (
                                                    <>
                                                      <Option value="mayusculas">
                                                        Mayúsculas
                                                      </Option>
                                                    </>
                                                  )}
                                                {getFieldValue().niveles[name]
                                                  ?.atributoExpandido &&
                                                  store.losatributos.numbers.find(
                                                    (e) =>
                                                      e.nombre ===
                                                      getFieldValue().niveles[
                                                        name
                                                      ]?.atributoExpandido
                                                  ) && (
                                                    <>
                                                      <Option value="miles">
                                                        Miles
                                                      </Option>
                                                      <Option value="BTC">
                                                        BTC
                                                      </Option>
                                                      <Option value="USD">
                                                        USD
                                                      </Option>
                                                      <Option value="COP">
                                                        COP
                                                      </Option>
                                                      <Option value="MXN">
                                                        MXN
                                                      </Option>
                                                      <Option value="EUR">
                                                        EUR
                                                      </Option>
                                                    </>
                                                  )}
                                              </Select>
                                            </Form.Item>
                                          );
                                        }}
                                      </Form.Item>

                                      <Form.Item
                                        label="Sufijo"
                                        name={[name, "sufijoExpandido"]}
                                        fieldKey={[fieldKey, "sufijoExpandido"]}
                                        {...restField}
                                        style={{ flex: 1, marginLeft: 3 }}
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Input.Group>
                                  </Form.Item>

                                  <Form.Item
                                    label="Mostrar Nombre/Ide"
                                    name={[name, "mostrarNombreIde"]}
                                    fieldKey={[fieldKey, "mostrarNombreIde"]}
                                    {...restField}
                                    style={{
                                      flex: 2,
                                      marginLeft: 3,
                                      minWidth: 200,
                                    }}
                                  >
                                    <Select>
                                      <Option value="ningúno">Ningúno</Option>
                                      <Option value="nombre">Nombre</Option>
                                      <Option value="ide">IDE</Option>
                                    </Select>
                                  </Form.Item>
                                  <Form.Item
                                    label="Texto Lateral"
                                    name={[name, "textoLateral"]}
                                    fieldKey={[fieldKey, "textoLateral"]}
                                    {...restField}
                                    style={{
                                      flex: 2,
                                      marginLeft: 3,
                                      minWidth: 200,
                                    }}
                                  >
                                    <Input />
                                  </Form.Item>
                                  <Form.Item
                                    label="Color Base"
                                    name={[name, "color"]}
                                    fieldKey={[fieldKey, "color"]}
                                    {...restField}
                                    style={{
                                      flex: 2,
                                      marginLeft: 3,
                                      minWidth: 200,
                                    }}
                                  >
                                    <Input />
                                  </Form.Item>
                                </div>
                                <FontAwesomeIcon
                                  style={{
                                    cursor: "pointer",
                                    alignSelf: "center",

                                    marginLeft: 10,
                                  }}
                                  icon={faMinusCircle}
                                  onClick={() => remove(name)}
                                />
                              </div>
                            )
                          )}
                          <p
                            style={{
                              fontSize: 13,
                              marginBottom: 30,
                              fontWeight: 600,
                              cursor: "pointer",
                              marginTop: 10,
                              textAlign: "center",
                            }}
                            onClick={() => {
                              store.losatributos.texts.length > 0
                                ? add()
                                : message.error(
                                    "Debe tener atributos TEXTO o NÚMERO para crear condicionales"
                                  );
                            }}
                          >
                            + Agregar Nivel
                          </p>
                        </div>
                      )}
                    </Form.List>
                  </>
                );
              }
            }}
          </Form.Item>
        }

        <Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: 15,
            }}
          >
            <Boton loading={loading} texto="Guardar" htmlType="submit" />
            <Boton
              loading={loading}
              onClick={() => {
                props.setVisible(false);
              }}
              texto="Cancelar"
              tipo="danger"
              htmlType="button"
            />
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}

export default CrearActualizarWidgetArbolGenealogico;
