import { Drawer } from "antd";
import React, { useState } from "react";
import { store } from "../../store";
import CrearActualizarWidgetAdminRecursos from "./tipos/Admin Recursos/CrearActualizarWidgetAdminRecursos";
import CrearActualizarWidgetArbolGenealogico from "./tipos/arbolGenealogico/crearActualizarWidgetArbolGenealogico";
import CrearActualizarDebitoCredito from "./tipos/debitoCredito/CrearActualizarWidgetDebitoCredito";
import CrearActualizarWidgetLink from "./tipos/link/CrearActualizarWidgetLinkComponente";
import CrearActualizarWidgetListaRecursos from "./tipos/Lista recursos/CrearActualizarWigetListaRecursos";
import CrearActualizarWidgetNumero from "./tipos/numero/CrearActualizarWidgetNumeroComponente";
import CrearActualizarWidgetObjetivo from "./tipos/objetivo/CrearActualizarWidgetNumeroComponente";
import CrearActualizarWidgetTabla from "./tipos/tabla/CrearActualizarWidgetTabla";
import CrearActualizarWidgetTexto from "./tipos/texto/CrearActualizarWidgetTextoComponente";
import CrearActualizarWidgetWoocommerce from "./tipos/woocommerce/CrearActualizarWidgetWoocommerce";
import CrearActualizarWidgetBanner from "./tipos/banner/CrearActualizarWidgetBannerComponente";
import CrearActualizarWidgetLogros from "./tipos/logros/CrearActualizarWidgetLogrosComponente";
import CrearActualizarWidgetVideo from "./tipos/video/CrearActualizarWidgetVideoComponente";
import CrearActualizarUploadFiles from "./tipos/uploadFiles/CrearActualizarWidgetUploadFiles";

import TiposFormulariosWidgetsDev1 from "../../frontDEV1/widgets/TiposFormulariosWidgetsDev1";
import { ListadoWidgetsBloques } from "../../frontDEV1/widgets/ListaWidgetsComponenteDev1";

function TiposWidgetsComponente() {
  const [visible, setVisible] = useState(false);
  const [mostrar, setMostrar] = useState("Número");

  const showDrawer = (tipo) => {
    setMostrar(tipo);
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  return (
    <>
      <Drawer
        title={mostrar}
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
        width={700}
        destroyOnClose
      >
        {mostrar === "Número" && (
          <CrearActualizarWidgetNumero
            visible={visible}
            setVisible={setVisible}
          />
        )}
        {mostrar === "Texto" && (
          <CrearActualizarWidgetTexto
            visible={visible}
            setVisible={setVisible}
          />
        )}
        {mostrar === "Objetivo" && (
          <CrearActualizarWidgetObjetivo
            visible={visible}
            setVisible={setVisible}
          />
        )}
        {mostrar === "woocommerce" && (
          <CrearActualizarWidgetWoocommerce
            visible={visible}
            setVisible={setVisible}
          />
        )}
        {mostrar === "Link" && (
          <CrearActualizarWidgetLink
            visible={visible}
            setVisible={setVisible}
          />
        )}
        {mostrar === "arbolGenealogico" && (
          <CrearActualizarWidgetArbolGenealogico
            visible={visible}
            setVisible={setVisible}
          />
        )}
        {mostrar === "tabla" && (
          <CrearActualizarWidgetTabla
            visible={visible}
            setVisible={setVisible}
          />
        )}
        {mostrar === "listaRecursos" && (
          <CrearActualizarWidgetListaRecursos
            visible={visible}
            setVisible={setVisible}
          />
        )}
        {mostrar === "adminRecursos" && (
          <CrearActualizarWidgetAdminRecursos
            visible={visible}
            setVisible={setVisible}
          />
        )}
        {mostrar === "debitoCredito" && (
          <CrearActualizarDebitoCredito
            visible={visible}
            setVisible={setVisible}
          />
        )}
        {mostrar === "uploadFiles" && (
          <CrearActualizarUploadFiles
            visible={visible}
            setVisible={setVisible}
          />
        )}
        {mostrar === "banner" && (
          <CrearActualizarWidgetBanner
            visible={visible}
            setVisible={setVisible}
          />
        )}
        {mostrar === "video" && (
          <CrearActualizarWidgetVideo
            visible={visible}
            setVisible={setVisible}
          />
        )}

        {mostrar === "logros" && (
          <CrearActualizarWidgetLogros
            visible={visible}
            setVisible={setVisible}
          />
        )}

        <TiposFormulariosWidgetsDev1
          data={false}
          mostrar={mostrar}
          visible={visible}
          setVisible={setVisible}
        />
      </Drawer>
      <div
        onClick={() => {
          showDrawer("Número");
        }}
        className="item-lista-widgets"
      >
        <img
          width="25px"
          style={{ marginRight: 15 }}
          alt="tabla"
          src="https://octopusmultinivel.b-cdn.net/octopus10/scale.svg"
        />
        <span>Número</span>
      </div>
      <div
        onClick={() => {
          showDrawer("Texto");
        }}
        className="item-lista-widgets"
      >
        <img
          width="25px"
          style={{ marginRight: 15 }}
          alt="tabla"
          src="https://octopusmultinivel.b-cdn.net/octopus10/font.svg"
        />
        <span>Texto</span>
      </div>
      <div
        onClick={() => {
          showDrawer("tabla");
        }}
        className="item-lista-widgets"
      >
        <img
          width="25px"
          style={{ marginRight: 15 }}
          alt="tabla"
          src="https://octopusmultinivel.b-cdn.net/octopus10/tabla.svg"
        />
        <span>Tabla</span>
      </div>
      <div
        onClick={() => {
          showDrawer("Objetivo");
        }}
        className="item-lista-widgets"
      >
        <img
          width="25px"
          style={{ marginRight: 15 }}
          alt="tabla"
          src="https://octopusmultinivel.b-cdn.net/octopus10/loading.svg"
        />
        <span>Objetivo</span>
      </div>
      <div
        onClick={() => {
          showDrawer("Fecha");
        }}
        className="item-lista-widgets"
      >
        <img
          width="25px"
          style={{ marginRight: 15 }}
          alt="tabla"
          src="https://octopusmultinivel.b-cdn.net/octopus10/no-tobacco-day.svg"
        />
        <span>Fecha</span>
      </div>
      <div
        onClick={() => {
          showDrawer("Cuenta Regresiva");
        }}
        className="item-lista-widgets"
      >
        <img
          width="25px"
          style={{ marginRight: 15 }}
          alt="tabla"
          src="https://octopusmultinivel.b-cdn.net/octopus10/chronometer.svg"
        />
        <span>Cuenta Regresiva</span>
      </div>
      <div
        onClick={() => {
          showDrawer("video");
        }}
        className="item-lista-widgets"
      >
        <img
          width="25px"
          style={{ marginRight: 15 }}
          alt="video"
          src="https://octopusmultinivel.b-cdn.net/octopus10/video-player.png"
        />
        <span>Video</span>
      </div>

      <div
        onClick={() => {
          showDrawer("Estadistica Torta");
        }}
        className="item-lista-widgets"
      >
        <img
          width="25px"
          style={{ marginRight: 15 }}
          alt="tabla"
          src="https://octopusmultinivel.b-cdn.net/octopus10/pie-chart.svg"
        />
        <span>Estadistica Torta</span>
      </div>
      <div
        onClick={() => {
          showDrawer("Link");
        }}
        className="item-lista-widgets"
      >
        <img
          width="25px"
          style={{ marginRight: 15 }}
          alt="link"
          src="https://octopusmultinivel.b-cdn.net/octopus10/link.svg"
        />
        <span>Link</span>
      </div>
      <div
        onClick={() => {
          showDrawer("arbolGenealogico");
        }}
        className="item-lista-widgets"
      >
        <img
          width="25px"
          style={{ marginRight: 15 }}
          alt="arbolGenealogico"
          src="https://octopusmultinivel.b-cdn.net/octopus10/family-tree.svg"
        />
        <span>Arbol Genealogico</span>
      </div>
      <div
        onClick={() => {
          showDrawer("uploadFiles");
        }}
        className="item-lista-widgets"
      >
        <img
          width="25px"
          style={{ marginRight: 15 }}
          alt="uploadFiles"
          src="https://octopusmultinivel.b-cdn.net/octopus10/uploading.png"
        />
        <span>Subir Archivos</span>
      </div>
      <div
        onClick={() => {
          showDrawer("debitoCredito");
        }}
        className="item-lista-widgets"
      >
        <img
          width="25px"
          style={{ marginRight: 15 }}
          alt="debitoCredito"
          src="https://octopusmultinivel.b-cdn.net/octopus10/upload.svg"
        />
        <span>Debito / Credito</span>
      </div>
      <div
        onClick={() => {
          showDrawer("banner");
        }}
        className="item-lista-widgets"
      >
        <img
          width="25px"
          style={{ marginRight: 15 }}
          alt="banner"
          src="https://octopusmultinivel.b-cdn.net/octopus10/hanging-billboard%20(1).png"
        />
        <span>Banner</span>
      </div>

      <ListadoWidgetsBloques showDrawer={showDrawer} />

      <div
        onClick={() => {
          showDrawer("logros");
        }}
        className="item-lista-widgets"
      >
        <img
          width="25px"
          style={{ marginRight: 15 }}
          alt="logros"
          src="https://octopusmultinivel.b-cdn.net/octopus10/rating.png"
        />
        <span>Logros</span>
      </div>

      {store.cuenta.modulos.find((e) => {
        return e.nombre === "woocommerce";
      }) && (
        <div
          onClick={() => {
            showDrawer("woocommerce");
          }}
          className="item-lista-widgets"
        >
          <img
            width="25px"
            style={{ marginRight: 15 }}
            alt="link"
            src="https://octopusmultinivel.b-cdn.net/octopus10/shopping-cart.svg"
          />
          <span>Woocommerce</span>
        </div>
      )}
      {store.cuenta.modulos.find((e) => {
        return e.nombre === "Recursos";
      }) && (
        <>
          <div
            onClick={() => {
              showDrawer("listaRecursos");
            }}
            className="item-lista-widgets"
          >
            <img
              width="25px"
              style={{ marginRight: 15 }}
              alt="link"
              src="https://octopusmultinivel.b-cdn.net/octopus10/carpeta%20(1).svg"
            />
            <span>Lista Recursos</span>
          </div>
          <div
            onClick={() => {
              showDrawer("adminRecursos");
            }}
            className="item-lista-widgets"
          >
            <img
              width="25px"
              style={{ marginRight: 15 }}
              alt="link"
              src="https://octopusmultinivel.b-cdn.net/octopus10/settings.svg"
            />
            <span>Admin Recursos</span>
          </div>
        </>
      )}
    </>
  );
}

export default TiposWidgetsComponente;
