import React from "react";
import { Button, InputLabel, Select } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Chip from "@material-ui/core/Chip";
import { store } from "../../../../store";

function ComponenteOpcionesFiltroDisparador(props) {
  const [decision, setDecision] = React.useState();
  const [valueSelect, setValueSelect] = React.useState([]);
  const [chips, setChips] = React.useState([]);
  const [selectActivo, setSelectActivo] = React.useState("x");
  const [selectTipoNumber, setSelectTipoNumber] = React.useState("igualque");
  const [diasAtras, setDiasAtras] = React.useState(0);
  const atributoacomparar = React.useRef(null);
  const diasatrasfocus = React.useRef(null);
  const opcion = props.opciones;
  const agregarEtiqueta = (value) => {
    setChips([...chips, value]);
    setDecision([...chips, value]);
  };
  const removerEtiqueta = (index) => {
    const chip = chips;
    chip.splice(index, 1);
    setChips(chip);
  };

  const actualizardecision = () => {
    switch (opcion.tipo) {
      case "select":
        props.setDecision({
          tipo: "select",
          atributo: { ...store.atributos.selects[selectActivo] },
          label: `El atributo "${store.atributos.selects[selectActivo].nombre}" contiene "${valueSelect}"`,
          opcion: valueSelect,
        });
        break;
      case "number":
        props.setDecision({
          tipo: "number",
          atributo: { ...store.atributos.numbers[selectActivo] },
          label: `El atributo "${store.atributos.numbers[selectActivo].nombre}" es ${selectTipoNumber} ${decision}`,
          opcion: {
            mayorigualmenor: selectTipoNumber,
            valor: decision,
          },
        });
        break;
      case "text":
        props.setDecision({
          tipo: "text",
          atributo: { ...store.atributos.texts[selectActivo] },
          label: `El atributo "${store.atributos.texts[selectActivo].nombre}" es igual a "${decision}"`,
          opcion: decision,
        });
        break;
      case "tags":
        props.setDecision({
          tipo: "tags",
          atributo: { ...store.atributos.tags[selectActivo] },
          label: `El atributo "${store.atributos.tags[selectActivo].nombre}" contiente "${decision}"`,
          opcion: decision,
        });
        break;
      case "list":
        props.setDecision({
          tipo: "list",
          atributo: { ...store.atributos.tags[selectActivo] },
          label: `El atributo "${store.atributos.lists[selectActivo].nombre}" es igual a "${decision}"`,
          opcion: decision,
          diasatras: diasAtras
        });
        break;
      default:
        break;
    }
  };
  const SeleccionarAtributo = (props) => {
    return (
      <select
        style={{ marginTop: 10 }}
        value={selectActivo}
        onChange={(e) => {
          setSelectActivo(e.target.value);
          setValueSelect([]);
        }}
        className="seleccionar"
      >
        <option value="x" disabled>
          Selecciona un Atributo
        </option>
        {store.atributos[props.tipo].map((res, index) => {
          return (
            <option key={index} value={index}>
              {res.nombre}
            </option>
          );
        })}
      </select>
    );
  };
  const TipoNumber = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <SeleccionarAtributo tipo="numbers" />

        {selectActivo !== "x" && (
          <>
            <div style={estilos.coint}>{opcion.indicaciones}</div>
            <select
              style={{ marginTop: 10 }}
              value={selectTipoNumber}
              onChange={(e) => {
                setSelectTipoNumber(e.target.value);
              }}
              className="seleccionar"
            >
              <option value="x" disabled>
                ¿ Igual, mayor o menor ?
              </option>
              <option value="igualque">Igual que</option>
              <option value="mayorque">Mayor que</option>
              <option value="menorque">Menor que</option>
            </select>
            <input
              style={estilos.input}
              value={decision}
              autoFocus
              type="number"
              min="1"
              onChange={(event) => {
                setDecision(event.target.value);
              }}
            />
          </>
        )}
      </div>
    );
  };
  const TipoSelect = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <SeleccionarAtributo tipo="selects" />

        {selectActivo !== "x" && (
          <>
            <div style={estilos.coint}>{opcion.indicaciones}</div>
            <Select
              labelId="demo-mutiple-checkbox-label"
              id="demo-mutiple-checkbox"
              multiple
              value={valueSelect}
              onChange={(e) => {
                setValueSelect(e.target.value);
              }}
              style={{ minWidth: 150 }}
              renderValue={(selected) => {
                return selected.join(", ");
              }}
              input={<Input />}
            >
              <MenuItem disabled value="">
                <em>Opciones</em>
              </MenuItem>
              {store.atributos.selects[selectActivo].opciones.map(
                (res, index) => (
                  <MenuItem key={index} value={res}>
                    <Checkbox checked={valueSelect.indexOf(res) > -1} />
                    <ListItemText primary={res} />
                  </MenuItem>
                )
              )}
            </Select>
          </>
        )}
      </div>
    );
  };
  const TipoText = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <SeleccionarAtributo tipo="texts" />

        {selectActivo !== "x" && (
          <>
            <div style={estilos.coint}>{opcion.indicaciones}</div>
            <input
              style={estilos.input}
              value={decision}
              autoFocus
              required
              type="text"
              min="1"
              onChange={(event) => {
                setDecision(event.target.value);
              }}
            />
          </>
        )}
      </div>
    );
  };
  const TipoTag = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <SeleccionarAtributo tipo="tags" />
        {selectActivo !== "x" && (
          <>
            <div style={estilos.coint}>{opcion.indicaciones}</div>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  maxWidth: 225,
                  marginBottom: 10,
                  marginTop: 5,
                }}
              >
                {chips.map((tag, index) => {
                  return (
                    <Chip style={{ margin: 2, fontSize: 10 }} label={tag} />
                  );
                })}
              </div>
              <input
                autoFocus
                style={{
                  border: "1px solid rgb(232, 232, 232)",
                  width: "100%",
                  height: 40,
                  marginBottom: 10,
                  textAlign: "center",
                  outline: "none",
                }}
                placeholder="Escribe la etiqueta y luego ENTER"
                type="text"
                onKeyDown={(e) => {
                  e.key === "Enter" && agregarEtiqueta(e.target.value);
                }}
              />
            </div>
          </>
        )}
      </div>
    );
  };
  const TipoList = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <SeleccionarAtributo tipo="lists" />

        {selectActivo !== "x" && (
          <>
            <div style={estilos.coint}>
              Desde hace X dias atrás. ( Si coloca 0 será desde siempre )
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <input
                style={{ flex: 2, ...estilos.input }}
                ref={diasatrasfocus}
                type="number"
                name="inputdiasatras"
                value={diasAtras}
                onChange={(e) => {
                  setDiasAtras(e.target.value);
                  setTimeout(() => {
                    diasatrasfocus.current.focus();
                  }, 5);
                }}
                min={0}
              />
              <span style={{ flex: 1, marginLeft: 15 }}>Dia(s)</span>
            </div>

            {store.atributos.lists[selectActivo].opciones[0].tipo ===
              "number" && (
              <>
                <div style={estilos.coint}>Numero a comparar</div>
                <input
                  style={{ flex: 2, ...estilos.input }}
                  type="number"
                  ref={atributoacomparar}
                  value={decision}
                  onChange={(e) => {
                    setDecision(e.target.value);
                    setTimeout(() => {
                      atributoacomparar.current.focus();
                    }, 5);
                  }}
                  min={0}
                />
              </>
            )}
            {store.atributos.lists[selectActivo].opciones[0].tipo ===
              "text" && (
              <>
                <div style={estilos.coint}>Texto a comparar</div>
                <input
                  ref={atributoacomparar}
                  style={{ flex: 2, ...estilos.input }}
                  type="text"
                  value={decision}
                  onChange={(e) => {
                    setDecision(e.target.value);
                    setTimeout(() => {
                      atributoacomparar.current.focus();
                    }, 5);
                  }}
                  min={0}
                />
              </>
            )}
          </>
        )}
      </div>
    );
  };
  const OpcionActual = () => {
    var x = null;
    if (!opcion) {
      return x;
    }
    switch (opcion.tipo) {
      case "select":
        x = <TipoSelect />;
        break;
      case "number":
        x = <TipoNumber />;
        break;
      case "text":
        x = <TipoText />;
        break;
      case "tags":
        x = <TipoTag />;
        break;
      case "list":
        x = <TipoList />;
        break;
      default:
        break;
    }

    return x;
  };
  return (
    <div style={estilos.containe}>
      <OpcionActual />
      {selectActivo !== "x" && (
        <Button
          onClick={() => {
            props.setVistaactiva(0);
            actualizardecision();
          }}
          style={{ marginTop: 15 }}
          variant="outlined"
          color="primary"
        >
          Agregar
        </Button>
      )}
    </div>
  );
}
const estilos = {
  coint: {
    maxWidth: 200,
    fontSize: 11,
    background: "rgb(224 247 231)",
    marginTop: 10,
    borderRadius: 3,
    padding: 10,
    marginBottom: 5,
  },
  input: {
    outline: "none",
    fontSize: 20,
    border: "1px solid rgb(232, 232, 232)",
    paddingTop: 5,
    paddingBottom: 5,
    textAlign: "center",
    marginTop: 5,
    marginBottom: 5,
  },
  containe: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
};
export default ComponenteOpcionesFiltroDisparador;
