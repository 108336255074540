import React from "react";
import { Form, Input, InputNumber, Select } from "antd";
import { store } from "../../../../../../store";

const CompararCamposListado = (props) => {
  function renderSelect(campo, index) {
    return (
      <>
        <Select.Option
          key={index}
          value={campo.NombreCampo + "-" + campo.tipoCampo}
        >
          {campo.NombreCampo + "-" + campo.tipoCampo}
        </Select.Option>
      </>
    );
  }

  return (
    <>
      <Form.Item shouldUpdate noStyle>
        {(formulario) => {
          return (
            <>
              <Form.Item
                name={props.nombreCampo}
                label={props.labelNombreCampo}
                rules={[
                  {
                    required: true,
                    message: "Debes seleccionar una opción",
                  },
                ]}
              >
                <Select
                  placeholder="Seleccionar una opción"
                  onChange={() => {
                    if (formulario.getFieldValue(props.valorCampo) != "") {
                      formulario.setFieldsValue({ [props.valorCampo]: "" });
                    }
                  }}
                >
                  {formulario.getFieldsValue()[props.dependencia] &&
                    store.listados
                      .find(
                        (e) =>
                          e._id ==
                          formulario.getFieldsValue()[props.dependencia]
                      )
                      .campos.map((campo, index) => {
                        return (
                          <>
                            {(campo.tipoCampo == props.filtro ||
                              props.filtro == "todos") &&
                              renderSelect(campo, index)}
                          </>
                        );
                      })}
                </Select>
              </Form.Item>
            </>
          );
        }}
      </Form.Item>
      <Form.Item
        name={props.operacionRelacional}
        label={props.labelOperacionRelacional}
        rules={[
          {
            required: true,
            message: "Debes seleccionar una opción del listado",
          },
        ]}
      >
        <Select placeholder="Seleccionar una opción">
          <Select.Option value="$gt">Mayor que</Select.Option>
          <Select.Option value="$lt">Menor que</Select.Option>
          <Select.Option value="$gte">Mayor igual que</Select.Option>
          <Select.Option value="$lte">Menor igual que</Select.Option>
          <Select.Option value="$eq">Igual a</Select.Option>
          <Select.Option value="$ne">Diferente de</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {(formulario) => {
          if (!formulario.getFieldsValue()[props.nombreCampo]) {
            return null;
          }

          return (
            <>
              {formulario.getFieldsValue()[props.nombreCampo] &&
                formulario
                  .getFieldsValue()
                  [props.nombreCampo].slice(
                    formulario
                      .getFieldsValue()
                      [props.nombreCampo].indexOf("-") + 1
                  ) == "numero" && (
                  <Form.Item
                    name={props.valorCampo}
                    label={props.labelValorCampo}
                    rules={[
                      {
                        required: true,
                        message: "Debes llenar este campo",
                      },
                    ]}
                  >
                    <InputNumber placeholder="Ingresa un número" />
                  </Form.Item>
                )}
              {formulario.getFieldsValue()[props.nombreCampo] &&
                formulario
                  .getFieldsValue()
                  [props.nombreCampo].slice(
                    formulario
                      .getFieldsValue()
                      [props.nombreCampo].indexOf("-") + 1
                  ) == "texto" && (
                  <Form.Item
                    name={props.valorCampo}
                    label={props.labelValorCampo}
                    rules={[
                      {
                        required: true,
                        message: "Debes llenar este campo",
                      },
                    ]}
                  >
                    <Input placeholder="Ingresa un dato" />
                  </Form.Item>
                )}

              {formulario
                .getFieldsValue()
                [props.nombreCampo].slice(
                  formulario.getFieldsValue()[props.nombreCampo].indexOf("-") +
                    1
                ) == "multiple" && (
                <Form.Item
                  name={props.valorCampo}
                  label={props.labelValorCampo}
                  rules={[
                    {
                      required: true,
                      message: "Debes seleccionar una opción",
                    },
                  ]}
                >
                  <Select placeholder="Selecciona una opción">
                    {store.listados
                      .find((lis) => {
                        return lis._id == formulario.getFieldsValue().idListado;
                      })
                      .campos.find((camp) => {
                        return (
                          camp.NombreCampo ==
                          formulario
                            .getFieldsValue()
                            .idCampo.slice(
                              0,
                              formulario.getFieldsValue().idCampo.indexOf("-")
                            )
                        );
                      })
                      .opciones.map((op, index) => {
                        return (
                          <>
                            <Select.Option key={index} value={op.opcion}>
                              {op.opcion}
                            </Select.Option>
                          </>
                        );
                      })}
                  </Select>
                </Form.Item>
              )}
            </>
          );
        }}
      </Form.Item>
    </>
  );
};

export default CompararCamposListado;
