import React from "react";
import { useParams } from "react-router-dom";
import {  store } from "../../store";
import FlujoIndividual from "./FlujoIndividual";

function ListasFlujos(props) {
  const [flujos, setFlujos] = React.useState([{ id: "2342" }, { id: "23423" }]);

  const idtablero = useParams().id;

  return (
    <div>
      {flujos.length > 0
        ? flujos.map((fl) => {
            return <FlujoIndividual key={fl.id} />;
          })
        : !store.pensando && (
            <div style={{ marginTop: 30, textAlign: "center" }}>
              <span style={{ color: "rgb(83 100 131)" }}>
                No tienes flujos actualmente
              </span>
            </div>
          )}
    </div>
  );
}

export default ListasFlujos;
