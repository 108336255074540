import React from "react";
import { Card, Spin, Typography } from "antd";
import { FormProvider, useForm } from "react-hook-form";
import { Api } from "../../../../../api/configApi";
import { store } from "../../../../../store";
import { ButtonT } from "../../../../../plugins/Formularios/FormularioTecclas";

const DesignWidgetEncuestas = (props) => {
  const [widget, setWidget] = React.useState({});
  const [boll, setBoll] = React.useState(true);
  const [respuestaBackend, setRespuestaBackend] = React.useState("");
  const [totalPeso, setTotalPeso] = React.useState(0);
  const methods = useForm();
  const {
    formState: { errors },
  } = methods;
  const onSubmit = (values) => {
    const valuesArr = Object.values(values);
    let pesoTotal = 0;
    valuesArr.forEach((ele) => {
      if (ele) {
        if (ele.peso) {
          if (ele.respuesta.trim() === "") {
            pesoTotal += 0;
          } else {
            pesoTotal += Number(ele.peso);
          }
        } else if (typeof ele === "string") {
          pesoTotal += JSON.parse(ele).peso;
        } else if (ele.respuesta[0]) {
          ele?.respuesta.forEach((checked) => {
            if (checked.checked) {
              pesoTotal += Number(checked.peso);
            }
          });
        }
      }
    });
    setTotalPeso((x) => (x = pesoTotal));
    let objEnviar = {
      valores: values,
      pesoTotal: pesoTotal,
      _idWidget: widget._id,
    };
    enviarDatos(objEnviar);
    actualizate();
  };
  function actualizate() {
    if (JSON.parse(widget.finalizarEncuesta).boll) {
      window.location.reload();
    } else {
      setBoll((x) => (x = JSON.parse(widget.finalizarEncuesta).boll));
    }
  }

  React.useEffect(() => {
    const widgetActual = store.widgets.find((wid) => {
      return wid._id === props._id;
    });

    setWidget(widgetActual);
  }, [props]);

  function enviarDatos(obj) {
    Api(
      "encuestas",
      "encuestasBackend",
      {},
      obj,
      "https://9d0e-181-132-3-197.ngrok.io"
    )
      .then((res) => {
        console.log(res);
        setRespuestaBackend(res.success);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className="widgetShadow">
      <div style={{ padding: "20px" }}>
        {widget?.preguntas?.length === 0 ? (
          <></>
        ) : !boll ? (
          <div
            className="site-card-border-less-wrapper"
            style={{ width: "100%" }}
          >
            {respuestaBackend === true ? (
              <Card title="Tu resultado es:">
                <Typography.Title>{totalPeso}</Typography.Title>
              </Card>
            ) : respuestaBackend === false ? (
              <Typography.Title style={{textAlign:"center"}} level={3}>
                Lo sentimos hubo un error al cargar tú resultado...
              </Typography.Title>
            ) : (
              <div style={{display:"flex", justifyContent:"center"}}>
                <Spin tip="Cargando..." />
              </div>
            )}
          </div>
        ) : (
          <FormProvider {...methods}>
            <div
              style={{
                width: "100%",
                height: "840px",
                overflowY: "scroll",
                display: "-ms-flexbox",
                flexWrap: "wrap",
                flexBasis: "auto",
              }}
            >
              <form
                onSubmit={methods.handleSubmit(onSubmit)}
                style={{
                  display: "grid",
                  flexWrap: "wrap",
                  width: "100%",
                }}
              >
                <h1 style={{ fontSize: "20px", fontWeight:"900", textAlign:"center" }}>{widget.titulo}</h1>
                <p
                  style={{
                    color: "gray",
                    fontWeight:"700",
                    textAlign:"center"
                  }}
                >
                  {widget.descripcion}
                </p>
                {widget.preguntas?.map((propiedades, index) => {
                  const vistaAfiliado = () => {
                    switch (propiedades.tipoRespuestas) {
                      case "numero":
                        return (
                          <>
                            <div style={{ display: "flex", flexWrap: "wrap", flexDirection:"column", marginTop:"20px", marginBottom:"20px" }}>
                              <div style={{ margin: "4px" }}>
                                <label style={{fontWeight:"500"}}>{`${index + 1}. ${
                                  propiedades.pregunta
                                }:`}</label>
                              </div>
                              <input
                                style={{
                                  border: "thin solid rgb(118 118 118)",
                                  borderRadius: "3px",
                                  color: "black",
                                  width: "300px",
                                }}
                                type="number"
                                {...methods.register(`${index}.respuesta`, {
                                  required: JSON.parse(propiedades.obligatoria)
                                    .boll,
                                })}
                              />
                              {errors[index] && errors[index].respuesta && (
                                <span style={{ color: "rgb(255, 0, 0 )" }}>
                                  Escribe la respuestaa.
                                </span>
                              )}
                            </div>

                            <input
                              type="number"
                              hidden
                              value={propiedades.pesoPregAbierta}
                              {...methods.register(`${index}.peso`)}
                            />
                          </>
                        );
                      case "texto":
                        return (
                          <>
                            <div style={{ display: "flex", flexWrap: "wrap", flexDirection:"column", marginTop:"20px", marginBottom:"20px" }}>
                              <div style={{ margin: "4px" }}>
                                <label style={{fontWeight:"500"}}>{`${index + 1}. ${
                                  propiedades.pregunta
                                }:`}</label>
                              </div>
                              <input
                                style={{
                                  border: "thin solid rgb(118 118 118)",
                                  borderRadius: "3px",
                                  color: "black",
                                  width: "300px",
                                }}
                                type="texto"
                                {...methods.register(`${index}.respuesta`, {
                                  required: JSON.parse(propiedades.obligatoria)
                                    .boll,
                                })}
                              />
                              {errors[index] && errors[index].respuesta && (
                                <span style={{ color: "rgb(255, 0, 0 )" }}>
                                  Escribe la respuesta.
                                </span>
                              )}
                            </div>
                            <input
                              type="number"
                              hidden
                              value={propiedades.pesoPregAbierta}
                              {...methods.register(`${index}.peso`)}
                            />
                            <br />
                          </>
                        );
                      case "unico":
                        return (
                          <>
                            <div
                              style={{
                                display: "grid",
                                flexWrap: "wrap",
                                textAlign: "justify",
                                marginTop:"20px", marginBottom:"20px",
                              }}
                            >
                              <div style={{ margin: "4px" }}>
                                <label style={{fontWeight:"500"}}>{`${index + 1}. ${
                                  propiedades.pregunta
                                }:`}</label>
                              </div>
                              <div
                                style={{ display: "grid", flexWrap: "wrap" }}
                              >
                                {propiedades.respuestas.map(
                                  (respuestasAdm, indexHijo) => {
                                    return (
                                      <>
                                        <div
                                          style={{
                                            margin: "4px",
                                            display: "flex",
                                            alignItems: "center",
                                            padding: "2px",
                                          }}
                                        >
                                          <input
                                            style={{marginRight:"20px"}}
                                            type="radio"
                                            value={
                                              `{"respuesta":"${respuestasAdm.respuesta}", "peso":${respuestasAdm.pesoRespuesta}}` ||
                                              ""
                                            }
                                            {...methods.register(`${index}`, {
                                              required: JSON.parse(
                                                propiedades.obligatoria
                                              ).boll,
                                            })}
                                          />
                                          <label>{`${respuestasAdm.respuesta}.`}</label>
                                        </div>
                                      </>
                                    );
                                  }
                                )}
                                <div
                                  style={{
                                    margin: "4px",
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "2px",
                                  }}
                                >
                                  {errors[index] && (
                                    <span style={{ color: "rgb(255, 0, 0 )" }}>
                                      Selecciona una opción.
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      case "multiple":
                        return (
                          <>
                            <div
                              style={{ display: "grid", textAlign: "justify", marginTop:"20px", marginBottom:"20px" }}
                            >
                              <div style={{ margin: "4px" }}>
                                <label style={{fontWeight:"500"}}>{`${index + 1}. ${
                                  propiedades.pregunta
                                }:`}</label>
                              </div>
                              <div>
                                <div style={{ display: "grid" }}>
                                  {propiedades.respuestas.map(
                                    (respuestasAdm, indexHijo) => {
                                      return (
                                        <>
                                          <div
                                            style={{
                                              margin: "4px",
                                              display: "flex",
                                              alignItems: "center",
                                              padding: "2px",
                                            }}
                                          >
                                            <input
                                              type="checkbox"
                                              style={{marginRight:"20px"}}
                                              {...methods.register(
                                                `${index}.respuesta.${indexHijo}.checked`
                                              )}
                                            />
                                            <input
                                              hidden
                                              value={
                                                respuestasAdm.pesoRespuesta
                                              }
                                              {...methods.register(
                                                `${index}.respuesta.${indexHijo}.peso`
                                              )}
                                            />
                                            <input
                                              hidden
                                              value={respuestasAdm.respuesta}
                                              {...methods.register(
                                                `${index}.respuesta.${indexHijo}.respuesta`
                                              )}
                                            />
                                            <label>{`${respuestasAdm.respuesta}.`}</label>
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      default:
                        return <></>;
                    }
                  };
                  return <>{vistaAfiliado()}</>;
                })}
                <div>
                  <ButtonT
                    control={methods.control}
                    type="primary"
                    htmlType="submit"
                  >
                    Enviar encuesta
                  </ButtonT>
                  {/* <button type="submit">Enviar encuesta</button> */}
                </div>
              </form>
            </div>
          </FormProvider>
        )}
      </div>
    </div>
  );
};

export default DesignWidgetEncuestas;
