import React from "react";
import { Card, Image, message, Tag } from "antd";
import { Delete, EditOutlined } from "@material-ui/icons";
import Meta from "antd/lib/card/Meta";
import Modal from "antd/lib/modal/Modal";
import { Api } from "../../../../../api/configApi";
function ListaDeRecursos({
  recursos,
  ObtenerLosRecursos,
  widget,
  setDrawerRecurso,
  setActualizarRecurso,
}) {
  const [previewRecurso, setPreviewRecurso] = React.useState(false);
  const [recursoActual, setRecursoActual] = React.useState({ tipoVideo: "" });
  function eliminarRecurso(rec) {
    Api("recursos", "Delete", { _id: rec._id }).then((res) => {
      if (res.success) {
        ObtenerLosRecursos(widget);
        message.success("Recurso eliminado");
      } else {
        message.error("No pudimos eliminar el recurso");
      }
    });
  }

  return (
    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
      <Modal
        style={{ backgroundColor: "#fff0" }}
        visible={previewRecurso}
        destroyOnClose={true}
        onCancel={() => {
          setPreviewRecurso(false);
        }}
        footer={[]}
      >
        <>
          {recursoActual.tipoVideo === "youtube" && (
            <iframe
              style={{ width: "100%", height: 300 }}
              src={
                "https://www.youtube.com/embed/" +
                recursoActual.link.split("=")[1].split("&")[0]
              }
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          )}
          {recursoActual.tipoVideo === "vimeo" && (
            <iframe
              src="https://player.vimeo.com/video/583150507"
              width="100%"
              height="300"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              title="video"
            ></iframe>
          )}
          {recursoActual.tipoVideo === "mp4" && (
            <video width="100%" controls={true} src={recursoActual.link} />
          )}
          {recursoActual.tipo === "audio" && (
            <audio
              style={{ width: "100%" }}
              controls={true}
              type="audio/mp3"
              src={recursoActual.link}
            />
          )}
        </>
      </Modal>
      {recursos.map((recurso) => {
        return (
          <Card
            style={{
              minWidth: 200,
              maxWidth: 250,
              margin: 10,
              flexGrow: 1,
              background: "#fbfbfb",
            }}
            cover={
              <>
                <Tag
                  style={{
                    position: "absolute",
                    top: 3,
                    left: 3,
                    width: "auto",
                    zIndex: 1,
                  }}
                  color="magenta"
                >
                  {recurso.tipo}
                </Tag>
                {recurso.tipo === "imagen" && (
                  <Image alt={recurso.titulo} src={recurso.link} />
                )}
                {(recurso.tipo === "audio" || recurso.tipo === "video") && (
                  <div
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      padding: 10,
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 20,
                    }}
                    onClick={() => {
                      setRecursoActual(recurso);
                      setPreviewRecurso(true);
                    }}
                  >
                    <img
                      width={100}
                      alt="audio"
                      src="https://octopusmultinivel.b-cdn.net/octopus10/play%20(2).svg"
                    />
                  </div>
                )}
                {recurso.tipo === "pdf" && (
                  <div
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      padding: 10,
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 20,
                    }}
                    onClick={() => {
                      window.open(recurso.link, "_blank");
                    }}
                  >
                    <img
                      width={100}
                      alt="pdf"
                      src="https://octopusmultinivel.b-cdn.net/octopus10/pdf.svg"
                    />
                  </div>
                )}
              </>
            }
            actions={[
              <EditOutlined
                onClick={() => {
                  setActualizarRecurso(recurso);
                  setDrawerRecurso(true);
                }}
                key="edit"
              />,
              <Delete
                onClick={() => {
                  eliminarRecurso(recurso);
                }}
                key="delete"
              />,
            ]}
          >
            <Meta title={recurso.titulo} description={recurso.descripcion} />
          </Card>
        );
      })}
    </div>
  );
}

export default ListaDeRecursos;
