import { Select } from "antd";
import React from "react";
import { store } from "../../../../store";

function AgregarFilaNuevaComponente(props) {
  const { Option } = Select;
  const [opacidad, setOpacidad] = React.useState(0.5);
  function onChange(value) {
    props.setWidgetNuevo(store.widgets[value]);
  }

  return (
    <div
      onMouseEnter={() => {
        setOpacidad(1);
      }}
      onMouseLeave={() => {
        setOpacidad(0.5);
      }}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 50,
        opacity: opacidad,
      }}
    >
      <strong style={{ fontSize: 20 }}>Nueva Fila</strong>
      <p>Agrega un widget a la nueva fila</p>
      <Select
        showSearch
        defaultActiveFirstOption={false}
        style={{ width: 300 }}
        placeholder="Nuevo Widget"
        optionFilterProp="children"
        onSelect={onChange}
      >
        {store.widgets.map((widget, index) => {
          return <Option value={index}>{widget.nombre}</Option>;
        })}
      </Select>
    </div>
  );
}

export default AgregarFilaNuevaComponente;
