import { Button, Drawer, Popover } from "antd";
import React from "react";
import { Api } from "../../../../../api/configApi";
import FormularioModLeccion from "../Formularios/FormularioModLeccion";

const ContenedorLecciones = (props) => {
  const [visiblePop, setVisiblePop] = React.useState(false);
  const [visibleModLeccion, setVisibleModLeccion] = React.useState(false);

  function eliminarLeccion() {
    Api(
      "academia",
      "eliminarLeccion",
      { idWidget: props.idWidget._id },
      { infoCurso: props.dataCurso, infoLeccionEliminar: props.data },
      "https://ed3a-181-132-3-197.ngrok.io"
    )
      .then((res) => {
        props.obtenerLecciones()
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const showDrawerModLeccion = () => {
    setVisibleModLeccion(true);
  };

  const onCloseModLeccion = () => {
    setVisibleModLeccion(false);
  };

  const hide = () => {
    setVisiblePop(false);
  };

  const handleVisibleChange = (newVisible) => {
    setVisiblePop(newVisible);
  };

  React.useEffect(() => {}, [props]);

  return (
    <>
      <div
        style={{
          padding: "10px",
          backgroundColor: "#fff",
          borderRadius: "10px",
          margin: "10px 30px 10px 10px",
          boxShadow: "0 0px 10px -6px "
        }}
      >
        <div style={{ borderRadius:"5px", marginBottom:"5px", padding:"10px"}}>
          <h3 style={{fontWeight:600, margin:"0 0 5px 0"}}>{props.tituloLeccion}</h3>
          <p style={{margin:0}}>{props.descripcionLeccion}</p>
        </div>
        <hr style={{width:"95%", border:"1px solid #f5f5f5"}}/>
        <div style={{ display: "flex", justifyContent: "center",  borderRadius:"5px", padding:"5px 0 5px 0" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "70%",
            }}
          >
            <Button style={{ cursor: "pointer" }} onClick={showDrawerModLeccion}>
              Modificar
            </Button>
            <Popover
              content={
                <>
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <Button
                      type="secondary"
                      onClick={() => {
                        hide();
                        eliminarLeccion();
                      }}
                    >
                      Eliminar
                    </Button>
                    <Button type="secondary" onClick={hide}>
                      Cancelar
                    </Button>
                  </div>
                </>
              }
              title="¿Estás seguro de que deseas eliminar el curso?"
              trigger="click"
              visible={visiblePop}
              onVisibleChange={handleVisibleChange}
            >
              <Button type="secondary" danger style={{ cursor: "pointer" }}>Eliminar</Button>
            </Popover>
          </div>
        </div>
      </div>
      <Drawer
        title="Modificar lección"
        placement="right"
        closable={false}
        // onClose={onCloseModLeccion}
        visible={visibleModLeccion}
      >
        <FormularioModLeccion
          dataCurso={props.dataCurso}
          idWidget={props.idWidget}
          data={props.data}
          setVisible={setVisibleModLeccion}
          obtenerLecciones= {props.obtenerLecciones}
        ></FormularioModLeccion>
      </Drawer>
    </>
  );
};

export default ContenedorLecciones;
