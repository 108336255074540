import { Form, Input, Button, message } from "antd";
import React from "react";
import { Api } from "../../api/configApi";
import {
  setPensando,
  setStoreActualizarLaEstructura,
  store,
} from "../../store";
import Boton from "../design/elementos/boton";

function CamposRequeridosModuloComponente({ modulo, setVisible, actualizar }) {
  const [form] = Form.useForm();
  const [loscampos, setLoscampos] = React.useState([]);
  React.useEffect(() => {
    setLoscampos(modulo.campos);
  }, []);
  function instalarModulo(values) {
    setPensando(true);
    if (actualizar) {
      Api(
        "modulosDisponibles",
        "ActualizarModulo",
        { cuenta: store.cuentaactiva },
        { idmodulo: modulo._id, campos: values.campos }
      )
        .then((res) => {
          message.success("Modulo Actualizado");
          setPensando(false);
          setVisible(false);
          setStoreActualizarLaEstructura();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      Api(
        "modulosDisponibles",
        "InstalarModulo",
        { cuenta: store.cuentaactiva },
        { idmodulo: modulo._id, campos: values.campos }
      )
        .then((res) => {
          message.success("Modulo Instalado");
          setPensando(false);
          setVisible(false);
          setStoreActualizarLaEstructura();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  return (
    <div>
      <Form onFinish={instalarModulo} layout="vertical" form={form}>
        <Form.List name="campos">
          {(fields, { add, remove }) => (
            <Form.Item>
              {loscampos.map((ca, index) => {
                return (
                  <div key={index}>
                    <Form.Item
                      initialValue={ca.value}
                      rules={[
                        {
                          required: ca.requerido,
                          message: "Campo obligatorio",
                        },
                      ]}
                      key={ca.id}
                      name={ca.name}
                      label={ca.label}
                    >
                      <Input placeholder={ca.label} />
                    </Form.Item>
                  </div>
                );
              })}
            </Form.Item>
          )}
        </Form.List>
        <Boton
          disabled={store.pensando}
          type="primary"
          htmlType="submit"
          texto={actualizar ? "Actualizar Modulo" : "Instalar Modulo"}
        />
      </Form>
    </div>
  );
}
export default CamposRequeridosModuloComponente;
