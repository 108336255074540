import { Form, Input, InputNumber, Select, Space } from "antd";
import { store } from "../../../../../store";

function CamposFormAccionesMultiple(props) {
  const { key, name, restField } = props;
  return (
    <div key={key}>
      {props.accionActual.campos.map((e) => (
        <div key={e.name}>
          {e.tipo === "listados" && (
            <Form.Item
              name={[name, e.name]}
              label={e.label}
              rules={[{ required: e.requerido, message: "Campo obligatorio" }]}
              key={e.name}
              {...restField}
            >
              <Select>
                {store.listados.map((e) => (
                  <Select.Option key={e._id} value={e._id}>
                    {e.nombre}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {e.tipo === "select" && (
            <Form.Item
              {...restField}
              name={[name, e.name]}
              label={e.label}
              rules={[{ required: e.requerido, message: "Campo obligatorio" }]}
              key={e.name}
            >
              <Select>
                {e.opciones.map((e) => (
                  <Select.Option key={e} value={e}>
                    {e}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {e.tipo === "variableAtributo" && (
            <Form.Item
              {...restField}
              name={[name, e.name]}
              label={e.label}
              rules={[{ required: e.requerido, message: "Campo obligatorio" }]}
              key={e.name}
            >
              <Select>
                {props.disparador.variablesDisparador &&
                  props.disparador.variablesDisparador
                    .filter((e) => e.tipoVariable === "atributoNumero")
                    .map((e) => (
                      <Select.Option
                        key={e.nombreVariable}
                        value={e.nombreVariable}
                      >
                        {e.nombreVariable}
                      </Select.Option>
                    ))}
              </Select>
            </Form.Item>
          )}

          {e.tipo === "condicionalCampoMultiple" && (
            <>
              <Form.Item shouldUpdate noStyle key={e.name}>
                {({ getFieldValue, getFieldsValue }) => {
                  if (!getFieldsValue()?.datos) return;
                  if (!getFieldsValue()?.datos[name]) return;

                  var xx = getFieldsValue().datos[name][e.dependencia];
                  return (
                    <>
                      <p>Filtrar por</p>
                      <Space>
                        <Form.Item
                          {...restField}
                          name={[name, e.name, "campo"]}
                          rules={[
                            {
                              required: e.requerido,
                              message: "Campo obligatorio",
                            },
                          ]}
                          key={e.name + "valor"}
                        >
                          <Select style={{ minWidth: 110 }}>
                            {store.listados.find((list) => list._id === xx) &&
                              store.listados
                                .find((list) => list._id === xx)
                                .campos?.filter(
                                  (a) => a.tipoCampo === "multiple"
                                )
                                .map((e) => (
                                  <Select.Option
                                    value={e.NombreCampo}
                                    key={e.NombreCampo}
                                  >
                                    {e.NombreCampo}
                                  </Select.Option>
                                ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, e.name, "condicional"]}
                          rules={[
                            {
                              required: e.requerido,
                              message: "Campo obligatorio",
                            },
                          ]}
                          key={e.name + "valor"}
                        >
                          <Select style={{ minWidth: 170 }}>
                            <Select.Option value="cualquiera">
                              Igual o diferente a
                            </Select.Option>
                            <Select.Option value="$eq">Igual a</Select.Option>
                            <Select.Option value="$ne">
                              Diferente a
                            </Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, e.name, "valor"]}
                          rules={[
                            {
                              required: e.requerido,
                              message: "Campo obligatorio",
                            },
                          ]}
                          key={e.name + "valor"}
                        >
                          <Select style={{ minWidth: 150 }}>
                            {store.listados.find((list) => list._id === xx) &&
                              [
                                ...(store.listados
                                  .find((list) => list._id === xx)
                                  .campos?.find(
                                    (a) =>
                                      a.NombreCampo ===
                                      props.form.getFieldsValue().datos[name][
                                        e.name
                                      ]?.campo
                                  )?.opciones || []),
                              ].map((e) => (
                                <Select.Option value={e.opcion} key={e.opcion}>
                                  {e.opcion}
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Space>
                    </>
                  );
                }}
              </Form.Item>
            </>
          )}
          {e.tipo === "seleccionarOpcionCampo" && (
            <>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue, getFieldsValue }) => {
                  if (!getFieldsValue()?.datos) return;
                  if (!getFieldsValue()?.datos[name]) return;
                  if (!getFieldsValue()?.datos[name][e.dependencia]) return;
                  var xx = getFieldsValue().datos[name][e.dependencia];

                  return (
                    <>
                      <p>{e.label}</p>
                      <Space>
                        <Form.Item
                          {...restField}
                          name={[name, e.name, "campo"]}
                          rules={[
                            {
                              required: e.requerido,
                              message: "Campo obligatorio",
                            },
                          ]}
                        >
                          <Select style={{ minWidth: 110 }}>
                            {store.listados.find((list) => list._id === xx) &&
                              store.listados
                                .find((list) => list._id === xx)
                                .campos?.filter(
                                  (a) => a.tipoCampo === "multiple"
                                )
                                .map((e) => (
                                  <Select.Option
                                    value={e.NombreCampo}
                                    key={e.NombreCampo}
                                  >
                                    {e.NombreCampo}
                                  </Select.Option>
                                ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, e.name, "valor"]}
                          rules={[
                            {
                              required: e.requerido,
                              message: "Campo obligatorio",
                            },
                          ]}
                          key={e.name + "valor"}
                        >
                          <Select style={{ minWidth: 150 }}>
                            {store.listados.find((list) => list._id === xx) &&
                              [
                                ...(store.listados
                                  .find((list) => list._id === xx)
                                  .campos?.find(
                                    (a) =>
                                      a.NombreCampo ===
                                      props.form.getFieldsValue().datos[name][
                                        e.name
                                      ]?.campo
                                  )?.opciones || []),
                              ].map((e) => (
                                <Select.Option value={e.opcion} key={e.opcion}>
                                  {e.opcion}
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Space>
                    </>
                  );
                }}
              </Form.Item>
            </>
          )}

          {e.tipo === "campos" && (
            <Form.Item shouldUpdate noStyle key={e.name}>
              {({ getFieldValue, getFieldsValue }) => {
                if (!getFieldsValue()?.datos) return;
                if (!getFieldsValue()?.datos[name]) return;
                if (!getFieldsValue()?.datos[name][e.dependencia]) return;
                var xx = getFieldsValue().datos[name][e.dependencia];
                return (
                  <Form.Item
                    {...restField}
                    name={[name, e.name]}
                    label={e.label}
                    rules={[
                      { required: e.requerido, message: "Campo obligatorio" },
                    ]}
                    key={e.name}
                  >
                    <Select>
                      {store.listados.find((list) => list._id === xx) &&
                        store.listados
                          .find((list) => list._id === xx)
                          .campos?.filter((a) =>
                            e.tipoCampo === "todos"
                              ? true
                              : a.tipoCampo === e.tipoCampo
                          )
                          .map((e) => (
                            <Select.Option
                              value={e.NombreCampo}
                              key={e.NombreCampo}
                            >
                              {e.NombreCampo}
                            </Select.Option>
                          ))}
                    </Select>
                  </Form.Item>
                );
              }}
            </Form.Item>
          )}
          {e.tipo === "numero" && (
            <Form.Item
              {...restField}
              name={[name, e.name]}
              label={e.label}
              rules={[{ required: e.requerido, message: "Campo obligatorio" }]}
              key={e.name}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          )}
          {e.tipo === "texto" && (
            <Form.Item
              {...restField}
              name={[name, e.name]}
              label={e.label}
              rules={[{ required: e.requerido, message: "Campo obligatorio" }]}
              key={e.name}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          )}
        </div>
      ))}
    </div>
  );
}

export default CamposFormAccionesMultiple;
